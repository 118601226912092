import React from "react";

const TrackingCard = ({
  children,
  id,
  cardClass = "card card-body mt-3",
  titleClass = "row mb-3",
  labelClass = "",
  title,
  switchComponent,
  helpText,
}) => {
  return (
    <div id={id}>
      <div className={cardClass}>
        <div className={titleClass}>
          <label className={labelClass}>
            <b>{title}</b>
          </label>
          {switchComponent && switchComponent}
          {helpText !== "" && (
            <small id={id + "help"} className="form-text text-muted">
              {helpText}
            </small>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default TrackingCard;
