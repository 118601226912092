import React from "react";

const NumberField = ({
  number,
  label,
  placeholder,
  handleChange,
  min,
  max,
  disabled,
  isValid = true,
  plainText = false,
}) => {
  return (
    <>
      <input
        type="number"
        className={
          plainText
            ? `form-control-plaintext col-sm-2 m-2 ${
                isValid ? "" : "selector-invalid"
              }`
            : `form-control col-sm-2 m-2 ${isValid ? "" : "selector-invalid"}`
        }
        min={min}
        max={max}
        value={number}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          if (typeof handleChange === "function") {
            handleChange(e);
          }
        }}
      />
      <label>{label}</label>
    </>
  );
};

export default NumberField;
