/*global chrome*/
import React, { useEffect, useState } from "react";
import Alert from "../../../../components/share/Alert";
import Loader from "../../../../components/share/Loader";
import config from "../../../../config/config.json";
import uiApi from "../../../../api/api-ui";

const extensionList = config.extensionList;

const sendTokenToChromeExtension = ({ extensionId, token }) => {
  chrome.runtime.sendMessage(extensionId, { token }, (response) => {
    if (chrome.runtime.lastError) {
      console.log("It's fine, this extension ID in the list is not active");
    }
    // return true;
  });
};

const TokenGenerator = () => {
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  useEffect(() => {
    uiApi
      .generateToken()
      .then((token) => {
        // Send token to all whitelisted extension ids
        extensionList.map((extensionId) => {
          return sendTokenToChromeExtension({
            extensionId,
            token,
          });
        });
        setAlert({
          show: true,
          type: "success",
          message:
            "Good news! You have signed in to the Insightech Chrome Extension successfully. Please close this tab and start analysing your web pages.",
          count: alert.count + 1,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {alert.show ? (
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TokenGenerator;
