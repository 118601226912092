import React from "react";
import "./style.scss";

const Popup = ({ popupRef, handleClosePopup, description, url }) => {
  return (
    <div className="popup" ref={popupRef}>
      <div className="popup-content">
        <span className="close-button" onClick={handleClosePopup}>
          &times;
        </span>
        <p>{description}</p>
        {url && (
          <a href={url} target="_blank">
            {url}
          </a>
        )}
      </div>
    </div>
  );
};

export default Popup;
