import React, { useState, useEffect } from "react";
import { fetchProfile } from "../../../../lib/firebase/profile";
import PathingBuilder from "../../../../components/app/PathingBuilder";
import { withRouter } from "react-router";
import "./style.scss";

const PathingRules = (props) => {
  const profileId = props.match.params.profileId;
  const [loading, setLoading] = useState(true);
  const [pathingSteps, setPathingSteps] = useState([
    { name: "", filters: { operator: "", values: [""] } },
  ]);

  useEffect(() => {
    fetchProfile({ profileId }).then((res) => {
      const updatedFilters = [];
      if (res.data.data.pathRules && res.data.data.pathRules.length !== 0) {
        res.data.data.pathRules.forEach((pathRule) => {
          updatedFilters.push({
            name: pathRule.name,
            filters: JSON.parse(pathRule.filters),
          });
        });

        setPathingSteps(updatedFilters);
      }
      setLoading(false);
    });
  }, [profileId]);

  return loading ? (
    <div className="rules-loader">
      <i className="fa fa-spinner fa-4x fa-spin"></i>
    </div>
  ) : (
    <PathingBuilder
      pathingSteps={pathingSteps}
      setPathingSteps={setPathingSteps}
    />
  );
};

export default withRouter(PathingRules);
