import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { UserDataContext } from "./../../../../components/app/UserData";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import ReactTablePagination from "./../../../../components/app/ReactTablePagination";
import { fetchUsersByProfileId } from "./../../../../lib/firebase/user";
import { fetchProfileById } from "./../../../../lib/firebase/profile";
import moment from "moment";
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import "./style.scss";

function Users(props) {
  const profileId = props.match.params.profileId;

  const { profileUsers, setProfileUsers } = useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  let searchTimeout = null;

  useEffect(() => {
    if (!authUser.isAdmin) {
      props.history.push("/");
    }

    // Clear timeout when component is unmounted
    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  useEffect(() => {
    setIsLoading(true);

    const asyncCalls = [];

    asyncCalls.push(fetchProfileById(profileId));
    asyncCalls.push(fetchUsersByProfileId(profileId, true));

    Promise.all(asyncCalls).then((res) => {
      // Profile Data
      const adminIds = [];
      const profileData = res[0].docs[0].data();
      profileData.admins.forEach((admin) => {
        adminIds.push(admin.path);
      });

      // Users Data
      if (!profileUsers.all.length) {
        const users = {
          byId: {},
          all: [],
        };
        const tableData = [];
        res[1].forEach((doc) => {
          const user = doc;
          users.byId[doc.id] = user;
          users.all.push(doc.id);
          if (user.lastAccessTimestamp) {
            user.lastLoginTimestamp = user.lastAccessTimestamp;
          }
          if (adminIds.indexOf(`users/${user.id}`) > -1) {
            user.permission = "Admin";
          } else {
            user.permission = "Viewer";
          }

          tableData.push(user);
        });
        setProfileUsers(users);
        setData(tableData);
      } else {
        const tableData = [];
        res[1].forEach((doc) => {
          const user = doc;
          if (user.lastAccessTimestamp) {
            user.lastLoginTimestamp = user.lastAccessTimestamp;
          }
          if (adminIds.indexOf(`users/${user.id}`) > -1) {
            user.permission = "Admin";
          } else {
            user.permission = "Viewer";
          }
          tableData.push(user);
        });
        setData(tableData);
      }
      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Permission",
        accessor: "permission",
      },
      {
        Header: "Last Active",
        accessor: "lastLoginTimestamp",
        Cell: ({ value }) => {
          return value ? moment(value).format("MMM D YYYY H:m:s") : "N/A";
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <Link
              className="btn btn-primary btn-sm"
              to={`/profile/${profileId}/admin/users/${value}`}
            >
              Edit User
            </Link>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [],
        sortBy: [{ id: "name", desc: false }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleChangeKeyword = (e) => {
    const value = e.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card shadow">
          <div className="card-body">
            <div className="mb-3 d-flex">
              <div className="form-inline mr-auto">
                <div className="form-group">
                  <input
                    placeholder="Keyword search"
                    className="form-control form-control-sm"
                    type="search"
                    onChange={handleChangeKeyword}
                  />
                </div>
              </div>
              <Link
                className="btn btn-primary btn-sm"
                to={`/profile/${profileId}/admin/users/add`}
              >
                Invite User
              </Link>
            </div>

            <table className="table table-hover ui-table users-table">
              <thead className=" thead-dark">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading && (
                  <tr>
                    <td colSpan="6" align="center">
                      Loading Users...
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} data-rowid={row.values.id}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <ReactTablePagination
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Users.propTypes = {};
Users.defaultProps = {};

export default Users;
