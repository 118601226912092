import React, { useEffect, useContext } from "react";
import { Route, withRouter } from "react-router-dom";
import { AuthContext } from "../auth/FirebaseAuthContext";
import Loader from "../share/Loader";
import { UserDataProvider } from "./UserData";
import { SegmentsProvider } from "./../../context/Segments";
import ReportWrapper from "./ReportWrapper";

const AppRouter = ({ component: Component, template: Template, ...rest }) => {
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    // Store the current url if user is not authenticated
    // and if the user is not signin or signup
    // before redirecting the user to the signin page
    if (
      authUser.checked &&
      !authUser.user &&
      !["/signin", "/signup"].includes(window.location.pathname)
    ) {
      sessionStorage.setItem("redirect", window.location.pathname);
      rest.history.push("/signin");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.checked]);

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        authUser.checked ? (
          !!authUser.user && (
            <UserDataProvider>
              <SegmentsProvider>
                {rest.type !== "report" ? (
                  <Template {...rest}>
                    <Component {...matchProps} />
                  </Template>
                ) : (
                  <ReportWrapper
                    template={Template}
                    rest={rest}
                    matchProps={matchProps}
                  />
                )}
              </SegmentsProvider>
            </UserDataProvider>
          )
        ) : (
          <Loader />
        )
      }
    />
  );
};

export default withRouter(AppRouter);
