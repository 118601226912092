import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

function Tag(props) {
  const { noDelete, text, variant } = props;
  let variantClass = "";
  switch (variant) {
    case "analytics":
      variantClass = "tag-analytics";
      break;
    default:
      variantClass = "";
  }

  return (
    <div className={`insight-ui-tag ${variantClass}`}>
      <div className="d-flex">
        <span className="text">{text}</span>
        {!noDelete && (
          <button className="btn-delete">
            <i className="fa fa-times-circle"></i>
          </button>
        )}
      </div>
    </div>
  );
}

Tag.propTypes = {
  text: PropTypes.string,
};
Tag.defaultProps = {};

export default Tag;
