import React from 'react'

import {
    signinWithGmail,
    signinWithMicrosoft,
    handleSuccessSignin,
} from './../../../lib/firebase';

function withAuthMethods(WrappedComponent) {

    return function(){
        const [signinError, setSigninError] = React.useState('');
        const [isBusy, setIsBusy] = React.useState(false);

        const handleAuthError = (error) => {
            switch (error.code) {
                case 'auth/popup-closed-by-user':
                case 'auth/cancelled-popup-request':
                    break;
                default:
                    setSigninError(error.message);
                    break;
            }
        }

        const handleGmailSignin = () => {
            setIsBusy(true);
            signinWithGmail((response) => {
                handleSuccessSignin(response, () => {
                    const redirect = sessionStorage.getItem('redirect');
                    if(redirect){
                        window.location = redirect;
                        return;
                    }
                    window.location = '/';
                })
            }, (error) => {
                setIsBusy(false);
                handleAuthError(error);
            })
        }

        const handleMicrosoftSignin = () => {
            setIsBusy(true);
            signinWithMicrosoft((response) => {
                handleSuccessSignin(response, () => {
                    const redirect = sessionStorage.getItem('redirect');
                    if (redirect) {
                        window.location = redirect;
                        return;
                    }
                    window.location = '/';
                })
            }, (error) => {
                setIsBusy(false);
                handleAuthError(error);
            })
        }

        return (
            <WrappedComponent
                isBusy={isBusy}
                setIsBusy={setIsBusy}
                signinError={signinError}
                setSigninError={setSigninError}
                handleGmailSignin={handleGmailSignin}
                handleMicrosoftSignin={handleMicrosoftSignin} />
        )
    }
}

export default withAuthMethods;