import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import {
  Form,
  Input,
  Textarea,
  Field,
  Switch,
  Button,
  Drawer,
} from "./../../share/InsightUI";
import { segmentFormValidator } from "./../../../helpers/formValidators";
import { initialConditions, UserDataContext } from "./../UserData";
import {
  deepCopy,
  arrayToReactSelectValue,
  seggregatePageContainers,
} from "./../../../utils";
import FilterPanel from "./../../app/FilterPanel";
import { saveSegment, fetchSegmentById } from "./../../../lib/firebase/segment";
import {
  includedPageContainersFixture,
  excludedPageContainersFixture,
} from "./../../../fixtures/filters";
import { ReducerContext } from "./../../../reducer/context";
import { ADD_TOAST } from "./../../../reducer/index";
import { FaLock } from "react-icons/fa";
import { FiUnlock } from "react-icons/fi";
import "./style.scss";
import { AuthContext } from "../../auth/FirebaseAuthContext";

function SegmentsDrawer(props) {
  const { profileUsers } = useContext(UserDataContext);
  const { dispatch } = useContext(ReducerContext);
  const { activeProfileId } = useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);
  const {
    setFocusedSegmentId,
    segmentId,
    isVisible,
    handleClose,
    handleClickDelete,
    segments,
    setSegments,
    listData,
    setListData,
    index,
  } = props;

  const [isCopy, setIsCopy] = useState(false);
  const [isFormSaving, setIsFormSaving] = useState(false);
  const [initialFormData, setInitialFormData] = useState({
    name: "",
    description: "",
    shared: false,
    default: false,
    tags: [],
  });
  const [isFiltersValid, setIsFiltersValid] = useState(false);
  const [invalidFields, setInvalidFields] = useState({
    userConditions: [],
    includePageContainers: [],
    excludePageContainers: [],
    total: 0,
  });
  const [userConditions, setUserConditions] = useState(
    deepCopy(initialConditions.userConditions)
  );
  const [includedPageContainers, setIncludedPageContainers] = useState(
    deepCopy(includedPageContainersFixture)
  );
  const [excludedPageContainers, setExcludedPageContainers] = useState(
    deepCopy(excludedPageContainersFixture)
  );

  let isSegmentEditor = true;
  let segmentCreatorId = null;
  listData
    ? (segmentCreatorId = listData[index]?.ownerId)
    : (segmentCreatorId = segments[segmentId]?.ownerId);
  authUser.user.uid === segmentCreatorId || authUser.isAdmin || !segmentId
    ? (isSegmentEditor = true)
    : (isSegmentEditor = false);

  const getInvalidFields = () => {
    const invalidFields = {
      userConditions: [],
      includedPageContainers: [],
      excludedPageContainers: [],
      total: 0,
    };
    userConditions.forEach((condition, index) => {
      if (!invalidFields.userConditions[index]) {
        invalidFields.userConditions[index] = {};
      }
      // Rule
      if (condition.attribute && !condition.operator) {
        invalidFields.userConditions[index] = {
          operator: true,
        };
        invalidFields.total++;
      }
    });
    includedPageContainers.forEach((container, containerIndex) => {
      if (!invalidFields.includedPageContainers[containerIndex]) {
        invalidFields.includedPageContainers[containerIndex] = {
          eventConditions: [],
        };
      }
      container.eventConditions.forEach((event, eventIndex) => {
        if (
          !invalidFields.includedPageContainers[containerIndex].eventConditions[
            eventIndex
          ]
        ) {
          invalidFields.includedPageContainers[containerIndex].eventConditions[
            eventIndex
          ] = {
            conditions: [],
          };
        }
        event.conditions.forEach((condition, conditionIndex) => {
          if (
            !invalidFields.includedPageContainers[containerIndex]
              .eventConditions[eventIndex].conditions[conditionIndex]
          ) {
            invalidFields.includedPageContainers[
              containerIndex
            ].eventConditions[eventIndex].conditions[conditionIndex] = {};
          }
          if (conditionIndex === 0) {
            // Rule
            if (event.type && !condition.attribute) {
              invalidFields.includedPageContainers[
                containerIndex
              ].eventConditions[eventIndex].conditions[
                conditionIndex
              ].attribute = true;
              invalidFields.total++;
            }
          }
          // Rule
          if (
            condition.attribute &&
            condition.attribute !== "Any" &&
            !condition.operator
          ) {
            invalidFields.includedPageContainers[
              containerIndex
            ].eventConditions[eventIndex].conditions[
              conditionIndex
            ].operator = true;
            invalidFields.total++;
          }
        });
      });
    });
    excludedPageContainers.forEach((container, containerIndex) => {
      if (!invalidFields.excludedPageContainers[containerIndex]) {
        invalidFields.excludedPageContainers[containerIndex] = {
          eventConditions: [],
        };
      }
      container.eventConditions.forEach((event, eventIndex) => {
        if (
          !invalidFields.excludedPageContainers[containerIndex].eventConditions[
            eventIndex
          ]
        ) {
          invalidFields.excludedPageContainers[containerIndex].eventConditions[
            eventIndex
          ] = {
            conditions: [],
          };
        }
        event.conditions.forEach((condition, conditionIndex) => {
          if (
            !invalidFields.excludedPageContainers[containerIndex]
              .eventConditions[eventIndex].conditions[conditionIndex]
          ) {
            invalidFields.excludedPageContainers[
              containerIndex
            ].eventConditions[eventIndex].conditions[conditionIndex] = {};
          }
          if (conditionIndex === 0) {
            // Rule
            if (event.type && !condition.attribute) {
              invalidFields.excludedPageContainers[
                containerIndex
              ].eventConditions[eventIndex].conditions[
                conditionIndex
              ].attribute = true;
              invalidFields.total++;
            }
          }
          // Rule
          if (
            condition.attribute &&
            condition.attribute !== "Any" &&
            !condition.operator
          ) {
            invalidFields.excludedPageContainers[
              containerIndex
            ].eventConditions[eventIndex].conditions[
              conditionIndex
            ].operator = true;
            invalidFields.total++;
          }
        });
      });
    });

    return invalidFields;
  };

  const handleClickClose = (e) => {
    // Reset form fields and filters
    setInitialFormData(deepCopy(initialFormData));

    setUserConditions(deepCopy(initialConditions.userConditions));
    setIncludedPageContainers(deepCopy(includedPageContainersFixture));
    setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
    setIsCopy(false);
    handleClose();
  };

  const handleSubmitForm = (values, { setSubmitting, resetForm }) => {
    setIsFormSaving(true);
    setSubmitting(false);

    const payload = {
      ...values,
      tags: values.tags.map((tag) => {
        return tag.value;
      }),
      profileId: activeProfileId,
      filter: JSON.stringify({
        userConditions: [...userConditions],
        pageContainers: [...includedPageContainers, ...excludedPageContainers],
      }),
    };

    payload["default"] = !payload["default"] ? false : true;
    payload["description"] = !payload["description"]
      ? ""
      : payload["description"];

    saveSegment(payload, activeProfileId, segmentId).then(async (res) => {
      setIsFormSaving(false);
      setSegments({
        ...segments,
        [res.id]: {
          ...payload,
          id: res.id,
        },
      });

      const updatedList = deepCopy(listData);
      if (segmentId) {
        // Edit
        updatedList[index] = {
          ...updatedList[index],
          ...payload,
        };
      } else {
        // Create
        const newSegment = await fetchSegmentById({
          profileId: activeProfileId,
          segmentId: res.id,
        });
        const newSegmentData = newSegment.data();
        newSegmentData.id = res.id;
        newSegmentData.creator = profileUsers.byId[newSegmentData.ownerId];
        updatedList.push(newSegmentData);
      }
      setListData(updatedList);
      resetForm({});

      setUserConditions(deepCopy(initialConditions.userConditions));
      setIncludedPageContainers(deepCopy(includedPageContainersFixture));
      setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
      setIsCopy(false);
      handleClose();
    });
  };

  const handleClickCopy = (e) => {
    const updatedData = deepCopy(initialFormData);
    updatedData.name = `Copy of ${updatedData.name}`;
    setInitialFormData(updatedData);
    setFocusedSegmentId(null);
    setIsCopy(true);

    dispatch({
      type: ADD_TOAST,
      messageType: "success",
      messageText:
        "The segment is copied successfully, please save the segment if you want to keep it",
    });
  };

  useEffect(() => {
    if (segmentId) {
      const segment = segments[segmentId];

      // Populate form fields
      setInitialFormData({
        name: segment.name,
        description: segment.description,
        shared: segment.shared,
        default: segment.default,
        tags: arrayToReactSelectValue(segment.tags),
      });

      // Populate filters
      const filters = JSON.parse(segment.filter);
      const [includedPageContainers, excludedPageContainers] =
        seggregatePageContainers(filters.pageContainers);
      setIncludedPageContainers(includedPageContainers);
      setExcludedPageContainers(excludedPageContainers);
      setUserConditions(filters.userConditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentId]);

  useEffect(() => {
    const invalidFields = getInvalidFields();
    setInvalidFields(invalidFields);
    setIsFiltersValid(!invalidFields.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedPageContainers, excludedPageContainers, userConditions]);

  return (
    <Drawer
      className="segments-drawer"
      title="Segment Detail"
      isVisible={isVisible}
      handleClose={handleClickClose}
    >
      <Formik
        enableReinitialize
        initialValues={initialFormData}
        validate={segmentFormValidator}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
            <Switch
              labelFirst
              name="default"
              id="drawer-default"
              label="Default Segment"
              isChecked={values.default}
              onChange={handleChange}
              style={{ marginBottom: -22 }}
            />
            <Input
              required
              isInvalid={!!(touched.name && errors.name)}
              invalidFeedback={errors.name}
              name="name"
              label="Name"
              value={values.name || ""}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Field label="Description">
              <Textarea
                name="description"
                rows="5"
                value={values.description || ""}
                onChange={handleChange}
              />
            </Field>
            <Field label="Tags">
              <CreatableSelect
                isMulti
                value={values.tags}
                onChange={(e) => {
                  setFieldValue("tags", e);
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </Field>
            <Field label="Shared Level">
              <div className="boolean-field">
                <div className="label">
                  {values.shared && (
                    <>
                      <span className="fa">
                        <FiUnlock />
                      </span>
                      <label htmlFor="drawer-shared">Public</label>
                    </>
                  )}
                  {!values.shared && (
                    <>
                      <span className="fa">
                        <FaLock />
                      </span>
                      <label htmlFor="drawer-shared">Private</label>
                    </>
                  )}
                </div>
                <div className="separator"></div>
                <Switch
                  name="shared"
                  id="drawer-shared"
                  isChecked={values.shared}
                  onChange={handleChange}
                />
              </div>
            </Field>

            <label className="mt-5 mb-2">
              <span className="fa fa-filter mr-3"></span>
              Filter by
            </label>
            <div className="filters-section mb-5 bg-gray-100">
              <FilterPanel
                includedPageContainers={includedPageContainers}
                setIncludedPageContainers={setIncludedPageContainers}
                excludedPageContainers={excludedPageContainers}
                setExcludedPageContainers={setExcludedPageContainers}
                userConditions={userConditions}
                setUserConditions={setUserConditions}
                invalidFields={invalidFields}
              />
            </div>

            <div className="drawer-buttons">
              {isSegmentEditor && (
                <Button
                  disabled={isFormSaving || !isFiltersValid}
                  style={{ width: 120 }}
                  type="submit"
                  size="small"
                  variant="primary"
                >
                  {!!isFormSaving && (
                    <>
                      <span className="fa fa-spinner spin"></span>
                      {` `}
                    </>
                  )}
                  {!!isFormSaving && `Saving`}
                  {!isFormSaving && `Save`}
                </Button>
              )}
              {!isCopy && (
                <Button
                  onClick={handleClickCopy}
                  className="ml-2"
                  disabled={isFormSaving}
                  style={{ width: 120 }}
                  type="button"
                  size="small"
                  variant="primary"
                >
                  Copy
                </Button>
              )}
              <Button
                style={{ width: 120 }}
                disabled={isFormSaving}
                type="button"
                size="small"
                className="mr-auto"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {!!segmentId && isSegmentEditor && (
                <Button
                  variant="danger"
                  style={{ width: 120 }}
                  disabled={isFormSaving}
                  type="button"
                  size="small"
                  className="ml-2"
                  onClick={handleClickDelete}
                >
                  Delete
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
}

SegmentsDrawer.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
};
SegmentsDrawer.defaultProps = {};

export default SegmentsDrawer;
