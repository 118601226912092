import { firebaseApp, Firestore, doc, getDoc, getAuth } from "./index";
import uiApi from "./../../api/api-ui";

export const fetchAccountById = (accountId) => {
  return getDoc(doc(Firestore, "accounts", accountId));
};

export const fetchAccount = (data) => {
  return getAuth(firebaseApp)
    .currentUser.getIdToken()
    .then((token) => {
      return uiApi.fetchAccount(token, data);
    })
    .then((response) => {
      return response;
    });
};
