import React from "react";
import "./style.scss";
import instructionImg from "../../../../../../components/assets/images/save-report-example.png";

const EmptyReportChart = () => {
  return (
    <div className="base-plate">
      <span className="base-text">
        <strong>No Reports Found. </strong>
        Use ‘Save report’ feature to create a new one.
      </span>
      <div className="image-container">
        <img
          loading="lazy"
          src={instructionImg}
          alt="Save report instruction"
        />
      </div>
    </div>
  );
};

export default EmptyReportChart;
