import React from "react";
import { Button } from "./../../share/InsightUI";
import PageContainer from "./../FilterPanel/PageContainer";
import "./funnelStep.scss";

const conditions = require("./../../../inc/eventConditions.json");
const funnelStepConditions = JSON.parse(JSON.stringify(conditions));
delete funnelStepConditions["Any"];

function FunnelStep(props) {
  const {
    invalidFields,
    index,
    data,
    funnelSteps,
    eventFilters,
    isValid,
    handleClickDeleteStep,
    handleChangeStepName,
    handleUpdateCondition,
    handleClickAddEventConditionRow,
    handleClickDeleteEventConditionRow,
    handleUpdateSubCondition,
    handleClickAddSubConditionRow,
    handleClickDeleteSubConditionRow,
    onStepArrow,
  } = props;

  const updateCondition = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    handleUpdateCondition(
      index,
      containerIndex,
      eventIndex,
      conditionIndex,
      data
    );
  };

  const updateSubCondition = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    handleUpdateSubCondition(
      index,
      containerIndex,
      eventIndex,
      conditionIndex,
      data
    );
  };

  const addSubConditionRow = (type, containerIndex, eventIndex) => {
    handleClickAddSubConditionRow(index, containerIndex, eventIndex);
  };

  const deleteSubConditionRow = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex
  ) => {
    handleClickDeleteSubConditionRow(
      index,
      containerIndex,
      eventIndex,
      conditionIndex
    );
  };

  const addCondition = (e) => {
    const containerIndex = e.currentTarget.dataset.containerindex;
    handleClickAddEventConditionRow(index, containerIndex);
  };

  const deleteCondition = (type, containerIndex, eventIndex) => {
    handleClickDeleteEventConditionRow(index, containerIndex, eventIndex);
  };

  const renderArrow = (arrow) => {
    if (arrow === "down") {
      return funnelSteps.length - 1 === index ? (
        <Button disabled>
          <span className="fa fa-arrow-down" />
        </Button>
      ) : (
        <Button onClick={() => onStepArrow(index)}>
          <span className="fa fa-arrow-down" />
        </Button>
      );
    }
    if (arrow === "up") {
      return index === 0 ? (
        <Button disabled>
          <span className="fa fa-arrow-up" />
        </Button>
      ) : (
        <Button onClick={() => onStepArrow(index - 1)}>
          <span className="fa fa-arrow-up" />
        </Button>
      );
    }
    return;
  };

  return (
    <div
      className={`col-md-8 offset-md-2 step-col
        ${isValid ? "valid" : "invalid"}
      `}
      key={index}
    >
      {/* ${invalidSteps[index] ? 'invalid' : ''}
        ${validSteps[index] ? 'valid' : ''} */}

      <div className={`funnel-step`}>
        <div className="counter-col">
          <div className="counter">{index + 1}</div>
        </div>
        <span
          className={`line
            ${
              funnelSteps.length > 1 &&
              funnelSteps.length - 1 !== index &&
              "show"
            }
          `}
        ></span>
        {renderArrow("down")}
        {renderArrow("up")}
        <div className="funnel-step-form">
          <input
            onChange={handleChangeStepName}
            value={funnelSteps[index].name}
            data-stepid={index}
            placeholder="Step Name"
            className={`form-control ${
              !funnelSteps[index].name && "form-control-error"
            }`}
            type="text"
          />
          <div className="rule-wrapper">
            <label>
              <span className="fa fa-mouse-pointer"></span>
              Include users which behavior were...
            </label>
            <PageContainer
              invalidFields={invalidFields}
              handleDelete={() => {}}
              containers={[data]}
              data={data}
              inclusion={true}
              containerIndex={0}
              editFieldOnly={false}
              filters={eventFilters}
              handleUpdateCondition={updateCondition}
              handleUpdateSubCondition={updateSubCondition}
              handleClickAddCondition={addCondition}
              handleClickDeleteConditionRow={deleteCondition}
              handleClickAddSubConditionRow={addSubConditionRow}
              handleClickDeleteSubConditionRow={deleteSubConditionRow}
            />
          </div>
        </div>
        <Button
          data-stepid={index}
          onClick={handleClickDeleteStep}
          className="btn-delete"
        >
          <span className="fa fa-times" size="small"></span>
        </Button>
      </div>
    </div>
  );
}

FunnelStep.propTypes = {};
FunnelStep.defaultProps = {};

export default FunnelStep;
