import React from "react";
import { Button } from "./../../share/InsightUI";
import Rule from "./Rule";
import PageContainer from "./PageContainer";
import PropTypes from "prop-types";
import { deepCopy } from "./../../../utils";
import {
  pageContainerFixture,
  eventFixture,
  conditionFixture,
} from "./../../../fixtures/filters";

import "./style.scss";

const userFilters = require("./../../../inc/userFilters.json");
const eventFilters = require("./../../../inc/eventFilters.json");

function FilterPanel(props) {
  const {
    invalidFields,
    includedPageContainers,
    setIncludedPageContainers,
    excludedPageContainers,
    setExcludedPageContainers,
    userConditions,
    setUserConditions,
    editFieldOnly, // Only existing fields can be edited
  } = props;

  const updateCondition = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    if (type === "user") {
      const newData = deepCopy(userConditions);
      newData[eventIndex] = data;
      setUserConditions(newData);
    }
    if (type === "event") {
      const newData = deepCopy(includedPageContainers);
      newData[containerIndex].eventConditions[eventIndex] = data;
      setIncludedPageContainers(newData);
    }
    if (type === "excludedEvent") {
      const newData = deepCopy(excludedPageContainers);
      newData[containerIndex].eventConditions[eventIndex] = data;
      setExcludedPageContainers(newData);
    }
  };

  const handleUpdateSubCondition = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    if (type === "event") {
      const newData = deepCopy(includedPageContainers);
      newData[containerIndex].eventConditions[eventIndex].conditions[
        conditionIndex
      ] = data;
      setIncludedPageContainers(newData);
    }
    if (type === "excludedEvent") {
      const newData = deepCopy(excludedPageContainers);
      newData[containerIndex].eventConditions[eventIndex].conditions[
        conditionIndex
      ] = data;
      setExcludedPageContainers(newData);
    }
  };

  const handleClickAddUserConditionRow = () => {
    setUserConditions([...userConditions, deepCopy(conditionFixture)]);
  };

  const handleClickAddEventConditionRow = (e) => {
    const containerIndex = e.currentTarget.dataset.containerindex;
    const inclusion =
      e.currentTarget.dataset.inclusion === "true" ? true : false;
    const containerArray = inclusion
      ? deepCopy(includedPageContainers)
      : deepCopy(excludedPageContainers);
    const setter = inclusion
      ? setIncludedPageContainers
      : setExcludedPageContainers;
    containerArray[containerIndex].eventConditions.push(deepCopy(eventFixture));
    setter(containerArray);
  };

  const handleClickAddSubConditionRow = (type, containerIndex, eventIndex) => {
    const newCondition = deepCopy(conditionFixture);

    if (type === "event") {
      const newData = deepCopy(includedPageContainers);
      newData[containerIndex].eventConditions[eventIndex].conditions.push(
        newCondition
      );
      setIncludedPageContainers(newData);
    }
    if (type === "excludedEvent") {
      const newData = deepCopy(excludedPageContainers);
      newData[containerIndex].eventConditions[eventIndex].conditions.push(
        newCondition
      );
      setExcludedPageContainers(newData);
    }
  };

  const handleClickDeleteConditionRow = (type, containerIndex, eventIndex) => {
    let inclusion = true;
    let containerArray = [];
    switch (type) {
      case "user":
        containerArray = deepCopy(userConditions);
        break;
      case "event":
        inclusion = true;
        containerArray = deepCopy(includedPageContainers);
        break;
      case "excludedEvent":
        inclusion = false;
        containerArray = deepCopy(excludedPageContainers);
        break;
      default:
        break;
    }

    if (type === "user") {
      if (containerArray.length === 1 && eventIndex === 0) {
        containerArray[eventIndex] = deepCopy(conditionFixture);
      } else {
        containerArray.splice(eventIndex, 1);
      }
      setUserConditions(containerArray);
      return;
    }

    const containerSetter = inclusion
      ? setIncludedPageContainers
      : setExcludedPageContainers;

    if (
      containerArray[containerIndex].eventConditions.length === 1 &&
      eventIndex === 0
    ) {
      containerArray[containerIndex].eventConditions[eventIndex] =
        deepCopy(eventFixture);
    } else {
      containerArray[containerIndex].eventConditions.splice(eventIndex, 1);
    }
    containerSetter(containerArray);
  };

  const handleClickDeleteSubConditionRow = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex
  ) => {
    let newRules = [];
    let inclusion = true;
    let containerArray = [];
    switch (type) {
      case "user":
        newRules = deepCopy(userConditions);
        break;
      case "event":
        inclusion = true;
        containerArray = deepCopy(includedPageContainers);
        break;
      case "excludedEvent":
        inclusion = false;
        containerArray = deepCopy(excludedPageContainers);
        break;
      default:
        break;
    }
    if (newRules.length > 1 && type === "user") {
      newRules.splice(eventIndex, 1);
      setUserConditions(newRules);
      return;
    }

    const containerSetter = inclusion
      ? setIncludedPageContainers
      : setExcludedPageContainers;

    containerArray[containerIndex].eventConditions[
      eventIndex
    ].conditions.splice(conditionIndex, 1);
    containerSetter(containerArray);
  };

  const handleClickAddContainer = (e) => {
    const inclusion =
      e.currentTarget.dataset.inclusion === "true" ? true : false;
    const newContainer = deepCopy(pageContainerFixture);
    newContainer.inclusion = inclusion;
    const containerArray = inclusion
      ? deepCopy(includedPageContainers)
      : deepCopy(excludedPageContainers);
    const setter = inclusion
      ? setIncludedPageContainers
      : setExcludedPageContainers;
    containerArray.push(newContainer);
    setter(containerArray);
  };

  const handleClickDeleteContainer = (e) => {
    const inclusion =
      e.currentTarget.dataset.inclusion === "true" ? true : false;
    const index = e.currentTarget.dataset.containerindex;
    const containerArray = inclusion
      ? deepCopy(includedPageContainers)
      : deepCopy(excludedPageContainers);
    const setter = inclusion
      ? setIncludedPageContainers
      : setExcludedPageContainers;
    containerArray.splice(index, 1);
    setter(containerArray);
  };

  const getValidConditionsCount = (conditions) => {
    let count = 0;
    /**
     * @TODO: add attribute check
     */
    conditions.forEach((condition) => {
      if (condition.values && condition.values.join("").trim()) {
        count += 1;
      }

      if (condition.conditions) {
        for (let i = 0, len = condition.conditions.length; i < len; i++) {
          const subCondition = condition.conditions[i];
          if (
            (subCondition.values && subCondition.values.join("").trim()) ||
            subCondition.attribute === "Any"
          ) {
            count += 1;
            break;
          }
        }
      }
    });
    return count;
  };

  return (
    <>
      <div className="filter-panel">
        <div className="filter-row">
          <h3 className="row-header">
            <div className="label">
              <i className="fas fa-users"></i>
              <span>Find users/sessions whose...</span>
            </div>
            <div className="actions">
              <span>{`Users Filters (${getValidConditionsCount(
                userConditions
              )})`}</span>
              {!editFieldOnly && (
                <Button
                  onClick={handleClickAddUserConditionRow}
                  icon="fas fa-plus"
                ></Button>
              )}
            </div>
          </h3>

          <div className="rules">
            {userConditions.map((rule, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && (
                    <div className="filter-separator">
                      <span className="text-gray-500">AND</span>
                    </div>
                  )}
                  <Rule
                    invalidFields={invalidFields}
                    index={index}
                    filters={userFilters}
                    data={rule}
                    initial={index === 0}
                    showDelete={true}
                    handleUpdateConditions={updateCondition}
                    handleClickDeleteConditionRow={
                      handleClickDeleteConditionRow
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="filter-separator">
          <span className="text-gray-500">AND</span>
        </div>

        {/* Event */}
        <div className="filter-row">
          <h3 className="row-header">
            <div className="label">
              <i className="fas fa-mouse-pointer"></i>
              <span>Include users which behaviour were...</span>
            </div>
            <div className="actions">
              <Button
                data-inclusion={true}
                variant="secondary"
                size="x-small"
                onClick={handleClickAddContainer}
                icon="fas fa-plus"
              >
                Add Pageview
              </Button>
            </div>
          </h3>
          <div className="rules">
            <div className="container-body">
              {includedPageContainers.map((container, index) => {
                return (
                  <React.Fragment key={index}>
                    {index !== 0 && (
                      <div className="filter-separator">
                        <span className="text-gray-500">AND</span>
                      </div>
                    )}
                    <PageContainer
                      invalidFields={invalidFields}
                      handleDelete={handleClickDeleteContainer}
                      containers={includedPageContainers}
                      data={container}
                      inclusion={true}
                      containerIndex={index}
                      editFieldOnly={editFieldOnly}
                      filters={eventFilters}
                      handleUpdateCondition={updateCondition}
                      handleUpdateSubCondition={handleUpdateSubCondition}
                      handleClickAddCondition={handleClickAddEventConditionRow}
                      handleClickDeleteConditionRow={
                        handleClickDeleteConditionRow
                      }
                      handleClickAddSubConditionRow={
                        handleClickAddSubConditionRow
                      }
                      handleClickDeleteSubConditionRow={
                        handleClickDeleteSubConditionRow
                      }
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="filter-separator">
          <span className="text-gray-500">AND</span>
        </div>

        {/* Exclude Events */}
        <div className="filter-row">
          <h3 className="row-header text-danger./">
            <div className="label">
              <i className="fas fa-ban"></i>
              <span>Exclude</span>
            </div>
            <div className="actions">
              <Button
                data-inclusion={false}
                variant="secondary"
                size="x-small"
                onClick={handleClickAddContainer}
                icon="fas fa-plus"
              >
                Add Pageview
              </Button>
            </div>
          </h3>

          <div className="rules">
            <div className="container-body">
              {excludedPageContainers.map((container, index) => {
                return (
                  <React.Fragment key={index}>
                    {index !== 0 && (
                      <div className="filter-separator">
                        <span className="text-gray-500">AND</span>
                      </div>
                    )}
                    <PageContainer
                      invalidFields={invalidFields}
                      handleDelete={handleClickDeleteContainer}
                      containers={excludedPageContainers}
                      data={container}
                      inclusion={false}
                      containerIndex={index}
                      editFieldOnly={editFieldOnly}
                      filters={eventFilters}
                      handleUpdateCondition={updateCondition}
                      handleUpdateSubCondition={handleUpdateSubCondition}
                      handleClickAddCondition={handleClickAddEventConditionRow}
                      handleClickDeleteConditionRow={
                        handleClickDeleteConditionRow
                      }
                      handleClickAddSubConditionRow={
                        handleClickAddSubConditionRow
                      }
                      handleClickDeleteSubConditionRow={
                        handleClickDeleteSubConditionRow
                      }
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

FilterPanel.propTypes = {
  userConditions: PropTypes.array,
  setUserConditions: PropTypes.func,
  includedPageContainers: PropTypes.array,
  setIncludedPageContainers: PropTypes.func,
  excludedPageContainers: PropTypes.array,
  setExcludedPageContainers: PropTypes.func,
  editFieldOnly: PropTypes.bool,
};
FilterPanel.defaultProps = {
  editFieldOnly: false,
};

export default FilterPanel;
