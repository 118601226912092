import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './../../share/InsightUI';
import './style.scss';

function Collapsible(props){

  const {
    id,
    title,
    collapsed,
    className,
    handleToggle,
    triggerOptions
  } = props;

  const collapsableRef = React.useRef();

  const onClickToggle = async (e)=>{
    let isOpen;
    let target = e.currentTarget;
    await setTimeout(()=>{
      if(collapsableRef.current.classList.contains('show')){
        isOpen = true;
      } else {
        isOpen = false;
      }
      handleToggle(target, isOpen);
    }, 200);
  }

  return (
    <div
      className={`collapsable ${className}`}
      id={`${id}-parent`}
    >
      <h3 className="title bg-gray-100">
        <Button
          className={`${collapsed && 'collapsed'}`}
          variant="plain"
          data-toggle="collapse"
          data-target={`#${id}`}
          data-parent={`#${id}-parent`}
          onClick={onClickToggle}
          {...triggerOptions}
        >
          <i className="fa fa-chevron-down"></i>
          {title}
        </Button>
      </h3>
      <div
        ref={collapsableRef}
        className={`collapse body ${!collapsed && 'show'}`}
        id={id}
      >
        {props.children}
      </div>
    </div>
  );
}

Collapsible.propTypes = {
  handleToggle: PropTypes.func,
  triggerOptions: PropTypes.object
}
Collapsible.defaultProps = {
  handleToggle: ()=>{},
  triggerOptions: {}
}

export default Collapsible;