import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../components/auth/FirebaseAuthContext";
import Alert from '../../../components/share/Alert';
import { addLog } from './../../../lib/firebase';
import EmailField from "../../../components/share/fields/EmailField";
import SubmitButtons from "../../../components/share/fields/SubmitButtons";

const DeleteUser = () => {
    
  const {authUser} = useContext(AuthContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [formVisibility, setFormVisibility] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [alert, setAlert] = useState({
    'show': false,
    'type':'',
    'message':'',
    'count': 0
  });

  return (
    <>
      <div className="row">      
        <Alert show={alert.show} type={alert.type} message={alert.message} count={alert.count} />
      </div>
      {formVisibility?(
      <div className="row">
        <div className="col mb-4">
            <div className="card shadow mb-4">
                <div className="card-body">
                    <EmailField id="email-address" email={emailAddress} label="Email Address" placeholder="Your email address" helpText="Please put in your registered email address to verify." handleChange={(e) => {
                        setEmailAddress(e.target.value);
                    }}></EmailField>
                    <SubmitButtons buttonStyle="danger" processing={processing} submitButtonText="DELETE" backButtonTo="/user/profile" handleClick={(e) => {
                        e.preventDefault();
                        setProcessing(true);
                        if(emailAddress === authUser.user.email){
                            addLog('deleting account');
                            authUser.user.delete().then(function(){
                                setProcessing(false);
                                setFormVisibility(false);
                                setAlert({
                                    'show':true, 
                                    'type':'success',
                                    'message':'Your account has been deleted. Please click "Back" button to go back to your profile page.',
                                    'count':alert.count+1
                                });
                            }).catch(function(error){
                                setProcessing(false);
                                setAlert({
                                    'show':true, 
                                    'type':'danger',
                                    'message':error.message,
                                    'count':alert.count+1
                                });
                            })
                        }else{
                            setProcessing(false);
                            setAlert({
                                'show':true, 
                                'type':'danger',
                                'message':'Incorrect email address.',
                                'count':alert.count+1
                            });
                        }
                    }}></SubmitButtons>
                </div>
            </div>
        </div>
      </div>
      ):(<Link className="btn btn-secondary" to="/user/profile">Back</Link>)}
    </>
  );
};

export default DeleteUser;