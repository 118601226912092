import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Tag } from "./../InsightUI";
import { Tooltip as ReactTooltip } from "react-tooltip";
import UserAvatar from "./../../app/UserAvatar";
import Comment from "./../Comment";
import { fetchComments, saveComment } from "./../../../lib/firebase/comments";
import { FaLock } from "react-icons/fa";
import { FiUnlock } from "react-icons/fi";
import { GrTextAlignFull } from "react-icons/gr";
import { BsCalendarCheck } from "react-icons/bs";
import { NOTE_CARD_PRIVATE, NOTE_CARD_SHARED } from "../../../constants/info";

import "./style.scss";

const analyticsTags = require("../../../inc/analyticsModes.json");

const NoteCard = (props) => {
  const {
    index,
    id,
    profileId,
    compact,
    photoUrl,
    noteTime,
    creationTime,
    content,
    handleClickPlayNote,
    noteUrl,
    authorName,
    tags,
    analyticsMode,
    startDate,
    endDate,
    shared,
    handleClickCopy,
    handleClickEditNote,
    handleClickDeleteNote,
    handleClickViewReplay,
    showOwnerControls,
    profileUsers,
    isActive,
  } = props;

  const [isSavingComment, setIsSavingComment] = useState(false);
  const [commentData, setCommentData] = useState({
    comment: "",
  });
  const [comments, setComments] = useState([]);

  const handleTimestampObject = (date) => {
    return moment(date.toDate()).format("MMM DD, YYYY");
  };

  const handleDateObject = (date) => {
    let date2string = new Date(date).toDateString();
    let formatDate = date2string.slice(3);
    return formatDate;
  };

  const formatNoteDateRange = (startDate, endDate) => {
    let formatStartDate =
      Object.prototype.toString.call(startDate) === "[object Date]"
        ? `${handleDateObject(startDate)}`
        : `${handleTimestampObject(startDate)}`;
    let formatEndDate =
      Object.prototype.toString.call(endDate) === "[object Date]"
        ? `${handleDateObject(endDate)}`
        : `${handleTimestampObject(endDate)}`;
    return `${formatStartDate} to ${formatEndDate}`;
  };

  const handleClickSaveComment = () => {
    setIsSavingComment(true);
    if (!commentData.comment) {
      setIsSavingComment(false);
      return;
    }
    saveComment(commentData, profileId, id).then(() => {
      setIsSavingComment(false);
      setCommentData({
        comment: "",
      });
      const comments = [];
      fetchComments(profileId, id).then((res) => {
        if (res) {
          res.docs.forEach((data) => {
            const commentData = data.data();
            commentData.id = data.id;
            comments.push(commentData);
          });
          setComments(comments);
        }
      });
    });
  };

  const handleChangeText = (e) => {
    setCommentData({ ...commentData, comment: e.target.value });
  };

  useEffect(() => {
    const comments = [];
    fetchComments(profileId, id).then((res) => {
      if (res) {
        res.docs.forEach((data) => {
          const commentData = data.data();
          commentData.id = data.id;
          comments.push(commentData);
        });
        setComments(comments);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`replay-note
      ${compact ? "compact" : ""}
      ${isActive ? "active" : ""}
    `}
    >
      <div className="replay-note-meta">
        <div className="timestamp">
          {moment
            .duration(noteTime, "milliseconds")
            .format("HH:mm:ss", { trim: false })}
        </div>

        <div
          data-tooltip-id="note-card-tooltip"
          data-tooltip-content={authorName || "Removed User"}
        >
          <UserAvatar
            className="rounded-circle ml-1"
            photoUrl={photoUrl}
            name={authorName || "Removed User"}
            size={"26px"}
          />
        </div>

        <div
          className="note-sharable mr-auto"
          data-tooltip-id="note-card-tooltip"
          data-tooltip-content={!shared ? NOTE_CARD_PRIVATE : NOTE_CARD_SHARED}
        >
          {!shared ? <FaLock /> : <FiUnlock />}
        </div>
        {compact ? (
          <Button
            variant="plain"
            size="small"
            onClick={handleClickPlayNote}
            data-timestamp={noteTime}
          >
            <i className="fa fa-play"></i>
          </Button>
        ) : (
          <div className="creation-time">
            Created:{" "}
            {moment.utc(Number(creationTime)).local().format("MMM DD, YYYY")}
          </div>
        )}
      </div>
      <div className="replay-note-body">
        <div className="note-description">
          <div className="note-row-label">
            <div className="note-label-icon">
              <GrTextAlignFull />
            </div>
            Description:
          </div>
          <div className="note-row-content">{content}</div>
        </div>
        {startDate &&
          endDate &&
          analyticsMode.toLowerCase() !== "moment" &&
          !!analyticsMode && (
            <div className="note-date-range">
              <div className="note-row-label">
                <div className="note-label-icon">
                  <BsCalendarCheck />
                </div>
                Date Range:
              </div>
              <div className="note-row-content">
                {formatNoteDateRange(startDate, endDate)}
              </div>
            </div>
          )}
      </div>
      <div className="replay-note-tags">
        {!!analyticsMode && !!analyticsTags[analyticsMode] && (
          <Tag
            text={`${analyticsTags[analyticsMode].label} ${
              analyticsTags[analyticsMode]?.subLabel
                ? `- ${analyticsTags[analyticsMode]?.subLabel}`
                : ""
            }`}
            key={index}
            variant="analytics"
            noDelete
          />
        )}
        {tags.map((tag, index) => {
          return <Tag text={tag} key={index} noDelete />;
        })}
      </div>

      {!compact && (
        <div className="mt-2 note-url">
          <div className="input-group">
            <input
              type="text"
              readOnly
              value={noteUrl}
              className="form-control form-control-sm"
            />
            <div className="input-group-append">
              <CopyToClipboard text={noteUrl} onCopy={handleClickCopy}>
                <button className="btn btn-sm btn-secondary" type="button">
                  <i className="fa fa-clipboard"></i> Copy Link
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}
      {!compact && (
        <div className="mt-2">
          <Button
            onClick={handleClickViewReplay}
            data-noteid={id}
            variant="primary"
            size="small"
          >
            View Replay
          </Button>
        </div>
      )}
      <div className="mt-2 owner-btns">
        {showOwnerControls && (
          <Fragment>
            <Button
              onClick={handleClickEditNote}
              size="small"
              variant="secondary"
              data-noteid={id}
            >
              Edit
            </Button>
            <Button
              onClick={handleClickDeleteNote}
              data-noteid={id}
              data-noteindex={index}
              size="small"
              className="ml-1 mr-auto"
              variant="danger"
            >
              Delete
            </Button>
          </Fragment>
        )}
        {!showOwnerControls && <div className="ml-1 mr-auto"></div>}
        {compact && (
          <div className="note-copy-link">
            <CopyToClipboard text={noteUrl} onCopy={handleClickCopy}>
              <button
                className="btn btn-sm btn-secondary ml-auto"
                type="button"
              >
                <i className="fa fa-clipboard"></i> Copy Link
              </button>
            </CopyToClipboard>
          </div>
        )}
      </div>
      <div className="mt-4">
        <div className="comment-form">
          <textarea
            value={commentData.comment}
            onChange={handleChangeText}
            className="form-control"
            placeholder="Write a comment"
          ></textarea>
          <div className="text-right mt-2">
            <Button
              disabled={isSavingComment}
              onClick={handleClickSaveComment}
              icon={isSavingComment && "fa fa-spinner fa-spin"}
              variant="primary"
              size="small"
            >
              Save Comment
            </Button>
          </div>
        </div>
        {!!comments.length && (
          <small className="text-gray-900">
            <strong>All comments</strong>
          </small>
        )}
        {comments.map((comment, index) => {
          return (
            <Comment
              id={comment.id}
              index={index}
              noteId={id}
              profileId={profileId}
              key={comment.id}
              comments={comments}
              setComments={setComments}
              authorName={
                profileUsers &&
                profileUsers.byId[comment.ownerId] &&
                profileUsers.byId[comment.ownerId].name
              }
              authorPhoto={
                profileUsers &&
                profileUsers.byId[comment.ownerId] &&
                profileUsers.byId[comment.ownerId].photoURL
              }
              data={comment}
            />
          );
        })}
      </div>
      <ReactTooltip id="note-card-tooltip" className="tooltip-sm" />
    </div>
  );
};

export default NoteCard;
