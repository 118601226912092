import React, { useRef, useState } from "react";
import { Button } from "../../share/InsightUI";
import {
  FaChevronUp,
  FaChevronDown,
  FaSortAmountUpAlt,
  FaSortAmountDown,
} from "react-icons/fa";
import useClickOutside from "../../../hooks/useClickOutside";

const SortingDropdown = (props) => {
  const { onChange, sortOptions, sortOrder, selectedSortOption } = props;
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };

  // Setup outside click listener
  useClickOutside(dropdownRef, () => {
    setIsVisible(false);
  });

  return (
    <div className="mb-0 dropdown" ref={dropdownRef}>
      <label htmlFor="sortby" className="mr-2 mb-0">
        Sort by:
      </label>
      <Button
        data-display="static"
        variant="outline-dark"
        size="small"
        id="session-sorting-dropdown"
        aria-haspopup="true"
        aria-expanded={isVisible}
        onClick={toggleDropdown}
      >
        {sortOrder === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDown />}
        <span className="mr-1 ml-2">
          {sortOptions.find((o) => o.value === selectedSortOption).text}
        </span>
        {isVisible ? (
          <FaChevronUp style={{ marginTop: "-2px", marginLeft: "5px" }} />
        ) : (
          <FaChevronDown style={{ marginTop: "-2px", marginLeft: "5px" }} />
        )}
      </Button>
      <div
        className={`dropdown-menu dropdown-menu-md-right session-sorting-dropdown 
        shadow animated--grow-in ${isVisible ? "show" : ""}`}
        aria-labelledby="session-sorting-dropdown"
      >
        {sortOptions.map((option) => (
          <div
            className="dropdown-item"
            key={option.value}
            value={option.value}
            onClick={() => {
              onChange(option.value);
              setIsVisible(false);
            }}
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SortingDropdown;
