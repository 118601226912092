import React from "react";
import "./style.scss";
import SunburstChart from "sunburst-chart";
import fromKapsule from "react-kapsule";
import { thousandSeparatorAndFixedDecimal } from "../../../utils";

const ReactSunburst = fromKapsule(SunburstChart, {
  methodNames: ["focusNode"],
});

const Chart = ({ data, rules }) => {
  const content = (node, rules) => {
    let pctNum = (node.value / node.data.sumSessions) * 100;
    let sessionPct = Math.round((pctNum + Number.EPSILON) * 100) / 100;
    var strSessions = `${thousandSeparatorAndFixedDecimal(
      node.value
    )} sessions (${sessionPct}%)</br> `;
    var strRules = `${node.data.rule}`;
    if (node.data.name === "start") {
      return strSessions;
    } else {
      return strSessions + strRules;
    }
  };

  return (
    <ReactSunburst
      excludeRoot
      label="name"
      size="size"
      color={"color"}
      centerRadius={0.3}
      radiusScaleExponent={0.7}
      tooltipContent={(d, node) => content(node)}
      data={data}
    />
  );
};

export default Chart;
