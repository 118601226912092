import React from "react";
import ConversionsBuilder from "../../../../components/app/ConversionsBuilder";

const Conversions = () => {
  return (
    <React.Fragment>
      <ConversionsBuilder />
    </React.Fragment>
  );
};

export default Conversions;
