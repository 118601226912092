import React, { useContext } from "react";
import PropTypes from "prop-types";
import DataTitle from "../../../pages/app/profiles/Reports/components/DataTitle";
import DataTags from "../../../pages/app/profiles/Reports/components/DataTags";
import { SegmentsContext } from "../../../context/Segments";

const TableBody = (props) => {
  const { reports, searchFilter, onReport } = props;
  const { segments } = useContext(SegmentsContext);

  return (
    <tbody>
      {!!reports.length &&
        reports.filter(searchFilter).map((report) => (
          <tr key={report.Id}>
            <td className="col-6">
              <DataTitle report={report} onReport={onReport} />
            </td>
            <td className="col-5">
              <DataTags report={report} segments={segments} />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

TableBody.propTypes = {
  reports: PropTypes.array,
  searchFilter: PropTypes.func,
  onReport: PropTypes.func,
  title: PropTypes.string,
  funnelId: PropTypes.string,
};

export default TableBody;
