import React, { useEffect, useContext, useState } from "react";
import { UserDataContext } from "../UserData";
import QuickFilter from "./../QuickFilter";
import Toast from "./../../share/Toast";
import QuickReport from "../QuickReport";
import Alert from "../../share/Alert";
import SideBarNav from "../SideBarNav";
import TopBarNav from "../TopBarNav";

const DynamicProfileLayout = (props) => {
  const profileId = props.computedMatch.params.profileId;
  const {
    activeProfileId,
    setActiveProfileId,
    setQueryId,
    isUserHasProfileAccess,
  } = useContext(UserDataContext);

  const [hasTitle, setHasTitle] = useState(true);
  const [isQuickFilterVisible, setIsQuickFilterVisible] = useState(
    props.quickFilter
  );
  const [isQuickReportVisible, setIsQuickReportVisible] = useState(
    props.quickReport
  );
  document.title = props.title;
  const title = props.title;
  const TitleIcon = props.titleIcon;
  const description = props.description;
  const type = props.type;

  useEffect(() => {
    if (profileId !== activeProfileId) {
      setActiveProfileId(profileId);
      setQueryId(null);
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.src = "/theme.js";
    document.head.appendChild(script);
    return () => script.parentNode.removeChild(script);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isUserHasProfileAccess) {
    return (
      <div id="wrapper">
        <TopBarNav />

        <div className="d-flex wrapper-content">
          <SideBarNav profileId={profileId} />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <div
                className={`layout-header ${
                  isQuickFilterVisible ? "sticky" : ""
                }`}
              >
                {!isQuickReportVisible && hasTitle && (
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{title}</h1>
                  </div>
                )}
                {isQuickReportVisible && (
                  <QuickReport
                    title={title}
                    titleIcon={TitleIcon}
                    description={description}
                    type={type}
                  />
                )}
                {isQuickFilterVisible && <QuickFilter type={type} />}
              </div>
              <div className="container-fluid">
                {React.cloneElement(props.children, {
                  setIsQuickFilterVisible,
                  setIsQuickReportVisible,
                  setHasTitle,
                })}
              </div>
            </div>
            <div className="mb-4"></div>
          </div>
        </div>

        <Toast />
      </div>
    );
  } else {
    return (
      <Alert
        show={true}
        type={"danger"}
        message={
          "You don't have access to the requested URL. Please talk to your administrator if you believe you should have access."
        }
        count={0}
      />
    );
  }
};

export default DynamicProfileLayout;
