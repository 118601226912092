export const replayKeyboardEvents = [
  {
    Name: "Spacebar",
    Value: "space",
  },
  {
    Name: "Arrowr Right",
    Value: "arrowright",
  },
  {
    Name: "Arrow Left",
    Value: "arrowleft",
  },
  {
    Name: "Digit 1",
    Value: "digit1",
  },
  {
    Name: "Digit 2",
    Value: "digit2",
  },
  {
    Name: "Digit 4",
    Value: "digit4",
  },
];
