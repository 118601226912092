import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FunnelFoldersDropdownButton = ({
  toggleFoldersDropdown,
  selectedFolder,
  isFoldersDropdownVisible,
}) => {
  return (
    <button className="btn btn-outline-dark" onClick={toggleFoldersDropdown}>
      {selectedFolder.value ? selectedFolder.label : "Select Folder"}
      {isFoldersDropdownVisible ? (
        <FaChevronUp className="ml-2" />
      ) : (
        <FaChevronDown className="ml-2" />
      )}
    </button>
  );
};

export default FunnelFoldersDropdownButton;
