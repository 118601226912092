import React from "react";
import PropTypes from "prop-types";
import { useTable, useFilters, usePagination } from "react-table";
import "./style.scss";

function Table(props) {
  const { columns, data, isLoading, isDataLoaded, loadingText, hScroll, id } =
    props;

  const { getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 100,
        hiddenColumns: ["id", "ownerId"],
      },
    },
    useFilters,
    usePagination
  );

  return (
    <div className={`table-wrapper ${hScroll && "hscroll"}`}>
      <table
        className={`table table-hover ui-table
        ${!!isDataLoaded && "with-data"}
        ${!!isLoading && "loading"}
        ${(!page.length || !isDataLoaded) && "no-data"}
      `}
        id={id}
      >
        <thead className=" thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td
                colSpan={columns.length}
                align="center"
                style={{ textAlign: "center !important" }}
              >
                {loadingText}
              </td>
            </tr>
          )}
          {!isLoading &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {}}
                  data-rowid={row.values.id}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          {!isLoading && isDataLoaded && !page.length && (
            <tr>
              <td
                colSpan={columns.length}
                align="center"
                style={{ textAlign: "center !important" }}
              >
                No data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isDataLoaded: PropTypes.bool,
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hScroll: PropTypes.bool,
};
Table.defaultProps = {
  loadingText: "Loading Data",
  hScroll: false,
};

export default Table;
