import React, { useContext, useState, useMemo } from "react";
import useFetchSegments from "../../../../hooks/useFetchSegments";
import UserAvatar from "./../../../../components/app/UserAvatar";
import CollapsableCard from "./../../../../components/app/CollapsableCard";
import ReactTablePagination from "./../../../../components/app/ReactTablePagination";
import SegmentsDrawer from "./../../../../components/app/SegmentsDrawer";
import { Button } from "./../../../../components/share/InsightUI";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { deleteSegmentById } from "./../../../../lib/firebase/segment";
import { ReducerContext } from "./../../../../reducer/context";
import { UserDataContext } from "../../../../components/app/UserData";
import { SegmentsContext } from "../../../../context/Segments";
import {
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  CONFIRM_BUSY,
  CONFIRM_IDLE,
} from "./../../../../reducer/index";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { deepCopy } from "./../../../../utils";
import { FaLock } from "react-icons/fa";
import { FiUnlock } from "react-icons/fi";
import "./style.scss";

function Segments(props) {
  const { authUser } = useContext(AuthContext);
  const { dispatch } = useContext(ReducerContext);
  const { profileUsers } = useContext(UserDataContext);
  const { segments, setSegments } = useContext(SegmentsContext);

  const profileId = props.match.params.profileId;

  const [isSegmentPanelVisible, setIsSegmentPanelVisible] = useState(false);
  const [focusedSegmentId, setFocusedSegmentId] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const [filters, setFilters] = useState({
    name: undefined,
    owner: undefined,
    shared: undefined,
  });

  const { isLoading, segmentsList, setSegmentsList } =
    useFetchSegments(profileId);

  const handleClickRow = (e) => {
    const rowid = e.currentTarget.dataset.rowid;
    const rowindex = e.currentTarget.dataset.rowindex;
    setFocusedSegmentId(rowid);
    setFocusedIndex(rowindex);
    document.body.classList.add("locked");
    setIsSegmentPanelVisible(true);
  };

  const handleClickDelete = (e) => {
    const segmentId = focusedSegmentId;
    const segmentIndex = focusedIndex;
    dispatch({
      type: OPEN_CONFIRM,
      confirmConfig: {
        title: "Confirm Action",
        text: "Are you sure you want to delete this segment?",
        confirmText: "Delete Segment",
        confirmAction: () => {
          dispatch({ type: CONFIRM_BUSY });
          deleteSegmentById(profileId, segmentId).then(() => {
            if (segments.hasOwnProperty(segmentId)) {
              const updatedSegments = { ...segments };
              delete updatedSegments[segmentId];
              setSegments(updatedSegments);
            }
            dispatch({ type: CONFIRM_IDLE });
            dispatch({ type: CLOSE_CONFIRM });
            handleCloseSegmentDrawer();
            setFocusedIndex(null);
            setFocusedSegmentId(null);
          });
          const updatedData = deepCopy(segmentsList);
          updatedData.splice(segmentIndex, 1);
          setSegmentsList(updatedData);
        },
      },
    });
    return false;
  };

  const renderSharedColumn = ({ value }) => {
    return (
      <div style={{ textAlign: "center" }}>
        {!!value && (
          <span className="fa">
            <FiUnlock />
          </span>
        )}
        {!value && (
          <span className="fa">
            <FaLock />
          </span>
        )}
      </div>
    );
  };

  const renderDefaultColumn = ({ value }) => {
    return (
      <div style={{ textAlign: "center" }}>
        {!!value && (
          <span
            className="fa fa-check"
            style={{
              color: "#1cc88a",
            }}
          ></span>
        )}
        {!value && (
          <span
            className="fa fa-times"
            style={{
              color: "#858796",
            }}
          ></span>
        )}
      </div>
    );
  };

  const renderSharedHeader = () => {
    return <div style={{ textAlign: "center" }}>Shared Level</div>;
  };

  const renderDefaultHeader = () => {
    return <div style={{ textAlign: "center" }}>Default Segment</div>;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Owner",
        accessor: "ownerId",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Creator",
        accessor: "creator",
        Cell: ({ value }) => {
          return value ? (
            <>
              <UserAvatar
                name={value.name}
                className="rounded-circle mr-2"
                size={32}
                photoUrl={value.photoURL}
              />
              {value.name}
            </>
          ) : (
            <></>
          );
        },
      },
      {
        Header: renderSharedHeader,
        accessor: "shared",
        Cell: renderSharedColumn,
      },
      {
        Header: renderDefaultHeader,
        accessor: "default",
        Cell: renderDefaultColumn,
      },
      {
        Header: "",
        accessor: "ID",
        Cell: ({ row }) => {
          return (
            <Button
              variant="secondary"
              data-segmentid={row.values.id}
              data-segmentindex={row.index}
              onClick={handleClickRow}
              size="small"
            >
              Edit
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setAllFilters,

    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data: segmentsList,
      initialState: {
        hiddenColumns: ["id", "ownerId"],
        sortBy: [{ id: "name", desc: false }],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleApplyFilters = () => {
    const updatedFilters = [
      { id: "name", value: filters["name"] },
      { id: "shared", value: filters["shared"] },
      { id: "ownerId", value: filters["owner"] },
    ];
    setAllFilters(updatedFilters);
  };

  const handleChangeKeyword = (e) => {
    const state = { ...filters };
    state["name"] = e.target.value;
    setFilters(state);
  };

  const handleChangeSharedLevel = (e) => {
    const state = { ...filters };
    state["shared"] = e.target.value;
    setFilters(state);
  };

  const handleChangeOwner = (e) => {
    const state = { ...filters };
    state["owner"] = e.target.value;
    setFilters(state);
  };

  const handleCloseSegmentDrawer = () => {
    document.body.classList.remove("locked");
    setIsSegmentPanelVisible(false);
  };

  const handdleClickAddSegment = () => {
    document.body.classList.add("locked");
    setFocusedIndex(null);
    setFocusedSegmentId(null);
    setIsSegmentPanelVisible(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 small">
          <CollapsableCard title="Filter by">
            <div className="row">
              <div className="col-md-12">
                <div className="form-inline">
                  <div className="form-group">
                    <input
                      placeholder="Keyword search"
                      className="form-control form-control-sm"
                      type="search"
                      onChange={handleChangeKeyword}
                    />
                  </div>
                  <div className="form-group ml-4">
                    <label>Creator</label>
                    <select
                      className="form-control form-control-sm mx-2"
                      onChange={handleChangeOwner}
                    >
                      <option value="">All</option>
                      <option value={authUser.user.uid}>Me</option>
                      {profileUsers?.all.length &&
                        profileUsers?.all.map((uid) => {
                          if (uid === authUser.user.uid) {
                            return null;
                          }
                          const user = profileUsers.byId[uid];
                          return (
                            <option value={uid} key={uid}>
                              {user.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="form-group ml-4">
                    <label>Shared Level</label>
                    <select
                      className="form-control form-control-sm mx-2"
                      onChange={handleChangeSharedLevel}
                    >
                      <option value="">All</option>
                      <option value="false">Private</option>
                      <option value="true">Public</option>
                    </select>
                  </div>
                </div>
                <hr />
                <Button
                  style={{ width: 120 }}
                  className="apply-button"
                  variant="primary"
                  size="small"
                  onClick={handleApplyFilters}
                >
                  Apply
                </Button>
              </div>
            </div>
          </CollapsableCard>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card shadow">
            <div className="card-body">
              <div className="text-right mb-2">
                <Button
                  onClick={handdleClickAddSegment}
                  variant="primary"
                  size="small"
                >
                  Add Segment
                </Button>
              </div>
              <table className="table table-hover ui-table segment-list-table">
                <thead className="thead-dark">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {isLoading && (
                    <tr>
                      <td colSpan={columns.length} align="center">
                        Loading Segments...
                      </td>
                    </tr>
                  )}
                  {!isLoading &&
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={handleClickRow}
                          data-rowid={row.values.id}
                          data-rowindex={row.index}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <ReactTablePagination
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                pageOptions={pageOptions}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>

      {isSegmentPanelVisible && (
        <SegmentsDrawer
          setFocusedSegmentId={setFocusedSegmentId}
          segmentId={focusedSegmentId}
          isVisible={isSegmentPanelVisible}
          handleClose={handleCloseSegmentDrawer}
          handleClickDelete={handleClickDelete}
          segments={segments}
          setSegments={setSegments}
          listData={segmentsList}
          setListData={setSegmentsList}
          index={focusedIndex}
        />
      )}
    </>
  );
}

Segments.propTypes = {};
Segments.defaultProps = {};

export default Segments;
