import React from "react";
import WidgetNumber from "../WidgetNumber";

const FunnelWidget = (props) => {
  const { funnelStepsData, isFunnelStepsLoading, funnelGraphError } = props;

  return (
    <div className="row funnel-widget">
      <WidgetNumber
        name="Started Sessions"
        error={funnelGraphError}
        isLoading={isFunnelStepsLoading}
        number={funnelStepsData[0]?.SessionCount}
        status={!!funnelStepsData.length && !isFunnelStepsLoading}
        columnClass="col-xl"
        info="Sessions that start the funnel"
      />

      <WidgetNumber
        name="Completed Sessions"
        error={funnelGraphError}
        isLoading={isFunnelStepsLoading}
        number={funnelStepsData[funnelStepsData.length - 1]?.SessionCount}
        status={!!funnelStepsData.length && !isFunnelStepsLoading}
        columnClass="col-xl"
        info="Sessions that complete the final step"
      />

      <WidgetNumber
        name="Funnel Conversion Rate"
        error={funnelGraphError}
        isLoading={isFunnelStepsLoading}
        number={
          funnelStepsData[0]?.SessionCount
            ? (
                (funnelStepsData[funnelStepsData.length - 1]?.SessionCount /
                  funnelStepsData[0]?.SessionCount) *
                100
              ).toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            : "0"
        }
        suffix="%"
        status={!!funnelStepsData.length && !isFunnelStepsLoading}
        columnClass="col-xl"
        color="text-success"
        info="% of sessions that complete the funnel"
      />
    </div>
  );
};

export default FunnelWidget;
