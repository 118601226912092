import React from "react";
// import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import "./style.scss";

function Pagination(props) {
  const {
    previousPage,
    canPreviousPage,
    pageOptions,
    pageIndex,
    nextPage,
    canNextPage,
    pageSize,
    setPageSize,
    gotoPage,
  } = props;

  const handleClickPage = (e) => {
    const index = e.currentTarget.dataset.pageindex;
    gotoPage(index);
  };

  return (
    <div className="pagination-row">
      <div className="pagination-buttons">
        <Button
          size="small"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <span className="fa fa-chevron-left"></span>
        </Button>

        {pageOptions.map((index) => {
          return (
            <Button
              data-pageindex={index}
              key={index}
              size="small"
              variant={Number(pageIndex) === Number(index) ? "secondary" : ""}
              onClick={handleClickPage}
            >
              {index + 1}
            </Button>
          );
        })}

        <Button size="small" onClick={() => nextPage()} disabled={!canNextPage}>
          <span className="fa fa-chevron-right"></span>
        </Button>
      </div>
      <select
        className="form-control form-control-sm"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 15, 20].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {`${pageSize} / page`}
          </option>
        ))}
      </select>
    </div>
  );
}

Pagination.propTypes = {};
Pagination.defaultProps = {};

export default Pagination;
