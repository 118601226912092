import React, { useContext } from "react";
import { withRouter } from "react-router";
import { Button } from "../../share/InsightUI";
import { deepCopy } from "../../../utils";
import { ADD_TOAST } from "../../../reducer";
import { ReducerContext } from "../../../reducer/context";
import { Formik } from "formik";
import { Form } from "../../share/InsightUI";
import PathingStep from "./PathingStep";
import uiApi from "../../../api/api-ui";
import "./style.scss";

const PathingBuilder = (props) => {
  const { pathingSteps, setPathingSteps } = props;
  const profileId = props.match.params.profileId;
  const { dispatch } = useContext(ReducerContext);

  const exchangeItems = (itemsContainer, exchangedItemIndex, tempItem) => {
    const updatedItemsContainer = itemsContainer.map((item, index) => {
      if (index === exchangedItemIndex) {
        tempItem = { ...item };
        item = { ...itemsContainer[exchangedItemIndex + 1] };
        return item;
      }
      if (index === exchangedItemIndex + 1) {
        return tempItem;
      }
      return item;
    });

    return updatedItemsContainer;
  };

  const handleStepArrow = (stepIndex) => {
    let tempPathingSteps = {};
    setPathingSteps([
      ...exchangeItems(pathingSteps, stepIndex, tempPathingSteps),
    ]);
  };

  const handleClickDeletePath = (e) => {
    const index = parseInt(e.currentTarget.dataset["stepid"]);
    const pathIndex = parseInt(e.currentTarget.dataset["pathid"]);

    const updatedPathingSteps = deepCopy(pathingSteps);

    const updatedPaths =
      updatedPathingSteps[index].filters.values.length === 1
        ? [""]
        : updatedPathingSteps[index].filters.values.filter(
            (path, index) => index !== pathIndex
          );

    updatedPathingSteps[index].filters.values = [...updatedPaths];
    setPathingSteps(updatedPathingSteps);
  };

  const handleClickAddPathField = (e) => {
    const index = parseInt(e.currentTarget.dataset["stepid"]);

    const updatedPathingSteps = deepCopy(pathingSteps);
    updatedPathingSteps[index].filters.values.push("");
    setPathingSteps(updatedPathingSteps);
  };

  const handleClickAddStep = () => {
    const updatedPathingSteps = deepCopy(pathingSteps);
    updatedPathingSteps.push({
      name: "",
      filters: {
        operator: "",
        values: [""],
      },
    });
    setPathingSteps([...updatedPathingSteps]);
  };

  const handleClickDeleteStep = (stepIndex) => {
    const updatedPathingSteps = pathingSteps.filter(
      (step, index) => index !== stepIndex
    );
    setPathingSteps([...updatedPathingSteps]);
  };

  const handleChangeStepName = (e) => {
    const index = parseInt(e.currentTarget.dataset["stepid"]);
    const updatedPathingSteps = deepCopy(pathingSteps);
    updatedPathingSteps[index].name = e.currentTarget.value;
    setPathingSteps(updatedPathingSteps);
  };

  const handlePagePath = (e) => {
    const index = parseInt(e.currentTarget.dataset["stepid"]);
    const pathIndex = parseInt(e.currentTarget.dataset["pathid"]);

    const updatedPathingSteps = deepCopy(pathingSteps);

    const updatedPaths = updatedPathingSteps[index].filters.values.map(
      (path, index) => {
        if (pathIndex === index) {
          return e.currentTarget.value;
        }
        return path;
      }
    );

    updatedPathingSteps[index].filters.values = [...updatedPaths];

    setPathingSteps(updatedPathingSteps);
  };

  const handleSubmitForm = (values, { setSubmitting }) => {
    setSubmitting(true);
    const pathRules = [];
    pathingSteps.forEach((pathingStep) => {
      pathRules.push({
        name: pathingStep.name,
        filters: JSON.stringify(pathingStep.filters),
      });
    });
    uiApi.updateProfile({ pathRules, profileId }).then((res) => {
      setSubmitting(false);
      if (res.error) {
        dispatch({
          type: ADD_TOAST,
          messageType: "danger",
          messageText: res.error,
        });
        return;
      }

      dispatch({
        type: ADD_TOAST,
        messageType: "success",
        messageText: `New pathing rules was saved successfully.`,
      });
      props.history.push(
        `/profile/${props.match.params.profileId}/pathing/sunburst`
      );
    });
  };

  const handleClickCancel = () => {
    props.history.push(
      `/profile/${props.match.params.profileId}/pathing/sunburst`
    );
  };

  const handleChangeOperator = (e) => {
    const index = parseInt(e.currentTarget.dataset["stepid"]);
    const updatedPathingSteps = deepCopy(pathingSteps);
    updatedPathingSteps[index].filters.operator = e.currentTarget.value;
    setPathingSteps(updatedPathingSteps);
  };

  const isValidForm = () => {
    let isValid = true;
    let pathingStepNames = new Map();
    pathingSteps.forEach((pathingStep) => {
      if (
        !pathingStep.name ||
        pathingStep.name.includes(">") ||
        pathingStep.filters.values.includes("") ||
        pathingStepNames.get(pathingStep.name)
      ) {
        isValid = false;
      }
      if (!pathingStepNames[pathingStep.name])
        pathingStepNames.set(pathingStep.name, true);
    });

    return isValid;
  };

  return (
    <>
      <div className="pathing-form bg-grey-100">
        <Formik onSubmit={handleSubmitForm} initialValues={{}}>
          {({ isSubmitting, handleSubmit }) => {
            return (
              <Form isSubmitting={isSubmitting} handleSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-md-4 mr-auto"></div>
                  <div>
                    <Button
                      className="mt-1"
                      type="submit"
                      variant="primary"
                      disabled={!isValidForm()}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="secondary"
                      className="mt-1 ml-2"
                      onClick={handleClickCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="explanation">
        <div className="message">
          Apply names and conditions for how you would like to group your
          website URLs together for the pathing report. <br />
          Rules with bigger numbers have higher priorities. As such, you might
          want to rank the most important last in your rule set.
        </div>
      </div>

      <div
        className={`pathing-steps-container bg-gray-100 small ${
          !!(pathingSteps.length >= 100) && "max-steps"
        }`}
      >
        <div className="row">
          {pathingSteps.map((step, index) => (
            <PathingStep
              key={index}
              index={index}
              data={step}
              pathingSteps={pathingSteps}
              isValid={step}
              onStepArrow={handleStepArrow}
              onDeletedStep={handleClickDeleteStep}
              onStepName={handleChangeStepName}
              onPagePath={handlePagePath}
              onAddedPathField={handleClickAddPathField}
              onDeletedPath={handleClickDeletePath}
              onOperator={handleChangeOperator}
            />
          ))}
        </div>
        <div className="row">
          {pathingSteps.length < 100 && (
            <div className="col-md-8 offset-md-2 step-col add-btn-col">
              <Button
                onClick={handleClickAddStep}
                className="btn-add"
                size="small"
              >
                <span className="fa fa-plus"></span> Add New Page Grouping Rule
              </Button>
              &nbsp;
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(PathingBuilder);
