import React from "react";
import PropTypes from "prop-types";
import { reportTypes } from "../../../helpers/reportTypes";
import InsightechIcon from "../../share/InsightechIcon";

const ReportsSearchBar = ({ searchInputText, handleKeywordChange, type }) => {
  return (
    <div className="reports-search">
      <InsightechIcon name="Search" />
      <input
        type="search"
        className="form-control form-control-sm"
        placeholder={`Search ${reportTypes[type]} reports`}
        value={searchInputText}
        onChange={handleKeywordChange}
      ></input>
    </div>
  );
};

ReportsSearchBar.propTypes = {
  searchInputText: PropTypes.string,
  handleKeywordChange: PropTypes.func,
};

export default ReportsSearchBar;
