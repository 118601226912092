import React, { useState, useEffect } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import api from "./../../../api";
import * as Sentry from "@sentry/react";
import { Button, Dropdown, Slider } from "./../../share/InsightUI";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoaderWithText from "./../../share/LoaderWithText";
import ReplayEventsNav from "./../../app/ReplayEventsNav";
import { FaFileDownload } from "react-icons/fa";
import { AiOutlineCopy } from "react-icons/ai";
import { getTransformScale } from "../../../helpers/replayIframeStyle";
import "./style.scss";
import { RAGE_CLICK_COLOR } from "../../../styles/color";

export default function ReplayComponent(props) {
  const {
    duration,
    isLoading,
    progress,
    setProgress,
    isFullscreen,
    initialTimestamp,
    handleScreenResize,
    handleChangeProgress,
    handleClickFullscreen,
    handleChangePlaySpeed,
    handleClickAddNote,
    handleToggleSkipIdle,
    skipIdle,
    activePageviewIndex,
    setActivePageviewIndex,
    pageviews,
    playing,
    pageUrl,
    pause,
    playSpeed,
    playButtonClick,
    playElapsedEvents,
  } = props;

  const [isUserJourneyVisible, setIsUserJourneyVisible] = useState(false);
  const [isMarkersSet, setIsMarkersSet] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = () => {
    setIsExporting(true);
    const iframe = document.getElementById("replay-iframe");

    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    const iframeTransformScale = getTransformScale(iframe);

    const iframeContentHeight = Math.max(
      iframeDocument.body.scrollHeight,
      iframeDocument.documentElement.scrollHeight
    );

    const iframeContentWidth = Math.max(
      iframeDocument.body.scrollWidth,
      iframeDocument.documentElement.scrollWidth
    );

    // Calculate the aspect ratio for the canvas
    const aspectRatio = iframeContentWidth / iframeContentHeight;

    // Calculate the maximum canvas width based on the viewport width
    const maxCanvasWidth = window.innerWidth * 0.9; // Adjust the factor as needed

    // Calculate canvas dimensions while considering the transform scale
    let canvasWidth = iframeContentWidth * iframeTransformScale;
    let canvasHeight = iframeContentHeight * iframeTransformScale;

    // Ensure that canvas dimensions fit within the viewport width
    if (canvasWidth > maxCanvasWidth) {
      canvasWidth = maxCanvasWidth;
      canvasHeight = canvasWidth / aspectRatio;
    }

    // Create a canvas element with the calculated dimensions
    const canvas = document.createElement("canvas");
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const addContentToCanvas = () => {
      // Capture the content of the iframe into the canvas
      html2canvas(iframeDocument.body, {
        allowTaint: true,
        useCORS: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        canvas,
        scale: iframeTransformScale,
      })
        .then(() => {
          const pdf = new jsPDF(aspectRatio > 1 ? "l" : "p", "px", [
            canvas.width,
            canvas.height,
          ]);

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            canvas.width,
            canvas.height,
            "",
            "FAST"
          );

          pdf.save("Analysis Replay Report.pdf");
          setIsExporting(false);
        })
        .catch((error) => {
          // Handle and log any errors that occur during iframe capture
          setIsExporting(false);
          console.error("Error capturing iframe content:", error);
          Sentry.captureMessage("Error capturing iframe content", {
            level: "info",
          });
        });
    };

    addContentToCanvas();
  };

  const handleToggleUserJourney = () => {
    setIsUserJourneyVisible(!isUserJourneyVisible);
  };

  // Unmount
  useEffect(() => {
    return () => {
      api.cancel("getSessionEvents");
      window.removeEventListener("resize", handleScreenResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageviews && !isMarkersSet) {
      const newMarkers = [];
      let previousDurationAggregate = 0;
      pageviews.forEach((currentPageview, pageViewIndex) => {
        const previousPageview = pageviews[pageViewIndex - 1];
        const durationOfPreviousPageviewLastEvent =
          pageViewIndex > 0
            ? previousPageview.events[previousPageview.events.length - 1].ct -
              previousPageview.events[0].ct
            : 0;

        // add together duration of previous pageviews
        previousDurationAggregate += durationOfPreviousPageviewLastEvent;

        if (currentPageview.events) {
          currentPageview.events.forEach((currentEvent, eventIndex) => {
            let type = null;
            if (currentEvent.t === 1) {
              type = currentPageview.noDomPage
                ? "DUMMY_PAGE_LOAD"
                : "PAGE_LOAD";
            }
            if (currentEvent.t === 7) {
              type = "CLICK";
            }
            if (currentEvent.t === 19) {
              type = "URL_CHANGE";
            }
            if (currentEvent.t === 98) {
              type = "RAGE_CLICK";
            }

            const eventTimestamp = [11, 12, 15].includes(currentEvent.t)
              ? currentEvent.d[0].ct
              : currentEvent.ct;
            const startTime =
              eventTimestamp -
              currentPageview.events[0].ct +
              previousDurationAggregate;

            if (type) {
              newMarkers.push({
                type,
                timestamp: startTime,
              });
            }
          });
        }
      });
      if (newMarkers.length) {
        setMarkers(newMarkers);
      }
      setIsMarkersSet(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageviews]);

  return (
    <div id="replay-div">
      <div className="replay-url">
        <div className="replay-url-link">
          <a
            href={pageUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-tooltip-id="replay-url-tooltip"
            data-tooltip-content={pageUrl}
          >
            {pageUrl}&nbsp;
          </a>
        </div>
        <ReactTooltip
          id="replay-url-tooltip"
          className="tooltip-xl tooltip-with-url"
          place="bottom"
        />

        <button
          className="url-clipboard"
          onClick={() => navigator.clipboard.writeText(pageUrl)}
          value={pageUrl}
          id="replay-url-clipboard"
        >
          <AiOutlineCopy size={19} color="#858796" />
        </button>
      </div>
      <div
        className={`${!!isFullscreen ? "fullscreen" : ""}`}
        id="replay-screen-area"
      >
        <div id="replay-background">
          {isLoading && (
            <div className="overlay">
              <LoaderWithText text="Loading Replay" />
            </div>
          )}
          <div id="replay-scale">
            <iframe id="replay-iframe" title="replay-iframe"></iframe>
          </div>
        </div>

        {isUserJourneyVisible && (
          <ReplayEventsNav
            pause={pause}
            setIsUserJourneyVisible={setIsUserJourneyVisible}
            activePageviewIndex={activePageviewIndex}
            setActivePageviewIndex={setActivePageviewIndex}
            pageviews={pageviews}
            setProgress={setProgress}
            progress={progress}
            playElapsedEvents={playElapsedEvents}
          />
        )}
        <div id="replay-control">
          <Slider
            className=""
            min="0"
            max={duration}
            value={progress}
            handleChange={handleChangeProgress}
          />
          <div className="progress-markers">
            {markers.map((marker, i) => {
              let icon;
              let iconColor = "";
              switch (marker.type) {
                case "PAGE_LOAD":
                  icon = "fa-file";
                  break;
                case "DUMMY_PAGE_LOAD":
                  icon = "far fa-file";
                  break;
                case "CLICK":
                  icon = "fa-hand-pointer";
                  break;
                case "URL_CHANGE":
                  icon = "fa-file-code";
                  break;
                case "RAGE_CLICK":
                  icon = "fa-face-angry";
                  iconColor = RAGE_CLICK_COLOR;
                  break;
                default:
                  break;
              }
              const leftOffset = (marker.timestamp / duration) * 100;
              return (
                <i
                  key={i}
                  className={`fa ${icon}`}
                  style={{
                    left: `${leftOffset}%`,
                    color: `${iconColor}`,
                  }}
                ></i>
              );
            })}
          </div>
          <div>
            <div className="row">
              <div className="control-group ml-2">
                <Button
                  disabled={!initialTimestamp}
                  size="small"
                  className="mr-2"
                  onClick={playButtonClick}
                >
                  <i className={playing ? "fa fa-pause" : "fa fa-play"}></i>
                </Button>

                <Dropdown
                  id="replay-playback-speed"
                  align="right"
                  toggleButton={
                    <Button
                      className="dropdown-toggle"
                      size="small"
                      variant="plain"
                      id="replay-playback-speed"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <strong>x{playSpeed}</strong>
                    </Button>
                  }
                >
                  {playSpeed !== 1 && (
                    <div
                      onClick={handleChangePlaySpeed}
                      data-speed={1}
                      className="dropdown-item"
                    >
                      x1
                    </div>
                  )}
                  {playSpeed !== 2 && (
                    <div
                      onClick={handleChangePlaySpeed}
                      data-speed={2}
                      className="dropdown-item"
                    >
                      x2
                    </div>
                  )}
                  {playSpeed !== 4 && (
                    <div
                      onClick={handleChangePlaySpeed}
                      data-speed={4}
                      className="dropdown-item"
                    >
                      x4
                    </div>
                  )}
                </Dropdown>

                <div className="form-check form-check-inline ml-2">
                  <input
                    checked={skipIdle}
                    className="form-check-input"
                    type="checkbox"
                    id="skip-idle-checkbox"
                    onChange={handleToggleSkipIdle}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="skip-idle-checkbox"
                  >
                    Skip Idle
                  </label>
                </div>
              </div>

              <div className="control-group mr-auto play-progress-parent">
                <div className="play-progress">
                  <span className="mr-1 ml-2" id="current-duration">
                    {moment
                      .duration(progress, "milliseconds")
                      .format("HH:mm:ss", { trim: false })}
                  </span>
                  /
                  <span className="mr-1 ml-1" id="total-duration">
                    {moment
                      .duration(duration, "milliseconds")
                      .format("HH:mm:ss", { trim: false })}
                  </span>
                </div>
              </div>
              <div className="control-group">
                <Button
                  size="small"
                  onClick={handleToggleUserJourney}
                  disabled={isLoading}
                >
                  <u>Replay Journey</u>
                </Button>
                <Button
                  className="mx-2"
                  size="small"
                  variant="primary"
                  onClick={handleClickAddNote}
                  disabled={isLoading}
                >
                  Save Note
                </Button>
                <Button
                  className="mx-2"
                  size="small"
                  disabled={isExporting}
                  onClick={handleExport}
                  title="Export Report"
                >
                  {isExporting && <span className="fa fa-spinner spin"></span>}
                  {!isExporting && (
                    <div className="export-icon">
                      <FaFileDownload size={16} />
                    </div>
                  )}
                </Button>
                <Button
                  size="small"
                  className="mx-2"
                  onClick={handleClickFullscreen}
                >
                  <i
                    className={`fa ${
                      isFullscreen ? "fa-compress" : "fa-expand"
                    }`}
                  ></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
