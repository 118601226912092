import React from "react";
import {
    requestPasswordRecovery,
} from './../../../lib/firebase';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Alert } from './../../../components/share/InsightUI';
import { passwordRecoveryFormValidator } from './../../../helpers/formValidators';
import { Formik } from 'formik';

import './../common.scss';

const ResetPasswordRequest = (props) => {
    const [isLinkSent, setisLinkSent] = React.useState(false);

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        requestPasswordRecovery(values.email, ()=>{
            setisLinkSent(true);
        }, ()=>{
            setisLinkSent(true);
        })
    }

    return (
        <div className="page-container container">
            <Link className="main-logo" to="/">
                <img className=""
                    srcSet="/images/logo@2x.png 2x, /images/logo.png 1x"
                    src="/images/logo.png" alt="" />
            </Link>
            <h1 className="h4 text-gray-900 mt-4 mb-4">Recover your Password</h1>
            {
                isLinkSent ?
                <Alert variant="success">
                    If account exists, an email will be
                    {` `}
                    sent with further instructions
                </Alert> :
                <div className="signup-form">
                    <Formik
                        initialValues={{ email: '' }}
                        validate={passwordRecoveryFormValidator}
                        validateOnBlur={false}
                        validateOnChange={true}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <Form
                                    className="user"
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting || props.isBusy}
                                >
                                    {props.signinError &&
                                        <Alert variant="danger">{props.signinError}</Alert>
                                    }
                                    <Input
                                        required
                                        isInvalid={!!(touched.email && errors.email)}
                                        invalidFeedback={errors.email}
                                        className="form-control-user"
                                        name="email"
                                        placeholder="Email Address"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="email"
                                        value={values.email} />

                                    <Button type="submit"
                                        className="btn-user btn-block"
                                        variant="primary">Request Password Recovery</Button>
                                    <hr />
                                </Form>
                            )}
                    </Formik>
                </div>
            }
            <p className="signin-text text-center">
                <small><Link to="/signup">Create an Account!</Link></small>
                <br />
                <small>Already signed up? <Link to="/signin">Sign In</Link></small>
            </p>
        </div>
    );
};

export default ResetPasswordRequest;