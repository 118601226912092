import React from "react";
import "./style.scss";

function CardCheckbox(props) {
  const { title, id, onChange, children, ...rest } = props;

  return (
    <div className="card-checkbox">
      <label htmlFor={id}></label>
      <input id={id} type="checkbox" onChange={onChange} {...rest} />
      <div className="bg"></div>
      <span className="fa fa-check text-gray-900"></span>
      <h4 className="title text-gray-500">{title}</h4>
      {children}
    </div>
  );
}

CardCheckbox.propTypes = {};
CardCheckbox.defaultProps = {};

export default CardCheckbox;
