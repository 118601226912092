import React, { useEffect } from "react";

const timezones = require("../../../inc/timezone.json");

const TimezoneField = ({
  id,
  defaultTimezone,
  helpText = '',
  setTimezone,
  textOnly
}) => {

  if (typeof (id) === 'undefined') {
    id = "timezone";
  }

  var array = [];
  Object.keys(timezones).forEach(function (key) {
    array.push({
      title: key,
      value: timezones[key]
    });
  });

  const handleChange = (event) => {
    setTimezone(event.target.value);
  }

  useEffect(()=>{
    if (typeof (defaultTimezone) === 'undefined' || defaultTimezone === null) {
      setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let defaultTimezoneText = '';

  const dropdown = (
    <select id={id}
      className="form-control"
      value={defaultTimezone}
      onChange={handleChange}
    >
      {array.map((timezone, pk) => {
        if(defaultTimezone === timezone.value){
          defaultTimezoneText = timezone.title;
        }
        return (
          <option key={"tz"+pk} value={timezone.value}>{timezone.title}</option>
        )
      })}
    </select>
  )

  return (
    <div className="form-group row">
      <label htmlFor={id} className="col-sm-2 col-form-label">Timezone</label>
      <div className="col-sm-10 col-md-4 col-lg-6">
        {textOnly ?
          <div className="mt-1">{defaultTimezoneText}</div> :
          dropdown
        }
        {!textOnly && helpText !== '' && 
          <small id={id+"help"} className="form-text text-muted">{helpText}</small>
        }
      </div>
    </div>
  );
}

export default TimezoneField;