import React, { useState } from "react";
import { FaRegHandPointer } from "react-icons/fa6";
import { MdOutlineAdsClick } from "react-icons/md";
import { PiCursorClickBold } from "react-icons/pi";
import { FaRegAngry } from "react-icons/fa";
import { LuGoal, LuCircleDollarSign } from "react-icons/lu";
import { AiOutlineCopy } from "react-icons/ai";
import {
  thousandSeparatorAndFixedDecimal,
  ellipsize,
  kSeparator,
} from "../../../../utils";
import Notification from "../../../share/InsightUI/Notification";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ElementStats = (props) => {
  const { data, viewSize } = props;
  const [isCopied, setIsCopied] = useState(false);

  // Handler: Copy the element CSS selector to the clipboard
  const handleCopySelector = () => {
    navigator.clipboard.writeText(data.path);
    setIsCopied(true);

    // Hide the notification after 3 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handleCloseNotification = () => {
    setIsCopied(false);
  };

  // Tooltip content for each stat
  const getTooltipContent = (type) => {
    const formattedPageviews = kSeparator(data.totalPageviews);
    const tooltips = {
      hovers: `${kSeparator(
        data.hovers
      )} element hovers / ${formattedPageviews} pageviews`,
      clicks: `${kSeparator(
        data.clicks
      )} element clicks / ${formattedPageviews} pageviews`,
      lastClicks: `${kSeparator(
        data.lastClicks
      )} element last clicks / ${formattedPageviews} pageviews`,
      rageClicks: `${kSeparator(
        data.rageClicks
      )} element rage clicks / ${formattedPageviews} pageviews`,
      conversions: `${kSeparator(
        data.conversions
      )} converted session / ${kSeparator(
        data.clickedSessions
      )} clicked sessions`,
      revenue: `$${thousandSeparatorAndFixedDecimal(
        data.revenue,
        2,
        2
      )} total revenue and $${thousandSeparatorAndFixedDecimal(
        data.revenueAvg,
        2,
        2
      )}average revenue from converted sessions`,
    };

    return tooltips[type];
  };

  // Prevent injecting massive text in the DOM
  const contentTextShownMaxLenght = viewSize === "lg" ? 700 : 150;
  const contentTextShown = data?.innerText
    ? ellipsize(data.innerText, contentTextShownMaxLenght)
    : "";

  return (
    <>
      <div className="element-stats">
        <div
          className="element-tag text-ellipsis"
          data-tooltip-id="element-stats-tooltip"
          data-tooltip-content={contentTextShown}
        >
          <strong>
            {`<${data.tag}> Element`}
            {!!contentTextShown && <span>{` - "${contentTextShown}"`}</span>}
          </strong>
        </div>
        <h4>Engagement</h4>
        <table className="table">
          <tbody>
            <tr
              data-tooltip-id="element-stats-tooltip"
              data-tooltip-html={getTooltipContent("hovers")}
            >
              <td className="stat-name">
                <span className="icon">
                  <MdOutlineAdsClick color="#4a4afa" />
                </span>
                Hovers
              </td>
              <td className="stat-value">{kSeparator(data.hovers)}</td>
              <td className="stat-percentage">{data.hoversPct}</td>
            </tr>
            <tr
              data-tooltip-id="element-stats-tooltip"
              data-tooltip-html={getTooltipContent("clicks")}
            >
              <td className="stat-name">
                <span className="icon">
                  <FaRegHandPointer color="#4a4afa" />
                </span>
                Clicks
              </td>
              <td className="stat-value">{kSeparator(data.clicks)}</td>
              <td className="stat-percentage">{data.clicksPct}</td>
            </tr>
            <tr
              data-tooltip-id="element-stats-tooltip"
              data-tooltip-html={getTooltipContent("lastClicks")}
            >
              <td className="stat-name">
                <span className="icon">
                  <PiCursorClickBold color="#4a4afa" />
                </span>
                Last Clicks
              </td>
              <td className="stat-value">{kSeparator(data.lastClicks)}</td>
              <td className="stat-percentage">{data.lastClicksPct}</td>
            </tr>
            <tr
              data-tooltip-id="element-stats-tooltip"
              data-tooltip-html={getTooltipContent("rageClicks")}
            >
              <td className="stat-name">
                <span className="icon">
                  <FaRegAngry color="#FD9041" />
                </span>
                Rage Clicks
              </td>
              <td className="stat-value">{kSeparator(data.rageClicks)}</td>
              <td className="stat-percentage">{data.rageClicksPct}</td>
            </tr>
            <tr
              data-tooltip-id="element-stats-tooltip"
              data-tooltip-html={getTooltipContent("conversions")}
            >
              <td className="stat-name">
                <span className="icon">
                  <LuGoal color="#05CF9B" />
                </span>
                Conversions
              </td>
              <td className="stat-value">{kSeparator(data.conversions)}</td>
              <td className="stat-percentage">{data.conversionsPct}</td>
            </tr>
            <tr
              data-tooltip-id="element-stats-tooltip"
              data-tooltip-html={getTooltipContent("revenue")}
            >
              <td className="stat-name">
                <span className="icon">
                  <LuCircleDollarSign color="#05CF9B" />
                </span>
                Revenue / Avg
              </td>
              <td className="stat-value">
                {"$" + thousandSeparatorAndFixedDecimal(data.revenue, 2, 2)}
              </td>
              <td className="stat-percentage">
                {"$" + thousandSeparatorAndFixedDecimal(data.revenueAvg, 2, 2)}
              </td>
            </tr>
          </tbody>
        </table>

        <h4>
          Selector
          <AiOutlineCopy className="copy-icon" onClick={handleCopySelector} />
        </h4>
        <div className="element-path">
          <span>{data.path}</span>
        </div>
      </div>
      {isCopied && (
        <Notification
          message="Successfully copied element selector!"
          onClose={handleCloseNotification}
        />
      )}
      <ReactTooltip
        id="element-stats-tooltip"
        className={viewSize ? `tooltip-${viewSize}` : "tooltip-md"}
      />
    </>
  );
};

export default ElementStats;
