import React from "react";
// import PropTypes from 'prop-types';

function CollapsableCard(props) {
  const { title, id } = props;

  return (
    <div className="card data-tile mb-4">
      <a
        href={id}
        className="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls={id}
      >
        <h6 className="m-0 font-weight-bold">{title}</h6>
      </a>

      <div className="collapse show" id={id}>
        <div className="card-body">{props.children}</div>
      </div>
    </div>
  );
}

CollapsableCard.propTypes = {};
CollapsableCard.defaultProps = {};

export default CollapsableCard;
