import React, { Fragment } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";

const RadioButton = (props) => {
  const { changed, id, isSelected, label, value, btnIcon, tooltip } = props;

  return (
    <Fragment>
      <div
        className="radio-button"
        data-tooltip-id={tooltip ? "modal-tooltip" : null}
        data-tooltip-content={tooltip ? tooltip : null}
      >
        <input
          id={id}
          onChange={changed}
          value={value}
          type="radio"
          checked={isSelected}
        />
        <label htmlFor={id}>
          {label}
          {btnIcon}
        </label>
      </div>
      <ReactTooltip id="modal-tooltip" className="tooltip-md" />
    </Fragment>
  );
};

export default RadioButton;
