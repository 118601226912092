import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import { deepCopy, checkNestedProperty } from "./../../../utils";

const defaultConditions = require("./../../../inc/eventConditions");
const operators = require("./../../../inc/operators");
const units = require("./../../../inc/units");
const eventTypes = require("./../../../inc/subEventsFilters");
const operatorConditions = require("./../../../inc/operatorConditions.json");

function SubCondition(props) {
  const {
    invalidFields,
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    handleUpdateSubCondition,
    handleClickAddSubConditionRow,
    handleClickDeleteSubConditionRow,
    data,
    customConditions,
    editFieldOnly,
    conditionType,
  } = props;

  const invalidFieldKey =
    conditionType === "event"
      ? "includedPageContainers"
      : "excludedPageContainers";

  const { attribute, path, unit, operator, values } = data;
  const conditions = customConditions ? customConditions : defaultConditions;
  const [availableOperators, setAvailableOperators] = useState([]);
  const operatorType = conditions[attribute]
    ? conditions[attribute].operatorType
    : null;
  const availableUnits = units[operatorType] ? units[operatorType] : null;
  const availableConditionsKeys =
    eventTypes[type] && eventTypes[type] ? eventTypes[type].attributes : null;
  const operatorCondition =
    operator && operatorConditions[operator]
      ? operatorConditions[operator]
      : null;

  useEffect(() => {
    let conditionKey =
      conditions[attribute] && conditions[attribute]["operatorType"]
        ? conditions[attribute]["operatorType"]
        : null;
    let conditionOperators = conditionKey ? operators[conditionKey] : null;
    if (conditionOperators) {
      setAvailableOperators(conditionOperators);
    } else {
      setAvailableOperators([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);

  const onClickDeleteSubConditionRow = (e) => {
    handleClickDeleteSubConditionRow(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex
    );
  };

  const onClickAddSubConditionRow = (e) => {
    handleClickAddSubConditionRow(
      props.conditionType,
      props.containerIndex,
      props.eventIndex
    );
  };

  const handleChangeAttribute = (e) => {
    // Reset condition if attribute is changed
    const state = {
      attribute: e.target.value,
      path: "",
      unit: "",
      operator: "",
      values: [""],
    };
    if (e.target.value === "FieldSelected") {
      state["values"] = ["true"];
    }
    handleUpdateSubCondition(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex,
      state
    );
  };

  const handleChangeOperator = (e) => {
    const state = deepCopy(data);
    state["operator"] = e.target.value;
    if (operatorType && units[operatorType]) {
      state["unit"] = units[operatorType][0].key;
    }
    handleUpdateSubCondition(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex,
      state
    );
  };

  const handleChangeValue = (e) => {
    const state = deepCopy(data);
    state.values[e.target.dataset.index] = e.target.value;
    handleUpdateSubCondition(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex,
      state
    );
  };

  const handleChangePath = (e) => {
    const state = deepCopy(data);
    state["path"] = e.target.value;
    handleUpdateSubCondition(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex,
      state
    );
  };

  const handleChangeUnit = (e) => {
    const state = deepCopy(data);
    state["unit"] = e.target.value;
    handleUpdateSubCondition(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex,
      state
    );
  };

  const handleClickAddValueField = (e) => {
    const state = deepCopy(data);
    state.values.push("");
    handleUpdateSubCondition(
      props.conditionType,
      props.containerIndex,
      props.eventIndex,
      props.conditionIndex,
      state
    );
  };

  const handleClickRemoveValueField = (e) => {
    const state = deepCopy(data);
    const index = parseInt(e.currentTarget.dataset.index, 0);
    if (index === 0) {
      state.values[0] = "";
      handleUpdateSubCondition(
        props.conditionType,
        props.containerIndex,
        props.eventIndex,
        props.conditionIndex,
        state
      );
    }
    if (state.values.length > 1) {
      state.values.splice(index, 1);
      handleUpdateSubCondition(
        props.conditionType,
        props.containerIndex,
        props.eventIndex,
        props.conditionIndex,
        state
      );
    }
  };

  return (
    <div className="rule condition">
      <div className="rule-group">
        {props.id === 1 ? (
          <span className="conj">and the</span>
        ) : (
          <span className="conj">and the</span>
        )}

        {/** Condition Field */}
        <div className="field-col">
          <select
            className="form-control form-control-sm"
            name="condition"
            value={attribute}
            onChange={handleChangeAttribute}
          >
            <option value="">Choose a Condition</option>
            {availableConditionsKeys.map((key) => {
              const filter = conditions[key];
              return filter ? (
                <option key={filter.key} value={filter.key}>
                  {filter.label}
                </option>
              ) : null;
            })}
          </select>
        </div>

        {/** JSON Path  or Api Error JSON Path Payload*/}
        {type &&
          attribute &&
          (attribute === "JSONPath" ||
            attribute === "ApiErrorJsonPathPayload") && (
            <div className="field-col">
              <input
                placeholder="Define JSON Path"
                type="text"
                className="form-control form-control-sm"
                name="value"
                value={path}
                onChange={handleChangePath}
              />
            </div>
          )}

        {/** Operator Field */}
        {!!(
          (type &&
            attribute &&
            (attribute !== "JSONPath" ||
              attribute !== "ApiErrorJsonPathPayload") &&
            availableOperators.length) ||
          (type &&
            attribute &&
            (attribute === "JSONPath" ||
              attribute === "ApiErrorJsonPathPayload") &&
            path &&
            availableOperators.length)
        ) && (
          <div className="field-col">
            <select
              className={`form-control form-control-sm ${
                checkNestedProperty(invalidFields, [
                  invalidFieldKey,
                  containerIndex,
                  "eventConditions",
                  eventIndex,
                  "conditions",
                  conditionIndex,
                  "operator",
                ]) && "form-control-error"
              }`}
              name="operator"
              value={operator}
              onChange={handleChangeOperator}
            >
              <option value="">Choose an operator</option>
              {availableOperators.map((op) => {
                return (
                  <option key={op.key} value={op.key}>
                    {op.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        {/** Value Field */}
        {(!!(type && attribute && attribute !== "Any" && operator) ||
          !!(type && attribute && operatorType === "boolean")) &&
          (operatorType === "boolean" ? (
            <div className="field-col">
              <select
                value={values[0]}
                data-index={0}
                className="form-control form-control-sm"
                onChange={handleChangeValue}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          ) : (
            values.map((val, index) => {
              let inputType = "text";
              switch (operatorType) {
                case "number":
                case "duration":
                  inputType = "number";
                  break;
                default:
                  break;
              }
              return (
                <React.Fragment key={index}>
                  <div
                    className={`field-col value-field-container
                      ${availableUnits && "with-unit"}`}
                    key={index}
                  >
                    <span className="conj">or</span>
                    <input
                      type={inputType}
                      className="form-control form-control-sm"
                      name="value"
                      data-index={index}
                      value={val}
                      onChange={handleChangeValue}
                    />
                    {!availableUnits && (
                      <Button
                        className="btn-delete"
                        data-index={index}
                        size="small"
                        icon="fas fa-times"
                        onClick={handleClickRemoveValueField}
                      ></Button>
                    )}
                  </div>

                  {/* Units Field */}
                  {availableUnits && (
                    <div className="field-col unit-field-container">
                      <select
                        className="form-control form-control-sm"
                        value={unit}
                        onChange={handleChangeUnit}
                      >
                        {availableUnits.map((unit, index) => {
                          return (
                            <option key={index} value={unit.key}>
                              {unit.label}
                            </option>
                          );
                        })}
                      </select>
                      <Button
                        className="btn-delete"
                        data-index={index}
                        size="small"
                        icon="fas fa-times"
                        onClick={handleClickRemoveValueField}
                      ></Button>
                    </div>
                  )}
                </React.Fragment>
              );
            })
          ))}

        {/** OR Button */}
        {type &&
          attribute &&
          attribute !== "Any" &&
          operator &&
          !editFieldOnly &&
          (!operatorCondition ||
            (operatorCondition && !operatorCondition.singleValue)) && (
            <div className="field-col add-btn-container">
              <Button
                variant="secondary"
                size="small"
                onClick={handleClickAddValueField}
              >
                or
              </Button>
            </div>
          )}

        <div className="field-col add-condition-btn-container">
          {type && !editFieldOnly && attribute && (
            <Button
              size="small"
              onClick={onClickAddSubConditionRow}
              variant="secondary"
            >
              and
            </Button>
          )}
        </div>
      </div>

      <div className="actions">
        {!editFieldOnly && (
          <Button
            icon="fas fa-times-circle"
            onClick={onClickDeleteSubConditionRow}
          ></Button>
        )}
      </div>
    </div>
  );
}

SubCondition.propTypes = {
  handleDiscard: PropTypes.func,
};
SubCondition.defaultProps = {};

export default SubCondition;
