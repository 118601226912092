import React, { useContext, useState } from "react";
import Alert from "../../../../components/share/Alert";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import TextField from "../../../../components/share/fields/TextField";

import config from "../../../../config/config.json";

import { Field } from "../../../../components/share/InsightUI";
import SubmitButtons from "../../../../components/share/fields/SubmitButtons";
import api from "../../../../api";

const defineApiServer = (apiSubDomain) => {
  const server =
    config.dataApiLocalhost || `https://${apiSubDomain}.insightech.com`;

  api.apiServer = server;
};

const DeleteAccount = (param) => {
  const { authUser } = useContext(AuthContext);

  const apiServers = config.dataApiServers;
  const [apiServer, setApiServer] = useState(apiServers[0]);

  const [accountId, setAccountId] = useState("");
  const [confirm, setConfirm] = useState("");

  const [processing, setProcessing] = useState(false);

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  // Todo make sure we can't acess without beoing logged in
  return (
    <>
      <>
        <div className="row">
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            count={alert.count}
          />
        </div>
        <div className="row">
          <div className="col mb-4">
            <div className="card shadow mb-4">
              <div className="card-body">
                <p>Please make sure there are no more profiles</p>
                <TextField
                  handleChange={(e) => {
                    setAccountId(e.target.value);
                  }}
                  id="account-id"
                  label="Account Id"
                  placeholder="e.g. xxxxx"
                  text={accountId}
                  helpText="Account id."
                ></TextField>

                <Field inline label="Server" columnwidths={[2, 4]}>
                  <select
                    value={apiServer}
                    className="form-control form-control"
                    name="apiServer"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setApiServer(e.target.value);
                    }}
                    required
                  >
                    {apiServers.map((server, index) => (
                      <option key={index} value={server}>
                        {server}
                      </option>
                    ))}
                  </select>
                </Field>

                <TextField
                  handleChange={(e) => {
                    console.log("confirm", e.target.value);
                    setConfirm(e.target.value);
                  }}
                  id="confirm"
                  label="Confirm"
                  placeholder="Type `delete` to confirm"
                  text={confirm}
                  helpText="Type `delete` to confirm"
                ></TextField>

                <SubmitButtons
                  processing={processing}
                  backButtonTo="/"
                  handleClick={(e) => {
                    e.preventDefault();
                    if (confirm !== "delete") {
                      setAlert({
                        show: true,
                        type: "danger",
                        message: "Please type `delete` to confirm",
                        count: alert.count + 1,
                      });
                      return;
                    }
                    setProcessing(true);
                    defineApiServer(apiServer);

                    api
                      .deleteAccount(authUser, {
                        accountId: accountId,
                      })
                      .then((res) => {
                        if (res.status === 200 && res.data.status === 200) {
                          // display generic error
                          setAlert({
                            show: true,
                            type: "info",
                            message:
                              "Account id:" +
                              res.data.data.accountId +
                              ", name:" +
                              res.data.data.accountName +
                              " deleted",
                            count: alert.count + 1,
                          });
                          return;
                        }
                        // Not ok, show error
                        const errMsg =
                          res.data.result ||
                          "Oops, something went wrong. Please try again later.";
                        // display generic error
                        setAlert({
                          show: true,
                          type: "danger",
                          message: errMsg,
                          count: alert.count + 1,
                        });
                      })
                      .catch((res) => {
                        // http status not ok, show error
                        const errMsg =
                          res?.response?.data?.result ||
                          "Oops, something went wrong. Please try again later.";
                        setAlert({
                          show: true,
                          type: "danger",
                          message: errMsg,
                          count: alert.count + 1,
                        });
                      })
                      .finally(() => {
                        setProcessing(false);
                      });
                  }}
                ></SubmitButtons>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default DeleteAccount;
