import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function OutlinedContainer({ padding=15, ...props }){

  return (
    <div
      className={`outlined-container ${props.className}`}
      style={{
        padding: padding
      }}
    >
      {props.children}
    </div>
  );
}

OutlinedContainer.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
OutlinedContainer.defaultProps = {}

export default OutlinedContainer;