import React from "react";
import PropTypes from "prop-types";
import { Button } from "./../InsightechUI";
import "./style.scss";

function DropdownAction(props) {
  const { label, align, btnVariant } = props;
  let alignClass = "";
  switch (align) {
    case "right":
      alignClass = "dropdown-menu-right";
      break;
    case "left":
      alignClass = "dropdown-menu-left";
      break;
    default:
      alignClass = "";
      break;
  }

  return (
    <div className={`dropdown ${props.className}`}>
      <Button
        color={btnVariant || "plain-default"}
        size="chip"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {label ? label : <span className="fa fa-ellipsis-v"></span>}
      </Button>
      <div
        className={`dropdown-menu ${alignClass} shadow`}
        aria-labelledby="dropdownMenuButton"
      >
        {props.children}
      </div>
    </div>
  );
}

DropdownAction.propTypes = {
  align: PropTypes.oneOf(["right", "left"]),
};
DropdownAction.defaultProps = {};

export default DropdownAction;
