import React, { useEffect, useState } from "react";
import "./style.scss";

const Badge = ({ val, isAnimation }) => {
  const [animate, setAnimate] = useState(false);

  // Listen for changes to set animation
  useEffect(() => {
    if (isAnimation) {
      setAnimate(true);

      const animationTimeout = setTimeout(() => {
        setAnimate(false);
      }, 2000);

      return () => clearTimeout(animationTimeout);
    } else {
      setAnimate(false);
    }
  }, [val, isAnimation]);

  return (
    <span
      className={`badge inner-badge badge-danger ${
        animate ? "shadow-pulse" : ""
      }`}
    >
      {val}
    </span>
  );
};

export default Badge;
