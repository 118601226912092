import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserDataContext } from "../../components/app/UserData";
import { defaultSortOrder, pushNonProfileViewEvent } from "../../utils";
import { AuthContext } from "../../components/auth/FirebaseAuthContext";
import InviteCard from "../../components/share/InviteCard";

const Home = () => {
  const { access, invites } = useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    pushNonProfileViewEvent(authUser.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      <InviteNotification invites={invites}></InviteNotification>
      <ProfileList access={access}></ProfileList>
    </div>
  );
};

const InviteNotification = ({ invites }) => {
  return <InviteCard invites={invites} />;
};

const ProfileList = ({ access }) => {
  return (
    <>
      {access.loadingAccess === false &&
        access.accounts !== null &&
        access.accounts.sort(defaultSortOrder("name")).map((account, key) => {
          return (
            <div className="col-12 mb-4" key={account.id}>
              <div className="card data-tile mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold">{account.name}</h6>
                  {account.isAdmin && (
                    <div className="dropdown no-arrow">
                      <a
                        className="dropdown-toggle"
                        href="/#"
                        role="button"
                        data-toggle="dropdown"
                        id={account.id + "-dropdown"}
                        aria-haspopup="true"
                        area-expaneded="false"
                      >
                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby={account.id + "-dropdown"}
                      >
                        <div className="dropdown-header">Manage Account:</div>
                        <Link
                          className="dropdown-item"
                          to={"/account/" + account.id + "/users"}
                        >
                          Manage Users
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link
                          className="dropdown-item"
                          to={"/account/" + account.id + "/create-profile"}
                        >
                          Create Profile
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  {account.profiles !== null && account.profiles.length > 0 && (
                    <div className="table-responsive">
                      <div className="dataTable">
                        <div className="row">
                          <div className="col-sm-12">
                            <table className="table table-bordered">
                              <thead>
                                <tr className="small">
                                  <th>Profile</th>
                                  <th>ID</th>
                                </tr>
                              </thead>
                              <tbody>
                                {account.profiles
                                  .sort(defaultSortOrder("name"))
                                  .map((profile, pk) => {
                                    return (
                                      <tr key={profile.id}>
                                        <td className="font-weight-bold small">
                                          <a
                                            href={
                                              "/profile/" +
                                              profile.id +
                                              "/analytics/dashboard"
                                            }
                                          >
                                            {profile.name}
                                          </a>
                                        </td>
                                        <td className="small">{profile.id}</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(account.profiles === null ||
                    account.profiles.length === 0) && (
                    <span>
                      No profiles in this account yet, please create a new
                      profile.
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      {access.loadingAccess === false &&
        (access.accounts === null || access.accounts.length === 0) && (
          <div className="col-12 mb-4">
            You don't have access to any accounts yet, please ask your admin to
            grant you access if your team is using Insightech, or{" "}
            <a href="https://www.insightech.com/demo" target="_blank">
              talk to our sales team
            </a>{" "}
            to use Insightech.
          </div>
        )}
      {access.loadingAccess === true && (
        <div className="col-12 mb-4">
          <span>
            <i className="fa fa-spinner fa-spin" />
            Loading accounts...
          </span>
        </div>
      )}
    </>
  );
};

export default Home;
