import React, { useContext, useState } from "react";
import Alert from "../../../../components/share/Alert";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import TextField from "../../../../components/share/fields/TextField";

import { HiOutlineExternalLink } from "react-icons/hi";
import config from "../../../../config/config.json";

import { Field } from "./../../../../components/share/InsightUI";
import SubmitButtons from "../../../../components/share/fields/SubmitButtons";
import api from "../../../../api";

const defineApiServer = (apiSubDomain) => {
  const server =
    config.dataApiLocalhost || `https://${apiSubDomain}.insightech.com`;

  api.apiServer = server;
};

const CreateAccount = (param) => {
  const { authUser } = useContext(AuthContext);

  const apiServers = config.dataApiServers;
  const [accountName, setAccountName] = useState("");
  const [planName, setPlanName] = useState("");
  const [apiServer, setApiServer] = useState(apiServers[0]);
  const [processing, setProcessing] = useState(false);
  const [createdAccountId, setCreatedAccountId] = useState(null);

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const handleSumbit = (e) => {
    e.preventDefault();
    setProcessing(true);
    defineApiServer(apiServer);

    api
      .createAccount(authUser, {
        accountName: accountName,
        apiServer: apiServer,
        planName: planName,
      })
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if (res.data.status === 200) {
            // update the access data
            // redirect to next step
            setCreatedAccountId(res.data.data.accountId);
            return;
          }
          const errMsg =
            res.data.result ||
            "Oops, something went wrong. Please try again later.";

          setAlert({
            show: true,
            type: "danger",
            message: errMsg,
            count: alert.count + 1,
          });
        }
      })
      .catch((res) => {
        // http status not ok, show error
        const errMsg =
          res?.response?.data?.result ||
          "Oops, something went wrong. Please try again later.";
        setAlert({
          show: true,
          type: "danger",
          message: errMsg,
          count: alert.count + 1,
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  // Todo make sure we can't acess without beoing logged in
  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              {createdAccountId !== null ? (
                <>
                  <p>
                    Account {createdAccountId} was created
                    <ul>
                      <li>
                        <a
                          href={"/account/" + createdAccountId + "/users"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiOutlineExternalLink size={16} />
                          Manage Account Users
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "/account/" + createdAccountId + "/create-profile"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiOutlineExternalLink size={16} />
                          Create profile
                        </a>
                      </li>
                    </ul>
                  </p>
                </>
              ) : (
                <>
                  <TextField
                    handleChange={(e) => {
                      setAccountName(e.target.value);
                    }}
                    id="profile-name"
                    label="Account Name"
                    placeholder="e.g. Acme corporation"
                    text={accountName}
                    helpText="Account name."
                  ></TextField>

                  <TextField
                    handleChange={(e) => {
                      setPlanName(e.target.value);
                    }}
                    id="plan-name"
                    label="Plan Name"
                    placeholder="e.g. US 3mo"
                    text={planName}
                    helpText="Plan name. e.g. US 3mo"
                  ></TextField>

                  <Field inline label="Server" columnwidths={[2, 4]}>
                    <select
                      value={apiServer}
                      className="form-control form-control"
                      name="apiServer"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setApiServer(e.target.value);
                      }}
                      required
                    >
                      {apiServers.map((server, index) => (
                        <option key={index} value={server}>
                          {server}
                        </option>
                      ))}
                    </select>
                  </Field>

                  <SubmitButtons
                    processing={processing}
                    backButtonTo="/"
                    handleClick={handleSumbit}
                  ></SubmitButtons>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
