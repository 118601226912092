import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { Form, Button, Switch } from "./../../../components/share/InsightUI";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CLOSED_FUNNEL, OPEN_FUNNEL } from "../../../constants/info";
import { UserDataContext } from "./../../app/UserData";
import { AuthContext } from "./../../auth/FirebaseAuthContext";
import { ReducerContext } from "./../../../reducer/context";
import {
  saveFunnel,
  fetchFunnel,
  deleteFunnelById,
} from "./../../../lib/firebase/funnels";
import {
  ADD_TOAST,
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  CONFIRM_BUSY,
  CONFIRM_IDLE,
} from "./../../../reducer/index";
import { deepCopy, saveProfilePref } from "./../../../utils";
import {
  eventFixture,
  pageContainerFixture,
} from "./../../../fixtures/filters";
import { funnelValidator } from "./../../../helpers/formValidators";
import { DEFAULT_FUNNEL_FOLDER_NAME } from "../../../constants/funnels";
import FunnelScreen from "./../FunnelScreen";
import FunnelStep from "./FunnelStep";
import FunnelFoldersDropdown from "./FunnelFoldersDropdown";
import "./style.scss";

const events = require("./../../../inc/funnelEvents.json");

function FunnelBuilder(props) {
  const initialData =
    props.history.location.state && props.history.location.state.initialData
      ? props.history.location.state.initialData
      : null;

  const {
    funnelId,
    setFunnelId,
    funnelFolders,
    setFunnelFolders,
    funnelFoldersMap,
    setSelectedFunnelFolder,
  } = props;
  const { dispatch } = useContext(ReducerContext);
  const { authUser } = useContext(AuthContext);
  const { activeProfileId } = useContext(UserDataContext);

  const [stepFilters, setStepFilters] = useState([
    deepCopy(pageContainerFixture),
  ]);
  const [funnelSteps, setFunnelSteps] = useState([
    {
      name: "",
      filters: "",
    },
  ]);
  const [invalidSteps, setInvalidSteps] = useState({});
  const [validSteps, setValidSteps] = useState({});
  const [funnelData, setFunnelData] = useState({
    name: "",
    description: "",
    tags: [],
    folder: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [invalidFields, setInvalidFields] = useState([
    {
      includePageContainers: [],
      total: 0,
    },
  ]);
  const [isFoldersDropdownVisible, setIsFoldersDropdownVisible] =
    useState(false);

  const toggleFoldersDropdown = (e) => {
    e.preventDefault();
    setIsFoldersDropdownVisible(!isFoldersDropdownVisible);
  };

  const handleClickCopyFunnel = () => {
    props.history.push(`/profile/${activeProfileId}/funnels/edit`, {
      initialData: {
        funnelData: { ...funnelData, name: `Copy of ${funnelData.name}` },
        funnelSteps,
        stepFilters,
      },
    });
    setFunnelId(null); // for save the copied funnel with new funnelId
  };

  const handleClickAddStep = () => {
    const filters = deepCopy(stepFilters);
    const steps = deepCopy(funnelSteps);

    filters.push({
      inclusion: true,
      eventConditions: [deepCopy(eventFixture)],
    });
    steps.push({
      name: "",
      filters: "",
    });
    setStepFilters(filters);
    setFunnelSteps(steps);
  };

  const handleClickCancel = () => {
    if (funnelId) {
      props.history.push(
        `/profile/${props.match.params.profileId}/funnels/view/${funnelId}`
      );
    } else {
      props.history.push(
        `/profile/${props.match.params.profileId}/funnels/list`
      );
    }
  };

  const handleClickDeleteFunnel = async (e) => {
    const funnelId = e.currentTarget.dataset["funnelid"];
    // Fetch this delete funnel data to get the folder
    let deletedFunnel = await fetchFunnel({
      profileId: activeProfileId,
      funnelId,
    }).then((res) => {
      return res.data();
    });

    dispatch({
      type: OPEN_CONFIRM,
      confirmConfig: {
        title: "Confirm Action",
        text: "Are you sure you want to delete this funnel?",
        confirmText: "Delete Funnel",
        confirmAction: () => {
          dispatch({ type: CONFIRM_BUSY });
          deleteFunnelById(props.match.params.profileId, funnelId).then(() => {
            dispatch({ type: CONFIRM_IDLE });
            dispatch({ type: CLOSE_CONFIRM });
            props.history.push(
              `/profile/${props.match.params.profileId}/funnels/list`
            );
          });
          // reset to "General" folder If it is the last funnel in the folder
          if (funnelFoldersMap?.get(deletedFunnel?.folder) === 1) {
            setSelectedFunnelFolder({
              label: DEFAULT_FUNNEL_FOLDER_NAME,
              value: DEFAULT_FUNNEL_FOLDER_NAME,
            });

            saveProfilePref(
              activeProfileId,
              "lastFunnelFolder",
              DEFAULT_FUNNEL_FOLDER_NAME
            );
          }
        },
      },
    });
  };

  const handleUpdateCondition = (
    stepIndex,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex] = data;
    setStepFilters(newData);
  };

  const handleClickAddEventConditionRow = (stepIndex, containerIndex) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions.push(deepCopy(eventFixture));
    setStepFilters(newData);
  };

  const handleClickDeleteEventConditionRow = (
    stepIndex,
    containerIndex,
    eventIndex
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions.splice(eventIndex, 1);
    setStepFilters(newData);
  };

  const handleUpdateSubCondition = (
    stepIndex,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex].conditions[conditionIndex] =
      data;
    setStepFilters(newData);
  };

  const handleClickAddSubConditionRow = (
    stepIndex,
    containerIndex,
    eventIndex
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex].conditions.push(
      deepCopy(eventFixture)
    );
    setStepFilters(newData);
  };

  const handleClickDeleteSubConditionRow = (
    stepIndex,
    containerIndex,
    eventIndex,
    conditionIndex
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex].conditions.splice(
      conditionIndex,
      1
    );
    setStepFilters(newData);
  };

  const handleClickDeleteStep = (e) => {
    const filters = deepCopy(stepFilters);
    const steps = deepCopy(funnelSteps);

    const stepId = e.currentTarget.dataset["stepid"];
    if (stepFilters.length === 1) {
      setStepFilters([deepCopy(pageContainerFixture)]);
      setFunnelSteps([
        {
          name: "",
          filters: "",
        },
      ]);
      return;
    }
    filters.splice(stepId, 1);
    steps.splice(stepId, 1);
    setStepFilters(filters);
    setFunnelSteps(steps);
  };

  const handleChangeStepName = (e) => {
    const index = e.target.dataset["stepid"];
    const state = deepCopy(funnelSteps);
    state[index].name = e.target.value;
    setFunnelSteps(state);
  };

  const getInvalidFields = (eventConditions) => {
    const invalidFields = {
      includedPageContainers: [{ inclusion: true, eventConditions: [] }],
      total: 0,
    };
    const containerIndex = 0;

    eventConditions.forEach((event, eventIndex) => {
      if (
        !invalidFields.includedPageContainers[containerIndex].eventConditions[
          eventIndex
        ]
      ) {
        invalidFields.includedPageContainers[containerIndex].eventConditions[
          eventIndex
        ] = {
          conditions: [],
        };
      }
      // Rule
      if (!event.type) {
        invalidFields.includedPageContainers[containerIndex].eventConditions[
          eventIndex
        ].type = true;
        invalidFields.total++;
      }
      event.conditions.forEach((condition, conditionIndex) => {
        if (
          !invalidFields.includedPageContainers[containerIndex].eventConditions[
            eventIndex
          ].conditions[conditionIndex]
        ) {
          invalidFields.includedPageContainers[containerIndex].eventConditions[
            eventIndex
          ].conditions[conditionIndex] = {};
        }
        if (conditionIndex === 0) {
          // Rule
          if (event.type && !condition.attribute) {
            invalidFields.includedPageContainers[
              containerIndex
            ].eventConditions[eventIndex].conditions[
              conditionIndex
            ].attribute = true;
            invalidFields.total++;
          }
        }
        // Rule
        if (
          condition.attribute &&
          condition.attribute !== "Any" &&
          !condition.operator
        ) {
          invalidFields.includedPageContainers[containerIndex].eventConditions[
            eventIndex
          ].conditions[conditionIndex].operator = true;
          invalidFields.total++;
        }
      });
    });

    return invalidFields;
  };

  const getFunnelStepsValidity = (stepFilters) => {
    let invalidIds = {};
    let validIds = {};
    let invalidCount = 0;
    const updatedInvalidFields = deepCopy(invalidFields);
    for (let i = 0, len = stepFilters.length; i < len; i++) {
      const step = stepFilters[i];

      // Initially set the step as invalid
      invalidIds[i] = true;
      invalidCount += 1;

      let fields = getInvalidFields(step.eventConditions);
      updatedInvalidFields[i] = fields;
      if (!fields.total && funnelSteps[i].name) {
        delete invalidIds[i];
        invalidCount -= 1;
        validIds[i] = true;
      }
    }
    setInvalidFields(updatedInvalidFields);
    return { validIds, invalidIds, invalidCount };
  };

  const handleSubmitForm = (values, { setSubmitting, resetForm }) => {
    const { invalidIds, invalidCount } = getFunnelStepsValidity(stepFilters);
    const payload = {};
    setIsFormSubmitted(true);
    setInvalidSteps(invalidIds);
    setSubmitting(false);

    if (!invalidCount) {
      setSubmitting(true);

      payload.name = values.name;
      payload.description = values.description;
      payload.openFunnel = values.openFunnel;
      payload.tags = values.tags.map((tag) => {
        return tag.value;
      });

      payload.steps = funnelSteps.map((step, index) => {
        return {
          name: step.name,
          filters: JSON.stringify({
            pageContainers: [stepFilters[index]],
          }),
        };
      });
      payload.folder = values.folder;

      saveFunnel(payload, activeProfileId, funnelId).then((res) => {
        setSubmitting(false);
        if (res.error) {
          dispatch({
            type: ADD_TOAST,
            messageType: "danger",
            messageText: res.error,
          });
          return;
        }
        const action = funnelId ? "saved" : "created";
        dispatch({
          type: ADD_TOAST,
          messageType: "success",
          messageText: `New funnel "${payload.name}" was ${action} successfully.`,
        });
        props.history.push(
          `/profile/${props.match.params.profileId}/funnels/view/${res.id}`
        );
      });

      setSelectedFunnelFolder({ label: values.folder, value: values.folder });
      saveProfilePref(activeProfileId, "lastFunnelFolder", values.folder);
    }
  };

  const exchangeItems = (itemsContainer, exchangedItemIndex, tempItem) => {
    const updatedItemsContainer = itemsContainer.map((item, index) => {
      if (index === exchangedItemIndex) {
        tempItem = { ...item };
        item = { ...itemsContainer[exchangedItemIndex + 1] };
        return item;
      }
      if (index === exchangedItemIndex + 1) {
        return tempItem;
      }
      return item;
    });

    return updatedItemsContainer;
  };

  const handleStepArrow = (stepIndex) => {
    //temp items
    let tempStepFilter = {};
    let tempFunnelStep = {};

    //update states
    setStepFilters([...exchangeItems(stepFilters, stepIndex, tempStepFilter)]);
    setFunnelSteps([...exchangeItems(funnelSteps, stepIndex, tempFunnelStep)]);
  };

  useEffect(() => {
    const { validIds, invalidIds } = getFunnelStepsValidity(stepFilters);
    setValidSteps(validIds);
    setInvalidSteps(invalidIds);
    setIsFormSubmitted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelSteps, stepFilters]);

  useEffect(() => {
    if (funnelId && activeProfileId) {
      fetchFunnel({ profileId: activeProfileId, funnelId }).then((res) => {
        const data = res.data();
        if (!data) {
          props.history.push(
            `/profile/${props.match.params.profileId}/funnels/list`
          );
          return;
        }
        setFunnelData({
          name: data.name,
          openFunnel: data.openFunnel,
          description: data.description,
          tags: data.tags.map((tag) => {
            return {
              label: tag,
              value: tag,
            };
          }),
          folder: data.folder,
        });

        const filters = [];
        const steps = data.steps.map((step) => {
          let dbFilter = step.filters && JSON.parse(step.filters);
          if (dbFilter) {
            dbFilter = dbFilter.pageContainers[0];
          } else {
            dbFilter = deepCopy(pageContainerFixture);
          }
          filters.push(dbFilter);
          return {
            name: step.name,
            filters: "",
          };
        });
        setFunnelSteps(steps);
        setStepFilters(filters);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelId, activeProfileId]);

  useEffect(() => {
    if (initialData) {
      setStepFilters(initialData.stepFilters);
      setFunnelSteps(initialData.funnelSteps);
      setFunnelData(initialData.funnelData);
    }
  }, [initialData]);

  return (
    <FunnelScreen
      className="funnel-builder"
      title="Funnel Builder"
      handleClickCancel={handleClickCancel}
      funnelName={funnelData.name || "New Funnel"}
      funnelId={Number(funnelId)}
    >
      <div className="funnel-form  bg-gray-100">
        <Formik
          onSubmit={handleSubmitForm}
          enableReinitialize
          initialValues={{
            name: funnelData.name || "",
            openFunnel: funnelData.openFunnel || false,
            description: funnelData.description || "",
            tags: funnelData.tags || [],
            folder: funnelData.folder || "",
          }}
          validate={funnelValidator}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleSubmit,
            handleChange,
            isSubmitting,
          }) => {
            return (
              <Form isSubmitting={isSubmitting} handleSubmit={handleSubmit}>
                <div className="form-row">
                  <div></div>
                  <div className="col-md-3 ">
                    <input
                      className={`form-control ${errors.name && "is-invalid"}`}
                      name="name"
                      onChange={handleChange}
                      placeholder="New Funnel Name"
                      type="text"
                      value={values.name || ""}
                    />
                    {errors.name && (
                      <small className="invalid-feedback">{errors.name}</small>
                    )}
                  </div>
                  <div className="col row mr-atuo">
                    <div className="folder-label">Folder :</div>
                    <FunnelFoldersDropdown
                      setIsFoldersDropdownVisible={setIsFoldersDropdownVisible}
                      isFoldersDropdownVisible={isFoldersDropdownVisible}
                      toggleFoldersDropdown={toggleFoldersDropdown}
                      selectedFolderValue={values.folder}
                      setFieldValue={setFieldValue}
                      funnelFolders={funnelFolders}
                      setFunnelFolders={setFunnelFolders}
                      funnelFoldersMap={funnelFoldersMap}
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="d-flex mr-2">
                      <span
                        className="funnel-label"
                        data-tooltip-id="funnel-builder-tooltip"
                        data-tooltip-content={CLOSED_FUNNEL}
                      >
                        Closed Funnel
                      </span>
                      <Switch
                        name="openFunnel"
                        id="checkbox"
                        isChecked={values.openFunnel}
                        onChange={handleChange}
                        variant="funnel"
                      />
                      <span
                        className="funnel-label"
                        data-tooltip-id="funnel-builder-tooltip"
                        data-tooltip-content={OPEN_FUNNEL}
                      >
                        Open Funnel
                      </span>
                      <ReactTooltip
                        id="funnel-builder-tooltip"
                        className="tooltip-md"
                        place="bottom"
                      />
                    </div>
                    <Button
                      className="mt-1"
                      type="submit"
                      variant="primary"
                      disabled={Object.keys(invalidSteps).length}
                    >
                      Save Changes
                    </Button>
                    {!initialData && funnelId && (
                      <Button
                        className="mt-1 ml-2"
                        onClick={handleClickCopyFunnel}
                        variant="primary"
                        disabled={Object.keys(invalidSteps).length}
                      >
                        Copy Funnel
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      className="mt-1 ml-2"
                      onClick={handleClickCancel}
                    >
                      Cancel
                    </Button>
                    {authUser && funnelData && authUser.isAdmin && funnelId && (
                      <Button
                        variant="danger"
                        className="mt-1 ml-2"
                        data-funnelid={funnelId}
                        onClick={handleClickDeleteFunnel}
                      >
                        Delete Funnel
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div
        className={`
        funnel-steps-container bg-gray-100 small
        ${isFormSubmitted ? "submitted" : ""}
        ${!!(stepFilters.length >= 10) && "max-steps"}
        ${Object.keys(invalidSteps).length ? "invalid" : ""}
      `}
      >
        <div className="row">
          {stepFilters &&
            stepFilters.map((step, index) => {
              return (
                <FunnelStep
                  invalidFields={
                    invalidFields[index]
                      ? invalidFields[index]
                      : {
                          includePageContainers: [],
                          total: 0,
                        }
                  }
                  key={index}
                  index={index}
                  onStepArrow={handleStepArrow}
                  data={step}
                  isValid={validSteps[index]}
                  eventFilters={events}
                  funnelSteps={funnelSteps}
                  handleClickDeleteStep={handleClickDeleteStep}
                  handleChangeStepName={handleChangeStepName}
                  handleUpdateCondition={handleUpdateCondition}
                  handleClickAddEventConditionRow={
                    handleClickAddEventConditionRow
                  }
                  handleClickDeleteEventConditionRow={
                    handleClickDeleteEventConditionRow
                  }
                  handleUpdateSubCondition={handleUpdateSubCondition}
                  handleClickAddSubConditionRow={handleClickAddSubConditionRow}
                  handleClickDeleteSubConditionRow={
                    handleClickDeleteSubConditionRow
                  }
                />
              );
            })}
        </div>
        <div className="row">
          {Object.keys(invalidSteps).length === 0 &&
            stepFilters.length < 10 && (
              <div className="col-md-8 offset-md-2 step-col add-btn-col">
                <Button
                  onClick={handleClickAddStep}
                  className="btn-add"
                  size="small"
                >
                  <span className="fa fa-plus"></span> Add New Step
                </Button>
                &nbsp;
              </div>
            )}
        </div>
      </div>
    </FunnelScreen>
  );
}

FunnelBuilder.propTypes = {};
FunnelBuilder.defaultProps = {};

export default withRouter(FunnelBuilder);
