import React from "react";
import * as Icons from "../../assets/icons";

const InsightechIcon = ({ name, width = 20, height = 20, ...props }) => {
  const SvgIcon = Icons[name];

  if (!SvgIcon) {
    console.warn(`SvgIcon ${name} not found`);
    return null;
  }

  return <SvgIcon width={width} height={height} {...props} />;
};

export default InsightechIcon;
