import axios from "axios";
import { fetchUserAccess } from "./../lib/firebase/user";

const dataApi = {
  apiServer: "",
  requests: [],
  isCancel: axios.isCancel,

  cancel(requestId) {
    if (this.requests[requestId]) {
      this.requests[requestId].cancel(`Abort ${requestId}`);
    }
  },

  createCancelToken(requestId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.requests[requestId] = source;

    return source.token;
  },

  loadAccounts(authUser) {
    return fetchUserAccess();
  },

  createAccount(authUser, { accountName, apiServer, planName }) {
    var fd = new FormData();
    fd.set("name", accountName);
    fd.set("apiServer", apiServer);
    fd.set("planName", planName);
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/account/create",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("createAccount"),
        });
      });
    }
  },

  deleteAccount(authUser, { accountId }) {
    var fd = new FormData();
    fd.set("accountId", accountId);
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/account/delete",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("deleteAccount"),
        });
      });
    }
  },

  createProfile(authUser, { accountId, profileName, timezone, domains }) {
    var fd = new FormData();
    fd.set("accountId", accountId);
    fd.set("profileName", profileName);
    fd.set("timezone", timezone);
    fd.set("domains", domains);
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/create",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("createProfile"),
        });
      });
    }
  },

  deleteProfile(authUser, { profileId }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/delete",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("deleteProfile"),
        });
      });
    }
  },

  getSessionEvents(authUser, { profileId, visitorId, sessionNumber }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("vid", visitorId);
    fd.set("snum", sessionNumber);
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/session/pageview/events",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("getSessionEvents"),
        });
      });
    }
  },

  querySessionListToken : "querySessionList",
  querySessionList(
    authUser,
    { profileId, startDate, endDate, segments, filter, recordedSessions, sort, forceError }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("segments", segments);
    fd.set("filter", filter);
    fd.set("recordedSessions", recordedSessions);
    fd.set("sort", sort);
    forceError && fd.set("forceError", forceError);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/session/list/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken(this.querySessionListToken),
        });
      });
    }
  },

  readSessionListToken : "readSessionList",
  readSessionList(authUser, { profileId, queryId, key, page, pageSize }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    fd.set("page", page);
    fd.set("pageSize", pageSize);
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/session/list/read",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken(this.readSessionListToken),
        });
      });
    }
  },

  queryPathsListToken: "queryPathsList",
  queryPathsList(
    authUser,
    { profileId, startDate, endDate, segments, filter }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("segments", segments);
    fd.set("filter", filter);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/paths/query",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(this.queryPathsListToken),
        });
      });
    }
  },

  readPathsListToken: "readPathsList",
  readPathsList(authUser, { profileId, queryId, key }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/paths/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(this.readPathsListToken),
        });
      });
    }
  },

  readFunnelList(authUser, { profileId, queryId, key, page, pageSize }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    //fd.set('page', page);
    //fd.set('pageSize', pageSize);
    fd.set("page", 1);
    fd.set("pageSize", 0);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/funnel/list/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken("readFunnelList"),
        });
      });
    }
  },

  queryFunnelSteps(
    authUser,
    { profileId, funnelId, startDate, endDate, segments, filter }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("funnelId", funnelId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("segments", segments);
    fd.set("filter", filter);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/funnel/step/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryFunnelSteps" + funnelId),
        });
      });
    }
  },

  readFunnelSteps(authUser, { profileId, queryId, key, funnelId }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    //fd.set("forceError", "bytesBilledLimitExceeded");
    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/funnel/step/read",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken(
            "readFunnelSteps" + funnelId
          ),
        });
      });
    }
  },

  queryClickmap(
    authUser,
    { profileId, startDate, endDate, pageURL, segments }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("pageURL", pageURL);
    fd.set("segments", segments);
    //fd.set("forceError", "queryExceedsMaxDays"); // Test error handling

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/clickmap/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryClickmap"),
        });
      });
    }
  },

  readClickmap(authUser, { profileId, queryId, key }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/clickmap/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken("readClickmap"),
        });
      });
    }
  },

  queryScrollmap(
    authUser,
    { profileId, startDate, endDate, pageURL, segments }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("pageURL", pageURL);
    fd.set("segments", segments);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/scrollmap/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryScrollmap"),
        });
      });
    }
  },

  readScrollmap(authUser, { profileId, queryId, key }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/scrollmap/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken("readScrollmap"),
        });
      });
    }
  },

  queryPageList(
    authUser,
    { profileId, startDate, endDate, filter, pageURL, title, segments, sort }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("pageURL", pageURL);
    fd.set("segments", segments);
    fd.set("filter", filter);
    fd.set("sort", sort);
    fd.set("title", title);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/page/list/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryPageList"),
        });
      });
    }
  },

  queryPageTotal(
    authUser,
    { profileId, startDate, endDate, filter, pageURL, title, segments, sort }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("pageURL", pageURL);
    fd.set("segments", segments);
    fd.set("filter", filter);
    fd.set("sort", sort);
    fd.set("title", title);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/page/total/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryPageTotal"),
        });
      });
    }
  },

  readPageList(authUser, { profileId, queryId, key, page, pageSize = 10 }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    fd.set("page", page);
    fd.set("pageSize", pageSize);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/page/list/read",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("readPageList"),
        });
      });
    }
  },

  readPageTotal(authUser, { profileId, queryId, key }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/page/total/read",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("readPageTotal"),
        });
      });
    }
  },

  queryFormList(
    authUser,
    { profileId, startDate, endDate, filter, pageURL, segments, sort }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("pageURL", pageURL);
    fd.set("segments", segments);
    fd.set("filter", filter);
    fd.set("sort", sort);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/form/list/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryFormList"),
        });
      });
    }
  },

  queryFormTotal(
    authUser,
    { profileId, startDate, endDate, filter, pageURL, segments, sort }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("pageURL", pageURL);
    fd.set("segments", segments);
    fd.set("filter", filter);
    fd.set("sort", sort);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/form/total/query",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("queryFormTotal"),
        });
      });
    }
  },

  readFormList(authUser, { profileId, queryId, key, page, pageSize = 10 }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    fd.set("page", page);
    fd.set("pageSize", pageSize);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/form/list/read",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("readFormList"),
        });
      });
    }
  },

  readFormTotal(authUser, { profileId, queryId, key }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/form/total/read",
          data: fd,
          headers: {
            Token: token,
          },
          cancelToken: this.createCancelToken("readFormTotal"),
        });
      });
    }
  },

  queryMetrics(
    authUser,
    { profileId, startDate, endDate, segments, filter },
    cancelToken
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("segments", segments);
    fd.set("filter", filter);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/widget/metrics/query",
          data: fd,
          headers: { Token: token },
          cancelToken: cancelToken || this.createCancelToken("queryMetrics"),
        });
      });
    }
  },

  readMetrics(authUser, { profileId, queryId, key, cancelTokenName }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    //fd.set("forceError", "bytesBilledLimitExceeded");

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/widget/metrics/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(cancelTokenName),
        });
      });
    }
  },

  queryTrend(
    authUser,
    {
      profileId,
      startDate,
      endDate,
      segments,
      filter,
      interval,
      withConversion,
    },
    cancelToken
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("segments", segments);
    fd.set("filter", filter);
    fd.set("interval", interval);
    fd.set("withConversion", withConversion);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/widget/trends/query",
          data: fd,
          headers: { Token: token },
          cancelToken: cancelToken || this.createCancelToken("queryTrend"),
        });
      });
    }
  },

  readTrend(authUser, { profileId, queryId, key, cancelTokenName }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/widget/trends/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(cancelTokenName),
        });
      });
    }
  },

  queryDimension(
    authUser,
    { profileId, startDate, endDate, segments, filter },
    cancelToken
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("startDate", startDate);
    fd.set("endDate", endDate);
    fd.set("segments", segments);
    fd.set("filter", filter);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/widget/dimensions/query",
          data: fd,
          headers: { Token: token },
          cancelToken: cancelToken || this.createCancelToken(`queryDimension`),
        });
      });
    }
  },

  readDimension(authUser, { profileId, queryId, key, page = 0, pageSize, cancelTokenName }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("queryId", queryId);
    fd.set("key", key);
    fd.set("page", page);
    fd.set("pageSize", pageSize);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/widget/dimensions/read",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(cancelTokenName),
        });
      });
    }
  },

  updateProfile(authUser, { profileId, profileName, domains }) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("profileName", profileName);
    fd.set("domains", domains);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/update",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(`updateProfile`),
        });
      });
    }
  },

  updateTracking(
    authUser,
    {
      profileId,
      allowedFields,
      maskingSelectors,
      mutationExclusion,
      datalayerName,
      rageClickIntervalMs,
      rageClickClicks,
      rageClickExlusions,
      rageClickEnabled,
      apiErrorEnabled,
      apiErrorEndpoints,
      apiErrorEndpointsWithPayload,
    }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("allowedFields", allowedFields);
    fd.set("maskingSelectors", maskingSelectors);
    fd.set("mutationExclusion", mutationExclusion);
    fd.set("datalayerName", datalayerName);
    fd.set("rageClickIntervalMs", rageClickIntervalMs);
    fd.set("rageClickClicks", rageClickClicks);
    fd.set("rageClickExlusions", rageClickExlusions);
    fd.set("rageClickEnabled", rageClickEnabled);
    fd.set("apiErrorEnabled", apiErrorEnabled);
    fd.set("apiErrorEndpoints", apiErrorEndpoints);
    fd.set("apiErrorEndpointsWithPayload", apiErrorEndpointsWithPayload);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/tracking/update",
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(`updateTracking`),
        });
      });
    }
  },

  // Create or update conversion
  writeConversion(
    authUser,
    conversionId,
    {
      profileId,
      enabled,
      filters,
      enableRevenue,
      revenueJsonPath,
    }
  ) {
    var fd = new FormData();
    fd.set("profileId", profileId);
    fd.set("conversionId", conversionId);
    fd.set("enabled", enabled);
    fd.set("filter", filters); // No "s" at the end to stay consistent with other API endpoints
    fd.set("enableRevenue", enableRevenue);
    fd.set("revenueJsonPath", revenueJsonPath);

    if (authUser.checked && !!authUser.user) {
      return authUser.user.getIdToken().then((token) => {
        return axios({
          method: "post",
          url: this.apiServer + "/api/profile/conversion/" + (conversionId !== "" ? "update" : "create"),
          data: fd,
          headers: { Token: token },
          cancelToken: this.createCancelToken(`updateTracking`),
        });
      });
    }
    else {
      return Promise.reject("User not authenticated");
    }
    
  },
};

export default dataApi;
