import { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const useDownload = ({ title, funnelId }) => {
  var input, reportName;
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    setIsDownloading(true);

    if (funnelId) {
      input = document.getElementById("funnel-report");
      reportName = "Funnel View Report.pdf";
    } else {
      input = document.getElementById("content");
      reportName = `${title} Report.pdf`;
    }

    html2canvas(input, {
      allowTaint: true,
      useCORS: true,
      scale: 2,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const imgWidth = 200;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pageHeight = 285;
      let heightLeft = imgHeight;
      const pdf = new jsPDF("pt", "mm");
      let position = 0;
      pdf.addImage(imgData, "PNG", 5, 0, imgWidth, imgHeight, "", "FAST");
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          5,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      pdf.save(reportName);
      setIsDownloading(false);
    });
  };

  return { isDownloading, handleDownload };
};

export default useDownload;
