import React from "react";

const EmailField = ({id, email='', label='', placeholder='', helpText='', readOnly=false, plainText=false, handleChange}) => {
    return (
        <div className="form-group row">
            <label htmlFor={id} className="col-sm-2 col-form-label">{label}</label>
            <div className="col-sm-10 col-md-4 col-lg-6">
                <input type="email" className={(plainText)?"form-control-plaintext":"form-control"} id={id} placeholder={placeholder} readOnly={readOnly} value={email} onChange={(e) => {
                    if(typeof(handleChange) === 'function'){
                        handleChange(e);
                    }
                }} />
                {helpText !== '' && 
                    <small id={id+"help"} className="form-text text-muted">{helpText}</small>
                }
            </div>
        </div>
    );
}

export default EmailField;