import React from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import Condition from "./Condition";
import { deepCopy } from "./../../../utils";

const conditions = require("./../../../inc/eventConditions");
const operators = require("./../../../inc/operators");
const units = require("./../../../inc/units");
const operatorConditions = require("./../../../inc/operatorConditions.json");

function UrlEventRule(props) {
  const {
    id,
    isFunnel,
    data,
    filters,
    conditionType,
    showDelete,
    handleUpdateCondition,
    handleUpdateSubCondition,
    handleClickDeleteConditionRow,
    handleClickAddSubConditionRow,
    handleClickDeleteSubConditionRow,
    editFieldOnly,
  } = props;

  const { type } = data;
  const { attribute, path, unit, operator, values } = data.conditions[0];
  const [availableOperators, setAvailableOperators] = React.useState([]);

  const operatorType = conditions[attribute]
    ? conditions[attribute].operatorType
    : null;
  const availableUnits = units[operatorType] ? units[operatorType] : null;
  const availableConditionsKeys =
    filters[type] && filters[type] ? filters[type].attributes : null;
  const operatorCondition =
    operator && operatorConditions[operator]
      ? operatorConditions[operator]
      : null;

  React.useEffect(() => {
    let conditionKey =
      conditions[attribute] && conditions[attribute]["operatorType"]
        ? conditions[attribute]["operatorType"]
        : null;
    let conditionOperators = conditionKey ? operators[conditionKey] : null;
    if (conditionOperators) {
      setAvailableOperators(conditionOperators);
    } else {
      setAvailableOperators([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);

  const onClickDeleteConditionRow = (e) => {
    handleClickDeleteConditionRow(conditionType, props.id);
  };

  const handleEventTypeChange = (e) => {
    const state = {
      type: e.target.value,
      inclusion: true,
    };

    state["conditions"] = [
      {
        attribute: "",
        path: "",
        unit: "",
        operator: "",
        values: [""],
      },
    ];

    if (conditionType === "excludedEvent") {
      state["inclusion"] = false;
    }
    handleUpdateCondition(conditionType, props.id, state);
  };

  const onClickAddSubConditionRow = (e) => {
    handleClickAddSubConditionRow(conditionType, props.id);
  };

  const handleChangeAttribute = (e) => {
    const state = deepCopy(data);

    // Reset condition if attribute is changed
    state["conditions"] = [
      {
        attribute: e.target.value,
        path: "",
        unit: "",
        operator: "",
        values: [""],
      },
    ];
    if (e.target.value === "FieldSelected") {
      state["conditions"][0]["values"] = ["true"];
    }
    handleUpdateCondition(conditionType, props.id, state);
  };

  const handleChangeOperator = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["values"] = [...values];
    state["conditions"][0]["operator"] = e.target.value;
    if (operatorType && units[operatorType]) {
      state["conditions"][0]["unit"] = units[operatorType][0].key;
    }
    handleUpdateCondition(conditionType, props.id, state);
  };

  const handleChangeValue = (e) => {
    const state = deepCopy(data);
    const index = e.target.dataset.index;
    state["conditions"][0]["values"][index] = e.target.value;
    handleUpdateCondition(conditionType, props.id, state);
  };

  const handleChangePath = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["path"] = e.target.value;
    handleUpdateCondition(conditionType, props.id, state);
  };

  const handleChangeUnit = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["unit"] = e.target.value;
    handleUpdateCondition(conditionType, props.id, state);
  };

  const handleClickAddValueField = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["values"].push("");
    handleUpdateCondition(props.conditionType, id, state);
  };

  const handleClickRemoveValueField = (e) => {
    const state = deepCopy(data);
    const index = parseInt(e.currentTarget.dataset.index, 0);
    if (index === 0) {
      state.conditions[0].values[0] = "";
      handleUpdateCondition(props.conditionType, id, state);
    }
    if (state.conditions[0].values.length > 1) {
      state.conditions[0].values.splice(index, 1);
      handleUpdateCondition(props.conditionType, id, state);
    }
  };

  return (
    <div className={`rule ${isFunnel ? "funnel-mode" : ""}`}>
      <div className="rule-group">
        {/** Event Type Field */}
        <div className="field-col">
          <select
            className="form-control form-control-sm d-none"
            name="eventType"
            value={type}
            onChange={handleEventTypeChange}
          >
            <option value="">Choose an event</option>
            {Object.keys(filters).map((key) => {
              const filter = filters[key];
              return (
                <option key={filter.key} value={filter.key}>
                  {filter.label}
                </option>
              );
            })}
          </select>
        </div>

        {/** Condition Field */}
        {type && (
          <div className="field-col">
            <select
              className="form-control form-control-sm d-none"
              name="condition"
              value={attribute}
              onChange={handleChangeAttribute}
            >
              <option value="">Choose a Condition</option>
              {availableConditionsKeys.map((key) => {
                const condition = conditions[key];
                return (
                  <option key={condition.key} value={condition.key}>
                    {condition.label}
                  </option>
                );
              })}
            </select>
            <strong>{conditions[attribute].label}</strong>
          </div>
        )}

        {/** JSON Path */}
        {type && attribute && attribute === "JSONPath" && (
          <div className="field-col">
            <input
              placeholder="Define JSON Path"
              type="text"
              className="form-control form-control-sm"
              name="value"
              value={path}
              onChange={handleChangePath}
            />
          </div>
        )}

        {/** Operator Field */}
        {!!(
          (type &&
            attribute &&
            attribute !== "JSONPath" &&
            availableOperators.length) ||
          (type &&
            attribute &&
            attribute === "JSONPath" &&
            path &&
            availableOperators.length)
        ) && (
          <div className="field-col">
            <select
              className="form-control form-control-sm"
              name="operator"
              value={operator}
              onChange={handleChangeOperator}
            >
              <option value="">Choose an operator</option>
              {availableOperators.map((op) => {
                return (
                  <option key={op.key} value={op.key}>
                    {op.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        {/** Value Field */}
        {(!!(type && attribute && attribute !== "Any" && operator) ||
          !!(type && attribute && operatorType === "boolean")) &&
          (operatorType === "boolean" ? (
            <div className="field-col">
              <select
                value={values[0]}
                data-index={0}
                className="form-control form-control-sm"
                onChange={handleChangeValue}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          ) : (
            values.map((val, index) => {
              let inputType = "text";
              switch (operatorType) {
                case "number":
                case "duration":
                  inputType = "number";
                  break;
                default:
                  break;
              }
              return (
                <React.Fragment key={index}>
                  <div
                    className={`field-col value-field-container
                      ${availableUnits && "with-unit"}`}
                    key={index}
                  >
                    <span className="conj">or</span>
                    <input
                      type={inputType}
                      className="form-control form-control-sm"
                      name="value"
                      data-index={index}
                      value={val}
                      onChange={handleChangeValue}
                    />
                    {!availableUnits && (
                      <Button
                        className="btn-delete"
                        data-index={index}
                        size="small"
                        icon="fas fa-times"
                        onClick={handleClickRemoveValueField}
                      ></Button>
                    )}
                  </div>

                  {/* Units Field */}
                  {availableUnits && (
                    <div className="field-col unit-field-container">
                      <select
                        className="form-control form-control-sm"
                        value={unit}
                        onChange={handleChangeUnit}
                      >
                        {availableUnits.map((unit, index) => {
                          return (
                            <option key={index} value={unit.key}>
                              {unit.label}
                            </option>
                          );
                        })}
                      </select>
                      <Button
                        className="btn-delete"
                        data-index={index}
                        size="small"
                        icon="fas fa-times"
                        onClick={handleClickRemoveValueField}
                      ></Button>
                    </div>
                  )}
                </React.Fragment>
              );
            })
          ))}

        {/** OR Button */}
        {type &&
          attribute &&
          attribute !== "Any" &&
          operator &&
          (!operatorCondition ||
            (operatorCondition && !operatorCondition.singleValue)) && (
            <div className="field-col add-btn-container field-col">
              <Button
                variant="secondary"
                size="small"
                onClick={handleClickAddValueField}
              >
                or
              </Button>
            </div>
          )}

        {/** Action Field */}
        {data.conditions.length === 1 && !editFieldOnly && (
          <div className="field-col add-condition-btn-container">
            {type && attribute && (
              <Button
                size="small"
                onClick={onClickAddSubConditionRow}
                variant="secondary"
              >
                when
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="actions">
        {showDelete && !editFieldOnly && (
          <Button
            icon="fas fa-times-circle"
            onClick={onClickDeleteConditionRow}
          ></Button>
        )}
      </div>

      {data.conditions && !!data.conditions.length && (
        <div className="">
          {data.conditions.map((condition, index) => {
            return index === 0 ? null : (
              <Condition
                editFieldOnly={editFieldOnly}
                id={index}
                key={index}
                data={condition}
                conditionType={conditionType}
                type={type}
                eventId={props.id}
                handleUpdateSubCondition={handleUpdateSubCondition}
                handleClickAddSubConditionRow={handleClickAddSubConditionRow}
                handleClickDeleteSubConditionRow={
                  handleClickDeleteSubConditionRow
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

UrlEventRule.propTypes = {
  handleClickDeleteConditionRow: PropTypes.func,
};
UrlEventRule.defaultProps = {};

export default UrlEventRule;
