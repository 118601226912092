import InsightechIcon from "../../share/InsightechIcon";

// Nav menu icons
export const Dashboard = () => <InsightechIcon name="Dashboard" />;
export const PageAnalysis = () => <InsightechIcon name="Pages" />;
export const FormAnalysis = () => <InsightechIcon name="Forms" />;
export const Funnels = () => <InsightechIcon name="Funnels" />;
export const Pathing = () => <InsightechIcon name="Pathing" />;
export const Replays = () => <InsightechIcon name="Replays" />;
export const Notes = () => <InsightechIcon name="Notes" />;
export const Segments = () => <InsightechIcon name="Segments" />;
export const Reports = () => <InsightechIcon name="Reports" />;
export const Settings = () => <InsightechIcon name="Settings" />;
// Add other icons as needed
