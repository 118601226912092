import React from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";

function Switch(props) {
  const {
    label,
    id,
    onChange,
    name,
    value,
    labelFirst,
    style,
    variant,
    disabled,
    tooltip,
  } = props;
  let variantClass = "";
  let inputClass = "";
  let labelClass = "";

  switch (variant) {
    case "funnel":
      variantClass = "switch-funnel";
      inputClass = "funnel-check-box";
      labelClass = "funnel-slider";
      break;
    case "compare-switch":
      variantClass = "custom-control custom-switch compare-switch";
      inputClass = "custom-control-input";
      labelClass = "custom-control-label";
      break;
    default:
      variantClass = "custom-control custom-switch";
      inputClass = "custom-control-input";
      labelClass = "custom-control-label";
      break;
  }

  return (
    <div
      className={`${variantClass} ${labelFirst ? "label-first" : ""}`}
      style={style}
      data-tooltip-id="switch-tooltip"
      data-tooltip-content={tooltip}
    >
      <input
        type="checkbox"
        checked={!!props.isChecked}
        className={`${inputClass}`}
        onChange={onChange}
        value={value || ""}
        name={name}
        id={id}
        disabled={disabled}
      />
      <label className={`${labelClass}`} htmlFor={id}>
        {label}
      </label>
      <ReactTooltip id="switch-tooltip" className="tooltip-sm" />
    </div>
  );
}

Switch.propTypes = {
  id: PropTypes.string,
};
Switch.defaultProps = {};

export default Switch;
