import React from 'react';
import { ReducerContext } from './../../../reducer/context';
import { DISMISS_TOAST, TOAST_PAUSE, TOAST_RESUME } from './../../../reducer/index';
import './style.scss';

function Toast(props){
  const {
    state, dispatch,
  } = React.useContext(ReducerContext);

  let toastQueueTimeout = null;
  let toastPauseTimeout = null;

  React.useEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    toastQueueTimeout = setTimeout(() => {
      dispatch({ type: DISMISS_TOAST });
      dispatch({ type: TOAST_PAUSE });

      // eslint-disable-next-line react-hooks/exhaustive-deps
      toastPauseTimeout = setTimeout(()=>{
        dispatch({ type: TOAST_RESUME })
      }, 500)
    }, 3000)

  }, [state.toastMessages.length])

  const onClickClose = ()=>{
    if(toastQueueTimeout){
      clearTimeout(toastQueueTimeout);
    }
    if(toastPauseTimeout){
      clearTimeout(toastPauseTimeout);
    }
    dispatch({ type: DISMISS_TOAST})
  }

  let icon = 'fa fa-check-circle';
  switch(state.toastMessages[0] && state.toastMessages[0].type){
    case "danger":
      icon = "fa fa-times-circle";
      break;
    case "success":
      icon = "fa fa-check-circle";
      break;
    default:
      icon = "fa fa-check-circle";
      break;
  }

  return (
    <>
      {((state.toastMessages.length) > 0 && !state.toastPaused) &&
        (
          <div className={`ui-toast card shadow animated--fade-in ${state.toastMessages[0].type}`}>
            <span className={icon}></span>
            <p className="small">{state.toastMessages[0].message}</p>
            <button className="btn" onClick={onClickClose}>
              <span className="fa fa-times"></span>
            </button>
          </div>
        )
      }
    </>
  );
}

Toast.propTypes = {}
Toast.defaultProps = {}

export default Toast;