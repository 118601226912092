import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../share/InsightUI";

const operators = require("../../../inc/operators.json");

const PathingEventCondition = (props) => {
  const {
    index,
    data,
    onPagePath,
    onAddedPathField,
    onDeletedPath,
    onOperator,
  } = props;

  return (
    <div className="rule">
      <div className="rule-group">
        <div className="field-col">
          <h6>Page Path:</h6>
        </div>
        <div className="field-col">
          <select
            className={`form-control form-control-sm`}
            data-stepid={index}
            onChange={(e) => onOperator(e)}
            value={data.filters.operator}
          >
            <option value="">Choose an operator</option>
            {operators["string"].map((operator) => (
              <option key={operator.key} value={operator.key}>
                {operator.label}
              </option>
            ))}
          </select>
        </div>

        {data.filters.operator &&
          data.filters.values.map((path, pathIndex) => (
            <React.Fragment key={pathIndex}>
              <div className="field-col value-field-container">
                <span className="conj">or</span>
                <input
                  type="text"
                  data-stepid={index}
                  data-pathid={pathIndex}
                  className={`form-control form-control-sm ${
                    !path && "form-control-error"
                  }`}
                  placeholder="Define Page Path"
                  value={path}
                  onChange={onPagePath}
                />
                <Button
                  className="btn-delete"
                  data-stepid={index}
                  data-pathid={pathIndex}
                  size="small"
                  icon="fas fa-times"
                  onClick={(e) => onDeletedPath(e)}
                />
              </div>
            </React.Fragment>
          ))}
        {/* OR button */}
        <div className="field-col add-btn-container">
          <Button
            data-stepid={index}
            variant="secondary"
            size="small"
            onClick={(e) => onAddedPathField(e)}
          >
            or
          </Button>
        </div>
      </div>
    </div>
  );
};

PathingEventCondition.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  onPagePath: PropTypes.func,
  onAddedPathField: PropTypes.func,
  onDeletedPath: PropTypes.func,
  onOperator: PropTypes.func,
};

export default PathingEventCondition;
