import React, { useRef, useEffect, useState, useMemo } from "react";
import { DEFAULT_FUNNEL_FOLDER_NAME } from "../../../../constants/funnels";
import FunnelFoldersDropdownButton from "./FunnelFoldersDropdownButton";
import FunnelFoldersDropdownMenu from "./FunnelFoldersDropdownMenu";

const FunnelFoldersDropdown = (props) => {
  const {
    toggleFoldersDropdown,
    isFoldersDropdownVisible,
    setIsFoldersDropdownVisible,
    setFieldValue,
    selectedFolderValue,
    funnelFolders,
    setFunnelFolders,
    funnelFoldersMap,
  } = props;

  const [selectedFolder, setSelectedFolder] = useState({
    label: "",
    value: "",
  });
  const [createFolderInput, setCreateFolderInput] = useState("");
  const [isCreatorEmptyInvalid, setIsCreatorEmptyInvalid] = useState(false);
  const dropdownRef = useRef(null);

  const isFolderValid = useMemo(() => {
    if (
      funnelFolders.find(
        (folder) =>
          folder.value !== "" &&
          folder.value.toLowerCase() === createFolderInput.toLowerCase()
      )
    )
      return false;

    return true;
  }, [createFolderInput, funnelFolders]);

  const handleCheckboxChange = (e) => {
    setSelectedFolder({
      label: e.currentTarget.dataset.label,
      value: e.currentTarget.value,
    });
    setFieldValue("folder", e.currentTarget.value);
  };

  const handleCreateFolderInput = (e) => {
    setCreateFolderInput(e.currentTarget.value);
    setIsCreatorEmptyInvalid(false);
  };

  const handleClickFolderCreate = () => {
    if (createFolderInput === "") {
      setIsCreatorEmptyInvalid(true);
      return;
    }
    setFunnelFolders([
      ...funnelFolders,
      {
        label: createFolderInput,
        value: createFolderInput,
      },
    ]);
    setSelectedFolder({
      label: createFolderInput,
      value: createFolderInput,
    });
    setFieldValue("folder", createFolderInput);
  };

  // Assign funnel to the folder
  useEffect(() => {
    if (
      !selectedFolderValue ||
      selectedFolderValue.length === 0 ||
      selectedFolderValue === ""
    ) {
      setSelectedFolder({
        label: DEFAULT_FUNNEL_FOLDER_NAME,
        value: DEFAULT_FUNNEL_FOLDER_NAME,
      });
      setFieldValue("folder", DEFAULT_FUNNEL_FOLDER_NAME);
    } else {
      setSelectedFolder({
        label: selectedFolderValue,
        value: selectedFolderValue,
      });
      setFieldValue("folder", selectedFolderValue);
    }
  }, [selectedFolderValue, setFieldValue]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsFoldersDropdownVisible(false);
      }
    };

    // Add outside click handler
    document.addEventListener("mousedown", handleClickOutside);

    // Remove outside click handler on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="dropdown mr-4">
      <FunnelFoldersDropdownButton
        toggleFoldersDropdown={toggleFoldersDropdown}
        selectedFolder={selectedFolder}
        isFoldersDropdownVisible={isFoldersDropdownVisible}
      />
      <FunnelFoldersDropdownMenu
        isFoldersDropdownVisible={isFoldersDropdownVisible}
        funnelFolders={funnelFolders}
        selectedFolder={selectedFolder}
        funnelFoldersMap={funnelFoldersMap}
        handleCheckboxChange={handleCheckboxChange}
        handleClickFolderCreate={handleClickFolderCreate}
        handleCreateFolderInput={handleCreateFolderInput}
        isFolderValid={isFolderValid}
        createFolderInput={createFolderInput}
        isCreatorEmptyInvalid={isCreatorEmptyInvalid}
      />
    </div>
  );
};

export default FunnelFoldersDropdown;
