export const getDimensionHeader = (dimension) => {
  switch (dimension) {
    case "channel":
      return "Source / Medium";
    case "landingpage":
      return "Page";
    case "os":
      return "OS";
    case "browser":
      return "Browser";
    case "country":
      return "Country";
    case "campaign":
      return "Campaign";
    default:
      return "value";
  }
};
