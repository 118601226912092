import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { Button } from "./../../../share/InsightUI";
import "./style.scss";

function Drawer(props) {
  const { title, isVisible, handleClose, width, disableClose } = props;

  if (!isVisible) return null;

  return createPortal(
    <div className={`drawer-overlay ${props.className}`}>
      <div className="drawer" style={{ width }}>
        <Button
          disabled={disableClose}
          onClick={handleClose}
          className="close"
          icon="fa fa-times"
        ></Button>
        {title && <h4 className="drawer-title">{title}</h4>}
        {props.children}
      </div>
    </div>,
    document.body
  );
}

Drawer.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  disableClose: PropTypes.bool,
  handleClose: PropTypes.func,
};
Drawer.defaultProps = {};

export default Drawer;
