import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./style.scss";

const DeleteModal = (props) => {
  const {
    isModalHidden,
    setIsModalHidden,
    isDeleting,
    bodyText,
    btnLabel,
    handleDelete,
  } = props;

  const popupRef = useRef(null);

  const handleModalClose = () => {
    setIsModalHidden(true);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setIsModalHidden(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupRef]);

  if (isModalHidden) return null;

  return createPortal(
    <div className="modal-overlay">
      <div className="modal-wrapper" id="myModal" ref={popupRef}>
        <div className="modal-main">
          <span>{bodyText}</span>
        </div>
        <div className="modal-btns">
          <div className="modal-close">
            <button
              disabled={isDeleting}
              className="close-btn"
              onClick={handleModalClose}
            >
              Cancel
            </button>
          </div>
          <div className="modal-confirm">
            <button
              disabled={isDeleting}
              className="delete-btn"
              onClick={handleDelete}
            >
              {isDeleting ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <span>{btnLabel}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteModal;
