export const ADD_TOAST = 'ADD_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const TOAST_RESUME = 'TOAST_RESUME';
export const TOAST_PAUSE = 'TOAST_PAUSE';

export const OPEN_CONFIRM = 'OPEN_CONFIRM';
export const CLOSE_CONFIRM = 'CLOSE_CONFIRM';
export const CONFIRM_BUSY = 'CONFIRM_BUSY';
export const CONFIRM_IDLE = 'CONFIRM_IDLE';

const reducer = (state, action)=>{
  switch(action.type){
    case 'ADD_TOAST':
      return {
        ...state,
        toastMessages: [
          ...state.toastMessages,
          {
            type: action.messageType,
            message: action.messageText
          }
        ]
      }
    case 'DISMISS_TOAST':
      let toastMessages = state.toastMessages;
      toastMessages.shift();
      return {
        ...state,
        toastMessages: [
          ...toastMessages
        ]
      }
    case 'TOAST_RESUME':
      return {
        ...state,
        toastPaused: false,
      }
    case 'TOAST_PAUSE':
      return {
        ...state,
        toastPaused: true
      }

    case OPEN_CONFIRM:
      return {
        ...state,
        isConfirmModalVisible: true,
        confirmModalConfig: action.confirmConfig
        // confirmModalAction: action.confirmAction
      }

    case CLOSE_CONFIRM:
      return {
        ...state,
        isConfirmModalVisible: false,
        confirmModalConfig: {
          title: 'Confirm Action',
          text: null,
          confirmText: null,
          confirmAction: () => {}
        }
      }

    case CONFIRM_BUSY:
      return {
        ...state,
        isConfirmBusy: true
      }

    case CONFIRM_IDLE:
      return {
        ...state,
        isConfirmBusy: false
      }

    default:
      return
  }
}

export default reducer;