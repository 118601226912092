import React, { useContext, useState, useEffect } from "react";
import Alert from "./../../../../components/share/Alert";
import { Button } from "./../../../../components/share/InsightUI";
import { Link } from "react-router-dom";
import { UserDataContext } from "./../../../../components/app/UserData";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import "./style.scss";

const TrackingCode = (props) => {
  const { apiServerSubDomain, isApiServerLoading } =
    useContext(UserDataContext);
  const [integrationMode, setIntegrationMode] = useState("onpage");
  const profileId = props.match.params.profileId;

  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    if (!authUser.isAdmin) {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const handleClickIntegrationMode = (e) => {
    const mode = e.currentTarget.dataset.mode;
    setIntegrationMode(mode);
  };

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4 tracking-code-card">
            <div className="card-body">
              <div className="mb-4">
                <Button
                  className={`method-toggle ${
                    integrationMode === "onpage" && "active"
                  } `}
                  data-mode="onpage"
                  onClick={handleClickIntegrationMode}
                >
                  <span className="fa fa-code"></span>
                  On-page Javascript
                </Button>
                <Button
                  className={`method-toggle ml-2 ${
                    integrationMode === "gtm" && "active"
                  } `}
                  data-mode="gtm"
                  onClick={handleClickIntegrationMode}
                >
                  <img src="/images/gtm-icon.svg" alt="" />
                  Google Tag manager
                </Button>
              </div>
              {/* <p>Copy and Paste this code as after the <strong>&lt;head&gt;</strong> tag on all pages</p> */}
              {integrationMode === "onpage" && (
                <p>
                  Paste this code as high in the <strong>&lt;head&gt;</strong>{" "}
                  of the page as possible:
                </p>
              )}
              {integrationMode === "gtm" && (
                <>
                  <p>
                    Please follow the below steps to create the Insightech Tag
                    in Google Tag Manager:
                  </p>
                  <ul>
                    <li>
                      Create a <strong>Custom HTML</strong> tag named
                      "Insightech Tag"
                    </li>
                    <li>
                      Copy the code below and paste it to the Custom HTML tag.
                    </li>
                    <li>
                      In <strong>Advanced Settings</strong>, select{" "}
                      <strong>Once per page</strong> for{" "}
                      <strong>Tag firing options</strong>
                    </li>
                    <li>
                      Set trigger condition to <strong>All Pages</strong>.
                    </li>
                    <li>Publish the tag to live websites.</li>
                  </ul>
                </>
              )}
              {isApiServerLoading && (
                <code className="code-block mb-4">
                  <span className="fa fa-spinner spin"></span> Loading Tracking
                  Code...
                </code>
              )}
              {!isApiServerLoading && apiServerSubDomain && (
                <code className="code-block mb-4">
                  {`<script>`}
                  {<br />}
                  {`!function(){ var async = function(c){ var d = document, s = d.createElement('script'), h = d.getElementsByTagName('head')[0]; s.src = 'https://cdn.insightech.com/insightech.mini.js'; if(c){s.addEventListener('load', function(e){c(null,e);}, false);} h.appendChild(s); }; async(function(){ insightech = new _InsightechObject("${profileId}:${apiServerSubDomain}"); }); }();`}
                  {<br />}
                  {`</script>`}
                </code>
              )}

              <div className="alert alert-info">
                If you are going to test Insightech replay feature on Localhost
                or intranet sites, please note that all resource files (e.g.
                images, style sheets, fonts) must be accessible from Internet.
                Insightech archives the resource files so that the playback can
                be perfect even your site has changed.
              </div>

              <p>Click on the button to change advanced tracking settings</p>
              <Link
                className="btn btn-primary btn-sm"
                to={`/profile/${profileId}/admin/code/settings`}
              >
                Change Tracking Settings
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingCode;
