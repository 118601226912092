import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DropdownAction from "./../../../components/share/DropdownAction";
import { Button } from "./../../../components/share/InsightUI";
import { UserDataContext } from "./../UserData";
import { mergeFiltersOutPageView } from "../../../utils";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsTriangleFill } from "react-icons/bs";
import "./style.scss";

function FunnelGraph(props) {
  const {
    MaxSessionPct,
    sessionCount,
    prevSessionCount,
    nextSessionCount,
    lastRow,
    maxBarWidth,
    stepNumber,
    stepName,
    filters,
    dropOffFilters,
  } = props;

  const { activeProfileId, userConditions, appliedFilters } =
    React.useContext(UserDataContext);

  const sessionPct = sessionCount / prevSessionCount;
  const prevDropOffPct = (prevSessionCount - sessionCount) / prevSessionCount;
  const dropOffRate = ((sessionCount - nextSessionCount) / sessionCount) * 100;
  const completedRate = sessionCount
    ? (nextSessionCount / sessionCount) * 100
    : 0; // catch sessionCount is 0

  const barWidth = ((maxBarWidth * sessionPct) / MaxSessionPct) * 100;

  const drawBarWidth = barWidth > 100 ? `100%` : `${barWidth}%`;

  const dropOffBarWidth =
    ((maxBarWidth * prevDropOffPct) / MaxSessionPct) * 100;

  const drawDropOffBarWidth =
    dropOffBarWidth > 100
      ? `${100 - barWidth}%`
      : dropOffBarWidth < 0
      ? `0%`
      : `${dropOffBarWidth}%`;

  const updatedFilters = useMemo(() => {
    return mergeFiltersOutPageView(filters, appliedFilters);
  }, [filters, appliedFilters]);

  const updatedDropOffFilters = useMemo(() => {
    return mergeFiltersOutPageView(dropOffFilters, appliedFilters);
  }, [dropOffFilters, appliedFilters]);

  const handleClickSession = () => {
    props.history.push(`/profile/${activeProfileId}/replay/list`, {
      overrideFilters: {
        userConditions: [...userConditions],
        pageContainers: updatedFilters,
      },
    });
  };

  const handleClickDropOff = () => {
    props.history.push(`/profile/${activeProfileId}/replay/list`, {
      overrideFilters: {
        userConditions: [...userConditions],
        pageContainers: updatedDropOffFilters,
      },
    });
  };

  return (
    <div className={`col-10 offset-md-1 step-col ${lastRow && "last-row"}`}>
      <div className="funnel-step">
        <div className="line"></div>
        <div className="counter-col">
          <div className="counter">{stepNumber}</div>
        </div>
        <div className="data-col">
          <div className="session-data">
            <strong className="text-gray-900">
              {sessionCount.toLocaleString()}
            </strong>
          </div>
          <div className="completed-rate">
            {!lastRow && (
              <>
                <span
                  className="value"
                  data-tooltip-id="funnel-tooltip"
                  data-tooltip-content={`Step Completion: ${nextSessionCount}/${sessionCount} sessions`}
                >
                  {`${
                    Math.round((completedRate + Number.EPSILON) * 100) / 100 ||
                    0
                  }%`}
                </span>
                <span className="triangle">
                  <BsTriangleFill />
                </span>
              </>
            )}
          </div>
          <div className="bar-col">
            <div className="step-name">
              <strong>Step {stepNumber}.</strong> {stepName}
            </div>
            <div className="bar">
              <div
                onClick={handleClickSession}
                className="session-bar"
                style={{ width: drawBarWidth }}
                data-tooltip-id="funnel-tooltip"
                data-tooltip-content={`View ${sessionCount} completed sessions`}
              ></div>
              <div
                onClick={handleClickDropOff}
                className="dropoff-bar"
                style={{ left: drawBarWidth, width: drawDropOffBarWidth }}
                data-tooltip-id="funnel-tooltip"
                data-tooltip-content={`View ${
                  prevSessionCount - sessionCount
                } drop off sessions`}
              ></div>
            </div>
          </div>
          <div className="dropoff-rate">
            {!lastRow && (
              <>
                {dropOffRate >= 0 && (
                  <div
                    className="trend negative"
                    data-tooltip-id="funnel-tooltip"
                    data-tooltip-content={`Step Drop Off: ${
                      sessionCount - nextSessionCount
                    }/${sessionCount} sessions`}
                  >
                    {`${
                      Math.round((dropOffRate + Number.EPSILON) * 100) / 100
                    }%`}
                    <span className="triangle">
                      <BsTriangleFill />
                    </span>
                  </div>
                )}
                {dropOffRate < 0 && (
                  <div className="trend positive">
                    <span>0%</span>
                  </div>
                )}
              </>
            )}
          </div>
          {sessionCount - nextSessionCount >= 0 ? (
            <div className="dropoff-session">
              <div className="dropoff-value">
                {(sessionCount - nextSessionCount).toLocaleString()}
              </div>
            </div>
          ) : (
            <div className="dropoff-session">
              <div className="dropoff-value up">0</div>
            </div>
          )}
          <div className="actions">
            <DropdownAction align="right">
              <Button
                variant="plain"
                size="small"
                onClick={handleClickSession}
                className="dropdown-item"
              >
                View Sessions
              </Button>
              <Button
                disabled={prevDropOffPct === 0}
                variant="plain"
                size="small"
                onClick={handleClickDropOff}
                className="dropdown-item"
              >
                View Drop-offs
              </Button>
            </DropdownAction>
          </div>
        </div>
      </div>
      <ReactTooltip id="funnel-tooltip" className="tooltip-md" />
    </div>
  );
}

FunnelGraph.propTypes = {
  sessionCount: PropTypes.number,
  prevSessionCount: PropTypes.number,
  nextSessionCount: PropTypes.number,
  stepNumber: PropTypes.number,
  stepName: PropTypes.string,
  maxBarWidth: PropTypes.number,
  lastRow: PropTypes.bool,
  filters: PropTypes.array,
  dropOffFilters: PropTypes.array,
};
FunnelGraph.defaultProps = {};

export default withRouter(FunnelGraph);
