import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import SettingMenu from "./SettingMenu";
import * as Icons from "../../assets/icons/MenuIconProvider";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ProfileMenu = ({ profileId }) => {
  const { authUser } = useContext(AuthContext);

  return (
    <>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/analytics/dashboard"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Dashboard"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/analytics/dashboard"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Dashboard />
          </span>
          <span className="sidebar-menu-title">Dashboard</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/pages/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Page Analysis"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/pages/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.PageAnalysis />
          </span>
          <span className="sidebar-menu-title">Page Analysis</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/forms/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Form Analysis"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/forms/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.FormAnalysis />
          </span>
          <span className="sidebar-menu-title">Form Analysis</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/funnels/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Funnels"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/funnels/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Funnels />
          </span>
          <span className="sidebar-menu-title">Funnels</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/pathing/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Pathing"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/pathing/sunburst"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Pathing />
          </span>
          <span className="sidebar-menu-title">Pathing</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/replay/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Replays"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/replay/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Replays />
          </span>
          <span className="sidebar-menu-title">Replays</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/notes/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Notes"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/notes/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Notes />
          </span>
          <span className="sidebar-menu-title">Notes</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/segments/"
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Segments"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/segments/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Segments />
          </span>
          <span className="sidebar-menu-title">Segments</span>
        </NavLink>
      </nav>
      <nav
        className={
          "sidebar-menu" +
          (new RegExp(`^/profile/${profileId}/reports?/`).test(
            window.location.pathname
          )
            ? " active"
            : "")
        }
        data-tooltip-id="sidebar-menu-tooltip"
        data-tooltip-content="Saved Reports"
      >
        <NavLink
          className="sidebar-menu-item"
          exact
          to={"/profile/" + profileId + "/report/list"}
          activeClassName="active"
        >
          <span className="nav-menu-icon">
            <Icons.Reports />
          </span>
          <span className="sidebar-menu-title">Saved Reports</span>
        </NavLink>
      </nav>
      {authUser.isAdmin && <SettingMenu profileId={profileId} />}
      <ReactTooltip
        id="sidebar-menu-tooltip"
        className="tooltip-sm"
        place="right"
      />
    </>
  );
};

export default ProfileMenu;
