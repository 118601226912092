import { useContext, useEffect, useRef, useState } from "react";
import { UserDataContext } from "../../components/app/UserData";
import { fetchSegments } from "../../lib/firebase/segment";
import { SegmentsContext } from "../../context/Segments";

const useFetchSegments = (profileId) => {
  const { profileUsers } = useContext(UserDataContext);
  const { setSegments } = useContext(SegmentsContext);
  const [segmentsList, setSegmentsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const _isMounted = useRef(true);

  useEffect(() => {
    setIsLoading(true);
    if (!_isMounted.current) {
      return;
    }

    // Segments Promise
    fetchSegments({ profileId })?.then((res) => {
      // Resolve Segments
      const result = [];
      Object.keys(res).forEach((id) => {
        const data = res[id];
        const usersData = profileUsers.byId;
        const creator = usersData[data.ownerId];
        data.id = id;
        data.creator = creator ? creator : { name: "Removed User" };
        result.push(data);
      });

      setSegmentsList(result);
      setSegments(res);
      setIsLoading(false);
    });

    return () => {
      _isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    isLoading,
    setIsLoading,
    _isMounted,
    segmentsList,
    setSegmentsList,
  };
};

export default useFetchSegments;
