import React from "react";
import PropTypes from "prop-types";
// import { Switch } from "./../index";

function Field(props) {
  const {
    isInvalid,
    invalidFeedback,
    label,
    inline,
    helptext,
    columnwidths,
  } = props;

  let classNames = props.className ? props.className : "";
  let fieldClass = "form-group";
  let errorClass = isInvalid ? "is-invalid was-validated" : "";
  let layoutClass = inline ? "row" : "";
  let labelClass = inline ? `col-sm-${columnwidths[0]} col-form-label` : "";

  return (
    <div className={`${fieldClass} ${layoutClass} ${classNames} ${errorClass}`}>
      {label && (
        <label className={`${labelClass}`} htmlFor={props.id}>
          {props.required && <span className="required">*</span>}
          {` `}
          {label}
        </label>
      )}
      {inline ? (
        <div className={`col-sm-${columnwidths[1]}`}>
          {props.children}
          {!isInvalid && helptext && (
            <small className="form-text">{helptext}</small>
          )}
          {isInvalid && (
            <div className="invalid-feedback">{invalidFeedback}</div>
          )}
        </div>
      ) : (
        <>
          {props.children}
          {!isInvalid && helptext && (
            <small className="form-text">{helptext}</small>
          )}
          {isInvalid && (
            <div className="invalid-feedback">{invalidFeedback}</div>
          )}
        </>
      )}
    </div>
  );
}

Field.propTypes = {
  isInvalid: PropTypes.bool,
  invalidFeedback: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
  helptext: PropTypes.string,
  columnwidths: PropTypes.array
};
Field.defaultProps = {};

export default Field;
