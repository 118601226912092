import {
  firebaseApp,
  getAuth,
  Firestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  where,
  query,
  getDoc,
  getDocs,
} from "./index";

export const saveSegment = (data, profileId, id = null) => {
  const collectionRef = collection(
    Firestore,
    "profiles",
    profileId,
    "segments"
  );
  if (id) {
    if (data.hasOwnProperty("ownerId")) {
      delete data["ownerId"];
    }
    return updateDoc(doc(collectionRef, id), { ...data }).then(() => {
      return { id };
    });
  }
  return addDoc(collectionRef, {
    ...data,
    ownerId: getAuth(firebaseApp).currentUser.uid,
  });
};

export const deleteSegmentById = (profileId, noteId) => {
  return deleteDoc(doc(Firestore, "profiles", profileId, "segments", noteId));
};

export const fetchSegments = ({ profileId, segmentId }) => {
  if (!profileId) {
    return;
  }

  let sharedSegmentsRef = null;
  let ownedSegmentsRef = null;

  if (!segmentId) {
    sharedSegmentsRef = getDocs(
      query(
        collection(Firestore, "profiles", profileId, "segments"),
        where("shared", "==", true)
      )
    );

    ownedSegmentsRef = getDocs(
      query(
        collection(Firestore, "profiles", profileId, "segments"),
        where("ownerId", "==", getAuth(firebaseApp).currentUser.uid)
      )
    );

    return Promise.all([sharedSegmentsRef, ownedSegmentsRef]).then(
      ([sharedSegments, ownedSegments]) => {
        const aggregateSegments = {};
        sharedSegments.forEach((segment) => {
          aggregateSegments[segment.id] = segment.data();
        });
        ownedSegments.forEach((segment) => {
          if (!aggregateSegments[segment.id]) {
            aggregateSegments[segment.id] = segment.data();
          }
        });

        return aggregateSegments;
      }
    );
  } else {
    getDoc(doc(Firestore, "profiles", profileId, "segments", segmentId));
  }
};

export const fetchSegmentById = ({ profileId, segmentId }) => {
  return getDoc(doc(Firestore, "profiles", profileId, "segments", segmentId));
};

export const fetchSizeBasedSegments = ({ profileId }) => {
  const segments = [];
  return fetchSegments({ profileId }).then((res) => {
    Object.keys(res).forEach((key) => {
      const segment = res[key];
      if (
        segment.filter.indexOf("BrowserWidth") > -1 ||
        segment.filter.indexOf("BrowserHeight") > -1
      ) {
        segments.push({
          ...segment,
          id: key,
        });
      }
    });
    return segments;
  });
};
