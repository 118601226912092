import {
  Firestore,
  doc,
  getDocs,
  getDoc,
  collection,
} from "./index";

export const fetchConversion = ({ profileId, conversionId }) => {
  return getDoc(
    doc(Firestore, "profiles", profileId, "conversions", conversionId)
  );
};

export const fetchConversions = ({ profileId }) => {
  return getDocs(collection(Firestore, "profiles", profileId, "conversions"));
};
