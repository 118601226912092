import axios from "axios";
import config from "../config/config.json";
import { getAuth, firebaseApp } from "../lib/firebase";

// Abstract query
const apiUiQuery = (method, path, userToken, data) => {
  const axiosConf = {
    method: method,
    url: config.apiUiHostPath + path,
    headers: {},
  };

  if (userToken) {
    axiosConf.headers["Authorization"] = "Bearer " + userToken;
  }
  axiosConf.headers["Accept"] = "application/json";

  if (data) {
    axiosConf.data = data;
    axiosConf.headers["Content-Type"] = "application/json";
  }

  return axios(axiosConf);
};

const uiApi = {
  // All routes should receive authUser
  fetchInvite() {
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("get", "/api/fetchInvite", token);
      });
  },
  fetchUserAccess(token) {
    return apiUiQuery("get", "/api/fetchUserAccess", token);
  },
  fetchAccount(token, { accountId, profileId }) {
    const data = { accountId: accountId, profileId: profileId };
    return apiUiQuery("post", "/api/fetchAccount", token, data);
  },
  fetchProfile(token, { profileId }) {
    return apiUiQuery("get", "/api/fetchProfile/" + profileId, token);
  },
  inviteUserToProfile(senderName, user, profileId) {
    const data = {
      senderName: senderName,
      user: user,
      profileId: profileId,
    };

    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/inviteUserToProfile", token, data);
      });
  },
  generateCode(email) {
    const data = { email: email };
    return apiUiQuery("post", "/api/generateCode", null, data);
  },
  verifyCode(emailAddress, code) {
    const data = {
      email: emailAddress,
      code: code,
    };
    return apiUiQuery("post", "/api/verifyCode", null, data);
  },
  acceptInvite(role, profileId, inviteId) {
    const data = {
      role: role,
      profileId: profileId,
      inviteId: inviteId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/acceptInvite", token, data);
      });
  },
  rejectInvite(inviteId) {
    const data = {
      inviteId: inviteId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/rejectInvite", token, data);
      });
  },
  generateToken() {
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/generateToken", token);
      });
  },
  fetchAccountUsers(accountId) {
    const data = { accountId: accountId };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/fetchAccountUsers", token, data);
      });
  },
  fetchAccountUser({ userId, accountId }) {
    const data = {
      userId: userId,
      accountId: accountId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/fetchAccountUser", token, data);
      });
  },
  addAccountUser({ user, accountId }) {
    const data = {
      user: user,
      accountId: accountId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/addAccountUser", token, data);
      });
  },
  removeAccountUser({ userId, accountId }) {
    const data = {
      userId: userId,
      accountId: accountId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/removeAccountUser", token, data);
      });
  },
  addProfileUser({ user, profileId }) {
    const data = {
      user: user,
      profileId: profileId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/addProfileUser", token, data);
      });
  },
  removeProfileUser({ userId, profileId }) {
    const data = {
      userId: userId,
      profileId: profileId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/removeProfileUser", token, data);
      });
  },
  updateProfile({ pathRules, profileId }) {
    const data = {
      pathRules: pathRules,
      profileId: profileId,
    };
    return getAuth(firebaseApp)
      .currentUser.getIdToken()
      .then((token) => {
        return apiUiQuery("post", "/api/updateProfile", token, data);
      });
  },
};

export default uiApi;
