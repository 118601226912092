import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { FaTimes } from "react-icons/fa";
import "./style.scss";

// This component rendering with React Portal
function Modal(props) {
  const {
    title,
    description,
    footer,
    isVisible,
    handleClose,
    isBusy = false,
    width,
    className,
    handleSubmit,
  } = props;

  if (!isVisible) return null;

  return createPortal(
    <form
      className={`modal-overlay fade show ${className}`}
      tabIndex="-1"
      role="dialog"
      onSubmit={handleSubmit}
    >
      <div
        className="modal-wrapper"
        role="document"
        style={{ maxWidth: width }}
      >
        <div className="modal-close">
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
            disabled={isBusy}
          >
            <FaTimes />
          </button>
        </div>

        <div className="modal-content">
          <div className="content-header">
            <div className="content-title">{title}</div>
            <div className="content-description">{description}</div>
          </div>
          <div className="content-body">{props.children}</div>
          {footer && <div className="content-footer">{footer}</div>}
        </div>
      </div>
    </form>,
    document.body
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.node,
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
};

Modal.defaultProps = {
  handleSubmit: (event) => event.preventDefault(), // Default to preventing submission
};

export default Modal;
