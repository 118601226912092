import React from 'react';
import {Button} from './../../share/InsightUI';
import './style.scss';

const InlineConfirmation = (props)=>{
  const {
    title,
    message,
    buttonText,
    confirmAction,
    isVisible,
    isProcessing,
    setIsVisible,
  } = props;
  const dropdownRef = React.useRef(null);

  const handleClickClose = (e)=>{
    e.preventDefault();
    e.stopPropagation();
    setIsVisible(false);
  }

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsVisible(false);
      }
    };

    // Add outside click handler
    document.addEventListener("mousedown", handleClickOutside);

    // Remove outside click handler on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className={`${!!isVisible && "show"} inline-confirmation card shadow`}
    >
      <div className="card-header">
        <div className="card-title text-gray-800">{title}</div>
        <Button
          disabled={isProcessing}
          onClick={handleClickClose}
          variant="plain"
          className="btn-close"
        >
          <span className="fa fa-times"></span>
        </Button>
      </div>
      <div className="card-body">
        <p className="text-gray-800">{message}</p>
        <Button
          disabled={isProcessing}
          icon={isProcessing ? "fa fa-spinner fa-spin" : ''}
          size="small"
          variant="danger"
          className="d-block"
          onClick={confirmAction}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default InlineConfirmation;