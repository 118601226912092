import React from "react";
import { Form, Field, Button } from "./../../../../components/share/InsightUI";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { requestPasswordRecovery } from "./../../../../lib/firebase";
import Alert from "./../../../../components/share/Alert";
import uiApi from "../../../../api/api-ui";

function UserForm(props) {
  const [isFormBusy, setIsFormBusy] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    email: "",
    name: "",
    role: "",
  });
  const [action, setAction] = React.useState(null);
  const { accountId, userId } = props.match.params;
  const [alertMessage, setAlertMessage] = React.useState(null);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (userId) {
      uiApi.fetchAccountUser({ userId, accountId }).then((res) => {
        if (!res.data.success) {
          props.history.push(`/account/${accountId}/users`);
          return;
        }
        const userData = res.data;

        setInitialValues({
          email: userData.email,
          name: userData.name,
          role: userData.role,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleSubmit = (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    setIsFormBusy(true);
    setSubmitting(false);
    if (action === "secondary") {
      uiApi.removeAccountUser({ userId, accountId }).then(() => {
        setIsFormBusy(false);
        setAction(null);
        setAlertMessage(
          <>
            {values.email}'s permission has been updated'. Back to {` `}
            <Link to={`/account/${accountId}/users`}>Users List</Link>
          </>
        );
      });
      return;
    } else {
      uiApi.addAccountUser({ user: values, accountId }).then(({ data }) => {
        if (data.success) {
          const message = !userId
            ? " has been granted access to the account. "
            : "'s permission has been updated. ";
          if (data.newUserCreated) {
            requestPasswordRecovery(
              values.email,
              () => {
                setIsFormBusy(false);
                setAction(null);
                setAlertMessage(
                  <>
                    {values.email}
                    {message} Back to {` `}
                    <Link to={`/account/${accountId}/users`}>Users List</Link>
                  </>
                );
              },
              () => {}
            );
          } else {
            setIsFormBusy(false);
            setAction(null);
            setAlertMessage(
              <>
                {values.email}
                {message} Back to {` `}
                <Link to={`/account/${accountId}/users`}>Users List</Link>
              </>
            );
          }
        }
      });
    }
  };

  return (
    <>
      <div className="row">
        {!!alertMessage && (
          <Alert
            show={alertMessage}
            type={`success`}
            message={alertMessage}
            count={1}
          />
        )}
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card shadow">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={() => {}}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  // touched,
                  handleChange,
                  // handleBlur,
                  // setFieldValue,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
                    <Field
                      inline
                      label="Email"
                      columnwidths={[2, 8]}
                      isInvalid={errors.email}
                      invalidFeedback={errors.email}
                    >
                      <input
                        value={values.email || ""}
                        onChange={handleChange}
                        name="email"
                        className="form-control"
                        type="email"
                        readOnly={userId}
                        required
                      />
                    </Field>
                    <Field inline label="Name" columnwidths={[2, 8]}>
                      <input
                        value={values.name || ""}
                        onChange={handleChange}
                        name="name"
                        className="form-control"
                        type="text"
                        readOnly={userId}
                        required
                      />
                    </Field>
                    <Field inline label="Permission" columnwidths={[2, 4]}>
                      <select
                        value={values.role || ""}
                        className="form-control form-control"
                        name="role"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select a Role</option>
                        <option value="admin">Admin</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    </Field>

                    <div className="row">
                      <div className="offset-md-2 col-md-8">
                        {!userId && (
                          <Button
                            disabled={isFormBusy}
                            style={{
                              paddingLeft: "30px",
                              paddingRight: "30px",
                            }}
                            type="submit"
                            size="small"
                            variant="primary"
                          >
                            {!!isFormBusy && action !== "secondary" && (
                              <>
                                <span className="fa fa-spinner spin"></span>
                                {` `}
                              </>
                            )}
                            {!!isFormBusy && `Granting Access`}
                            {!isFormBusy && `Grant Access`}
                          </Button>
                        )}
                        {!!userId && (
                          <>
                            <Button
                              disabled={isFormBusy}
                              style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                              }}
                              type="submit"
                              size="small"
                              variant="primary"
                            >
                              {!!isFormBusy && !action && (
                                <>
                                  <span className="fa fa-spinner spin"></span>
                                  {` `}
                                </>
                              )}
                              {!!isFormBusy &&
                                action !== "secondary" &&
                                `Updating Access`}
                              {(!isFormBusy ||
                                (!!isFormBusy && action === "secondary")) &&
                                `Update Access`}
                            </Button>
                            <Button
                              className="ml-2"
                              onClick={() => {
                                setAction("secondary");
                                handleSubmit();
                              }}
                              disabled={isFormBusy}
                              style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                              }}
                              size="small"
                              variant="danger"
                            >
                              {!!isFormBusy && action === "secondary" && (
                                <>
                                  <span className="fa fa-spinner spin"></span>
                                  {` `}
                                </>
                              )}
                              {!!isFormBusy &&
                                action === "secondary" &&
                                `Removing Access`}
                              {(!isFormBusy ||
                                (!!isFormBusy && action !== "secondary")) &&
                                `Remove Access`}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

UserForm.propTypes = {};
UserForm.defaultProps = {};

export default UserForm;
