export const SessionSortOptions = [
  { value: "timestamp_desc", text: "Newest First" },
  { value: "timestamp_asc", text: "Oldest First" },
  { value: "revenue_desc", text: "Revenue: High to Low" },
  { value: "revenue_asc", text: "Revenue: Low to High" },
  { value: "pageviews_desc", text: "Pageviews: High to Low" },
  { value: "pageviews_asc", text: "Pageviews: Low to High" },
  { value: "duration_desc", text: "Duration: Long to Short" },
  { value: "duration_asc", text: "Duration: Short to Long" },
];
