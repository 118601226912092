import React from 'react';
import PropTypes from 'prop-types';

function Textarea(props){
    const {id, value, ...rest} = props;
    return (
        <textarea
            value={value}
            className="form-control"
            id={id}
            {...rest}
        />
    );
}

Textarea.propTypes = {
    id: PropTypes.string
}
Textarea.defaultProps = {}

export default Textarea;