import React, { useState } from "react";
import { BsLink45Deg } from "react-icons/bs";
import { Button } from "../../share/InsightUI";

const CopyLinkIcon = () => {
  const initialText = "Copy link";
  const initialColor = "#3a3b45";
  const [btnText, setBtnText] = useState(initialText);
  const [btnColor, setBtnColor] = useState(initialColor);

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setBtnText(`copied!`);
    setBtnColor("green");

    setTimeout(() => {
      setBtnText(initialText);
      setBtnColor(initialColor);
    }, 1000);
  };

  const btnStyle = {
    color: btnColor,
  };

  return (
    <Button
      variant="plain"
      className="dropdown-item"
      onClick={handleClick}
      style={btnStyle}
    >
      <BsLink45Deg size={18} className="mr-2" />
      {btnText}
    </Button>
  );
};

export default CopyLinkIcon;
