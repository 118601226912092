import React, { useEffect, useState } from "react";
import RadioButton from "../../../../share/InsightUI/RadioButton";
import { REPORT_PRIVATE } from "../../../../../constants/info";
import "./style.scss";

const ShareableCheckbox = (props) => {
  const { handleChange, selectedReport } = props;
  const [dataSelect, setDataSelect] = useState(
    selectedReport?.shared ? "public" : "private"
  );

  const handleRadioChange = (e) => {
    e.target.name = "shared";
    setDataSelect(e.target.id);
    handleChange(e);
  };

  useEffect(() => {
    if (selectedReport?.Id) {
      if (selectedReport?.shared === true) {
        setDataSelect("public");
      } else {
        setDataSelect("private");
      }
    } else {
      setDataSelect("private");
    }
  }, [selectedReport]);

  return (
    <div className="modal-form-row">
      <div className="row-label">Visibility</div>
      <div className="row-input radio-button-container">
        <RadioButton
          id="private"
          label="Private"
          value="private"
          isSelected={dataSelect === "private"}
          changed={handleRadioChange}
          tooltip={REPORT_PRIVATE}
        />
        <RadioButton
          id="public"
          label="Visible to all account users"
          value="public"
          isSelected={dataSelect === "public"}
          changed={handleRadioChange}
        />
      </div>
    </div>
  );
};

export default ShareableCheckbox;
