import React from "react";
import { Button, Modal } from "../../share/InsightechUI";
import ModalForm from "./components/ModalForm";
import "./style.scss";

const ReportModal = (props) => {
  const {
    isVisible,
    handleClose,
    isBusy,
    handleChange,
    handleSubmit,
    isNameValid,
    nameErrorMsg,
    isUrlValid,
    urlErrorMsg,
    selectedReport,
    action,
    isReportEditor,
    isExternalReport,
  } = props;

  return (
    <Modal
      title={
        !isExternalReport
          ? "Add Report to my Saved Reports List"
          : "Add an External Report Shortcut"
      }
      description={
        !isExternalReport
          ? "Add this report to your Saved Reports List. It can be shared with all account users using the setting below"
          : "Link an External Report report here. If you to make it visible to other users, please ensure that this external link can be accessed to all users viewing this list."
      }
      isVisible={isVisible}
      handleClose={handleClose}
      isBusy={isBusy}
      width={"572px"}
      handleSubmit={handleSubmit}
      footer={
        <div className="modal-btn-group">
          {(action === "save" || action === "copy") && (
            <Button
              disabled={!isNameValid || !isUrlValid}
              name="save"
              color="primary"
              type="submit"
            >
              {isBusy && <span className="fa fa-spinner spin"> </span>}
              {selectedReport.Id ? "Save as New" : "Save report"}
            </Button>
          )}

          {selectedReport.Id && action === "update" && (
            <Button
              disabled={!isNameValid || !isUrlValid || !isReportEditor}
              name="update"
              type="submit"
              color="primary"
            >
              {isBusy && <span className="fa fa-spinner spin"> </span>}
              Update report
            </Button>
          )}

          <Button
            name="cancel"
            color="outlined-primary"
            disabled={isBusy}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <ModalForm
        handleChange={handleChange}
        isNameValid={isNameValid}
        nameErrorMsg={nameErrorMsg}
        isUrlValid={isUrlValid}
        urlErrorMsg={urlErrorMsg}
        selectedReport={selectedReport}
        action={action}
        isExternalReport={isExternalReport}
      />
    </Modal>
  );
};

export default ReportModal;
