import React from "react";
import { thousandSeparatorAndFixedDecimal } from "../../../utils";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";

const WidgetNumber = ({
  isCompareMode,
  isLoading,
  isComparedLoading,
  prefix,
  suffix,
  name,
  number,
  comparedNumber,
  comparisonDiffNumber,
  error,
  status,
  comparedStatus,
  columnClass,
  color,
  info,
}) => {
  return (
    <div
      className={`${!!columnClass ? columnClass : "col-xl-2"} col-md-4 mb-4`}
    >
      <div className="card data-tile h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="tile-label">
                <span>{name}</span>
                {!!info && (
                  <span
                    className="tile-info"
                    data-tooltip-id="widget-tooltip"
                    data-tooltip-content={info}
                  >
                    <IoInformationCircleOutline />
                  </span>
                )}
              </div>
              <div className="h5 mb-0 font-weight-bold">
                {!!error && !isLoading && error}
                {status && !isLoading && !isComparedLoading && !error && (
                  <>
                    {isCompareMode && !!comparedStatus && (
                      <span className="strip strip-blue mr-2"></span>
                    )}
                    <span className={color || ""}>
                      {prefix}
                      {thousandSeparatorAndFixedDecimal(number, 0)}
                      {suffix}
                    </span>
                  </>
                )}

                {isCompareMode &&
                  !!status &&
                  !!comparedStatus &&
                  !isLoading &&
                  !isComparedLoading &&
                  !error && (
                    <>
                      <div>
                        <span className="strip strip-orange mr-2"></span>
                        <span className="compare-data-text">
                          vs. {prefix}
                          {thousandSeparatorAndFixedDecimal(comparedNumber, 0)}
                          {suffix}
                        </span>
                      </div>
                      <div className="h6 mt-1 mb-0">{comparisonDiffNumber}</div>
                    </>
                  )}

                {(isLoading || isComparedLoading) && (
                  <span className={color || ""}>
                    <i className="fa fa-spinner fa-spin" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="widget-tooltip" className="tooltip-md" />
    </div>
  );
};

export default WidgetNumber;
