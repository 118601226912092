export const eventTypes = {
  1: {
    name: "Pageview",
    key: "",
  },
  2: {
    name: "Dom Ready",
    key: "events_dom_ready",
  },
  3: {
    name: "Page loaded",
    key: "events_page_loaded",
  },
  4: {
    name: "Page unload",
    key: "events_page_unload",
  },
  5: {
    name: "Device Allocated",
    key: "events_device_allocated",
  },
  6: {
    name: "Customer Identified",
    key: "events_customer_identified",
  },
  7: {
    name: "Click",
    key: "events_click",
  },
  8: {
    name: "Input",
    key: "events_input",
  },
  9: {
    name: "Select",
    key: "events_select",
  },
  10: {
    name: "Field Values",
    key: "events_field_values",
  },
  11: {
    name: "Mouse movement",
    key: "events_mouse_movement",
  },
  12: {
    name: "Scrolling",
    key: "events_scrolling",
  },
  13: {
    name: "Tab Invisible",
    key: "events_window_blur",
  },
  14: {
    name: "Tab Visible",
    key: "events_window_focus",
  },
  15: {
    name: "Window Resize",
    key: "events_window_resize",
  },
  16: {
    name: "DOM Mutation",
    key: "events_dom_mutation",
  },
  17: {
    name: "Field Focus",
    key: "events_field_focus",
  },
  18: {
    name: "Field Blur",
    key: "events_field_blur",
  },
  19: {
    name: "URL Changes",
    key: "events_url_change",
  },
  95: {
    name: "Console Message",
    key: "events_console_message",
  },
  96: {
    name: "API Request",
    key: "events_api_request",
  },
  97: {
    name: "API Error",
    key: "events_api_error",
  },
  98: {
    name: "Rage Click",
    key: "events_rage_click",
  },
  99: {
    name: "Custom Data",
    key: "events_custom_data",
  },
};
