import React from "react";
import PageContainer from "../FilterPanel/PageContainer";
import Alert from "../../share/Alert";
import "./conversionsStep.scss";

const conditions = require("../../../inc/eventConditions.json");
const funnelStepConditions = JSON.parse(JSON.stringify(conditions));
delete funnelStepConditions["Any"];

function ConversionsStep(props) {
  const {
    invalidFields,
    index,
    data,
    eventFilters,
    isValid,
    handleUpdateCondition,
    handleClickAddEventConditionRow,
    handleClickDeleteEventConditionRow,
    handleUpdateSubCondition,
    handleClickAddSubConditionRow,
    handleClickDeleteSubConditionRow,
    enableRevenue,
    onEnableRevenue,
    revenueJsonPath,
    onRevenueJsonPath,
    isConversion,
    handleSaveConversionPoint,
    enabled,
    onEnabled,
    alert,
    isFormProcessing,
  } = props;

  const updateCondition = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    handleUpdateCondition(
      index,
      containerIndex,
      eventIndex,
      conditionIndex,
      data
    );
  };

  const updateSubCondition = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    handleUpdateSubCondition(
      index,
      containerIndex,
      eventIndex,
      conditionIndex,
      data
    );
  };

  const addSubConditionRow = (type, containerIndex, eventIndex) => {
    handleClickAddSubConditionRow(index, containerIndex, eventIndex);
  };

  const deleteSubConditionRow = (
    type,
    containerIndex,
    eventIndex,
    conditionIndex
  ) => {
    handleClickDeleteSubConditionRow(
      index,
      containerIndex,
      eventIndex,
      conditionIndex
    );
  };

  const addCondition = (e) => {
    if (handleClickAddEventConditionRow === undefined) return undefined;
    const containerIndex = e.currentTarget.dataset.containerindex;
    handleClickAddEventConditionRow(index, containerIndex);
  };

  const deleteCondition = (type, containerIndex, eventIndex) => {
    handleClickDeleteEventConditionRow(index, containerIndex, eventIndex);
  };

  return (
    <div
      className={`col-md-8 offset-md-2 step-col
        ${isValid ? "valid" : "invalid"}
        `}
      key={index}
    >
      {/* ${invalidSteps[index] ? 'invalid' : ''}
        ${validSteps[index] ? 'valid' : ''} */}
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className={`funnel-step`}>
        <div className="funnel-step-form">
          <div className="rule-wrapper">
            <PageContainer
              invalidFields={invalidFields}
              enableRevenue={enableRevenue}
              onEnableRevenue={onEnableRevenue}
              enabled={enabled}
              onEnabled={onEnabled}
              revenueJsonPath={revenueJsonPath}
              onRevenueJsonPath={onRevenueJsonPath}
              isConversion={isConversion}
              isFormProcessing={isFormProcessing}
              handleDelete={() => {}}
              containers={[data]}
              data={data}
              inclusion={true}
              containerIndex={0}
              editFieldOnly={false}
              filters={eventFilters}
              handleUpdateCondition={updateCondition}
              handleUpdateSubCondition={updateSubCondition}
              handleClickAddCondition={addCondition}
              handleClickDeleteConditionRow={deleteCondition}
              handleClickAddSubConditionRow={addSubConditionRow}
              handleClickDeleteSubConditionRow={deleteSubConditionRow}
              handleSaveConversionPoint={handleSaveConversionPoint}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ConversionsStep.propTypes = {};
ConversionsStep.defaultProps = {};

export default ConversionsStep;
