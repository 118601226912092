import React, { Fragment, useContext } from "react";
import "./style.scss";
import { FiLock, FiUnlock } from "react-icons/fi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { UserDataContext } from "../../../../../../components/app/UserData";

const DataTitle = (props) => {
  const { report, onReport } = props;
  const { activeProfileId } = useContext(UserDataContext);

  return (
    <Fragment>
      <div className="data-title">
        {report.reportType === "external" ? (
          <a
            href={report.url}
            id={report.Id}
            target="_blank"
            rel="noopener noreferrer"
            className="title-link"
          >
            {report.name}
          </a>
        ) : (
          <Link
            to={`/profile/${activeProfileId}/reports/${report.Id}`}
            id={report.Id}
            onClick={() => onReport(report)}
            className="title-link"
          >
            {report.name}
          </Link>
        )}
        <span
          className="report-sharable"
          data-tooltip-id="report-list-tooltip"
          data-tooltip-content={
            !!report.shared ? "Visible to all account users" : "Private report"
          }
        >
          {!!report.shared ? <FiUnlock /> : <FiLock />}
        </span>
      </div>

      {report.description && (
        <div
          className="data-descr"
          data-tooltip-id="report-list-tooltip"
          data-tooltip-content={report.description || ""}
        >
          {report.description}
        </div>
      )}
      <ReactTooltip id="report-list-tooltip" className="tooltip-md" />
    </Fragment>
  );
};

export default DataTitle;
