// Dashboard widgets constants
export const Widgets = {
  Trend: "trend",
  Channel: "channel",
  Campaign: "campaign",
  TopLandingPage: "landing_page",
  OS: "os",
  Browser: "browser",
  Country: "country",
  TopPage: "top_page",
};
