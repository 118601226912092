const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordNumericRegex = /\d+/;
const passwordUppercaseRegex = /[A-Z]+/;
const passwordLowercaseRegex = /[a-z]+/;
const passwordSpecialRegex = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/;
const phoneRegex = /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const signinFormValidator = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Please enter your Email';
    }

    if (!emailRegex.test(String(values.email).toLowerCase())) {
        errors.email = 'Please enter a valid Email';
    }

    return errors;
}

export const signinCodeFormValidator = (values) => {
    const errors = {};

    if (!values.code) {
        errors.code = 'Please enter your Verification Code';
    }

    return errors;
}

export const signinPasswordFormValidator = (values) => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Please enter a password';
    }

    return errors;
}

export const signupFormValidator = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Please enter your Email';
    }

    if (!emailRegex.test(String(values.email).toLowerCase())) {
        errors.email = 'Please enter a valid Email';
    }

    if (!values.name) {
        errors.name = 'Please enter your Full Name';
    }

    if (!values.password) {
        errors.password = 'Please enter a password';
    }

    if (values.password.length < 8) {
        errors.password = 'Your password must be at least 8 characters';
    }

    if (!passwordSpecialRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 special character'
    }

    if (!passwordNumericRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 number'
    }

    if (!passwordUppercaseRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 uppercase letter'
    }

    if (!passwordLowercaseRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 lowercase letter'
    }

    if (values.password !== values.password2) {
        errors.password2 = 'Your passwords do not match';
    }

    return errors;
}

export const passwordRecoveryFormValidator = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Please enter your Email';
    }

    if (!emailRegex.test(String(values.email).toLowerCase())) {
        errors.email = 'Please enter a valid Email';
    }

    return errors;
}

export const passwordResetValidator = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Please enter a password';
    }

    if (values.password.length < 8) {
        errors.password = 'Your password must be at least 8 characters';
    }

    if (!passwordSpecialRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 special character'
    }

    if (!passwordNumericRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 number'
    }

    if (!passwordUppercaseRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 uppercase letter'
    }

    if (!passwordLowercaseRegex.test(String(values.password))) {
        errors.password = 'Your password must contain at least 1 lowercase letter'
    }

    if (values.password !== values.password2) {
        errors.password2 = 'Your passwords do not match';
    }

    return errors;
}

export const passwordChangeValidator = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Please enter your current password';
    }

    if (values.password1.length < 8) {
        errors.password1 = 'Your password must be at least 8 characters';
    }

    if (!passwordSpecialRegex.test(String(values.password1))) {
        errors.password1 = 'Your password must contain at least 1 special character'
    }

    if (!passwordNumericRegex.test(String(values.password1))) {
        errors.password1 = 'Your password must contain at least 1 number'
    }

    if (!passwordUppercaseRegex.test(String(values.password1))) {
        errors.password1 = 'Your password must contain at least 1 uppercase letter'
    }

    if (!passwordLowercaseRegex.test(String(values.password1))) {
        errors.password1 = 'Your password must contain at least 1 lowercase letter'
    }

    if (!values.password1) {
        errors.password1 = 'Please enter a new password';
    }

    if (values.password1 !== values.password2) {
        errors.password2 = 'Your passwords do not match';
    }

    if (!values.password2) {
        errors.password2 = 'Please re-enter your new password';
    }

    return errors;
}

export const nameValidator = (values) => {
    const errors = {};

    if (!values.displayName) {
        errors.displayName = 'Please enter your full name';
    }

    return errors;
}

export const phoneValidator = (values) => {
    const errors = {}

    if (!phoneRegex.test(String(values.phone))) {
        errors.phone = 'Please enter a valid number';
    }

    if (!values.phone) {
        errors.phone = 'Please enter your phone number';
    }

    return errors;
}

export const phoneVerificationValidator = (values) => {
    const errors = {}

    if(values.verificationCode.length<6){
        errors.verificationCode = 'Invalid verification code';
    }

    if (!values.verificationCode) {
        errors.verificationCode = 'Please enter the verification code sent to your phone';
    }

    return errors;
}

export const segmentFormValidator = (values) => {
    const errors = {};

    if(!values.name){
        errors.name = 'Please enter the Segment Name';
    }

    return errors;
}

export const funnelValidator = (values) => {
    const errors = {};
    if(!values.name){
        errors.name = 'Funnel Name is required';
    }
    return errors;
}