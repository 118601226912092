import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import api from "./../../../../api";
import { withRouter } from "react-router-dom";
import { Button } from "./../../../../components/share/InsightUI";
import Pagination from "../../../../components/app/Pagination";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { UserDataContext } from "./../../../../components/app/UserData";
import { SegmentsContext } from "./../../../../context/Segments";
import Table from "./../../../../components/share/Table";
import {
  captureExceptionToSentry,
  ellipsize,
  parseUrl,
} from "./../../../../utils";
import LoaderWithText from "./../../../../components/share/LoaderWithText";
import { fetchSizeBasedSegments } from "./../../../../lib/firebase/segment";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";
import Popup from "../../../../components/share/InsightUI/Popup";
import useClickOutside from "../../../../hooks/useClickOutside";
import {
  ERRORS_MAPPED,
  DEFAULT_ERROR,
} from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";

function Forms(props) {
  const { location } = props;
  const reportId = props.match.params.reportId;
  const profileId = props.match.params.profileId;
  const { authUser } = useContext(AuthContext);
  const {
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
  } = useContext(UserDataContext);
  const { selectedSegments, setSelectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [step, setStep] = useState(1);
  const [screenSegments, setScreenSegments] = useState([]);
  const [queryId, setQueryId] = useState(null);
  const [key, setKey] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isTotalLoaded, setIsTotalLoaded] = useState(false);
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [sortField, setSortField] = useState("pageviews");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [analysisOptions, setAnalysisOptions] = useState({
    url: "",
    segment: "",
    title: false,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoadingScreenSizes, setisLoadingScreenSizes] = useState(false);
  const [isScreenSizesLoaded, setIsScreenSizesLoaded] = useState(false);

  // Live clickmap pop up
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupIndex, setPopupIndex] = useState();
  const liveClickMapPopupRef = useRef();

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const handleOpenPopup = (e) => {
    const rowIndex = parseInt(e.currentTarget.dataset["index"]);

    setIsPopupVisible(true);
    setPopupIndex(rowIndex);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleClickHeaderSort = (e) => {
    let order = sortOrder;
    const field = e.target.dataset["sortField"];
    // Do not reverse order if field changes
    if (field !== sortField) {
      setSortField(field);
      return;
    }
    if (order === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    setSortField(field);
    setSortOrder(order);
  };

  const handleClickView = (e) => {
    const data = JSON.parse(e.currentTarget.dataset["payload"]);
    const [visitorId, sessionNumber] = data.exampleSessionId.split(":");
    props.history.push(
      `/profile/${profileId}/replay/session/${visitorId}/${sessionNumber}`,
      {
        replayOptions: {
          activeTab: "analytics",
          analyticsMode: "formAnalysis-lastClicks",
          url: data.url,
        },
      }
    );
  };

  const columns = useMemo(() => {
    let c = [
      {
        Header: "",
        accessor: "exampleSessionId",
        Cell: (param) => {
          const { row } = param;
          // Check if the pop-up should be visible for a specific row
          const isRowWithPopup = row.index === popupIndex;
          if (row.original.exampleSessionId) {
            return (
              <>
                <Button
                  size="small"
                  variant="primary"
                  onClick={handleClickView}
                  data-payload={JSON.stringify(row.original)}
                >
                  View Analytics
                </Button>
              </>
            );
          } else {
            return (
              <>
                {row.index !== 0 && (
                  <div className="popup-container">
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={handleOpenPopup}
                      data-payload={JSON.stringify(row.original)}
                      data-index={row.index}
                    >
                      View Analytics
                    </Button>
                    {isPopupVisible && isRowWithPopup && (
                      <Popup
                        popupRef={liveClickMapPopupRef}
                        handleClosePopup={handleClosePopup}
                        description={
                          "Click Map is available on the live website only, please click the link to open the page and open Insightech Chrome Extension to view the Click Map"
                        }
                        url={row.original.url}
                      />
                    )}
                  </div>
                )}
              </>
            );
          }
        },
      },
      {
        Header: "Page URL",
        accessor: "url",
        Cell: (param) => {
          const { value } = param;
          if (value) {
            const formattedValue = parseUrl(value);
            return (
              <span
                className="ml-2 url-span"
                data-tooltip-id="url-tooltip"
                data-tooltip-content={value}
              >
                {ellipsize(
                  formattedValue
                    ? `${formattedValue.pathname}${formattedValue.search}${formattedValue.hash}`
                    : value,
                  50
                )}
                <ReactTooltip id="url-tooltip" className="tooltip-lg" />
              </span>
            );
          } else {
            return (
              <span className="ml-2 url-span">
                <strong>(Grand Total)</strong>
              </span>
            );
          }
        },
      },
      {
        Header: (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="pageviews"
          >
            Page Views
            {sortField === "pageviews" ? (
              sortOrder === "asc" ? (
                <i className="fa fa-sort-up"></i>
              ) : (
                <i className="fa fa-sort-down"></i>
              )
            ) : (
              <i className="fa fa-sort"></i>
            )}
          </div>
        ),
        accessor: "pageviews",
      },
      {
        Header: (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="clickrate"
          >
            Form Bounce Rate
            {sortField === "clickrate" ? (
              sortOrder === "asc" ? (
                <i className="fa fa-sort-up"></i>
              ) : (
                <i className="fa fa-sort-down"></i>
              )
            ) : (
              <i className="fa fa-sort"></i>
            )}
          </div>
        ),
        accessor: "formBounceRate",
        Cell: ({ value }) => {
          return `${Math.round(value * 100)}%`;
        },
      },
      {
        Header: (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="scrollingdepthrate"
          >
            Median Interacted Fields
            {sortField === "scrollingdepthrate" ? (
              sortOrder === "asc" ? (
                <i className="fa fa-sort-up"></i>
              ) : (
                <i className="fa fa-sort-down"></i>
              )
            ) : (
              <i className="fa fa-sort"></i>
            )}
          </div>
        ),
        accessor: "medianClickFieldCount",
        Cell: ({ value }) => {
          return `${Math.round(value)}`;
        },
      },
      {
        Header: (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="scrollingdepthrate"
          >
            # of Fields
            {sortField === "scrollingdepthrate" ? (
              sortOrder === "asc" ? (
                <i className="fa fa-sort-up"></i>
              ) : (
                <i className="fa fa-sort-down"></i>
              )
            ) : (
              <i className="fa fa-sort"></i>
            )}
          </div>
        ),
        accessor: "maxClickFieldCount",
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        Header: (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="duration"
          >
            Median Duration
            {sortField === "duration" ? (
              sortOrder === "asc" ? (
                <i className="fa fa-sort-up"></i>
              ) : (
                <i className="fa fa-sort-down"></i>
              )
            ) : (
              <i className="fa fa-sort"></i>
            )}
          </div>
        ),
        accessor: "medianDuration",
        Cell: ({ value }) => {
          return moment
            .duration(value, "milliseconds")
            .format("HH:mm:ss", { trim: false });
        },
      },
    ];
    if (analysisOptions.title) {
      c.splice(2, 0, {
        Header: "Page Title",
        accessor: "title",
      });
    }
    return c;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder, analysisOptions, page, isPopupVisible]);

  const handleApiError = (error, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
    } else {
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readFormList = ({ queryId, key, page, pageSize }) => {
    api.cancel("readFormList");
    api
      .readFormList(authUser, {
        profileId,
        queryId,
        key,
        page,
        pageSize,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readFormList({ queryId, key, page, pageSize });
        }
        if (res.data.status === 200) {
          setIsLoadingData(false);
          setIsDataLoaded(true);
          setTotalRows(res.data.data.totalRows);
          setData(res.data.data.records || []);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const readFormTotal = ({ queryId, key }) => {
    api.cancel("readFormTotal");
    api
      .readFormTotal(authUser, {
        profileId,
        queryId,
        key,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readFormTotal({ queryId, key });
        }
        if (res.data.status === 200) {
          setIsLoadingTotal(false);
          setIsTotalLoaded(true);
          setTotal(res.data.data.records || []);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingTotal));
  };

  const fetchFormList = () => {
    setIsLoadingData(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryFormList");
    api.cancel("readFormList");
    api
      .queryFormList(authUser, {
        profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        filter: JSON.stringify(appliedFilters),
        pageURL: JSON.stringify({
          operator: "contains",
          values: [analysisOptions.url],
        }),
        title: analysisOptions.title,
        segments: JSON.stringify(segmentsArray),
        sort: `${sortField}-${sortOrder}`,
      })
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readFormList({ queryId, key, page, pageSize });
          setQueryId(queryId);
          setKey(key);
          setAlert({
            show: false,
            type: "",
            message: "",
            count: 0,
          });
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const fetchFormTotal = () => {
    setIsLoadingTotal(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryFormTotal");
    api.cancel("readFormTotal");
    api
      .queryFormTotal(authUser, {
        profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        filter: JSON.stringify(appliedFilters),
        pageURL: JSON.stringify({
          operator: "contains",
          values: [analysisOptions.url],
        }),
        segments: JSON.stringify(segmentsArray),
        sort: `${sortField}-${sortOrder}`,
      })
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readFormTotal({ queryId, key });
        } else {
          setIsLoadingTotal(false);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingTotal));
  };

  const handleClickNext = () => {
    props.history.push(window.location, { analysisOptions });
  };

  const handleCheckSegment = (e) => {
    setAnalysisOptions({
      ...analysisOptions,
      segment: e.target.value,
    });
  };

  const handleChangeUrl = (e) => {
    const value = e.target.value;
    setAnalysisOptions({
      ...analysisOptions,
      url: value,
    });
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setAnalysisOptions({
      ...analysisOptions,
      title: value,
    });
  };

  // Dismiss popup when click outside
  useClickOutside(liveClickMapPopupRef, handleClosePopup);

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      api.cancel("queryFormTotal");
      api.cancel("readFormTotal");
      api.cancel("queryFormList");
      api.cancel("readFormTotal");
    };
  }, []);

  // Pagination
  useEffect(() => {
    if (queryId && key) {
      setIsLoadingData(true);
      readFormList({ queryId, key, page, pageSize });
      // Grand Total is the same on all pages
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    if (
      !isApiServerLoading &&
      isInitialized &&
      profileId &&
      step === 2 &&
      appliedFilters &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      !isDependenciesLoaded
    ) {
      setIsDependenciesLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    profileId,
    step,
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    isDependenciesLoaded,
    isApiServerLoading,
  ]);

  useEffect(() => {
    if (profileId && !location.state && !isScreenSizesLoaded && step === 1) {
      setisLoadingScreenSizes(true);
      fetchSizeBasedSegments({ profileId }).then((res) => {
        const sortedSegments = res.sort((segment1, segment2) => {
          if (segment1.name < segment2.name) {
            return -1;
          }
          if (segment1.name > segment2.name) {
            return 1;
          }
          return 0;
        });
        setisLoadingScreenSizes(false);
        setIsScreenSizesLoaded(true);
        setScreenSegments(sortedSegments);
      });
    }

    if (isDependenciesLoaded && profileId && step === 2 && isInitialized) {
      fetchFormList();
      fetchFormTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    step,
    appliedFilters,
    sortOrder,
    sortField,
    analysisOptions,
    selectedSegments,
    startDate,
    endDate,
    isInitialized,
    isDependenciesLoaded,
  ]);

  useEffect(() => {
    if (!!reportId) {
      setStep(2);
      setIsInitialized(true);
    }
    if (
      location.state &&
      location.state.analysisOptions &&
      step === 1 &&
      !isInitialized
    ) {
      setAnalysisOptions(location.state.analysisOptions);
      if (location.state.analysisOptions.segment) {
        setSelectedSegments({
          ...selectedSegments,
          [location.state.analysisOptions.segment]: true,
        });
      }
      setStep(2);
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (step === 1) {
      document
        .getElementById("content-wrapper")
        .classList.add("page-analysis-step1");
      props.setHasTitle(false);
      props.setIsQuickFilterVisible(false);
      props.setIsQuickReportVisible(false);
    }
    if (step === 2) {
      props.setHasTitle(true);
      props.setIsQuickFilterVisible(true);
      props.setIsQuickReportVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      {step === 1 && !isLoadingScreenSizes && isScreenSizesLoaded && (
        <>
          <div className="row mb-5">
            <div className="col-12">
              <h1 className="h3 mb-4 mt-2 text-gray-800 text-center">
                Start analysing your forms
              </h1>
              <p className="text-gray-500 text-center">
                Start exploring a form page or skip to view all tracked pages
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-md-1">
              <div className="row mb-5">
                <div className="col-5">
                  Enter page URL (leave blank to view all pages):
                </div>
                <div className="col-7">
                  <input
                    onChange={handleChangeUrl}
                    type="text"
                    className="form-control form-control-sm"
                    value={analysisOptions.url}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-5">
                  Select device segment you want to view
                </div>
                <div className="col-7">
                  {screenSegments.map((segment, index) => {
                    return (
                      <label className="device-option" key={index}>
                        <input
                          onChange={handleCheckSegment}
                          checked={analysisOptions.segment === segment.id}
                          type="radio"
                          name="view"
                          value={segment.id}
                        />{" "}
                        {segment.name}
                      </label>
                    );
                  })}
                  <label className="device-option">
                    <input
                      onChange={handleCheckSegment}
                      checked={analysisOptions.segment === ""}
                      type="radio"
                      name="view"
                      value=""
                    />{" "}
                    All devices view
                  </label>
                </div>
              </div>
              <hr />
              <p className="text-right">
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleClickNext}
                >
                  Continue
                </Button>
              </p>
            </div>
          </div>
        </>
      )}

      {step === 1 && isLoadingScreenSizes && (
        <LoaderWithText
          text="Loading Screen Sizes"
          style={{ margin: "30px 0" }}
        />
      )}
      {step === 2 && (
        <Alert show={alert.show} type={alert.type} message={alert.message} />
      )}

      {step === 2 && (
        <div className="row">
          <div className="col-12 mb-3">
            <div className="card shadow">
              <div className="card-body">
                <div className="search-url form-inline">
                  <input
                    onChange={handleChangeUrl}
                    value={analysisOptions.url}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search for page URL"
                  />
                  &nbsp;
                  <select
                    className="form-control form-control-sm"
                    onChange={handleChangeTitle}
                  >
                    <option value={false}>Show page URL only</option>
                    <option value={true}>Show page URL and title</option>
                  </select>
                </div>
                <Table
                  hScroll
                  data={total.concat(data)}
                  columns={columns}
                  isLoading={isLoadingData || isLoadingTotal}
                  isDataLoaded={isDataLoaded && isTotalLoaded}
                  loadingText={
                    <>
                      <i className="fa fa-spinner spin"></i> Loading Forms...
                    </>
                  }
                />
                {!isLoadingData && (
                  <Pagination
                    activePage={page + 1}
                    totalRows={totalRows}
                    pageSize={pageSize}
                    changeHandler={(pageNum, pageSize) => {
                      setPage(pageNum - 1);
                      setPageSize(pageSize);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Forms.propTypes = {};
Forms.defaultProps = {};

export default withRouter(Forms);
