import React, { Fragment, useContext, useEffect, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { AiTwotoneNotification } from "react-icons/ai";
import { FaCircle } from "react-icons/fa";
import { UserDataContext } from "../../app/UserData";
import Button from "../../share/InsightUI/Button";
import DeleteModal from "../InsightUI/DeleteModal";
import uiApi from "../../../api/api-ui";
import "./style.scss";

const InviteCard = ({ invites }) => {
  const { loadInvitesThenSetAlert, loadAccountsThenSetAccess } =
    useContext(UserDataContext);
  const [isModalHidden, setIsModalHidden] = useState(true);
  const [rejectKey, setRejectKey] = useState(null);
  const [invitesCount, setInvitesCount] = useState(null);
  const [isLoading, setIsLoading] = useState({ btnId: false });
  const [isBusy, setIsBusy] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [allCompleted, setAllCompleted] = useState(false);

  const computeHoursAgo = (timestamp) => {
    const oneHour = 60 * 60 * 1000; // minutes*seconds*milliseconds
    const today = new Date();
    const inviteDate = new Date(timestamp);
    const diffHours = Math.round(Math.abs((today - inviteDate) / oneHour));
    return diffHours;
  };

  const computeInvitesAmount = (invites) => {
    let cnt = 0;
    for (let i = 0; i < invites.length; i++) {
      if (invites[i].inviteStatus !== "completed") {
        cnt += 1;
      }
    }
    setInvitesCount(cnt);
  };

  const checkCompletedStatus = (invites) => {
    const res = invites.findIndex((inv) => {
      return (inv.inviteStatus === "valid") | (inv.inviteStatus === "expired");
    });
    res === -1 ? setAllCompleted(true) : setAllCompleted(false);
  };

  const handleAccept = (e, key) => {
    let btnId = e.target.id;
    setIsLoading({
      [btnId]: true,
    });
    setIsBusy(true);
    uiApi.acceptInvite(key.role, key.profile, key.inviteId).then((data) => {
      if (data && data?.data?.success) {
        loadInvitesThenSetAlert();
        loadAccountsThenSetAccess();
        setIsBusy(false);
        setIsLoading({ btnId: false });
      }
    });
  };

  const handleReject = (e, key) => {
    setIsModalHidden(false);
    setRejectKey(key);
  };

  // handler to delete expired invite directly
  const handleRemoveExpire = (e, key) => {
    let btnId = e.target.id;
    setIsLoading({
      [btnId]: true,
    });
    setIsBusy(true);
    uiApi.rejectInvite(key.inviteId).then(({ data }) => {
      if (data?.success) {
        loadInvitesThenSetAlert();
        setIsBusy(false);
        setIsLoading({ btnId: false });
      }
    });
  };

  useEffect(() => {
    computeInvitesAmount(invites.invitesData);
    checkCompletedStatus(invites.invitesData);
  }, [invites.invitesData]);

  // confirm delete in deleteModal
  const handleDeleteInvite = () => {
    setIsDeleting(true);

    uiApi.rejectInvite(rejectKey.inviteId).then(({ data }) => {
      if (data?.success) {
        setIsModalHidden(true);
        setIsDeleting(false);
        loadInvitesThenSetAlert();
      }
    });
  };

  if (!invites.loadingInvites && allCompleted) return null;

  return (
    <Fragment>
      {!isModalHidden && (
        <DeleteModal
          isModalHidden={isModalHidden}
          setIsModalHidden={setIsModalHidden}
          bodyText="Are you sure you want to decline this invitation?"
          btnLabel="decline invitation"
          handleDelete={handleDeleteInvite}
          isDeleting={isDeleting}
        />
      )}

      {invites.loadingInvites === false && invites.invitesData.length !== 0 && (
        <div className="invite-wrapper">
          <div className="inv-header">
            <div className="header-icon">
              <IoMdNotifications size={30} color="rgb(89 127 237)" />
              {invitesCount > 0 && (
                <div className="header-counter">
                  <span className="counter-value">{invitesCount}</span>
                </div>
              )}
            </div>

            <span className="header-text">Invites</span>
          </div>
          <div className="inv-line"></div>
          <div className="inv-body">
            {invites.invitesData.map((key, idx) => {
              return (
                <Fragment key={idx}>
                  {key.inviteStatus !== "completed" && (
                    <div className={`inv-item ${key.inviteStatus}`} key={idx}>
                      <div className="item-header">
                        <div className={`inv-status ${key.inviteStatus}`}>
                          <div className="status-icon">
                            {key.inviteStatus === "valid" ? (
                              <FaCircle color="#5CB85C" size={7} />
                            ) : (
                              <FaCircle color="#FD1010" size={7} />
                            )}
                          </div>
                          <span className="status-text">
                            {key.inviteStatus}
                          </span>
                        </div>
                        <span>{computeHoursAgo(key.inviteTime)} hours ago</span>
                      </div>

                      {key.inviteStatus === "valid" ? (
                        <div className="item-line"></div>
                      ) : (
                        <div className="item-line expired"></div>
                      )}

                      <div className="item-body">
                        <div className="body-left">
                          <div className="inv-icon">
                            {key.inviteStatus === "valid" ? (
                              <AiTwotoneNotification
                                color="#FFC700"
                                size={28}
                              />
                            ) : (
                              <AiTwotoneNotification
                                color="#B4B4B4"
                                size={28}
                              />
                            )}
                          </div>
                          <div className={`body-main ${key.inviteStatus}`}>
                            <div className="main-title">Hi {key.userName},</div>
                            <div className="main-paragrah">
                              <b>{key.inviter}</b> invites you to join profile{" "}
                              <b>{key.profileName}</b>({key.profile}) as{" "}
                              {key.role === "admin" ? "an" : "a"}{" "}
                              <b>{key.role}</b>.
                            </div>
                          </div>
                        </div>
                        <div className="body-right">
                          {key.inviteStatus === "valid" && (
                            <div className="accept">
                              <Button
                                disabled={isBusy}
                                variant="primary"
                                size="small"
                                id={idx}
                                onClick={(e) => handleAccept(e, key)}
                              >
                                {isLoading[idx] ? (
                                  <i className="fa fa-spinner fa-spin" />
                                ) : (
                                  <span id={idx}>Accept</span>
                                )}
                              </Button>
                            </div>
                          )}
                          <div className="reject">
                            <Button
                              disabled={isBusy}
                              variant="outline-secondary"
                              size="small"
                              onClick={(e) =>
                                key.inviteStatus === "valid"
                                  ? handleReject(e, key)
                                  : handleRemoveExpire(e, key)
                              }
                            >
                              Dismiss
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
      {invites.loadingInvites === true && (
        <div className="col-12 mb-4">
          <span>
            <i className="fa fa-spinner fa-spin" />
            Loading invites...
          </span>
        </div>
      )}
    </Fragment>
  );
};

export default InviteCard;
