import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Form = React.forwardRef((props, ref)=>{
    const {handleSubmit, isSubmitting} = props;
    const classNames = props.className ? props.className : '';

    return (
        <form
            ref={ref}
            className={`ui-form ${classNames}`}
            onSubmit={handleSubmit}
        >
            {isSubmitting &&
                <div className="form-overlay">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {props.children}
        </form>
    )
})

Form.propTypes = {
    handleSubmit: PropTypes.func
}

Form.defaultProps = {
}

export default Form