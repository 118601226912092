import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function Slider(props){
  const {
    className,
    min,
    max,
    value,
    handleChange
  } = props;
  const progressWidth = `${(Number(value) / (Number(max) - Number(min))) * 100}%`;
  return (
    <div className={`insight-ui-slider ${className}`}>
      <div className="track-wrapper">
        <div className="track"></div>
        <div className="track-progress" style={{width: progressWidth}}></div>
      </div>
      <input type="range" min={min} max={max} value={value} onChange={handleChange} />
    </div>
  );
}

Slider.propTypes = {
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
Slider.defaultProps = {}

export default Slider;