import React from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import EventCondition from "./EventCondition";

function PageContainer(props) {
  const {
    invalidFields,
    handleDelete,
    containerIndex,
    inclusion,
    containers,
    data,
    editFieldOnly,
    filters,

    handleUpdateCondition,
    handleUpdateSubCondition,
    handleClickAddCondition,
    handleClickDeleteConditionRow,
    handleClickAddSubConditionRow,
    handleClickDeleteSubConditionRow,
    handleSaveConversionPoint,

    enableRevenue,
    onEnableRevenue,
    revenueJsonPath,
    onRevenueJsonPath,
    isConversion,
    enabled,
    onEnabled,
    isFormProcessing,
  } = props;

  return (
    <div className="page-container bg-gray-200">
      <h6 className="container-header d-flex bg-gray-300">
        <span className="mt-1 mr-auto">
          Visited a page with the following events:
        </span>
        {isConversion && data.eventConditions.length === 0 && (
          <Button
            title="Add event"
            data-inclusion={inclusion}
            data-containerindex={containerIndex}
            variant="secondary"
            size="x-small"
            onClick={handleClickAddCondition}
            icon="fas fa-plus"
          >
            Add Event
          </Button>
        )}
        {!isConversion && (
          <Button
            title="Add event"
            data-inclusion={inclusion}
            data-containerindex={containerIndex}
            variant="secondary"
            size="x-small"
            onClick={handleClickAddCondition}
            icon="fas fa-plus"
          >
            Add Event
          </Button>
        )}
        {containers.length > 1 && (
          <Button
            title="Delete container"
            variant="plain"
            data-inclusion={inclusion}
            data-containerindex={containerIndex}
            size="small"
            onClick={handleDelete}
            icon="fas fa-times-circle"
          ></Button>
        )}
      </h6>
      <div className="container-body">
        {data.eventConditions &&
          data.eventConditions.length &&
          data.eventConditions.map((event, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <div className="filter-separator">
                    <span className="text-gray-500">AND</span>
                  </div>
                )}
                <EventCondition
                  invalidFields={invalidFields}
                  editFieldOnly={editFieldOnly}
                  containerIndex={containerIndex}
                  eventIndex={index}
                  data={event}
                  filters={filters}
                  conditionType={inclusion ? "event" : "excludedEvent"}
                  initial={index === 0}
                  showDelete={true}
                  handleUpdateCondition={handleUpdateCondition}
                  handleUpdateSubCondition={handleUpdateSubCondition}
                  handleClickDeleteConditionRow={handleClickDeleteConditionRow}
                  handleClickAddSubConditionRow={handleClickAddSubConditionRow}
                  handleClickDeleteSubConditionRow={
                    handleClickDeleteSubConditionRow
                  }
                  handleSaveConversionPoint={handleSaveConversionPoint}
                  enableRevenue={enableRevenue}
                  onEnableRevenue={onEnableRevenue}
                  revenueJsonPath={revenueJsonPath}
                  enabled={enabled}
                  onEnabled={onEnabled}
                  onRevenueJsonPath={onRevenueJsonPath}
                  isConversion={isConversion}
                  isFormProcessing={isFormProcessing}
                />
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}

PageContainer.propTypes = {
  handleDelete: PropTypes.func,
  containers: PropTypes.array,
  data: PropTypes.object,
  inclusion: PropTypes.bool,
  index: PropTypes.number,
  editFieldOnly: PropTypes.bool,
  filters: PropTypes.object,
};
PageContainer.defaultProps = {
  handleDelete: () => {},
  container: [],
  data: {},
};

export default PageContainer;
