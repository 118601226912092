import React from "react";
import { Link } from "react-router-dom";

const SubmitButtons = ({id='', processing=false, disabled=false, buttonStyle="primary", submitButtonText='Submit', handleClick, backButtonText='Back', backButtonTo='', backButtonClick=null}) => {

    return (
        <div className="form-group row">
            <div className="col-sm-2 col-form-label"></div>
            <div className="col-sm-10">
                <button type="submit" id={id} className={"btn btn-sm btn-"+buttonStyle} disabled={processing||disabled} onClick={(e) => {
                                if(typeof(handleClick) === 'function'){
                                    handleClick(e);
                                }
                }}>{processing?(
                    <i className="fa fa-spinner fa-spin"></i>
                        ):(
                    <></>
                )} {submitButtonText}</button>
                {backButtonTo !== '' &&
                    <>&nbsp;<Link className="btn btn-secondary btn-sm" to={backButtonTo}>{backButtonText}</Link></>
                }
                {backButtonClick !== null &&
                    <>&nbsp;<button className="btn btn-secondary  btn-sm" onClick={() => backButtonClick()}>{backButtonText}</button></>
                }
            </div>
        </div>
    )
}

export default SubmitButtons