import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "./../Button";

function Dropdown(props) {
  const {
    align,
    className,
    toggleButton,
    id,
    items,
    defaultSelected,
    toggleOptions,
    handleChange,
    description,
    selectedItem,
  } = props;

  const [selected, setSelected] = useState({
    label: "Dropdown",
    value: "",
  });

  let alignClass = "";

  switch (align) {
    case "right":
      alignClass = "dropdown-menu-right";
      break;
    default:
      break;
  }

  const handleClickItem = (e) => {
    const target = e.target;
    const value = target.dataset["value"];
    const label = target.innerText;
    setSelected({
      value,
      label,
    });
    handleChange({
      value,
      label,
    });
  };

  // Keep single source of truth of the selected item
  useEffect(() => {
    if (selectedItem) {
      setSelected({ label: selectedItem.label, value: selectedItem.value });
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!defaultSelected && items && items.length) {
      setSelected({
        label: items[0].label,
        value: items[0].value,
      });
    }
    if (defaultSelected && items && items.length) {
      let defaultItem;
      items.forEach((item) => {
        if (item.value === defaultSelected) {
          defaultItem = item;
        }
      });
      setSelected({
        label: defaultItem?.label,
        value: defaultItem?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`dropdown ${className}`}>
      {toggleButton}
      {!toggleButton && (
        <Button
          className="dropdown-toggle"
          id={id}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          {...toggleOptions}
        >
          {selected.label}
          {description ? " " + description : ""}
        </Button>
      )}
      <div
        className={`dropdown-menu shadow ${alignClass}`}
        aria-labelledby={id}
      >
        {!items && props.children}
        {!!items &&
          items.map((item, index) => {
            return (
              <div
                key={index}
                onClick={handleClickItem}
                data-value={item.value}
                className="dropdown-item"
              >
                {item.label}
              </div>
            );
          })}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  toggleButton: PropTypes.node,
  items: PropTypes.array,
  handleChange: PropTypes.func,
  toggleOptions: PropTypes.object,
};
Dropdown.defaultProps = {
  handleChange: () => {},
  toggleOptions: {},
};

export default Dropdown;
