import React from "react";
import "./style.scss";

const ToggleButton = (props) => {
  const { variant, opt1Label, opt2Label, isActive, onChange } = props;

  return (
    <div
      className={`toggle-button ${variant} ${isActive ? "active" : ""}`}
      onClick={onChange}
    >
      <div className={`button-option ${!isActive ? "active" : ""}`}>
        {opt1Label}
      </div>
      <div className={`button-option ${isActive ? "active" : ""}`}>
        {opt2Label}
      </div>
    </div>
  );
};

export default ToggleButton;
