import React, { useContext, useState } from "react";
import Alert from './../../../../components/share/Alert';
import { Link, Redirect } from "react-router-dom";
import { UserDataContext } from "./../../../../components/app/UserData";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import TimezoneField from "./../../../../components/share/fields/TimezoneField";
import TextField from "./../../../../components/share/fields/TextField";
import TextareaField from "./../../../../components/share/fields/TextareaField";
import SubmitButtons from "./../../../../components/share/fields/SubmitButtons";
import api from './../../../../api';

const CreateProfile = (param) => {
  const { authUser } = useContext(AuthContext);
  const accountId = param.match.params.accountId;
  const { access, loadAccountsThenSetAccess } = useContext(UserDataContext);

  const [timezone, setTimezone] = useState('');
  const [profileName, setProfileName] = useState('');
  const [domains, setDomains] = useState('');
  const [processing, setProcessing] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const [alert, setAlert] = useState({
    'show': false,
    'type': '',
    'message': '',
    'count': 0
  });

  let accountName = '';
  if (access.loadingAccess === false && access.accounts !== null) {
    for (var i = 0; i < access.accounts.length; i++) {
      if (accountId === access.accounts[i].id && access.accounts[i].isAdmin) {
        accountName = access.accounts[i].name;
      }
    }
  }

  return (
    <>
      {redirect !== null ? (
        <Redirect to={redirect}></Redirect>
      ) : (
          <>
            {access.loadingAccess ? (
              <div className="col-12 mb-4">
                <span>
                  <i className="fa fa-spinner fa-spin" />
                                Loading accounts...
                            </span>
              </div>
            ) : (
                <>
                  {accountName !== '' ? (
                    <>
                      <div className="row">
                        <Alert show={alert.show} type={alert.type} message={alert.message} count={alert.count} />
                      </div>
                      <div className="row">
                        <div className="col mb-4">
                          <div className="card shadow mb-4">
                            <div className="card-body">
                              <TextField id="account-name" label="Account Name" readOnly={true} text={accountName} plainText={true}></TextField>
                              <TextField handleChange={(e) => {
                                setProfileName(e.target.value);
                              }} id="profile-name" label="Profile Name" placeholder="e.g. Production websites" text={profileName} helpText="Profile name is to identify the websites tracked in the profile."></TextField>
                              <TimezoneField setTimezone={setTimezone} defaultTimezone={timezone} helpText="Set your data processing timezone. The setting cannot be changed after it's set."></TimezoneField>
                              <TextareaField text={domains} placeholder="e.g. yourcompany.com" label="Domains" id="domains" helpText="List all the top domains of your website journey, including your payment processors (e.g. paypal.com). One line per domain." handleChange={(e) => {
                                setDomains(e.target.value);
                              }}></TextareaField>
                              <SubmitButtons processing={processing} backButtonTo="/" handleClick={(e) => {
                                e.preventDefault();
                                setProcessing(true);
                                api.createProfile(authUser, {
                                  accountId: accountId,
                                  profileName: profileName,
                                  timezone: timezone,
                                  domains: domains.split("\n").join(",")
                                }).then(res => {
                                  if (res.status === 200) {
                                    if (res.data.status === 200) {
                                      // update the access data
                                      loadAccountsThenSetAccess();
                                      // redirect to next step
                                      setRedirect("/profile/" + res.data.data.profileId + "/admin/code");
                                    } else if (res.data.status === 500) {
                                      // display generic error
                                      setProcessing(false);
                                      setAlert({
                                        'show': true,
                                        'type': 'danger',
                                        'message': 'Oops, something went wrong. Please try again later.',
                                        'count': alert.count + 1
                                      });
                                    } else {
                                      setProcessing(false);
                                      setAlert({
                                        'show': true,
                                        'type': 'danger',
                                        'message': res.data.result,
                                        'count': alert.count + 1
                                      });
                                    }
                                  }
                                }).catch(res => {
                                  // handle error
                                  setProcessing(false);
                                  setAlert({
                                    'show': true,
                                    'type': 'danger',
                                    'message': 'Oops, something went wrong. Please try again later.',
                                    'count': alert.count + 1
                                  });
                                });
                              }}></SubmitButtons>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                      <>
                        <div className="row">
                          <Alert show={true} type={"danger"} message={"Access denied."} count={0} allowClose={false} />
                        </div>
                        <Link className="btn btn-secondary btn-sm" to="/">Back</Link>
                      </>
                    )}
                </>
              )}
          </>
        )}

    </>

  )
}

export default CreateProfile;