import React from "react";
import Modal from "./../Modal";
import Button from "./../Button";
import { ReducerContext } from "./../../../../reducer/context";
import { CLOSE_CONFIRM } from "./../../../../reducer/index";

function Confirm(props) {
  const { state, dispatch } = React.useContext(ReducerContext);
  const { title, text, confirmText, confirmAction } = props;

  const handleClose = () => {
    dispatch({ type: CLOSE_CONFIRM });
  };

  return (
    <Modal
      isVisible={true}
      title={title}
      handleClose={handleClose}
      footer={
        <>
          <Button
            disabled={state.isConfirmBusy}
            onClick={handleClose}
            variant="secondary"
            size="small"
            type="button"
          >
            Cancel
          </Button>
          <Button
            disabled={state.isConfirmBusy}
            onClick={confirmAction}
            variant="primary"
            size="small"
            type="button"
          >
            {state.isConfirmBusy && (
              <>
                <span className="fa fa-spinner spin"></span>
                {` `}
              </>
            )}
            {confirmText || "Confirm"}
          </Button>
        </>
      }
    >
      {text}
    </Modal>
  );
}

Confirm.propTypes = {};
Confirm.defaultProps = {};

export default Confirm;

export const confirm = () => {};
