import React from "react";
import CollapsableCard from "./../../../../components/app/CollapsableCard";
import { Button } from "./../../../../components/share/InsightUI";
import Pagination from "../../../../components/app/Pagination";
import { UserDataContext } from "./../../../../components/app/UserData";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { fetchNotes } from "./../../../../lib/firebase/notes";
import { fetchUsersByProfileId } from "./../../../../lib/firebase/user";
import NoteCard from "./../../../../components/share/NoteCard";
import { ADD_TOAST } from "./../../../../reducer/index";
import { ReducerContext } from "./../../../../reducer/context";

import "./style.scss";

function Notes(props) {
  const { dispatch } = React.useContext(ReducerContext);
  const { profileUsers, setProfileUsers } = React.useContext(UserDataContext);
  const { authUser } = React.useContext(AuthContext);
  const _isMounted = React.useRef(true);

  const profileId = props.match.params.profileId;
  const [data, setData] = React.useState({
    byId: {},
    all: [],
  });
  const [filteredData, setFilteredData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({
    keyword: undefined,
    owner: undefined,
  });
  const [appliedFilters, setAppliedFilters] = React.useState({
    keyword: undefined,
    owner: undefined,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  React.useEffect(() => {
    setIsLoading(true);
    const asyncCalls = [];
    let notesPromiseIndex = 0;

    // Users Promise
    if (!profileUsers.all.length) {
      asyncCalls.push(fetchUsersByProfileId(profileId));
      notesPromiseIndex = 1;
    }

    // Segments Promise
    asyncCalls.push(fetchNotes({ profileId }));

    Promise.all(asyncCalls).then((res) => {
      if (!_isMounted.current) {
        return;
      }
      const users = {
        byId: {},
        all: [],
      };

      // Resolve Profile Users
      if (!profileUsers.all.length) {
        res[0].docs.forEach((doc) => {
          users.byId[doc.id] = doc.data();
          users.all.push(doc.id);
        });
        setProfileUsers(users);
      }

      // Resolve Segments
      const result = {
        byId: {},
        all: [],
      };
      Object.keys(res[notesPromiseIndex]).forEach((id) => {
        const data = res[notesPromiseIndex][id];
        const usersData = profileUsers.all.length
          ? profileUsers.byId
          : users.byId;

        data.id = id;
        data.creator = usersData[data.ownerId];
        result.byId[id] = data;
        result.all.push(id);
      });

      setData(result);
      setIsLoading(false);
    });

    return () => {
      _isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!_isMounted.current) {
      return;
    }
    if (data.all.length) {
      let filtered = data.all.map((id) => {
        return data.byId[id];
      });
      if (appliedFilters.keyword) {
        filtered = filtered.filter((note) => {
          if (
            note.tags
              .join(" ")
              .toLowerCase()
              .split(" ")
              .indexOf(appliedFilters.keyword.toLowerCase()) > -1
          ) {
            return true;
          }
          return (
            note.description
              .toLowerCase()
              .indexOf(appliedFilters.keyword.toLowerCase()) > -1
          );
        });
      }
      if (appliedFilters.owner) {
        filtered = filtered.filter((note) => {
          return note.ownerId === appliedFilters.owner;
        });
      }
      setFilteredData(filtered);
    }
  }, [data, appliedFilters, page, pageSize]);

  const handleApplyFilters = () => {
    const updatedFilters = { ...filters };
    setAppliedFilters(updatedFilters);
  };

  const handleChangeKeyword = (e) => {
    const state = { ...filters };
    state["keyword"] = e.target.value;
    setFilters(state);
  };
  const handleChangeOwner = (e) => {
    const state = { ...filters };
    state["owner"] = e.target.value;
    setFilters(state);
  };

  const handleClickViewReplay = (e) => {
    const noteid = e.currentTarget.dataset["noteid"];
    const note = data.byId[noteid];
    if (note) {
      const sessionData = note.sessionKey.split(".");
      props.history.push(
        `/profile/${profileId}/replay/session/${sessionData[0]}.${sessionData[1]}/${sessionData[2]}`,
        {
          noteData: {
            id: note.id,
            time: note.noteTime,
          },
          replayOptions: {
            activeTab: "notes",
          },
        }
      );
    }
  };

  const noteComponents = [];
  if (filteredData.length) {
    for (let i = 0, len = pageSize; i < len; i++) {
      const offset = page * pageSize + i;
      const note = filteredData.length > offset ? filteredData[offset] : null;
      if (!note) {
        break;
      }
      const key = note.id;
      const noteUrl = `${window.location.protocol}//${window.location.host}/note/${note.profileId}/${note.id}`;
      noteComponents.push(
        <div className="note-cell" key={note.id}>
          <NoteCard
            key={key}
            id={key}
            index={i}
            profileId={profileId}
            content={data.byId[key].description}
            noteUrl={noteUrl}
            noteTime={data.byId[key].noteTime}
            creationTime={data.byId[key].creationTime}
            tags={data.byId[key].tags}
            analyticsMode={data.byId[key]?.analyticsMode || "moment"}
            startDate={data.byId[key]?.startDate}
            endDate={data.byId[key]?.endDate}
            filter={data.byId[key]?.filter}
            segments={data.byId[key]?.segments}
            shared={data.byId[key]?.shared}
            version={data.byId[key]?.version || "0.0"}
            profileUsers={profileUsers}
            photoUrl={
              profileUsers.byId[data.byId[key].ownerId]
                ? profileUsers.byId[data.byId[key].ownerId].photoURL
                : null
            }
            authorName={
              profileUsers.byId[data.byId[key].ownerId]
                ? profileUsers.byId[data.byId[key].ownerId].name
                : ""
            }
            handleClickEditNote={() => {}}
            handleClickDeleteNote={() => {}}
            handleClickViewReplay={handleClickViewReplay}
            handleClickCopy={() => {
              dispatch({
                type: ADD_TOAST,
                messageType: "success",
                messageText: `Note url copied to clipboard`,
              });
            }}
            showOwnerControls={false}
            isActive={
              props.location.state &&
              props.location.state.noteData &&
              props.location.state.noteData.id &&
              props.location.state.noteData.id === key
            }
          />
        </div>
      );
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 small">
          <CollapsableCard title="Filter by">
            <div className="row">
              <div className="col-md-12">
                <div className="form-inline">
                  <div className="form-group">
                    <input
                      placeholder="Keyword search"
                      className="form-control form-control-sm"
                      type="search"
                      onChange={handleChangeKeyword}
                    />
                  </div>
                  <div className="form-group ml-4">
                    <label>Creator</label>
                    <select
                      className="form-control form-control-sm mx-2"
                      onChange={handleChangeOwner}
                    >
                      <option value="">All</option>
                      <option value={authUser.user.uid}>Me</option>
                      {profileUsers.all.length &&
                        profileUsers.all.map((uid) => {
                          if (uid === authUser.user.uid) {
                            return null;
                          }
                          const user = profileUsers.byId[uid];
                          return (
                            <option value={uid} key={uid}>
                              {user.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <hr />
                <Button
                  style={{ width: 120 }}
                  className="apply-button"
                  variant="primary"
                  size="small"
                  onClick={handleApplyFilters}
                >
                  Apply
                </Button>
              </div>
            </div>
          </CollapsableCard>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card shadow">
            <div className="card-body">
              <div className="note-grid">
                {!!isLoading && (
                  <p className="text-center my-5" style={{ width: "100%" }}>
                    Loading Notes
                  </p>
                )}
                {!noteComponents.length && !isLoading && (
                  <p className="text-center my-5" style={{ width: "100%" }}>
                    No notes to display
                  </p>
                )}
                {noteComponents}
              </div>

              <Pagination
                activePage={page + 1}
                totalRows={filteredData.length}
                pageSize={pageSize}
                changeHandler={(pageNum, pageSize) => {
                  setPage(pageNum - 1);
                  setPageSize(pageSize);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Notes.propTypes = {};
Notes.defaultProps = {};

export default Notes;
