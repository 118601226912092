export const DUMMY_TITLE_LITE_ON = "Page not included in your subscription";
export const DUMMY_PARAGRAPH_LITE_ON = `It looks like this page wasn’t tracked as your Insightech subscription doesn’t include tracking for all page views.<br>Please speak to the Insightech team if you would like to upgrade your plan 👑 <br> <a href="https://www.insightech.com/contact-us" target="_blank">contact@insightech.com</a>`;

export const DUMMY_TITLE_LITE_OFF = "This user was too fast for us";
export const DUMMY_PARAGRAPH_LITE_OFF =
  "Uh oh! It looks like this user likely navigated away from this page before we had a chance to track this page.";

export const DUMMY_TITLE_LITE_OFF_INTERACTIONS = "Something went wrong";
export const DUMMY_PARAGRAPH_LITE_OFF_INTERACTIONS =
  "Uh oh! Unfortunately, this page view is not recorded.";

export const DUMMY_FOOTER = "Will go to next page in 3 seconds";
