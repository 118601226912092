import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const RedirectOldUrl = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    const oldUrlPatterns = [
      {
        pattern: /^\/profile\/(\w+)\/analytics\/dashboard\/reports\/(\w+)$/,
        suffix: "dashboard",
      },
      {
        pattern: /^\/profile\/(\w+)\/replay\/list\/reports\/(\w+)$/,
        suffix: "replay_list",
      },
      {
        pattern: /^\/profile\/(\w+)\/pathing\/sunburst\/reports\/(\w+)$/,
        suffix: "pathing",
      },
      {
        pattern: /^\/profile\/(\w+)\/funnels\/list\/reports\/(\w+)$/,
        suffix: "funnel_list",
      },
      {
        pattern: /^\/profile\/(\w+)\/funnels\/view\/(\w+)\/reports\/(\w+)$/,
        funnel: true,
      },
      {
        pattern: /^\/profile\/(\w+)\/pages\/list\/reports\/(\w+)$/,
        suffix: "page_list",
      },
      {
        pattern: /^\/profile\/(\w+)\/forms\/list\/reports\/(\w+)$/,
        suffix: "form_list",
      },
    ];

    for (const { pattern, suffix, funnel } of oldUrlPatterns) {
      const match = pathname.match(pattern);

      if (match) {
        const profileId = match[1];
        let reportId = match[match.length - 1];

        if (funnel) {
          const funnelId = match[2];
          reportId = `${reportId}_${funnelId}`;
        } else if (suffix) {
          reportId = `${reportId}_${suffix}`;
        }

        const newPath = `/profile/${profileId}/reports/${reportId}`;
        history.replace(newPath);
        break;
      }
    }
  }, [location, history]);

  return null;
};

export default RedirectOldUrl;
