import React from "react";

const ListHeader = (props) => {
  const { columns } = props;
  return (
    <thead>
      <tr>
        {!!columns.length &&
          columns.map((column, index) => <th key={index}>{column.label}</th>)}
      </tr>
    </thead>
  );
};

export default ListHeader;
