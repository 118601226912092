import React from "react";
import FunnelFoldersCreator from "./FunnelFoldersCreator";
import FunnelFoldersTable from "./FunnelFoldersTable";

const FunnelFoldersDropdownMenu = ({
  isFoldersDropdownVisible,
  funnelFolders,
  selectedFolder,
  funnelFoldersMap,
  handleCheckboxChange,
  handleClickFolderCreate,
  handleCreateFolderInput,
  isFolderValid,
  createFolderInput,
  isCreatorEmptyInvalid,
}) => {
  return (
    <div
      className={`dropdown-menu dropdown-menu-md-left shadow folders-dropdown
           animated--grow-in ${isFoldersDropdownVisible ? "show" : ""}`}
    >
      <div className="folders-description">Select a folder or create one</div>
      <FunnelFoldersTable
        funnelFolders={funnelFolders}
        funnelFoldersMap={funnelFoldersMap}
        selectedFolder={selectedFolder}
        handleCheckboxChange={handleCheckboxChange}
      />
      <div className="d-flex folders-dropdown-footer"></div>
      <FunnelFoldersCreator
        isFolderValid={isFolderValid}
        handleClickFolderCreate={handleClickFolderCreate}
        handleCreateFolderInput={handleCreateFolderInput}
        createFolderInput={createFolderInput}
        isCreatorEmptyInvalid={isCreatorEmptyInvalid}
      />
    </div>
  );
};

export default FunnelFoldersDropdownMenu;
