import React from "react";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import PropTypes from "prop-types";
import EmptyReportChart from "../../../pages/app/profiles/Reports/components/EmptyReportChart";

const ReportsDropdownTable = (props) => {
  const { columns, reports, searchFilter, onReport } = props;

  return (
    <div className="reports-table-wrapper">
      {reports.length > 0 ? (
        <table className="table reports-table">
          <TableHeader columns={columns} />
          <TableBody
            reports={reports}
            searchFilter={searchFilter}
            onReport={onReport}
          />
        </table>
      ) : (
        <EmptyReportChart />
      )}
    </div>
  );
};

ReportsDropdownTable.propTypes = {
  isDark: PropTypes.bool,
  columns: PropTypes.array,
  reports: PropTypes.array,
  searchFilter: PropTypes.func,
  onReport: PropTypes.func,
  title: PropTypes.string,
  funnelId: PropTypes.string,
};

export default ReportsDropdownTable;
