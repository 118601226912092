import React, { Fragment, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SegmentsContext } from "../../../../../context/Segments";
import { reportTypes } from "../../../../../helpers/reportTypes";
import { UserDataContext } from "../../../../../components/app/UserData";
import { DeleteModal } from "../../../../../components/share/InsightUI";
import { AuthContext } from "../../../../../components/auth/FirebaseAuthContext";
import DataTitle from "../components/DataTitle";
import DataTags from "../components/DataTags";
import { HiOutlineExternalLink } from "react-icons/hi";
import useReports from "../../../../../components/app/QuickReport/hooks/useReports";

const ListBody = (props) => {
  const {
    reportListData,
    onReport,
    handleDeleteButton,
    handleConfirmReportDelete,
    isDeleteModalHidden,
    setIsDeleteModalHidden,
    isDeleting,
  } = props;
  const { handleComparisonMode } = useReports();
  const { segments } = useContext(SegmentsContext);
  const {
    profileUsers,
    setAction,
    setIsSaveModalVisible,
    setSelectedReport,
    setIsReportSelected,
  } = useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);
  const history = useHistory();

  const handleReportEdit = (report) => {
    setAction("update");
    setSelectedReport({ ...report });

    if (report.reportType !== "external") {
      setIsReportSelected(true);
      history.push(`/profile/${report.profileId}/reports/${report.Id}`);

      // Set comparison mode ON or OFF based on the report
      handleComparisonMode(report);
    }

    setIsSaveModalVisible(true);
  };

  return (
    <Fragment>
      <DeleteModal
        isModalHidden={isDeleteModalHidden}
        setIsModalHidden={setIsDeleteModalHidden}
        isDeleting={isDeleting}
        bodyText="Are you sure you want to delete this report?"
        btnLabel="Delete"
        handleDelete={handleConfirmReportDelete}
      />
      <tbody>
        {reportListData?.length > 0 &&
          reportListData.map((report) => (
            <tr key={report.Id}>
              <td className="col-3">
                <DataTitle report={report} onReport={onReport} />
              </td>
              <td>
                <span className="report-type">
                  {report.reportType === "external" && (
                    <HiOutlineExternalLink size={18} />
                  )}
                  {reportTypes[report.reportType]}
                </span>
              </td>
              <td className="col-3">
                {report.reportType !== "external" && (
                  <DataTags report={report} segments={segments} />
                )}
              </td>
              <td>
                {profileUsers.byId[report.ownerId]
                  ? profileUsers.byId[report.ownerId].name
                  : ""}
              </td>
              <td>
                {moment
                  .utc(Number(report.updateTime))
                  .local()
                  .format("MMM DD, YYYY")}
              </td>
              <td>
                {(authUser.user.uid === report.ownerId || authUser.isAdmin) && (
                  <span className="action-group">
                    <button
                      className="btn-icon"
                      id={report.Id}
                      onClick={() => {
                        handleReportEdit(report);
                      }}
                    >
                      <BiEditAlt />
                    </button>
                    <button
                      className="btn-icon"
                      onClick={handleDeleteButton}
                      data-reportid={report.Id}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </span>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Fragment>
  );
};

export default ListBody;
