import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as Icons from "../../assets/icons/MenuIconProvider";

const SettingMenu = ({ profileId }) => {
  return (
    <nav
      className={
        "dropright sidebar-menu" +
        (window.location.pathname.startsWith(
          "/profile/" + profileId + "/admin/"
        )
          ? " show"
          : "")
      }
      data-tooltip-id="sidebar-menu-tooltip"
      data-tooltip-content="Setting"
    >
      <div
        className={
          "sidebar-menu-item" +
          (window.location.pathname.startsWith(
            "/profile/" + profileId + "/admin/"
          )
            ? " active"
            : "")
        }
        data-toggle="dropdown"
        aria-expanded="false"
        aria-haspopup="true"
      >
        <span className="nav-menu-icon">
          <Icons.Settings />
        </span>
        <span className="sidebar-menu-title">Settings</span>
      </div>
      <div className="dropdown-menu">
        <NavLink
          className="dropdown-item"
          exact
          to={"/profile/" + profileId + "/admin/settings"}
          activeClassName="active"
        >
          Profile
        </NavLink>
        <NavLink
          className="dropdown-item"
          exact
          to={"/profile/" + profileId + "/admin/users"}
          activeClassName="active"
        >
          Users
        </NavLink>
        <NavLink
          className="dropdown-item"
          exact
          to={"/profile/" + profileId + "/admin/code"}
          activeClassName="active"
        >
          Tracking Code
        </NavLink>
        <NavLink
          className="dropdown-item"
          exact
          to={"/profile/" + profileId + "/admin/conversions"}
          activeClassName="active"
        >
          Conversions
        </NavLink>
      </div>
      <ReactTooltip
        id="sidebar-menu-tooltip"
        className="tooltip-sm"
        place="right"
      />
    </nav>
  );
};

export default SettingMenu;
