import React from "react";
import "./style.scss";
import Logo from "../Logo";
import UserMenu from "../menus/UserMenu";

const TopBarNav = () => {
  return (
    <header className="header">
      <Logo />
      <UserMenu />
    </header>
  );
};

export default TopBarNav;
