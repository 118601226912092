export const eventFixture = {
  type: "",
  conditions: [
    {
      attribute: "",
      path: "",
      unit: "",
      operator: "",
      values: [""],
    },
  ],
};

export const conditionFixture = {
  attribute: "",
  path: "",
  unit: "",
  operator: "",
  values: [""],
}

export const includedPageContainersFixture = [
  {
    "inclusion": true,
    "eventConditions": [{...eventFixture}]
  }
]

export const excludedPageContainersFixture = [
  {
    "inclusion": false,
    "eventConditions": [{...eventFixture}]
  }
]

export const pageContainerFixture = {
  inclusion: true,
  eventConditions: [{ ...eventFixture }],
};