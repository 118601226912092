import moment from "moment";

const today = moment();
const yesterday = moment().subtract(1, "d");
const last3days = yesterday.clone().subtract(2, "d");
const last7days = yesterday.clone().subtract(6, "d");
const last30days = yesterday.clone().subtract(29, "d");

export const dateRanges = {
  today: {
    id: "today",
    label: "Today",
    startDate: today.clone().startOf("day"),
    endDate: today.clone().endOf("day"),
  },
  yesterday: {
    id: "yesterday",
    label: "Yesterday",
    startDate: yesterday.clone().startOf("day"),
    endDate: yesterday.clone().endOf("day"),
  },
  last3days: {
    id: "last3days",
    label: "Last 3 days",
    startDate: last3days.startOf("day"),
    endDate: yesterday.clone().endOf("day"),
  },
  last7days: {
    id: "last7days",
    label: "Last 7 days",
    startDate: last7days.clone().startOf("day"),
    endDate: yesterday.clone().endOf("day"),
  },
  last30days: {
    id: "last30days",
    label: "Last 30 days",
    startDate: last30days.clone().startOf("day"),
    endDate: yesterday.clone().endOf("day"),
  },
  custom: {
    id: "custom",
    label: "Custom",
    startDate: null,
    endDate: null,
  },
};

export const dateRangesReverse = {
  [`${dateRanges["today"].startDate.clone().unix()}.${dateRanges[
    "today"
  ].endDate
    .clone()
    .unix()}`]: "today",
  [`${dateRanges["yesterday"].startDate.clone().unix()}.${dateRanges[
    "yesterday"
  ].endDate
    .clone()
    .unix()}`]: "yesterday",
  [`${dateRanges["last3days"].startDate.clone().unix()}.${dateRanges[
    "last3days"
  ].endDate
    .clone()
    .unix()}`]: "last3days",
  [`${dateRanges["last7days"].startDate.clone().unix()}.${dateRanges[
    "last7days"
  ].endDate
    .clone()
    .unix()}`]: "last7days",
  [`${dateRanges["last30days"].startDate.clone().unix()}.${dateRanges[
    "last30days"
  ].endDate
    .clone()
    .unix()}`]: "last30days",
};

export function getDateRange(rangeName) {
  if (dateRanges[rangeName]) {
    return [
      dateRanges[rangeName]["startDate"],
      dateRanges[rangeName]["endDate"],
    ];
  }
}
