import React, { Fragment, useContext, useEffect } from "react";
import { Button } from "./../../share/InsightechUI";
import { UserDataContext } from "../UserData";
import { SegmentsContext } from "../../../context/Segments";
import { withRouter } from "react-router";
import { useReportModal, useReports, useDownload } from "./hooks";
import ReportModal from "../ReportModal";
import ReportsDropdown from "../ReportsDropdown";
import CopyLinkIcon from "./CopyLinkIcon";
import PageDownloadIcon from "./PageDownloadIcon";
import { IoInformationCircleOutline } from "react-icons/io5";
import DropdownAction from "../../share/DropdownAction";
import "./style.scss";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InsightechIcon from "../../share/InsightechIcon";
import { DeleteModal } from "../../share/InsightUI";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import { defaultSortOrder } from "../../../utils";

const QuickReport = (props) => {
  const { title, funnelId, type, description, titleIcon: TitleIcon } = props;

  //custom hooks
  const { isDownloading, handleDownload } = useDownload({ title, funnelId });
  const {
    isDropdownVisible,
    setIsDropdownVisible,
    handleClickReport,
    setOriginalSegments,
    handleDeleteButton,
    handleConfirmReportDelete,
    isDeleteModalHidden,
    setIsDeleteModalHidden,
    isDeleting,
  } = useReports(props);

  const {
    isBusy,
    isNameValid,
    nameErrorMsg,
    isUrlValid,
    urlErrorMsg,
    handleChange,
    handleSaveReport,
    handleCloseModal,
    handleClickSubmit,
    handleSubmitExternalReport,
    isReportEditor,
  } = useReportModal(props);

  //context
  const { selectedSegments } = useContext(SegmentsContext);
  const {
    activeProfileId,
    selectedReport,
    setSelectedReport,
    reports,
    action,
    setAction,
    isSaveModalVisible,
  } = useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);

  const isExternalReport = type !== "report_list" ? false : true;

  const filteredReports =
    type !== "report"
      ? reports.filter((report) => report.reportType === type)
      : reports.filter(
          (report) => report.reportType === selectedReport.reportType
        );

  // fetch reports, add id, flag select
  useEffect(() => {
    //save original segments
    setOriginalSegments({ ...selectedSegments });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProfileId]);

  // cleanup report states if leave report
  useEffect(() => {
    if (type !== "report") {
      setSelectedReport({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Fragment>
      <DeleteModal
        isModalHidden={isDeleteModalHidden}
        setIsModalHidden={setIsDeleteModalHidden}
        isDeleting={isDeleting}
        bodyText="Are you sure you want to delete this report?"
        btnLabel="Delete"
        handleDelete={handleConfirmReportDelete}
      />
      <ReportModal
        isVisible={isSaveModalVisible}
        handleClose={handleCloseModal}
        isBusy={isBusy}
        handleChange={handleChange}
        handleSubmit={
          !isExternalReport ? handleClickSubmit : handleSubmitExternalReport
        }
        isNameValid={isNameValid}
        nameErrorMsg={nameErrorMsg}
        isUrlValid={isUrlValid}
        urlErrorMsg={urlErrorMsg}
        selectedReport={selectedReport}
        action={action}
        isReportEditor={isReportEditor}
        isExternalReport={isExternalReport}
      />

      <div
        className={`d-flex justify-content-between ${
          type !== "report_list" ? "mb-4" : ""
        }`}
      >
        <div className="header-left">
          {!!TitleIcon && (
            <span className="nav-menu-icon mr-3">
              <TitleIcon />
            </span>
          )}
          {title && <span className="page-title">{title}</span>}
          {!!description && (
            <span
              className="page-description"
              data-tooltip-id="quick-report-tooltip"
              data-tooltip-content={description}
            >
              <IoInformationCircleOutline />
            </span>
          )}
        </div>

        <div className="header-right">
          {type === "report_list" && (
            <div className="ml-4">
              <Button
                size="small"
                color="outlined-plain"
                onClick={() => {
                  setAction("save");
                  handleSaveReport();
                }}
              >
                <InsightechIcon name="Add" />
                Add External Report
              </Button>
            </div>
          )}
          {type !== "report_list" && (
            <Fragment>
              {!selectedReport.Id ? (
                <Button
                  size="chip"
                  color="plain"
                  onClick={() => {
                    setAction("save");
                    handleSaveReport();
                  }}
                >
                  <InsightechIcon name="Add" />
                  Save report
                </Button>
              ) : (
                <DropdownAction
                  label={
                    <>
                      <InsightechIcon name="PencilEdit" />
                      <span>Edit report</span>
                    </>
                  }
                  btnVariant="plain"
                  className="share-menu"
                  align="right"
                >
                  <button
                    className="btn btn-plain dropdown-item"
                    onClick={() => {
                      setAction("update");
                      handleSaveReport();
                    }}
                    disabled={!isReportEditor}
                  >
                    Update existing report
                  </button>

                  <button
                    className="btn btn-plain dropdown-item"
                    onClick={() => {
                      setAction("copy");
                      handleSaveReport();
                    }}
                  >
                    Save as a new report
                  </button>

                  <div className="dropdown-divider"></div>
                  <button
                    className="btn btn-plain dropdown-item text-danger"
                    onClick={handleDeleteButton}
                    disabled={
                      authUser.user.uid === selectedReport.ownerId ||
                      authUser.isAdmin
                        ? false
                        : true
                    }
                    data-reportid={selectedReport.Id}
                  >
                    Delete report
                  </button>
                </DropdownAction>
              )}

              <ReportsDropdown
                reports={filteredReports.sort(defaultSortOrder("name"))}
                onReport={handleClickReport}
                isDropdownVisible={isDropdownVisible}
                setIsDropdownVisible={setIsDropdownVisible}
                type={type}
              />
            </Fragment>
          )}

          <DropdownAction
            label={
              <>
                <InsightechIcon name="Share" />
                <span>Share</span>
              </>
            }
            btnVariant="plain"
            className="share-menu"
            align="right"
          >
            <PageDownloadIcon
              isDownloading={isDownloading}
              onDownload={handleDownload}
            />
            <CopyLinkIcon selectedReport={selectedReport} title={title} />
          </DropdownAction>
        </div>
      </div>
      <ReactTooltip id="quick-report-tooltip" className="tooltip-lg" />
    </Fragment>
  );
};

export default withRouter(QuickReport);
