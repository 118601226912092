import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function Collapsible(props) {

  const {
    height,
    progress
  } = props;

  return (
    <div
      className="insight-progress-bar bg-gray-200"
      style={{
        height
      }}
    >
      <div className="insight-progress" style={{width: progress}}></div>
    </div>
  );
}

Collapsible.propTypes = {
  height: PropTypes.number
}
Collapsible.defaultProps = {
  height: 8
}

export default Collapsible;