import React from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";

const Logo = () => {
  return (
    <NavLink to="/" className="logo-container">
      <div className="logo-brand-wrapper">
        <img
          src="/images/logo-app.png"
          alt="Insightech Logo"
          width="48"
          height="48"
        />
      </div>
      <span className="logo-brand-text">Insightech</span>
    </NavLink>
  );
};

export default Logo;
