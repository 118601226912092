import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

function LoaderWithText(props) {
  const { size, text, className, style, isIconShowed = true } = props;
  let sizeClass = "";
  switch (size) {
    case "small":
      sizeClass = "small";
      break;
    default:
      break;
  }
  return (
    <div className={`loader-with-text ${sizeClass} ${className}`} style={style}>
      {text && <h2 className="h4 text-gray-900 mb-4">{text}</h2>}
      {isIconShowed && <i className="fa fa-spinner fa-3x fa-spin" />}
    </div>
  );
}

LoaderWithText.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(["small"]),
};

LoaderWithText.defaultProps = {};

export default LoaderWithText;
