import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function Alert(props){

  const {variant} = props;

  let variantClass = '';
  let icon = null;
  switch (variant) {
    case 'danger':
      variantClass = 'alert-danger';
      icon = <i className="fas alert-icon fa-times-circle"></i>
      break;
    case 'success':
      variantClass = 'alert-success';
      icon = <i className="fas alert-icon fa-check-circle"></i>
      break;
    default:
      variantClass = '';
      break;
  }

  return (
    <div className={`ui-alert alert small ${variantClass}`}>
      {icon}
      {` `}
      {props.children}
    </div>
  )
}

Alert.propTypes = {
  variant: PropTypes.string
}

Alert.defaultProps = {
}

export default Alert;