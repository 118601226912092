import React from "react";
import NumberField from "../../../../components/share/fields/NumberField";
import TextareaField from "../../../../components/share/fields/TextareaField";

const RageClickSettings = ({
  rageClickClicks,
  rageClickEnabled,
  handleChangeRageClickExclusion,
  handleChangeRageClickNumber,
  handleChangeRageClickTime,
  rageClickExclusion,
  rageClickIntervalMs,
  isRageClickIntervalMsValid,
  isRageClickNumberValid,
  isRageClickExclusionValid,
}) => {
  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Rage Click Definition</label>
        <div className="col-sm-10 col-md-4 col-lg-6 row align-items-center">
          <NumberField
            number={rageClickClicks}
            label={"clicks within"}
            handleChange={handleChangeRageClickNumber}
            min={2}
            max={50}
            disabled={!rageClickEnabled}
            isValid={isRageClickNumberValid}
          />
          <NumberField
            number={rageClickIntervalMs}
            label={" milliseconds on the same element"}
            handleChange={handleChangeRageClickTime}
            min={100}
            max={10000}
            disabled={!rageClickEnabled}
            isValid={isRageClickIntervalMsValid}
          />
        </div>
      </div>
      <TextareaField
        text={rageClickExclusion.join("\n")}
        placeholder=""
        label="Rage Click Excluded Elements"
        id="rageClickExclusion"
        helpText="List of excluded element CSS selectors. One line per CSS selector."
        handleChange={handleChangeRageClickExclusion}
        isValid={isRageClickExclusionValid}
        disabled={!rageClickEnabled}
      />
    </>
  );
};

export default RageClickSettings;
