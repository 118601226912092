import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import moment from "moment";
import "./style.scss";

function ReplayEventsNav(props) {
  const {
    pause,
    pageviews,
    progress,
    activePageviewIndex,
    playElapsedEvents,
    setIsUserJourneyVisible,
  } = props;
  const [offset, setOffset] = useState(0);
  const [initialTimestamp, setInitialTimestamp] = useState(null);
  const [isEventDataSet, setIsEventDataSet] = useState(false);
  const [eventData, setEventData] = useState({
    url: "",
    title: "",
    timestamp: null,
    eventsCount: 0,
  });
  const [pageComponents, setPageComponents] = useState([]);
  const slidesRef = useRef();
  const wrapperRef = useRef();
  const stepsPerPage = 5;
  let totalDuration = 0;

  const handleClickNext = () => {
    const width = wrapperRef.current.offsetWidth;
    const slidesWidth = slidesRef.current.scrollWidth;
    const maxOffset = slidesWidth - width;
    let increment = width;
    let newOffset = offset - increment;
    if (newOffset < -maxOffset) {
      newOffset = -maxOffset;
    }
    setOffset(newOffset);
  };

  const handleClickPoint = (e) => {
    const target = e.currentTarget;
    const data = target.dataset;
    const url = data["url"];
    const timestamp = Number(data["timestamp"]);
    const eventsCount = data["eventscount"];
    const newData = {
      url: url,
      timestamp: moment
        .duration(timestamp, "milliseconds")
        .format("HH:mm:ss", { trim: false }),
      eventsCount,
    };
    pause();
    setEventData(newData);
    playElapsedEvents(timestamp);
  };

  const handleClickPrev = () => {
    const width = wrapperRef.current.offsetWidth;
    let increment = width;
    let newOffset = offset + increment;
    if (newOffset > 0) {
      newOffset = 0;
    }
    setOffset(newOffset);
  };

  const handleClickClose = () => {
    setIsUserJourneyVisible(false);
  };

  useEffect(() => {
    if (pageviews && pageviews.length && !initialTimestamp) {
      if (pageviews[0].events && pageviews[0].events.length) {
        setInitialTimestamp(pageviews[0].events[0].clientTimestamp);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageviews]);

  useEffect(() => {
    if (initialTimestamp && !isEventDataSet) {
      const newTimestamp =
        pageviews[activePageviewIndex].events[0].clientTimestamp -
        initialTimestamp;
      setEventData({
        url: pageviews[0].url,
        title: pageviews[0].title,
        timestamp: moment
          .duration(newTimestamp, "milliseconds")
          .format("HH:mm:ss", { trim: false }),
        eventsCount: pageviews[activePageviewIndex].events.length,
      });
      setIsEventDataSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageviewIndex, initialTimestamp]);

  useEffect(() => {
    let total = Math.ceil(pageviews.length / stepsPerPage);
    const components = [];
    for (let i = 0, len = total; i < len; i++) {
      const pageEntries = [];
      for (let j = 0, len2 = stepsPerPage; j < len2; j++) {
        const index = i * stepsPerPage + j;
        const data = pageviews[index];
        if (!data) {
          break;
        }
        let timestamp = 0;
        const duration =
          data.events[data.events.length - 1].ct - data.events[0].ct;
        if (index > 0) {
          timestamp = totalDuration;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        totalDuration += duration;
        const url = data.events && data.events.length && data.events[0].url;
        pageEntries.push(
          <div key={index} className={`${progress >= timestamp && "elapsed"}`}>
            <button
              className={`btn btn-plain`}
              onClick={handleClickPoint}
              data-timestamp={timestamp}
              data-url={url}
              data-eventscount={data.events && data.events.length}
              data-tooltip-id="replay-viewer-tooltip"
              data-tooltip-content={url}
            >
              {index + 1}
            </button>
          </div>
        );
      }
      const reverseEntries = pageEntries.reverse();
      components.push(
        <div className="slide-page" key={i}>
          <div className="slide-page-content">
            {reverseEntries.map((entry, i) => {
              return entry;
            })}
          </div>
        </div>
      );

      setPageComponents(components);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div className="replay-events-nav">
      <h3 className="heading">Pageviews</h3>
      <Button
        onClick={handleClickClose}
        variant="plain"
        size="small"
        className="btn-close"
      >
        <i className="fa fa-times"></i>
      </Button>
      <div className="nav-meta">
        <div className="title">
          <span className="timestamp">{eventData.timestamp}</span>
          <h3>{pageviews[activePageviewIndex]["title"]}</h3>
        </div>
        <a
          className="link"
          href={pageviews[activePageviewIndex]["url"]}
          target="_blank"
        >
          {pageviews[activePageviewIndex]["url"]}
        </a>
      </div>
      <div className="nav-content">
        <Button
          variant="plain"
          className="nav-btn btn-prev"
          onClick={handleClickPrev}
        >
          <i className="fa fa-chevron-left"></i>
        </Button>
        <Button
          variant="plain"
          className="nav-btn btn-next"
          onClick={handleClickNext}
        >
          <i className="fa fa-chevron-right"></i>
        </Button>
        <div className="slide-wrapper" ref={wrapperRef}>
          <div
            className="slides"
            ref={slidesRef}
            style={{
              transform: `translateX(${offset}px)`,
            }}
          >
            {pageviews &&
              pageviews.length &&
              pageComponents.map((page, i) => {
                return page;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

ReplayEventsNav.propTypes = {
  pageViews: PropTypes.array,
  activePageView: PropTypes.number,
};
ReplayEventsNav.defaultProps = {};

export default ReplayEventsNav;
