import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import { firebaseApp, getAuth, signOut } from "./../../../lib/firebase";
import UserAvatar from "../UserAvatar";
import { UserDataContext } from "../../../components/app/UserData";
import "./userMenu.scss";
import { defaultSortOrder } from "../../../utils";
import { FaChevronDown } from "react-icons/fa";
import InsightechIcon from "../../share/InsightechIcon";

const UserMenu = () => {
  const { access, activeProfileId } = useContext(UserDataContext);
  const isDisplayed =
    !!access &&
    !!access.accounts &&
    !!access.accounts.length &&
    !!activeProfileId;
  const history = useHistory();

  return (
    <div className="topbar-nav">
      {/* Extension */}
      <a
        target="_blank"
        href="https://chrome.google.com/webstore/detail/insightech-extension/fojjmhaiegijdpkhiliecbmaeblogcld"
        className="topbar-nav-item"
      >
        <span className="nav-icon">
          <InsightechIcon name="Extension" />
        </span>
        <span className="nav-text">Install Chrome Extension</span>
      </a>

      {/* Profile List */}
      {isDisplayed && (
        <div className="dropdown profile-selector-dropdown">
          <div
            className="topbar-nav-item"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="nav-icon">
              <InsightechIcon name="AccountDash" />
            </span>

            <span className="nav-text">{access.profiles[activeProfileId]}</span>
            <FaChevronDown color="#9ca6ab" style={{ marginLeft: "6px" }} />
          </div>
          <div className="dropdown-menu">
            {access.accounts
              .sort(defaultSortOrder("name"))
              .map((account, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <h4 className="dropdown-header">{account.name}</h4>
                    {account.profiles !== null &&
                      account.profiles.length > 0 &&
                      account.profiles
                        .sort(defaultSortOrder("name"))
                        .map((profile) => {
                          return (
                            <a
                              key={profile.id}
                              className="dropdown-item"
                              href={`/profile/${profile.id}/analytics/dashboard`}
                            >
                              {profile.name}
                            </a>
                          );
                        })}
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      )}

      {/* Support */}
      <a
        target="_blank"
        href="https://support.insightech.com/"
        className="topbar-nav-item"
      >
        <span className="nav-icon">
          <InsightechIcon name="Help" />
        </span>
        <span className="nav-text">Support</span>
      </a>

      {/* User */}
      <AuthContext.Consumer>
        {(context) => (
          <div className="dropdown">
            <div
              className="topbar-nav-item item-user"
              name="user"
              id="userDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <UserAvatar
                name={context.authUser.user.displayName}
                photoUrl={context.authUser.user.photoURL}
                className="img-profile rounded-circle"
                size="32"
              />
              <span>{context.authUser.user.displayName}</span>
            </div>
            <div
              className="dropdown-menu shadow"
              aria-labelledby="userDropdown"
            >
              <Link className="dropdown-item" to="/user/profile">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </Link>
              <Link className="dropdown-item" to="/user/log">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                Activity Log
              </Link>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                href="/"
                data-toggle="modal"
                data-target="#logoutModal"
                onClick={(e) => {
                  e.preventDefault();
                  signOut(getAuth(firebaseApp))
                    .then(() => {
                      // Sign-out successful.
                      history.push("/signin");
                    })
                    .catch((error) => {
                      // An error happened.
                    });
                }}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Sign Out
              </a>
            </div>
          </div>
        )}
      </AuthContext.Consumer>
    </div>
  );
};

export default UserMenu;
