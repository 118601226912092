import React from "react";
import "./style.scss";
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { createPortal } from "react-dom";

const Notification = ({ message, onClose }) => {
  return createPortal(
    <div className="notification">
      <div className="notification-icon">
        <FaRegCircleCheck />
      </div>
      <div className="notification-content">
        <span className="notification-message">{message}</span>
      </div>
      <button className="notification-close" onClick={onClose}>
        <IoClose />
      </button>
    </div>,
    document.getElementById("portal")
  );
};

export default Notification;
