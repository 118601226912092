import React from "react";
import { MAX_FUNNELS_FOLDER } from "../../../../constants/funnels";

const FunnelFoldersTable = ({
  funnelFolders,
  funnelFoldersMap,
  selectedFolder,
  handleCheckboxChange,
}) => {
  return (
    <div className="folders-table-wrapper">
      <table className="table folders-table">
        <tbody>
          {funnelFolders.map((folder, index) => {
            return (
              <tr key={index}>
                <td>
                  <input
                    type="radio"
                    data-label={folder.label}
                    value={folder.value}
                    onChange={handleCheckboxChange}
                    checked={selectedFolder.value === folder.value || false}
                    disabled={
                      funnelFoldersMap?.get(folder.label) >= MAX_FUNNELS_FOLDER
                        ? true
                        : false || false
                    }
                  />
                </td>
                <td>{folder.label}</td>
                {
                  <td>
                    {folder.label && funnelFoldersMap.get(folder.label) ? (
                      <span className="funnels-number">
                        {`${funnelFoldersMap.get(
                          folder.label
                        )} / ${MAX_FUNNELS_FOLDER}`}
                      </span>
                    ) : (
                      <></>
                    )}
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FunnelFoldersTable;
