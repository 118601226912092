import React from "react";
import UserAvatar from "./../../app/UserAvatar";
import InlineConfirmation from "./../../share/InlineConfirmation";
import { saveComment, deleteComment } from "./../../../lib/firebase/comments";
import { getAuth, firebaseApp } from "./../../../lib/firebase";
import { AuthContext } from "./../../auth/FirebaseAuthContext";

import "./style.scss";

const Comment = (props) => {
  const {
    comments,
    setComments,
    index,
    authorPhoto,
    authorName,
    data,
    id,
    profileId,
    noteId,
  } = props;
  const { comment } = data;
  const { authUser } = React.useContext(AuthContext);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [formData, setFormData] = React.useState({ comment });
  const [displayComment, setDisplayComment] = React.useState(comment);
  const [isConfirmationVisible, setIsConfirmationVisible] =
    React.useState(false);
  const textRef = React.useRef(null);

  const handleClickEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleClickSave = (e) => {
    e.preventDefault();
    setIsSaving(true);
    saveComment(formData, profileId, noteId, id).then((res) => {
      setTimeout(() => {
        setIsEditing(false);
        setIsSaving(false);
        setDisplayComment(formData.comment);
      }, 700);
    });
  };

  const handleChangeText = (e) => {
    e.preventDefault();
    setFormData({ ...formData, comment: e.target.value });
  };

  const handleClickCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleClickDelete = (e) => {
    e.preventDefault();
    setIsConfirmationVisible(true);
  };

  const handleDelete = (e) => {
    setIsDeleting(true);
    deleteComment(profileId, noteId, id).then((res) => {
      const newComments = [...comments];
      newComments.splice(index, 1);
      setComments(newComments);
    });
  };

  React.useEffect(() => {
    if (isEditing) {
      textRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div className="comment-block">
      <div className="comment-col1">
        <UserAvatar
          className="rounded-circle mr-auto"
          photoUrl={authorPhoto}
          name={authorName}
        />
      </div>
      <div className="comment-col2">
        {!!isEditing && (
          <textarea
            disabled={isEditing && isSaving}
            ref={textRef}
            value={formData.comment}
            onChange={handleChangeText}
            className="form-control"
            placeholder="Write a comment"
          ></textarea>
        )}
        {!isEditing && (
          <div className="comment-body bg-gray-100">
            <strong className="comment-author">{authorName}</strong>
            {displayComment}
          </div>
        )}
        {
          <div className="small mt-1 comment-actions">
            {!!isEditing && !!isSaving && (
              <>
                <span className="fa fa-spinner fa-spin"></span> Saving
              </>
            )}
            {!!isEditing && !isSaving && (
              <>
                <a
                  onClick={handleClickSave}
                  href="/"
                  className="text-secondary"
                >
                  Save
                </a>{" "}
                -{" "}
                <a onClick={handleClickCancel} href="/" className="text-danger">
                  Cancel
                </a>
              </>
            )}
            {!isEditing && (
              <>
                {data.ownerId === getAuth(firebaseApp).currentUser.uid && (
                  <a
                    onClick={handleClickEdit}
                    href="/"
                    className="text-secondary"
                  >
                    Edit
                  </a>
                )}
                {data.ownerId === getAuth(firebaseApp).currentUser.uid && " - "}
                {(authUser.isAdmin ||
                  data.ownerId === getAuth(firebaseApp).currentUser.uid) && (
                  <a
                    onClick={handleClickDelete}
                    href="/"
                    className="text-danger"
                  >
                    Delete
                    <InlineConfirmation
                      confirmAction={handleDelete}
                      isVisible={isConfirmationVisible}
                      isProcessing={isDeleting}
                      setIsVisible={setIsConfirmationVisible}
                      title="Delete Comment?"
                      message="Are you sure you want to delete this comment?"
                      buttonText="Delete comment"
                    />
                  </a>
                )}
              </>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default Comment;
