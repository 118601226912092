import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FunnelBuilder from "../../../../components/app/FunnelBuilder";
import FunnelViewer from "../../../../components/app/FunnelViewer";
import { UserDataContext } from "../../../../components/app/UserData";
import { SegmentsContext } from "../../../../context/Segments";
import { DEFAULT_FUNNEL_FOLDER_NAME } from "../../../../constants/funnels";
import { fetchFunnels } from "../../../../lib/firebase/funnels";
import { defaultSortOrder } from "../../../../utils";
import { fetchReportById } from "../../../../lib/firebase/reports";

const FunnelAction = (props) => {
  const profileId = props.match.params.profileId;
  const action = props.match.params.action || "view"; // set to view if redirect from report
  const [funnelId, setFunnelId] = useState(props.match.params.funnelId);
  const reportId = props.match.params.reportId;

  const {
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
    funnelFolders,
    setFunnelFolders, // used for create new folder
    setSelectedFunnelFolder, // used for set selected folder
    funnelFoldersMap, // used for checking the number of funnels in each folder
    setFunnelFoldersMap,
  } = useContext(UserDataContext);

  const { selectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);

  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [isLoadingFunnels, setIsLoadingFunnels] = useState(false);
  const [isFunnelsLoaded, setIsFunnelsLoaded] = useState(false);
  const _isMounted = useRef(true);

  const fetchReportData = () => {
    // Fetch report details based on reportId
    fetchReportById(profileId, reportId)
      .then((res) => {
        const fetchedReport = res.data();
        if (fetchedReport) {
          setFunnelId(fetchedReport?.funnelId);
        }
      })
      .catch((error) => {
        console.error("Error fetching report data", error);
      });
  };

  useEffect(() => {
    if (reportId && profileId) {
      fetchReportData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, profileId]);

  // Set funnel id from edit, and exclude report
  useEffect(() => {
    if (props.match.params?.funnelId && !reportId) {
      setFunnelId(props.match.params.funnelId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params?.funnelId]);

  useEffect(() => {
    if (
      !isApiServerLoading &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      profileId &&
      action !== "view"
    ) {
      setIsDependenciesLoaded(true);
    }
  }, [
    isInitialFiltersLoaded,
    isApiServerLoading,
    isInitialSegmentsLoaded,
    profileId,
    action,
  ]);

  useEffect(() => {
    if (
      isDependenciesLoaded &&
      profileId &&
      startDate &&
      endDate &&
      selectedSegments &&
      appliedFilters &&
      action !== "view"
    ) {
      setIsLoadingFunnels(true);
      const updatedFunnelFoldersMap = new Map();
      updatedFunnelFoldersMap.set(DEFAULT_FUNNEL_FOLDER_NAME, 0);
      const updatedFunnelFolders = [
        {
          label: DEFAULT_FUNNEL_FOLDER_NAME,
          value: DEFAULT_FUNNEL_FOLDER_NAME,
        },
      ];

      // Funnels
      fetchFunnels({ profileId })
        .then((res) => {
          if (!_isMounted.current) return;

          // Funnels documents
          res.forEach((funnelDoc) => {
            const folder = funnelDoc.data().folder;
            const folderCount = updatedFunnelFoldersMap.get(folder) || 0;

            //process funnel folders
            if (!folder || folder === DEFAULT_FUNNEL_FOLDER_NAME) {
              updatedFunnelFoldersMap.set(
                DEFAULT_FUNNEL_FOLDER_NAME,
                (updatedFunnelFoldersMap.get(DEFAULT_FUNNEL_FOLDER_NAME) || 0) +
                  1
              );
            } else {
              updatedFunnelFoldersMap.set(folder, folderCount + 1);
            }

            if (folder) {
              if (!updatedFunnelFolders.find((res) => res.value === folder)) {
                updatedFunnelFolders.push({
                  label: folder,
                  value: folder,
                });
              }
            }
          });
          setFunnelFoldersMap(updatedFunnelFoldersMap);
          // Keep the "General" folder as the first folder
          setFunnelFolders([
            updatedFunnelFolders[0],
            ...updatedFunnelFolders.slice(1).sort(defaultSortOrder("label")),
          ]);
          setIsLoadingFunnels(false);
          setIsFunnelsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDependenciesLoaded,
    profileId,
    startDate,
    endDate,
    selectedSegments,
    appliedFilters,
    action,
  ]);

  return (
    <Fragment>
      {action === "edit" && !!isFunnelsLoaded && !isLoadingFunnels && (
        <FunnelBuilder
          funnelId={funnelId}
          setFunnelId={setFunnelId}
          funnelFolders={funnelFolders}
          funnelFoldersMap={funnelFoldersMap}
          setFunnelFolders={setFunnelFolders}
          setSelectedFunnelFolder={setSelectedFunnelFolder}
        />
      )}
      {action === "view" && funnelId && <FunnelViewer funnelId={funnelId} />}
    </Fragment>
  );
};

export default FunnelAction;
