import React from "react";
import { NavLink } from "react-router-dom";
import { FaAtom } from "react-icons/fa";

const AppMenu = () => {
  return (
    <nav
      className={
        "sidebar-menu" +
        (window.location.pathname === "/" ||
        window.location.pathname.startsWith("/accounts/")
          ? " active"
          : "")
      }
    >
      <NavLink
        className="sidebar-menu-item"
        exact
        to="/"
        activeClassName="active"
      >
        <span className="nav-menu-icon">
          <FaAtom />
        </span>
        <span className="sidebar-menu-title">Accounts</span>
      </NavLink>
    </nav>
  );
};

export default AppMenu;
