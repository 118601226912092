import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import { SegmentsContext } from "../../../../context/Segments";
import { UserDataContext } from "../../../../components/app/UserData";
import { Button } from "../../../../components/share/InsightUI";
import { BiEditAlt } from "react-icons/bi";
import { fetchProfileById } from "../../../../lib/firebase/profile";
import Chart from "../../../../components/app/SunburstChart";
import OutlinedContainer from "../../../../components/share/OutlinedContainer";
import api from "../../../../api";
import {
  captureExceptionToSentry,
  thousandSeparatorAndFixedDecimal,
} from "../../../../utils";
import "./style.scss";
import {
  ERRORS_MAPPED,
  DEFAULT_ERROR,
} from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";

const Pathing = (props) => {
  const profileId = props.match.params.profileId;
  const { authUser } = React.useContext(AuthContext);
  const [isPathingLoading, setIsPathingLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");
  const { selectedSegments } = useContext(SegmentsContext);
  const { startDate, endDate, appliedFilters } = useContext(UserDataContext);
  const [chartData, setChartData] = useState();
  const [rules, setRules] = useState();
  const [pathAmount, setPathAmount] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);

  const [pathError, setPathError] = useState();
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  
  const handleApiError = (error, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
    } else {
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readPathsList = ({ queryId, key, callback }) => {
    api.cancel(api.readPathsListToken);
    setLoadingStatus("read");
    api
      .readPathsList(authUser, {
        profileId,
        queryId,
        key,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readPathsList({ queryId, key, callback });
        }
        else if (res.data.status === 200) {
          
          setLoadingStatus("rendering");
          const pathApi = res.data.data;
          setPathAmount(pathApi.totalRows);

          Promise.all([fetchProfileById(profileId)]).then((res) => {
            const rulesList = [];
            const profileData = res[0].docs[0].data();
            if (profileData.pathRules) {
              profileData.pathRules.forEach((rule) => {
                rulesList.push(rule);
              });
              setRules(rulesList);
            }

            if (pathApi) {
              if (pathApi.totalRows !== 0) {
                let sumSessions = 0;
                if (pathApi.records !== null) {
                  sumSessions = pathApi.records?.reduce(
                    (sum, r) => sum + r.sessions,
                    0
                  );
                  setTotalSessions(sumSessions);
                }

                let sortedPathApi = [...pathApi.records].sort(
                  (a, b) => a.path.length - b.path.length
                );

                var result = dataTransform(
                  sortedPathApi,
                  rulesList,
                  sumSessions
                );
                setChartData(result);
              } else {
                setChartData(null);
              }
            }
            setIsPathingLoading(false);
          });

          if (callback && typeof callback === "function") {
            callback();
          }
        } else {
          setPathError(ERRORS_MAPPED.DEFAULT_ERROR);
          setIsPathingLoading(false);
        }
      })
      .catch((error) => handleApiError(error, setIsPathingLoading));
  };

  const queryPathsList = ({
    segments = selectedSegments,
    filter = JSON.stringify(appliedFilters),
    start = startDate.format("YYYY-MM-DD"),
    end = endDate.format("YYYY-MM-DD"),
    callback,
  }) => {
    setLoadingStatus("query");
    setIsPathingLoading(true);
    api.cancel(api.queryPathsListToken);
    api.cancel(api.readPathsListToken);
    api
      .queryPathsList(authUser, {
        profileId,
        startDate: start,
        endDate: end,
        filter: filter,
        segments: JSON.stringify(
          Object.keys(segments).map((key) => {
            return key;
          })
        ),
      })
      .then((res) => {
        const { key, queryId } = res.data.data;
        if (key && queryId) {
          readPathsList({ queryId: queryId, key, callback });
          setAlert({
            show: false,
            type: "",
            message: "",
            count: 0,
          });
          setPathError(null);
        } else {
          setPathError(ERRORS_MAPPED.DEFAULT_ERROR);
          setIsPathingLoading(false);
        }
      })
      .catch((error) => handleApiError(error, setIsPathingLoading));
  };

  // integrate transform
  function dataTransform(dt, rules, sumSessions) {
    const splitPathData = formatPath(dt);
    var formatRes = formatData(splitPathData, rules, sumSessions);
    var root = {
      name: splitPathData[0].formatPath[0],
      color: "#f7f7f7",
      children: [],
    };
    var treeFormat = treeConstrutor(formatRes, root);
    const result = mergeTrees(treeFormat);
    return result[0];
  }

  // split path in data
  function formatPath(dt) {
    var splitPathData = dt.map((data) => ({
      formatPath: data.path.split(" > "),
      value: data.sessions,
    }));
    splitPathData.map((data) => ({
      formatPath: data.formatPath.unshift("start"),
      value: data.value,
    }));
    return splitPathData;
  }

  // format array with objs
  function formatData(dt, rules, sumSessions) {
    const formattedData = [];

    const formatRules = rules.map((r) => ({
      name: r.name.toLowerCase(),
      filters: JSON.parse(r.filters),
    }));

    for (var i = 0; i < dt.length; i++) {
      var pathLen = dt[i].formatPath.length;
      for (var j = 1; j < pathLen; j++) {
        let obj;
        var objName = dt[i].formatPath[j];
        var objID = `${i}-${j}`;
        var hex = convertToHex(objName);
        var colorCode = `#${hex}`;

        if (j === pathLen - 1) {
          if (pathLen === 2) {
            obj = {
              id: objID,
              name: objName,
              size: dt[i].value,
              sumSessions: sumSessions,
              color: colorCode,
              parentId: `0-0`,
              children: null,
            };
          } else {
            obj = {
              id: objID,
              name: objName,
              size: dt[i].value,
              sumSessions: sumSessions,
              color: colorCode,
              parentId: `${i}-${j - 1}`,
              children: null,
            };
          }
        } else if (j === 1) {
          obj = {
            id: objID,
            name: objName,
            size: 0,
            sumSessions: sumSessions,
            color: colorCode,
            parentId: `0-0`,
            children: null,
          };
        } else {
          obj = {
            id: objID,
            name: objName,
            size: 0,
            sumSessions: sumSessions,
            color: colorCode,
            parentId: `${i}-${j - 1}`,
            children: null,
          };
        }

        if (formatRules.length === 0) {
          obj["rule"] = `Page first section is "${obj.name}"`;
        }

        // var objName;
        if (obj.name.includes("/")) {
          var find = "/";
          var re = new RegExp(find, "g");
          objName = obj.name.replace(re, "");
        }
        objName = objName.toLowerCase();

        // eslint-disable-next-line no-loop-func
        var idx = formatRules.findIndex((r) => r.name === objName);
        if (idx >= 0) {
          let filterVal = formatRules[idx].filters.values
            .map((v) => `"${v}"`)
            .join(" or ");
          obj[
            "rule"
          ] = `Page Path ${formatRules[idx].filters.operator} ${filterVal} </br>`;
        } else {
          obj["rule"] = `Page first section is "${obj.name}"`;
        }
        formattedData.push(obj);
      }
    }

    return formattedData;
  }

  // construct tree by array objs
  function treeConstrutor(list, root) {
    var map = {},
      node,
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== "0-0") {
        list[map[node.parentId]].children.push(node);
      } else {
        root.children.push(node);
      }
    }
    return root;
  }

  function mergeTrees(tree) {
    function fillMap(src, map) {
      let dst = map.get(src.name);
      if (!dst) map.set(src.name, (dst = { ...src, children: new Map() }));
      for (let child of src.children ?? []) fillMap(child, dst.children);
    }
    // Merge into nested Map structure:
    let mergedTree = new Map();
    fillMap(tree, mergedTree);
    // Convert each map to array:
    const toArrays = (map) =>
      Array.from(map.values(), (node) =>
        Object.assign(node, { children: toArrays(node.children) })
      );
    return toArrays(mergedTree);
  }

  function toWeirdCase(string) {
    var reg = /\b(?![\s.])/;
    var res = string.split(reg);
    var newArr = [];

    for (let k = 0; k < res.length; k++) {
      let newString = "";
      for (let j = 0; j < res[k].length; j++) {
        if (j % 2 === 0) {
          newString += res[k].charAt(j).toUpperCase();
        } else {
          newString += res[k].charAt(j).toLowerCase();
        }
      }
      newArr.push(newString);
    }
    return newArr.join("");
  }

  function convertToHex(str) {
    if (str.includes("/")) {
      var find = "/";
      var re = new RegExp(find, "g");
      str = str.replace(re, "");
    }
    if (str.length < 4) {
      if (str.length === 0) {
        str = "=is!";
      } else {
        str = str.repeat(3);
      }
    }
    str = toWeirdCase(str);

    var hex = "";
    for (var i = 0; i < str.length; i++) {
      hex += "" + str.charCodeAt(i).toString(16);
    }
    return hex.slice(1, 7);
  }

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      api.cancel(api.queryPathsListToken);
      api.cancel(api.readPathsListToken);
    };
  }, []);

  useEffect(() => {
    if (
      profileId &&
      startDate &&
      endDate &&
      selectedSegments &&
      appliedFilters
    ) {
      setIsPathingLoading(true);
      queryPathsList({ profileId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, startDate, endDate, selectedSegments, appliedFilters]);

  return (
    <Fragment>
      <div className="row">
        <Alert show={alert.show} type={alert.type} message={alert.message} />
      </div>
      <div className="pathing shadow">
        <div className="chart-header">
          <div className="chart-title">
            {pathAmount !== 0 &&
              (pathAmount === 100
                ? `${thousandSeparatorAndFixedDecimal(
                    totalSessions
                  )} sessions in the top ${pathAmount} paths`
                : `${thousandSeparatorAndFixedDecimal(
                    totalSessions
                  )} sessions in ${pathAmount} paths`)}
          </div>
          {authUser.isAdmin && (
            <div className="btn-rules">
              <Link to={`/profile/${profileId}/pathing/edit`}>
                <Button variant="primary">
                  <div className="btn-edit-icon">
                    <BiEditAlt size={16} />
                  </div>
                  Edit Page Grouping Rules
                </Button>
              </Link>
            </div>
          )}
        </div>

        {isPathingLoading && (
          <div className="chart-loader">
            <i className="fa fa-spinner fa-4x fa-spin"></i>
            <div className="font-weight-lighter">
              {loadingStatus === "query" && "Querying..."}
              {loadingStatus === "read" && "Reading..."}
              {loadingStatus === "rendering" && "Rendering result..."}
            </div>
          </div>
        )}

        {!isPathingLoading && chartData && (
          <div className="sunburst">
            <Chart data={chartData} rules={rules} />
          </div>
        )}

        {!isPathingLoading && !chartData && pathError && (
          <div className="info-box">
            <OutlinedContainer padding={`60px 0px`}>
              <span className="fa fa-2x fa-chart-pie"></span>
              <h2 className="h4 font-weight-lighter my-4">{DEFAULT_ERROR}</h2>
            </OutlinedContainer>
          </div>
        )}

        {!isPathingLoading && !chartData && !pathError && (
          <div className="info-box">
            <OutlinedContainer padding={`60px 0px`}>
              <span className="fa fa-2x fa-chart-pie"></span>
              <h2 className="h4 font-weight-lighter my-4">No Pathing Data</h2>

              <p className="mt-2">
                Please change <b>conditions</b> in above{" "}
                <b>Segments & Filters</b> panel
              </p>
            </OutlinedContainer>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(Pathing);
