import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { UserDataContext } from "../UserData";

import { deepCopy } from "../../../utils";
import { eventFixture, pageContainerFixture } from "../../../fixtures/filters";
import ConversionsStep from "./conversionsStep";
import "./style.scss";
import {
  fetchConversions,
} from "../../../lib/firebase/conversions";

import { AuthContext } from "./../../../components/auth/FirebaseAuthContext";
import api from "./../../../api";

const events = require("../../../inc/conversionEvents.json");

function ConversionsBuilder(props) {
  const { activeProfileId } = useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);

  const [stepFilters, setStepFilters] = useState([
    deepCopy(pageContainerFixture),
  ]);

  const [enableRevenue, setEnableRevenue] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [revenueJsonPath, setRevenueJsonPath] = useState("");
  const [isConversion, setIsConversion] = useState();
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [conversionId, setConversionId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [invalidFields, setInvalidFields] = useState([
    {
      includePageContainers: [],
      total: 0,
    },
  ]);
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const handleEnabled = () => {
    setEnabled(!enabled);
  };

  const handleChangeEnableRevenue = () => {
    setEnableRevenue(!enableRevenue);
  };

  const handleChangeRevenueJsonPath = (e) => {
    setRevenueJsonPath(e.currentTarget.value);
  };

  const handleSaveConversionPoint = () => {
    setIsFormProcessing(true);
    let conversionData = {
      enableRevenue:
        stepFilters[0].eventConditions[0].type === "CustomData"
          ? enableRevenue
          : false,
      enabled,
      filters: JSON.stringify({
        pageContainers: [
          {
            inclusion: true,
            eventConditions: [
              {
                type: stepFilters[0].eventConditions[0].type,
                conditions: stepFilters[0].eventConditions[0].conditions,
              },
            ],
          },
        ],
      }),
      revenueJsonPath: enableRevenue ? revenueJsonPath : "",
    };

    const handleWriteError = (error) => {
      let errorMessage
      if(typeof error == "string") {
        errorMessage = error;
      } else if(typeof error == "object" && error.response && error.response.data && typeof error.response.data.result == "string") {
        errorMessage = error.response.data.result;
      } else if(typeof error == "object" && error.message) {
        errorMessage = error.message;
      } else {
        errorMessage = "An error occurred while saving the conversion point";
      }
      setAlert({
        show: true,
        type: "danger",
        message: errorMessage,
        count: alert.count + 1,
      })
    }


    // Create or update
    api.writeConversion(authUser, conversionId, {
      profileId : activeProfileId,
      enabled : conversionData.enabled,
      filters : conversionData.filters,
      enableRevenue : conversionData.enableRevenue,
      revenueJsonPath: conversionData.revenueJsonPath,
    })
    .then((res) => {
      if(res?.data?.data?.conversion?.id) {
        if (!conversionId) {
          setConversionId(res.data.data.conversion.id);
        }

        setRevenueJsonPath(conversionData.revenueJsonPath);
        setAlert({
          show: true,
          type: "success",
          message: "The conversion point has been saved successfully",
          count: alert.count + 1,
        });
        return;
      }
      else {
        console.log(res)
        handleWriteError("An error occurred while saving the conversion point");
      }
    })
    .catch((error) => {
      // Todo : in case of an AxiosError, 
      handleWriteError(error)
    })
    .finally(() => {
      setIsFormProcessing(false);
    });
  };

  const handleUpdateCondition = (
    stepIndex,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex] = data;
    setStepFilters(newData);
  };

  const handleClickAddEventConditionRow = (stepIndex, containerIndex) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions.push(deepCopy(eventFixture));
    setStepFilters(newData);
  };

  const handleClickDeleteEventConditionRow = (
    stepIndex,
    containerIndex,
    eventIndex
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions.splice(eventIndex, 1);
    setStepFilters(newData);
    setEnableRevenue(false);
    setRevenueJsonPath("");
    setEnabled(false);
  };

  const handleUpdateSubCondition = (
    stepIndex,
    containerIndex,
    eventIndex,
    conditionIndex,
    data
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex].conditions[conditionIndex] =
      data;
    setStepFilters(newData);
  };

  const handleClickAddSubConditionRow = (
    stepIndex,
    containerIndex,
    eventIndex
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex].conditions.push(
      deepCopy(eventFixture)
    );
    setStepFilters(newData);
  };

  const handleClickDeleteSubConditionRow = (
    stepIndex,
    containerIndex,
    eventIndex,
    conditionIndex
  ) => {
    const newData = deepCopy(stepFilters);
    newData[stepIndex].eventConditions[eventIndex].conditions.splice(
      conditionIndex,
      1
    );
    setStepFilters(newData);
  };

  //Check if in the conversions page
  useEffect(() => {
    if (props.location.pathname.includes("conversions")) setIsConversion(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Load the conversions data when user revisit the page
  useEffect(() => {
    fetchConversions({ profileId: activeProfileId }).then((res) => {
      if (res.docs.length === 0) return;

      setConversionId(res.docs[0].id);
      const data = res.docs[0].data();
      if (!data) return;

      //loading the data
      setEnableRevenue(data.enableRevenue);
      setRevenueJsonPath(data.revenueJsonPath);
      setEnabled(data.enabled);
      setStepFilters([...JSON.parse(data.filters).pageContainers]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`
        funnel-steps-container bg-gray-100 small
      `}
    >
      <div className="row">
        {stepFilters &&
          stepFilters.map((step, index) => {
            return (
              <ConversionsStep
                invalidFields={
                  invalidFields[index]
                    ? invalidFields[index]
                    : {
                        includePageContainers: [],
                        total: 0,
                      }
                }
                enableRevenue={enableRevenue}
                onEnableRevenue={handleChangeEnableRevenue}
                enabled={enabled}
                onEnabled={handleEnabled}
                revenueJsonPath={revenueJsonPath}
                onRevenueJsonPath={handleChangeRevenueJsonPath}
                isConversion={isConversion}
                isFormProcessing={isFormProcessing}
                key={index}
                index={index}
                data={step}
                alert={alert}
                eventFilters={events}
                handleUpdateCondition={handleUpdateCondition}
                handleClickAddEventConditionRow={
                  step.eventConditions.length === 0
                    ? handleClickAddEventConditionRow
                    : undefined
                }
                handleClickDeleteEventConditionRow={
                  handleClickDeleteEventConditionRow
                }
                handleUpdateSubCondition={handleUpdateSubCondition}
                handleClickAddSubConditionRow={handleClickAddSubConditionRow}
                handleClickDeleteSubConditionRow={
                  handleClickDeleteSubConditionRow
                }
                handleSaveConversionPoint={handleSaveConversionPoint}
              />
            );
          })}
      </div>
    </div>
  );
}

ConversionsBuilder.propTypes = {};
ConversionsBuilder.defaultProps = {};

export default withRouter(ConversionsBuilder);
