import {
  firebaseApp,
  getAuth,
  Firestore,
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
} from "./index";

import uiApi from "./../../api/api-ui";

export const fetchUsersByProfileId = async (
  profileId,
  includeActivity = false
) => {
  if (!includeActivity) {
    return getDocs(
      query(
        collection(Firestore, "users"),
        where("profileIds", "array-contains", profileId)
      )
    );
  }

  return getDocs(
    query(
      collection(Firestore, "users"),
      where("profileIds", "array-contains", profileId)
    )
  ).then(async (res) => {
    const asyncCalls = res.docs.map(async (doc) => {
      const userDoc = doc;
      const userData = doc.data();
      return await getDocs(
        collection(Firestore, "users", doc.id, "activities")
      ).then((res) => {
        userData["activityCount"] = res.docs.length;
        userData["id"] = userDoc.id;
        return userData;
      });
    });

    return Promise.all(asyncCalls);
  });
};

export const fetchUsersListByProfileId = (profileId) => {
  return getDocs(
    query(
      collection(Firestore, "users"),
      where("profileIds", "array-contains", profileId)
    )
  );
};

export const fetchUserByEmail = (email, profileId) => {
  return getDocs(
    query(collection(Firestore, "users"), where("email", "==", email))
  );
};

export const fetchUser = (uid) => {
  return getDoc(doc(Firestore, "users", uid));
};

export const fetchUserAccess = (data) => {
  return getAuth(firebaseApp)
    .currentUser.getIdToken()
    .then((token) => {
      return uiApi.fetchUserAccess(token)
    });
};
