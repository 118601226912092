import React from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import { conditionFixture } from "./../../../fixtures/filters";
import { deepCopy } from "./../../../utils";

const operators = require("./../../../inc/operators");
const units = require("./../../../inc/units");
const { countries } = require("./../../../inc/country");

function Rule(props) {
  const {
    index,
    invalidFields,
    data,
    filters,
    showDelete,
    handleClickDeleteConditionRow,
    handleUpdateConditions,
  } = props;
  const { attribute, path, unit, operator, values } = data;

  const [availableOperators, setAvailableOperators] = React.useState([]);

  const availableUnits =
    filters[attribute] && filters[attribute].operatorType
      ? units[filters[attribute].operatorType]
      : null;

  const operatorType =
    filters[attribute] && filters[attribute]["operatorType"]
      ? filters[attribute]["operatorType"]
      : null;

  const getInvalidClass = (
    invalidFieldKey,
    containerIndex,
    valueIndex,
    type
  ) => {
    return (
      invalidFields &&
      invalidFields[invalidFieldKey] &&
      invalidFields[invalidFieldKey][containerIndex] &&
      invalidFields[invalidFieldKey][containerIndex][
        type === "value" ? `value${valueIndex}` : type
      ] &&
      "form-control-error"
    );
  };

  React.useEffect(() => {
    let filterKey =
      filters[attribute] && filters[attribute]["operatorType"]
        ? filters[attribute]["operatorType"]
        : null;
    let attributeOperators = filterKey ? operators[filterKey] : null;
    if (attributeOperators) {
      setAvailableOperators(attributeOperators);
    } else {
      setAvailableOperators([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);

  const onClickDeleteConditionRow = () => {
    handleClickDeleteConditionRow("user", null, props.index);
  };

  const handleChangeAttribute = (e) => {
    const val = e.target.value;
    let operatorType =
      filters[val] && filters[val]["operatorType"]
        ? filters[val]["operatorType"]
        : null;
    let attributeOperators = operatorType ? operators[operatorType] : null;
    const state = deepCopy(conditionFixture);
    state.attribute = e.target.value;

    // If unit is not supported
    // set to false
    if (!units[operatorType]) {
      state["unit"] = null;
    } else {
      // Select first unit in dropdown if unit is not set
      if (!unit) {
        state["unit"] = units[operatorType][0].key;
      }
    }

    if (e.target.value === "SessionType") {
      state["operator"] = "is";
    }

    if (attributeOperators && attributeOperators.length === 1) {
      state["operator"] = attributeOperators[0].key;
    }

    if (attributeOperators) {
      setAvailableOperators(attributeOperators);
    } else {
      setAvailableOperators([]);
    }
    handleUpdateConditions("user", null, props.index, null, state);
  };

  const handleChangeOperator = (e) => {
    handleUpdateConditions("user", null, props.index, null, {
      attribute,
      path,
      unit,
      operator: e.target.value,
      values,
    });
  };

  const handleChangeValue = (e) => {
    const state = { ...data };
    state.values[e.target.dataset.index] = e.target.value;
    handleUpdateConditions("user", null, props.index, null, state);
  };

  const handleChangeUnit = (e) => {
    handleUpdateConditions("user", null, props.index, null, {
      attribute,
      path,
      unit: e.target.value,
      operator,
      values,
    });
  };

  const handleClickAddValueField = (e) => {
    const state = { ...data };
    state.values.push("");
    handleUpdateConditions("user", null, props.index, null, state);
  };

  const handleClickRemoveValueField = (e) => {
    const state = { ...data };
    const index = parseInt(e.currentTarget.dataset.index, 0);
    if (index === 0) {
      state.values[0] = "";
      handleUpdateConditions("user", null, props.index, null, state);
    }
    if (state.values.length > 1) {
      state.values.splice(index, 1);
      handleUpdateConditions("user", null, props.index, null, state);
    }
  };

  return (
    <div className="rule">
      <div className="rule-group">
        {/** Attribute Field */}
        <div className="field-col">
          <select
            className="form-control form-control-sm"
            name="attribute"
            value={attribute}
            onChange={handleChangeAttribute}
          >
            <option value="">Choose an attribute</option>
            {Object.keys(filters).map((key) => {
              const filter = filters[key];
              return (
                <option key={filter.key} value={filter.key}>
                  {filter.label}
                </option>
              );
            })}
          </select>
        </div>

        {/** Operator Field */}
        {!!(attribute && availableOperators.length) && (
          <div className="field-col">
            {availableOperators.length === 1 && (
              <span className="mx-2">{operator}</span>
            )}
            {availableOperators.length > 1 && (
              <select
                disabled={
                  operatorType === "sessionType" ||
                  availableOperators.length === 1
                }
                className={`form-control form-control-sm ${
                  invalidFields["userConditions"][index].operator &&
                  "form-control-error"
                }`}
                name="operator"
                value={operator}
                onChange={handleChangeOperator}
              >
                {availableOperators.length > 1 && (
                  <option value="">Choose an operator</option>
                )}
                {availableOperators.map((op) => {
                  return (
                    <option key={op.key} value={op.key}>
                      {op.label}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        )}

        {/** Session Type Field */}
        {attribute === "SessionType" && (
          <div className="field-col value-field-container">
            <select
              className="form-control form-control-sm"
              name="value"
              data-index={0}
              value={values[0]}
              onChange={handleChangeValue}
            >
              <option value="">Select Session Type</option>
              <option value="new">new visitor</option>
              <option value="return">return visitor</option>
            </select>
          </div>
        )}

        {/** Value Field */}
        {!!(attribute && operator && attribute !== "SessionType") &&
          values.map((val, valueIndex) => {
            let inputType = "text";
            switch (operatorType) {
              case "number":
              case "duration":
                inputType = "number";
                break;
              default:
                break;
            }
            return (
              <React.Fragment key={valueIndex}>
                <div
                  className={`field-col value-field-container
                      ${availableUnits && "with-unit"}`}
                  key={valueIndex}
                >
                  <span className="conj">or</span>

                  {/* Country Dropdown Field */}
                  {attribute === "Country" && (
                    <select
                      className="form-control form-control-sm"
                      name="value"
                      data-index={valueIndex}
                      value={val}
                      onChange={handleChangeValue}
                    >
                      <option value="">Select Country</option>
                      {Object.keys(countries).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {countries[key]}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {/* Default Value Field */}
                  {!!(
                    attribute !== "Country" && attribute !== "SessionType"
                  ) && (
                    <input
                      type={inputType}
                      className={`form-control form-control-sm ${getInvalidClass(
                        "userConditions",
                        index,
                        valueIndex,
                        "value"
                      )}`}
                      name="value"
                      data-index={valueIndex}
                      value={val}
                      onChange={handleChangeValue}
                    />
                  )}
                  {!availableUnits && (
                    <Button
                      className="btn-delete"
                      data-index={valueIndex}
                      size="small"
                      icon="fas fa-times"
                      onClick={handleClickRemoveValueField}
                    ></Button>
                  )}
                </div>

                {/* Units Field */}
                {availableUnits && (
                  <div className="field-col unit-field-container">
                    <select
                      className="form-control form-control-sm"
                      value={unit}
                      onChange={handleChangeUnit}
                    >
                      {availableUnits.map((unit, index) => {
                        return (
                          <option key={index} value={unit.key}>
                            {unit.label}
                          </option>
                        );
                      })}
                    </select>
                    <Button
                      className="btn-delete"
                      data-index={valueIndex}
                      size="small"
                      icon="fas fa-times"
                      onClick={handleClickRemoveValueField}
                    ></Button>
                  </div>
                )}
              </React.Fragment>
            );
          })}

        {attribute && operator && attribute !== "SessionType" && (
          <div className="field-col add-btn-container">
            <Button
              variant="secondary"
              size="small"
              onClick={handleClickAddValueField}
            >
              or
            </Button>
          </div>
        )}
      </div>

      <div className="actions">
        {showDelete && (
          <Button
            icon="fas fa-times-circle"
            onClick={onClickDeleteConditionRow}
          ></Button>
        )}
      </div>
    </div>
  );
}

Rule.propTypes = {
  handleClickDeleteConditionRow: PropTypes.func,
};
Rule.defaultProps = {};

export default Rule;
