import React from "react";
import PropTypes from "prop-types";

const TableHeader = (props) => {
  const { columns } = props;
  return (
    <thead>
      <tr>
        {!!columns.length &&
          columns.map((column, index) => <th key={index}>{column.label}</th>)}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array,
};

export default TableHeader;
