import React from "react";
import { Button } from "./../../share/InsightUI";
import UrlEventRule from "./UrlEventRule";
import PropTypes from "prop-types";
import { initialConditions } from "./../UserData";

import "./style.scss";

const eventFilters = require("./../../../inc/eventFilters.json");

function UrlFilterPanel(props) {
  const {
    userConditions,
    setUserConditions,
    eventConditions,
    setEventConditions,
    excludeEventConditions,
    setExcludeEventConditions,
    editFieldOnly, // Only existing fields can be edited
  } = props;

  const setter = {
    user: setUserConditions,
    event: setEventConditions,
    excludedEvent: setExcludeEventConditions,
  };

  const getClonedData = (type) => {
    let data = [];
    switch (type) {
      case "user":
        data = [...userConditions];
        break;
      case "event":
        data = [...eventConditions];
        break;
      case "excludedEvent":
        data = [...excludeEventConditions];
        break;
      default:
        break;
    }

    return data;
  };

  const updateConditions = (type, id, data) => {
    const newData = getClonedData(type);
    newData[id] = {
      ...newData[id],
      ...data,
    };
    setter[type](newData);
  };

  const handleUpdateSubCondition = (type, eventId, conditionId, data) => {
    let newData = getClonedData(type);
    newData[eventId]["conditions"][conditionId] = data;
    setter[type](newData);
  };

  const handleClickAddEventConditionRow = () => {
    setEventConditions([
      ...eventConditions,
      JSON.parse(JSON.stringify(initialConditions.eventConditions[0])),
    ]);
  };

  const handleClickAddSubConditionRow = (type, id) => {
    let data = getClonedData(type);

    data[id]["conditions"].push({
      attribute: "",
      path: "",
      unit: "",
      operator: "",
      values: [""],
    });
    setter[type](data);
  };

  const handleClickDeleteConditionRow = (type, id) => {
    let newRules = [];
    switch (type) {
      case "user":
        newRules = [...userConditions];
        break;
      case "event":
        newRules = [...eventConditions];
        break;
      case "excludedEvent":
        newRules = [...excludeEventConditions];
        break;
      default:
        break;
    }
    if (newRules.length === 1) {
      return;
    }
    newRules.splice(id, 1);
    setter[type](newRules);
  };

  const handleClickDeleteSubConditionRow = (type, ruleId, conditionId) => {
    let data = getClonedData(type);

    data[ruleId]["conditions"].splice(conditionId, 1);
    setter[type](data);
  };

  const getValidConditionsCount = (conditions) => {
    let count = 0;
    /**
     * @TODO: add attribute check
     */
    conditions.forEach((condition) => {
      if (condition.values && condition.values.join("").trim()) {
        count += 1;
      }

      if (condition.conditions) {
        for (let i = 0, len = condition.conditions.length; i < len; i++) {
          const subCondition = condition.conditions[i];
          if (
            (subCondition.values && subCondition.values.join("").trim()) ||
            subCondition.attribute === "Any"
          ) {
            count += 1;
            break;
          }
        }
      }
    });
    return count;
  };

  return (
    <>
      <div className="filter-panel">
        {/* Event */}
        <div className="filter-row">
          <h3 className="row-header">
            <div className="label">
              <i className="fas fa-mouse-pointer"></i>
              <span>Include users which behaviour were...</span>
            </div>
            <div className="actions">
              <span>{`Event Filters (${getValidConditionsCount(
                eventConditions
              )})`}</span>
              {!editFieldOnly && (
                <Button
                  onClick={handleClickAddEventConditionRow}
                  icon="fas fa-plus"
                ></Button>
              )}
            </div>
          </h3>
          <div className="rules">
            {eventConditions.map((event, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && (
                    <div className="filter-separator">
                      <span className="text-gray-500">AND</span>
                    </div>
                  )}
                  <UrlEventRule
                    editFieldOnly={editFieldOnly}
                    id={index}
                    data={event}
                    filters={eventFilters}
                    conditionType="event"
                    initial={index === 0}
                    showDelete={eventConditions.length > 1}
                    handleUpdateCondition={updateConditions}
                    handleUpdateSubCondition={handleUpdateSubCondition}
                    handleClickDeleteConditionRow={
                      handleClickDeleteConditionRow
                    }
                    handleClickAddSubConditionRow={
                      handleClickAddSubConditionRow
                    }
                    handleClickDeleteSubConditionRow={
                      handleClickDeleteSubConditionRow
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

UrlFilterPanel.propTypes = {
  userConditions: PropTypes.array,
  setUserConditions: PropTypes.func,
  eventConditions: PropTypes.array,
  setEventConditions: PropTypes.func,
  excludeEventConditions: PropTypes.array,
  setExcludeEventConditions: PropTypes.func,
  editFieldOnly: PropTypes.bool,
};
UrlFilterPanel.defaultProps = {
  editFieldOnly: false,
};

export default UrlFilterPanel;
