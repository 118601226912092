import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../components/auth/FirebaseAuthContext";
import Alert from '../../../components/share/Alert';
import { updateUser, addLog } from './../../../lib/firebase';
import { UPDATE_USERNAME } from './../../../constants/log';
import { Form, Input, Button } from './../../../components/share/InsightUI';
import { nameValidator } from "../../../helpers/formValidators";

import {Formik} from 'formik';

const UpdateUserName = () => {
    
  const {authUser} = useContext(AuthContext);
  const [alert, setAlert] = useState({
    'show': false,
    'type':'',
    'message':'',
    'count': 0
  });
  const [initialValues, setInitialValues] = useState({
    displayName: authUser.user.displayName || ''
  });

  const handleSubmit = (values, { setSubmitting }) => {
    authUser.user.updateProfile({
      displayName: values.displayName
    }).then(()=>{
      updateUser({ 'name': values.displayName })
        .then(() => {
          setSubmitting(false);
          setInitialValues({
            displayName: values.displayName
          })
          addLog(UPDATE_USERNAME);
          setAlert({
            'show': true,
            'type': 'success',
            'message': 'Your name has been updated. Please click "Back" button to go back to your profile page.',
            'count': alert.count + 1
          });
        }).catch((error) => {
          setSubmitting(false);
          setAlert({
            'show': true,
            'type': 'danger',
            'message': error.message,
            'count': alert.count + 1
          });
        })
    });
  }

  return (
    <>
      <div className="row">      
        <Alert show={alert.show} type={alert.type} message={alert.message} count={alert.count} />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Formik
                initialValues={
                  initialValues
                }
                validate={nameValidator}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  dirty,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                    <Form
                      className="user"
                      handleSubmit={handleSubmit}
                    >
                      <Input inline columnwidths={[2, 4]}
                        isInvalid={!!(touched.displayName && errors.displayName)}
                        invalidFeedback={errors.displayName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="displayName"
                        value={values.displayName || ''}
                        label="Full Name"
                        placeholder="Enter your full name" />
                      <div className="form-group row">
                        <div className="col-sm-2">&nbsp;</div>
                        <div className="col-sm-4">
                          <Button
                            disabled={isSubmitting || !dirty}
                            isprocessing={isSubmitting}
                            type="submit" variant="primary"
                          >
                            Submit
                          </Button>
                          {` `}
                          <Link to="/user/profile"
                            className="btn btn-secondary">Back</Link>
                        </div>
                      </div>
                    </Form>
                  )}
              </Formik>
            </div>
          </div>
        
        </div>
      </div>
  
    </>
  );
};

export default UpdateUserName;