import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Button } from "../../share/InsightUI";

const PageDownloadIcon = ({ isDownloading, onDownload }) => {
  return (
    <Fragment>
      <Button
        variant="plain"
        className="dropdown-item"
        onClick={onDownload}
        disabled={isDownloading}
      >
        {isDownloading ? (
          <>
            <span className="fa fa-spinner spin mr-2" />
            Downloading
          </>
        ) : (
          <>
            <BsFileEarmarkPdfFill size={18} className="mt-n1 mr-2" />
            Download PDF
          </>
        )}
      </Button>
    </Fragment>
  );
};

PageDownloadIcon.propTypes = {
  isDownloading: PropTypes.bool,
  onDownload: PropTypes.func,
};

export default PageDownloadIcon;
