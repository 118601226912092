import React from "react";
import { Button } from "../../../share/InsightUI";

const FunnelFoldersCreator = ({
  isFolderValid,
  handleClickFolderCreate,
  handleCreateFolderInput,
  createFolderInput,
  isCreatorEmptyInvalid,
}) => {
  return (
    <div className="folders-create">
      <input
        type="search"
        className={`form-control form-control-sm ${
          isCreatorEmptyInvalid ? "is-invalid" : ""
        }`}
        placeholder="Create Folder"
        value={createFolderInput}
        onChange={handleCreateFolderInput}
      />
      <Button
        className="ml-2"
        variant="primary"
        size="small"
        onClick={handleClickFolderCreate}
        disabled={!isFolderValid}
      >
        Create
      </Button>
    </div>
  );
};

export default FunnelFoldersCreator;
