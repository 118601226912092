import React, { Fragment } from "react";
import useTableFilter from "./useTableFilter";
import { BsFunnelFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from "react-tooltip";

const EntryMatchComparisonTable = ({
  data,
  widget,
  dataLabel,
  comparedDataLabel,
}) => {
  const { handleCellClick } = useTableFilter();

  return (
    <Fragment>
      <table
        className="table table-hover dashboard-table"
        id={`table-${widget}`}
      >
        <thead className="fixed-table-header">
          <tr>
            {data[0]?.map((cell, cellIndex) => (
              <th key={cellIndex}>
                <strong>{cell}</strong>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.slice(1).map((entry, index) => (
            <Fragment key={index}>
              <tr
                className={`text-dark font-weight-bold table-row ${
                  index % 2 === 0 ? "zebra-dark" : ""
                }`}
              >
                {entry.label ? (
                  <td colSpan="5">
                    {!!widget && (
                      <button
                        className="filter-icon-container"
                        value={entry.label}
                        onClick={() => handleCellClick(entry.label, widget)}
                        data-tooltip-id="compare-table-tooltip"
                        data-tooltip-content="Click to filter"
                      >
                        <BsFunnelFill className="filter-icon" />
                      </button>
                    )}
                    <span
                      className="comparison-cell-ellipsis"
                      data-tooltip-id="compare-table-tooltip"
                      data-tooltip-content={entry.label}
                    >
                      {entry.label}
                    </span>
                  </td>
                ) : null}
              </tr>
              {entry.rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={
                    (index % 2 === 0 ? "zebra-dark" : "") +
                    (rowIndex % 6 < 3 ? " zebra-dark-row" : "")
                  }
                >
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      {cellIndex === 0 &&
                      (cell.startsWith("d/") || cell.startsWith("c/")) ? (
                        <>
                          <div
                            className={`strip ${
                              cell.startsWith("d/")
                                ? "strip-blue"
                                : "strip-orange"
                            } mr-2`}
                          ></div>
                          {cell.startsWith("d/")
                            ? dataLabel
                            : comparedDataLabel}
                        </>
                      ) : (
                        cell
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
      <ReactTooltip id="compare-table-tooltip" className="tooltip-lg" />
    </Fragment>
  );
};

export default EntryMatchComparisonTable;
