import React, {useEffect, useState} from "react";

const Alert = ({show, type, message, count, handleVisibility, allowClose=true}) => {

  const [visibility, setVisibility] = useState(show);

    useEffect(() => {
      setVisibility(show);
    },[show,count]);

    const handleClose = (e)=>{
      if(handleVisibility){
        handleVisibility(false);
      } else {
        setVisibility(false);
      }
    }

    return (
      <>
        {(visibility)?(
            <div className="col">
              <div className={"alert shadow alert-"+type} role="alert">
                {message}
                <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                  {allowClose && 
                    <span aria-hidden="true">&times;</span>
                  }
                </button>
              </div>
            </div>
        ):(<></>)}
      </>
    );
    

}

export default Alert;