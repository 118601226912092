import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './../../share/InsightUI';
import './style.scss';

function OverlayScreen(props) {

  const {
    handleClickCancel,
    title
  } = props;

  React.useEffect(() => {
    document.body.classList.add('locked');

    return () => {
      document.body.classList.remove('locked');
    }
  })

  return (
    <div
      className={`overlay-screen ${props.className}`}
    >
      <div className="title-bar">
        <h1 className="title">{title}</h1>
        <div className="funnel-name mr-auto">
        </div>
        <Button
          onClick={handleClickCancel}
          className="btn-done"
        >
          Done
        </Button>
      </div>
      {props.children}
    </div>
  );
}

OverlayScreen.propTypes = {
  funnelId: PropTypes.number,
  handleClickCancel: PropTypes.func
}
OverlayScreen.defaultProps = {
  funnelId: null
}

export default OverlayScreen;