import React from "react";
import {
    resetPassword
} from './../../../../lib/firebase';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Alert } from './../../../../components/share/InsightUI';
import { passwordResetValidator } from './../../../../helpers/formValidators';
import { Formik } from 'formik';

import './../../common.scss';

const ResetPassword = (props) => {
    const [isResetSuccess, setIsResetSuccess] = React.useState(false);

    // Reset the user's password
    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        resetPassword(props.code, values.password, ()=>{
            setSubmitting(false);
            setIsResetSuccess(true);
        }, (error)=>{
            props.setError('There was an error while changing your password')
            setSubmitting(false);
        })
    }

    return (
        isResetSuccess ?
        <>
            <h1 className="h4 text-gray-900 mt-3 mb-4">Password changed</h1>
            <Alert variant="success">
                You can now <Link to="/signin">sign in</Link> with your new password
            </Alert>
        </> :
        <>
            <h1 className="h4 text-gray-900 mt-4 mb-5">Reset your Password</h1>
            <div className="signup-form">
                <Formik
                    initialValues={{ password: '', password2: '' }}
                    validate={passwordResetValidator}
                    validateOnBlur={false}
                    validateOnChange={true}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <Form
                                className="user"
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting || props.isBusy}
                            >
                                {props.signinError &&
                                    <Alert variant="danger">{props.signinError}</Alert>
                                }
                                <Input
                                    required
                                    isInvalid={!!(touched.password && errors.password)}
                                    invalidFeedback={errors.password}
                                    className="form-control-user"
                                    name="password"
                                    placeholder="New Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    value={values.password} />
                                <Input
                                    required
                                    isInvalid={!!(touched.password2 && errors.password2)}
                                    invalidFeedback={errors.password2}
                                    className="form-control-user"
                                    name="password2"
                                    placeholder="Confirm Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    value={values.password2} />

                                <Button type="submit"
                                    className="btn-user btn-block"
                                    variant="primary">Reset Password</Button>
                            </Form>
                        )}
                </Formik>
            </div>
        </>
    );
};

export default ResetPassword;