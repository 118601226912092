import React, { useContext, useState, useEffect, createContext } from "react";
import { UserDataContext } from "./../components/app/UserData";
import { fetchSegments } from "./../lib/firebase/segment";

export const SegmentsContext = createContext();

export const SegmentsProvider = ({ children }) => {
  const { activeProfileId } = useContext(UserDataContext);
  const [segments, setSegments] = useState({});
  const [defaultSegments, setDefaultSegments] = useState({}); // store the initial default segments, do not change elsewhere
  const [selectedSegments, setSelectedSegments] = useState({});
  const [unselectedSegments, setUnselectedSegments] = useState({});
  const [isInitialSegmentsLoaded, setIsInitialSegmentsLoaded] = useState(false);
  const [isCachedSegmentsLoaded, setIsCachedSegmentsLoaded] = useState(false);
  const [comparedSelectedSegments, setComparedSelectedSegments] = useState({});

  useEffect(() => {
    if (activeProfileId) {
      let initialSelectedSegments = {};
      let initialUnselectedSegments = {};
      let cachedSegments = {};
      try {
        cachedSegments = JSON.parse(cachedSegments);
        initialSelectedSegments = cachedSegments.segments.selected;
        initialUnselectedSegments = cachedSegments.segments.unselected;
      } catch {
        cachedSegments = null;
      }

      if (cachedSegments && cachedSegments.hasOwnProperty("profileId")) {
        // Wipe segments from localStorage if user switches profile
        if (cachedSegments.profileId !== activeProfileId) {
          setSelectedSegments({});
          setDefaultSegments({});
          setUnselectedSegments({});
          localStorage.removeItem("segments");
        } else {
          setSelectedSegments({ ...initialSelectedSegments });
          setDefaultSegments({ ...initialSelectedSegments });
          setUnselectedSegments({ ...initialUnselectedSegments });
        }
      }
      setIsCachedSegmentsLoaded(true);

      // Load all segments
      if (!isInitialSegmentsLoaded) {
        fetchSegments({ profileId: activeProfileId }).then((res) => {
          const segments = {};
          const defaultSelectedSegments = {};
          Object.keys(res).forEach((id) => {
            const data = res[id];

            // Re-check if each persisted segment exists
            Object.keys(initialSelectedSegments).forEach((key) => {
              if (!res[key]) {
                delete initialSelectedSegments[key];
              }
            });

            // If segment.default==true and was not explicitly unselected
            // set it as selected by default
            if (data.default && !unselectedSegments[id]) {
              defaultSelectedSegments[id] = true;
            }

            segments[id] = data;
            segments[id]["id"] = id;
          });
          setSegments(segments);
          setSelectedSegments({
            ...initialSelectedSegments,
            ...defaultSelectedSegments,
          });
          setDefaultSegments({
            ...initialSelectedSegments,
            ...defaultSelectedSegments,
          });
          setIsInitialSegmentsLoaded(true);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProfileId]);

  const values = {
    segments,
    setSegments,
    defaultSegments,
    selectedSegments,
    setSelectedSegments,
    unselectedSegments,
    setUnselectedSegments,
    isCachedSegmentsLoaded,
    isInitialSegmentsLoaded,
    setIsInitialSegmentsLoaded,
    comparedSelectedSegments,
    setComparedSelectedSegments,
  };

  return (
    <SegmentsContext.Provider value={values}>
      {children}
    </SegmentsContext.Provider>
  );
};
