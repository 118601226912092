import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { UserDataContext } from "../../../UserData";
import { SegmentsContext } from "../../../../../context/Segments";
import { Button } from "../../../../share/InsightechUI";
import {
  deepCopy,
  getFilterCount,
  seggregatePageContainers,
} from "../../../../../utils";

const TagsGroup = (props) => {
  const { id, label } = props;
  const { appliedFilters, isSegmentCompareOn } = useContext(UserDataContext);
  const { segments, selectedSegments, comparedSelectedSegments } =
    useContext(SegmentsContext);
  const [filtersTotal, setFiltersTotal] = useState(0);
  const [selectedSegmentsNames, setSelectedSegmentsNames] = useState(null);
  const [comparedSegmentsNames, setComparedSegmentsNames] = useState(null);

  const getFilterTotal = (appliedFilters) => {
    if (!Object.keys(appliedFilters).length) return 0;

    const filters = deepCopy(appliedFilters);
    const [includedPageContainers, excludedPageContainers] =
      seggregatePageContainers(filters.pageContainers);
    const userConditions = filters.userConditions;
    const userCount = getFilterCount("user", userConditions);
    const eventCount = getFilterCount("event", includedPageContainers);
    const excludedEventCount = getFilterCount(
      "excludedEvent",
      excludedPageContainers
    );
    return userCount + eventCount + excludedEventCount;
  };

  const getSegmentsNameArray = (selectedSegments, segments) => {
    if (!Object.keys(selectedSegments).length) return null;

    const segmentNames = Object.keys(selectedSegments)
      .filter((segmentId) => segments[segmentId])
      .map((segmentId) => segments[segmentId].name);

    return segmentNames;
  };

  useEffect(() => {
    setFiltersTotal(getFilterTotal(appliedFilters));
    setSelectedSegmentsNames(getSegmentsNameArray(selectedSegments, segments));
    setComparedSegmentsNames(
      getSegmentsNameArray(comparedSelectedSegments, segments)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters, selectedSegments, comparedSelectedSegments]);

  return (
    <div className="modal-form-row">
      <div className="row-label">
        <label htmlFor={id}>{label}</label>
        {isSegmentCompareOn && (
          <Button size="badge" color="orange">
            Compare Mode Activated
          </Button>
        )}
      </div>
      <div className="row-tags">
        <Button
          size="chip"
          readOnly={true}
        >{`Filters applied: ${filtersTotal}`}</Button>
        {selectedSegmentsNames?.length > 0 &&
          selectedSegmentsNames.map((name, idx) => (
            <Button color="success" size="chip" readOnly={true} key={idx}>
              {name}
            </Button>
          ))}
        {comparedSegmentsNames?.length > 0 &&
          comparedSegmentsNames.map((name, idx) => (
            <Button color="orange" size="chip" readOnly={true} key={idx}>
              {name}
            </Button>
          ))}
        {isSegmentCompareOn &&
          !Object.keys(comparedSelectedSegments).length && (
            <span className="plain-text">
              Compare To: All Traffic (no segment selected)
            </span>
          )}
      </div>
    </div>
  );
};

export default TagsGroup;
