import { initializeApp } from "firebase/app";
import {
  doc,
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  orderBy,
  where,
  documentId,
  limit,
  startAfter,
  increment,
  deleteField,
} from "firebase/firestore";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAuth,
  setPersistence,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  checkActionCode,
  applyActionCode,
  confirmPasswordReset,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
  updateProfile,
} from "firebase/auth";
import { isFunction } from "./../../utils";
const config = require("./../../config/firebase-config.json");

const firebaseApp = initializeApp(config);
const Firestore = getFirestore(firebaseApp);
const gmailProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");

const updateUser = (data, uid) => {
  if (typeof uid === "string" && uid.length > 0) {
  } else {
    uid = getAuth(firebaseApp).currentUser.uid;
  }
  if (!uid) {
    return;
  }

  var userDocRef = doc(Firestore, "users", uid);

  return setDoc(userDocRef, data, { merge: true });
};

const createRef = (ref) => {
  return doc(Firestore, ref);
};

const addLog = async (action, uid, successCallback, failureCallback) => {
  var dt = new Date();
  var data = {
    action: action,
    timestamp: dt.getTime(),
    time: dt,
    "user-agent": navigator.userAgent,
  };

  if (typeof uid === "string" && uid.length > 0) {
  } else {
    uid = getAuth(firebaseApp).currentUser.uid;
  }

  var userDocRef = doc(Firestore, "users", uid);

  try {
    await setDoc(userDocRef, { activityCount: increment(1) }, { merge: true });
  } catch (error) {
    if (typeof failureCallback !== "undefined") {
      failureCallback(error);
    }
  }

  try {
    await setDoc(doc(userDocRef, "activities", "" + dt.getTime()), data);
  } catch (error) {
    setDoc(userDocRef, { activityCount: increment(-1) }, { merge: true });
    if (typeof failureCallback !== "undefined") {
      failureCallback(error);
    }
  }

  if (typeof successCallback !== "undefined") successCallback();
};

const signinUser = (
  email,
  password,
  persistent,
  successCallback,
  failureCallback
) => {
  const persistence = persistent
    ? browserLocalPersistence
    : browserSessionPersistence;

  setPersistence(getAuth(firebaseApp), persistence).then(() => {
    signInWithEmailAndPassword(getAuth(firebaseApp), email, password)
      .then((response) => {
        if (!response.user.photoURL) {
          if (isFunction(successCallback)) {
            successCallback(response);
          }
          return;
        }
        updateUser(
          {
            photoURL: response.user.photoURL,
          },
          response.user.uid
        ).then(() => {
          if (isFunction(successCallback)) {
            successCallback(response);
          }
        });
      })
      .catch((error) => {
        if (isFunction(failureCallback)) {
          failureCallback(error);
        }
      });
  });
};

const registerUserWithEmail = (
  email,
  password,
  successCallback,
  failureCallback
) => {
  createUserWithEmailAndPassword(getAuth(firebaseApp), email, password)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const signinWithGmail = (successCallback, failureCallback) => {
  signInWithPopup(getAuth(firebaseApp), gmailProvider)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const signinWithMicrosoft = (successCallback, failureCallback) => {
  signInWithPopup(getAuth(firebaseApp), microsoftProvider)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const handleSuccessSignin = (authResult, successCallback, failureCallback) => {
  var dt = new Date();
  const userData = {
    name: authResult.user.displayName,
    email: authResult.user.email,
    lastLoginTimestamp: dt.getTime(),
    lastLoginTime: dt,
  };
  if (authResult && authResult.user && authResult.user.photoURL) {
    userData["photoURL"] = authResult.user.photoURL;
  }
  updateUser(userData);
  addLog("signed in", authResult.user.uid, successCallback, failureCallback);
};

const requestPasswordRecovery = (email, successCallback, failureCallback) => {
  sendPasswordResetEmail(getAuth(firebaseApp), email)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const verifyActionCode = (code, successCallback, failureCallback) => {
  checkActionCode(getAuth(firebaseApp), code)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const applyActionCode1 = (code, successCallback, failureCallback) => {
  applyActionCode(getAuth(firebaseApp), code)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const resetPassword = (code, password, successCallback, failureCallback) => {
  confirmPasswordReset(getAuth(firebaseApp), code, password)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

export {
  firebaseApp,
  Firestore,
  getDoc,
  getDocs,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  getAuth,
  addLog,
  updateUser,
  signinUser,
  registerUserWithEmail,
  signinWithGmail,
  signinWithMicrosoft,
  handleSuccessSignin,
  requestPasswordRecovery,
  verifyActionCode,
  resetPassword,
  applyActionCode1 as applyActionCode,
  createRef,
  query,
  orderBy,
  where,
  documentId,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  limit,
  startAfter,
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
  updateProfile,
  deleteField,
};
