import React from "react";
import PathingEventCondition from "./PathingEventCondition";
import PropTypes from "prop-types";

const PathingContainer = (props) => {
  const {
    index,
    data,
    onPagePath,
    onAddedPathField,
    onDeletedPath,
    onOperator,
  } = props;

  return (
    <div className="pathing-container bg-gray-200">
      <div className="container-body">
        {/*  event condition  */}
        <PathingEventCondition
          index={index}
          data={data}
          onPagePath={onPagePath}
          onAddedPathField={onAddedPathField}
          onDeletedPath={onDeletedPath}
          onOperator={onOperator}
        />
      </div>
    </div>
  );
};

PathingContainer.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  onPagePath: PropTypes.func,
  onAddedPathField: PropTypes.func,
  onDeletedPath: PropTypes.func,
  onOperator: PropTypes.func,
};

export default PathingContainer;
