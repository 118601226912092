import React, { useState, useEffect, useContext } from "react";
import SimpleMDE from "react-simplemde-editor";
import CreatableSelect from "react-select/creatable";
import { Button, Modal, RadioButton } from "./../../share/InsightUI";
import { saveNote, fetchNoteById } from "./../../../lib/firebase/notes";
import { SegmentsContext } from "../../../context/Segments";
import { UserDataContext } from "../UserData";
import { NOTE_PRIVATE, NOTE_PUBLIC } from "../../../constants/info";
import "easymde/dist/easymde.min.css";
import "./style.scss";

const analyticsMode = require("../../../inc/analyticsModes.json");

//make mode tag in node modal be consistent with node card
const getTagMode = (notes, noteId, activeTab, processAnalyticsMode) => {
  const note = notes.byId[noteId];
  const tagMode = note?.analyticsMode || processAnalyticsMode;
  const isAnalyticTab = activeTab === "analytics";

  if (note && tagMode) return tagMode;
  if (!note && isAnalyticTab && tagMode) return tagMode;
  if (!note && !isAnalyticTab) return "moment";
};

const getTagModeLabel = (tagMode) => {
  return `${analyticsMode[tagMode].label}${
    analyticsMode[tagMode].subLabel
      ? ` - ${analyticsMode[tagMode].subLabel}`
      : ""
  }`;
};

//style for creatableSelect
const customStyles = {
  multiValue: (provided, { data: { isFixed } }) =>
    isFixed
      ? {
          ...provided,
          backgroundColor: "#1cc88a",
          paddingLeft: 8,
          paddingRight: 8,
        }
      : {
          ...provided,
        },
  multiValueLabel: (provided, { data: { isFixed } }) =>
    isFixed
      ? {
          ...provided,
          color: "white",
        }
      : {
          ...provided,
        },
  multiValueRemove: (provided, { data: { isFixed } }) =>
    isFixed
      ? {
          ...provided,
          display: "none",
        }
      : {
          ...provided,
        },
};

function NoteModal(props) {
  const {
    profileId,
    progress,
    pageviewKey,
    visitorId,
    sessionNumber,
    pageURL,
    pageTitle,
    handleClose,
    noteId,
    setNotes,
    notes,
    processAnalyticsMode,
    filterConditions,
    activeTab,
    clickmapDataFilter,
  } = props;

  const tagMode = getTagMode(notes, noteId, activeTab, processAnalyticsMode);
  const tagModeLabel = getTagModeLabel(tagMode);
  const fixedTag = {
    label: tagModeLabel,
    value: tagMode,
    isFixed: true,
  };

  const filter = filterConditions[0].conditions[0];
  const [tags, setTags] = useState([fixedTag]);
  const [note, setNote] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [sharedButtonSelected, setSharedButtonSelected] = useState("public");
  const [objectAddToNote, setObjectAddToNote] = useState({});
  const { selectedSegments } = useContext(SegmentsContext);
  const { startDate, endDate } = useContext(UserDataContext);

  const handleChangeTags = (values) => {
    setTags(values || [fixedTag]);
  };

  const handleChangeNote = (value) => {
    setNote(value);
  };

  const handleRadioChange = (e) => {
    setSharedButtonSelected(e.currentTarget.value);
  };

  const handleClickSubmit = () => {
    // x profileId
    // x ownerId
    // x description
    // x noteTime (the time in the pageview)
    // x pageviewKey (the current version is based on pageview)
    // x pageTitle
    // x pageUrl
    // x filter
    // x segments
    // x processAnalyticsMode
    // x shared
    // x startDate
    // x endDate
    // x version
    // x creationTime
    // x updateTime (last update)
    // x tags
    // x clickmapDataFilter
    let noteData = {};
    if (note.trim() === "") return;
    setIsBusy(true);
    if (noteId) {
      noteData = {
        profileId,
        description: note,
        pageviewKey: pageviewKey,
        sessionKey: `${visitorId}.${sessionNumber}`,
        pageTitle,
        pageURL,
        ...objectAddToNote,
        tags: tags.slice(1).map((data) => {
          return data.value;
        }),
      };
    } else {
      noteData = {
        profileId,
        description: note,
        noteTime: progress,
        pageviewKey: pageviewKey,
        sessionKey: `${visitorId}.${sessionNumber}`,
        pageTitle,
        pageURL,
        ...objectAddToNote,
        tags: tags.slice(1).map((data) => {
          return data.value;
        }),
      };
    }

    saveNote(noteData, profileId, noteId).then((res) => {
      setIsBusy(false);
      handleClose();

      if (noteId) {
        // Edit
        const updatedNotes = {
          ...notes,
          byId: {
            ...notes.byId,
            [noteId]: {
              ...notes.byId[noteId],
              ...noteData,
            },
          },
        };
        setNotes(updatedNotes);
      } else {
        // Create
        fetchNoteById(profileId, res.id).then((res) => {
          const newNote = res.data();
          const updatedNotes = {
            ...notes,
            byId: {
              ...notes.byId,
              [res.id]: newNote,
            },
          };
          updatedNotes.all.push(res.id);
          setNotes(updatedNotes);
        });
      }
    });
  };

  useEffect(() => {
    if (noteId) {
      const initialData = noteId ? notes.byId[noteId] : null;

      if (initialData) {
        setNote(initialData.description);
        const updatedTags = [fixedTag].concat(
          initialData.tags.map((tag) => {
            return {
              label: tag,
              value: tag,
              isFixed: false,
            };
          })
        );
        setTags(updatedTags);
        setSharedButtonSelected(initialData.shared ? "public" : "private");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteId]);

  //Update note data to save when new note propertys change
  useEffect(() => {
    let updatedObjectAddToNotes = {
      version: "0.0",
    };

    if (activeTab) {
      if (noteId) {
        updatedObjectAddToNotes = {
          shared: sharedButtonSelected === "public" ? true : false,
          analyticsMode: tagMode,
          version: "1.0",
        };
      } else
        updatedObjectAddToNotes = {
          filter: filter,
          segments: Object.keys(selectedSegments),
          shared: sharedButtonSelected === "public" ? true : false,
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          analyticsMode: tagMode,
          clickmapDataFilter: clickmapDataFilter.value,
          version: "1.0",
        };
    }

    setObjectAddToNote({ ...updatedObjectAddToNotes });
  }, [
    processAnalyticsMode,
    sharedButtonSelected,
    selectedSegments,
    filter,
    startDate,
    endDate,
    activeTab,
    noteId,
    tagMode,
    clickmapDataFilter.value,
  ]);

  return (
    <Modal
      className="note-modal modal-form-wrapper"
      handleClose={handleClose}
      isVisible={true}
      width={600}
      title="Create a note"
      footer={
        <>
          <Button
            disabled={isBusy}
            onClick={handleClose}
            variant="secondary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={isBusy}
            onClick={handleClickSubmit}
            variant="primary"
            size="small"
          >
            {isBusy && (
              <>
                <span className="fa fa-spinner spin"></span>
                {` `}
              </>
            )}
            Save Note
          </Button>
        </>
      }
    >
      <div>
        <p className="small">
          This note will save the replay exactly as is, with the selected
          analytics report, segments, and date ranges applied.
        </p>
      </div>
      <div className="modal-form-row">Description*</div>
      <SimpleMDE
        options={{
          toolbar: false,
        }}
        value={note}
        onChange={handleChangeNote}
      />
      <div className="modal-form-row">
        <p className="small">Tags</p>
        <CreatableSelect
          isMulti
          value={tags}
          styles={customStyles}
          onChange={handleChangeTags}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className="modal-form-row">
        <div className="modal-form-col-30">Sharable:</div>
        <div className="modal-form-col-70 radio-button-container">
          <RadioButton
            id="public"
            label="Public"
            value="public"
            isSelected={sharedButtonSelected === "public"}
            changed={handleRadioChange}
            tooltip={NOTE_PUBLIC}
          />
          <RadioButton
            id="private"
            label="Private"
            value="private"
            isSelected={sharedButtonSelected === "private"}
            changed={handleRadioChange}
            tooltip={NOTE_PRIVATE}
          />
        </div>
      </div>
    </Modal>
  );
}

NoteModal.propTypes = {};
NoteModal.defaultProps = {};

export default NoteModal;
