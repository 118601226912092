import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

function Button(props) {
  const { variant, size, icon, active, innerRef, ...rest } = props;
  let classNames = props.className ? props.className : "";
  let variantClass = "";
  let sizeClass = "";
  let type = !props.type ? "button" : props.type;
  let activeClass = active ? "active" : "";
  switch (variant) {
    case "primary":
      variantClass = "btn-primary";
      break;
    case "secondary":
      variantClass = "btn-secondary";
      break;
    case "outline-secondary":
      variantClass = "btn-outline-secondary";
      break;
    case "outline-primary":
      variantClass = "btn-outline-primary";
      break;
    case "outline-dark":
      variantClass = "btn-outline-dark";
      break;
    case "danger":
      variantClass = "btn-danger";
      break;
    case "success":
      variantClass = "btn-success";
      break;
    case "link":
      variantClass = "btn-link";
      break;
    case "plain":
      variantClass = "btn-plain";
      break;
    case "accept":
      variantClass = "btn-accept";
      break;
    case "reject":
      variantClass = "btn-reject";
      break;
    case "menu":
      variantClass = "btn-menu";
      break;
    case "btn-grey":
      variantClass = "btn-grey";
      break;
    default:
      variantClass = "btn-default";
      break;
  }

  switch (size) {
    case "small":
      sizeClass = "btn-sm";
      break;
    case "x-small":
      sizeClass = "btn-xs";
      break;
    default:
      sizeClass = "";
      break;
  }

  return (
    <button
      {...rest}
      ref={innerRef}
      type={type}
      className={`btn ${variantClass} ${classNames} ${sizeClass} ${activeClass}`}
    >
      {icon && <i className={`${icon} btn-icon`}></i>}
      {props.children && <span className="btn-text">{props.children}</span>}
    </button>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large", "x-small"]),
};

Button.defaultProps = {};

export default Button;
