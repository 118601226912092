import React from "react";

const ComparisonTable = ({ data, remainData, widget }) => {
  return (
    <table className="table table-hover dashboard-table" id={`table-${widget}`}>
      <thead className="fixed-table-header">
        <tr>
          {data[0]?.map((row, rowIndex) => (
            <th key={rowIndex}>
              {" "}
              <strong>{row}</strong>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <>
          {data?.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex} className={rowIndex % 6 < 3 ? "zebra-dark" : ""}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>
                  {cellIndex === 0 &&
                  (cell.startsWith("d/") || cell.startsWith("c/")) ? (
                    <>
                      <div
                        className={`strip ${
                          cell.startsWith("d/") ? "strip-blue" : "strip-orange"
                        } mr-2`}
                      ></div>
                      {cell.substring(2)}
                    </>
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
          {remainData?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={
                (rowIndex % 2 === 0 && (data.length - 1) % 6 === 0) || //even
                (rowIndex % 2 !== 0 && (data.length - 1) % 6 !== 0) //odd
                  ? "zebra-dark"
                  : ""
              }
            >
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>
                  {cellIndex === 0 &&
                  (cell.startsWith("d/") || cell.startsWith("c/")) ? (
                    <>
                      <div
                        className={`strip ${
                          cell.startsWith("d/") ? "strip-blue" : "strip-orange"
                        } mr-2`}
                      ></div>
                      {cell.substring(2)}
                    </>
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};

export default ComparisonTable;
