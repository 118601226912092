import React from "react";
import "./style.scss";

const Button = ({
  children,
  color = "default",
  size = "medium",
  icon: Icon,
  dropdownArrow: DropdownArrow,
  onClick,
  readOnly = false,
  disabled,
  ...rest
}) => {
  return (
    <button
      className={`ui-button button-${color} button-${size} ${
        readOnly ? "read-only" : ""
      } ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {!!Icon && <Icon className="button-icon" />}
      {children}
      {!!DropdownArrow && (
        <span className="button-dropdown-arrow">
          <DropdownArrow className="button-dropdown-arrow" />
        </span>
      )}
    </button>
  );
};

export default Button;
