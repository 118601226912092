import React from "react";
import PathingContainer from "./PathingContainer";
import PropTypes from "prop-types";
import { Button } from "../../share/InsightUI";

const PathingStep = (props) => {
  const {
    pathingSteps,
    index,
    isValid,
    onStepArrow,
    onDeletedStep,
    onStepName,
    onPagePath,
    onAddedPathField,
    onDeletedPath,
    onOperator,
  } = props;
  const renderArrow = (arrow) => {
    if (arrow === "down") {
      return pathingSteps.length - 1 === index ? (
        <Button disabled>
          <span className="fa fa-arrow-down" />
        </Button>
      ) : (
        <Button onClick={() => onStepArrow(index)}>
          <span className="fa fa-arrow-down" />
        </Button>
      );
    }
    if (arrow === "up") {
      return index === 0 ? (
        <Button disabled>
          <span className="fa fa-arrow-up" />
        </Button>
      ) : (
        <Button onClick={() => onStepArrow(index - 1)}>
          <span className="fa fa-arrow-up" />
        </Button>
      );
    }
    return;
  };

  return (
    <div
      className={`col-md-8 offset-md-2 step-col ${
        isValid ? "valid" : "invalid"
      }`}
    >
      <div className="pathing-step">
        <div className="counter-col">
          <div className="counter">{index + 1}</div>
        </div>
        <span
          className={`line
            ${
              pathingSteps.length > 1 &&
              pathingSteps.length - 1 !== index &&
              "show"
            }
          `}
        ></span>
        {renderArrow("down")}
        {renderArrow("up")}
        <div className="pathing-step-form">
          <input
            className={`form-control ${
              (!pathingSteps[index].name ||
                pathingSteps[index].name.includes(">")) &&
              "form-control-error"
            }`}
            placeholder="Page Group Name"
            data-stepid={index}
            value={pathingSteps[index].name}
            onChange={onStepName}
            type="text"
          />
          <div className="rule-wrapper">
            <PathingContainer
              index={index}
              data={pathingSteps[index]}
              onPagePath={onPagePath}
              onAddedPathField={onAddedPathField}
              onDeletedPath={onDeletedPath}
              onOperator={onOperator}
            />
          </div>
        </div>
        <Button onClick={() => onDeletedStep(index)} className="btn-delete">
          <span className="fa fa-times" size="small"></span>
        </Button>
      </div>
    </div>
  );
};

PathingStep.propTypes = {
  pathingSteps: PropTypes.array,
  index: PropTypes.number,
  isValid: PropTypes.object,
  onStepArrow: PropTypes.func,
  onDeletedStep: PropTypes.func,
  onStepName: PropTypes.func,
  onPagePath: PropTypes.func,
  onAddedPathField: PropTypes.func,
  onDeletedPath: PropTypes.func,
  onOperator: PropTypes.func,
};

export default PathingStep;
