import React, { useContext, useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import FilterPanel from "./../FilterPanel";
import {
  Field,
  Button,
  Form,
  Input,
  Switch,
  Modal,
  Textarea,
} from "./../../share/InsightUI";
import CreatableSelect from "react-select/creatable";
import { UserDataContext } from "./../../app/UserData";
import { SegmentsContext } from "./../../../context/Segments";
import { ReducerContext } from "./../../../reducer/context";
import { ADD_TOAST } from "./../../../reducer/index";
import { segmentFormValidator } from "./../../../helpers/formValidators";
import { saveSegment } from "./../../../lib/firebase/segment";
import { deepCopy, seggregatePageContainers } from "./../../../utils";
import { useHistory } from "react-router-dom";
import "./style.scss";
import {
  conditionFixture,
  excludedPageContainersFixture,
  includedPageContainersFixture,
} from "../../../fixtures/filters";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import Badge from "../../share/Badge";
import InsightechIcon from "../../share/InsightechIcon";

const initialConditions = {
  userConditions: [
    {
      attribute: "",
      operator: "",
      values: [""],
    },
  ],

  eventConditions: [
    {
      type: "",
      inclusion: true,
      conditions: [
        {
          attribute: "",
          operator: "",
          values: [""],
        },
      ],
    },
  ],

  excludeEventConditions: [
    {
      type: "",
      inclusion: false,
      conditions: [
        {
          attribute: "",
          operator: "",
          values: [""],
        },
      ],
    },
  ],
};

function SegmentBuilder(props) {
  const history = useHistory();
  const { location, segments, setSegments } = props;
  const {
    setQueryId,
    appliedFilters,
    setAppliedFilters,
    activeProfileId,
    setCachedFilters,
    userConditions,
    setUserConditions,
    eventConditions,
    setEventConditions,
    excludeEventConditions,
    setExcludeEventConditions,
    crossFilterMsg,
    selectedStartDate,
    selectedEndDate,
    setStartDate,
    setEndDate,
    setReloadApi,
    includedPageContainers,
    setIncludedPageContainers,
    excludedPageContainers,
    setExcludedPageContainers,
    setIsOverrideFiltersLoaded,
  } = useContext(UserDataContext);

  const { selectedSegments, setSelectedSegments } = useContext(SegmentsContext);

  const { dispatch } = useContext(ReducerContext);

  const [userConditionsCount, setUserConditionsCount] = useState(0);
  const [eventConditionsCount, setEventConditionsCount] = useState(0);
  const [excludeEventConditionsCount, setExcludeEventConditionsCount] =
    useState(0);
  const [appliedConditionsCount, setAppliedConditionsCount] = useState(0);
  const [isSegmentModalVisible, setIsSegmentModalVisible] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [isFiltersValid, setIsFiltersValid] = useState(false);
  const [invalidFields, setInvalidFields] = useState({
    userConditions: [],
    includePageContainers: [],
    excludePageContainers: [],
    total: 0,
  });
  const [isFilterChangingApplied, setIsFilterChangingApplied] = useState(true);

  const formatAppliedFilters = JSON.stringify(appliedFilters);

  const dropdownRef = useRef(null);

  const getFilterCount = (type) => {
    let data = [];
    let aggregated = [];
    let arrayFilter = null;
    let filtered = [];
    switch (type) {
      case "user":
        data = [...userConditions];
        arrayFilter = (i) => {
          if (i.attribute && i.operator && i.values) {
            return true;
          }
          return false;
        };
        break;
      case "event":
        aggregated = includedPageContainers.reduce((acc, val) => {
          if (val && val.eventConditions) {
            acc.push(...val.eventConditions);
          }
          return acc;
        }, []);
        data = [...aggregated];
        arrayFilter = (i) => {
          if (i.type && i.conditions) {
            return true;
          }
          return false;
        };
        break;
      case "excludedEvent":
        aggregated = excludedPageContainers.reduce((acc, val) => {
          if (val && val.eventConditions) {
            acc.push(...val.eventConditions);
          }
          return acc;
        }, []);
        data = [...aggregated];
        arrayFilter = (i) => {
          if (i.type && i.conditions) {
            return true;
          }
          return false;
        };
        break;
      default:
        break;
    }
    filtered = data.filter(arrayFilter);
    return filtered.length;
  };

  const getInvalidFields = () => {
    const invalidFields = {
      userConditions: [],
      includedPageContainers: [],
      excludedPageContainers: [],
      total: 0,
    };
    userConditions.forEach((condition, index) => {
      if (!invalidFields.userConditions[index]) {
        invalidFields.userConditions[index] = {};
      }

      // Rule operator missing
      if (condition.attribute && !condition.operator) {
        invalidFields.userConditions[index] = {
          operator: true,
        };
        invalidFields.total++;
      }

      if (
        condition.attribute &&
        condition.attribute !== "Any" &&
        condition.operator
      ) {
        // Rule for operator values
        const operatorConfig = getOperatorConfig(
          condition.attribute,
          condition.operator,
          "users"
        );

        //Operator has its own rule
        if (operatorConfig && operatorConfig.validator) {
          // Rule custom validator
          condition.values.forEach((value, valueIndex) => {
            if (!validateValue(operatorConfig.validator, value)) {
              invalidFields.userConditions[index][`value${valueIndex}`] = true;
              invalidFields.total++;
            }
          });
        }
      }
    });
    includedPageContainers &&
      includedPageContainers.forEach((container, containerIndex) => {
        if (!invalidFields.includedPageContainers[containerIndex]) {
          invalidFields.includedPageContainers[containerIndex] = {
            eventConditions: [],
          };
        }
        container &&
          container.eventConditions &&
          container.eventConditions.forEach((event, eventIndex) => {
            if (
              !invalidFields.includedPageContainers[containerIndex]
                .eventConditions[eventIndex]
            ) {
              invalidFields.includedPageContainers[
                containerIndex
              ].eventConditions[eventIndex] = {
                conditions: [],
              };
            }
            event.conditions &&
              event.conditions.forEach((condition, conditionIndex) => {
                if (
                  !invalidFields.includedPageContainers[containerIndex]
                    .eventConditions[eventIndex].conditions[conditionIndex]
                ) {
                  invalidFields.includedPageContainers[
                    containerIndex
                  ].eventConditions[eventIndex].conditions[conditionIndex] = {};
                }
                if (conditionIndex === 0) {
                  // Rule missing attribute
                  if (event.type && !condition.attribute) {
                    invalidFields.includedPageContainers[
                      containerIndex
                    ].eventConditions[eventIndex].conditions[
                      conditionIndex
                    ].attribute = true;
                    invalidFields.total++;
                  }
                }
                // Rule missing operator
                if (
                  condition.attribute &&
                  condition.attribute !== "Any" &&
                  !condition.operator
                ) {
                  invalidFields.includedPageContainers[
                    containerIndex
                  ].eventConditions[eventIndex].conditions[
                    conditionIndex
                  ].operator = true;
                  invalidFields.total++;
                }
                // Rule for operator
                else if (
                  condition.attribute &&
                  condition.attribute !== "Any" &&
                  condition.operator
                ) {
                  const operatorConfig = getOperatorConfig(
                    condition.attribute,
                    condition.operator,
                    "events"
                  );

                  // Operator has its own rule
                  if (operatorConfig && operatorConfig.validator) {
                    // Rule custom validator
                    condition.values.forEach((value, valueIndex) => {
                      if (!validateValue(operatorConfig.validator, value)) {
                        invalidFields.includedPageContainers[
                          containerIndex
                        ].eventConditions[eventIndex].conditions[
                          conditionIndex
                        ][`value${valueIndex}`] = true;
                        invalidFields.total++;
                      }
                    });
                  }
                }
              });
          });
      });
    excludedPageContainers &&
      excludedPageContainers.forEach((container, containerIndex) => {
        if (!invalidFields.excludedPageContainers[containerIndex]) {
          invalidFields.excludedPageContainers[containerIndex] = {
            eventConditions: [],
          };
        }
        container &&
          container.eventConditions &&
          container.eventConditions.forEach((event, eventIndex) => {
            if (
              !invalidFields.excludedPageContainers[containerIndex]
                .eventConditions[eventIndex]
            ) {
              invalidFields.excludedPageContainers[
                containerIndex
              ].eventConditions[eventIndex] = {
                conditions: [],
              };
            }
            event &&
              event.conditions &&
              event.conditions.forEach((condition, conditionIndex) => {
                if (
                  !invalidFields.excludedPageContainers[containerIndex]
                    .eventConditions[eventIndex].conditions[conditionIndex]
                ) {
                  invalidFields.excludedPageContainers[
                    containerIndex
                  ].eventConditions[eventIndex].conditions[conditionIndex] = {};
                }
                if (conditionIndex === 0) {
                  // Rule missing attribute
                  if (event.type && !condition.attribute) {
                    invalidFields.excludedPageContainers[
                      containerIndex
                    ].eventConditions[eventIndex].conditions[
                      conditionIndex
                    ].attribute = true;
                    invalidFields.total++;
                  }
                }
                // Rule missing operator
                if (
                  condition.attribute &&
                  condition.attribute !== "Any" &&
                  !condition.operator
                ) {
                  invalidFields.excludedPageContainers[
                    containerIndex
                  ].eventConditions[eventIndex].conditions[
                    conditionIndex
                  ].operator = true;
                  invalidFields.total++;
                }
                // Rule for operator
                else if (
                  condition.attribute &&
                  condition.attribute !== "Any" &&
                  condition.operator
                ) {
                  const operatorConfig = getOperatorConfig(
                    condition.attribute,
                    condition.operator,
                    "events"
                  );

                  // Operator has its own rule
                  if (operatorConfig && operatorConfig.validator) {
                    // Rule custom validator
                    condition.values.forEach((value, valueIndex) => {
                      if (!validateValue(operatorConfig.validator, value)) {
                        invalidFields.excludedPageContainers[
                          containerIndex
                        ].eventConditions[eventIndex].conditions[
                          conditionIndex
                        ][`value${valueIndex}`] = true;
                        invalidFields.total++;
                      }
                    });
                  }
                }
              });
          });
      });

    return invalidFields;
  };

  useEffect(() => {
    if (location.state && location.state.overrideFilters) {
      const [overrideIncludedPageContainers, overrideExcludedPageContainers] =
        seggregatePageContainers(location.state.overrideFilters.pageContainers);
      setAppliedFilters(location.state.overrideFilters);
      setReloadApi(true);
      setUserConditions(location.state.overrideFilters.userConditions);
      setIncludedPageContainers(overrideIncludedPageContainers);
      setExcludedPageContainers(
        overrideExcludedPageContainers?.length
          ? overrideExcludedPageContainers
          : excludedPageContainers
      );
      setIsOverrideFiltersLoaded(true);
      // clear the overrideFilters in the history
      history.replace({
        ...history.location,
        state: {
          overrideFilters: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    // validate fields
    const invalidFields = getInvalidFields();
    setInvalidFields(invalidFields);
    setIsFiltersValid(!invalidFields.total);

    // count each container
    setUserConditionsCount(getFilterCount("user"));
    setEventConditionsCount(getFilterCount("event"));
    setExcludeEventConditionsCount(getFilterCount("excludedEvent"));

    // set changing filter
    setIsFilterChangingApplied(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConditions, includedPageContainers, excludedPageContainers]);

  useEffect(() => {
    setAppliedConditionsCount(
      getFilterCount("user") +
        getFilterCount("event") +
        getFilterCount("excludedEvent")
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatAppliedFilters]);

  // Setup outside click events
  useEffect(() => {
    const handleClickOutside = (e) => {
      const modalRoot = document.getElementById("portal");
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        (!modalRoot || !modalRoot.contains(e.target))
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    // Add outside click handler
    document.addEventListener("mousedown", handleClickOutside);

    // Remove outside click handler on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  // Get back applied filters when changing not applied
  useEffect(() => {
    if (!isFilterPanelOpen && !isFilterChangingApplied) {
      setUserConditions(
        appliedFilters?.userConditions
          ? [...appliedFilters.userConditions]
          : [deepCopy(conditionFixture)]
      );

      if (appliedFilters?.pageContainers) {
        const [includedPageContainers, excludedPageContainers] =
          seggregatePageContainers(appliedFilters.pageContainers);
        setIncludedPageContainers(
          includedPageContainers?.length
            ? includedPageContainers
            : deepCopy(includedPageContainersFixture)
        );
        setExcludedPageContainers(
          excludedPageContainers?.length
            ? excludedPageContainers
            : deepCopy(excludedPageContainersFixture)
        );
      } else {
        setUserConditions([deepCopy(conditionFixture)]);
        setIncludedPageContainers(deepCopy(includedPageContainersFixture));
        setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterPanelOpen]);

  const handleSubmitSegmentForm = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const payload = {
      ...values,
      tags: values.tags.map((tag) => {
        return tag.value;
      }),
      profileId: activeProfileId,
      filter: JSON.stringify({
        userConditions: [...userConditions],
        pageContainers: [...includedPageContainers, ...excludedPageContainers],
      }),
    };
    saveSegment(payload, activeProfileId).then((res) => {
      setSubmitting(false);
      setSegments({
        ...segments,
        [res.id]: {
          ...payload,
          id: res.id,
        },
      });
      setSelectedSegments({ ...selectedSegments, [res.id]: true });
      resetForm({});

      dispatch({
        type: ADD_TOAST,
        messageType: "success",
        messageText: `New segment "${payload.name}" was created and applied successfully.`,
      });

      setUserConditions(deepCopy(initialConditions.userConditions));
      setEventConditions(deepCopy(initialConditions.eventConditions));
      setExcludeEventConditions(
        deepCopy(initialConditions.excludeEventConditions)
      );
      setIncludedPageContainers(deepCopy(includedPageContainersFixture));
      setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
      setAppliedFilters({});
      setIsSegmentModalVisible(false);
      setIsFilterPanelOpen(false);
    });
  };

  const handleClickSave = () => {
    setIsSegmentModalVisible(true);
  };

  const handleCloseSegmentModal = () => {
    setIsSegmentModalVisible(false);
  };

  const handleApplyFilters = () => {
    const filtersPayload = {
      profileId: activeProfileId,
      conditions: {
        userConditions: [...userConditions],
        pageContainers: [...includedPageContainers, ...excludedPageContainers],
      },
    };
    setIsFilterChangingApplied(true);
    setReloadApi(true);
    setAppliedFilters(filtersPayload.conditions);
    setCachedFilters(filtersPayload);
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    setQueryId(null);
    setIsFilterPanelOpen(false);
  };

  const handleClearFilters = () => {
    setUserConditions([
      ...JSON.parse(JSON.stringify(initialConditions.userConditions)),
    ]);
    setIncludedPageContainers(deepCopy(includedPageContainersFixture));
    setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
  };

  const handleCloseFilterPanel = () => {
    setIsFilterPanelOpen(false);
  };

  const validateValue = (validator, value) => {
    switch (validator) {
      case "regexp":
        try {
          new RegExp(value);
        } catch (error) {
          return false;
        }
        return true;
      case "number":
        if (value === "") {
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const getOperatorConfig = (attribute, operator, conditionType) => {
    const operators = require("../../../inc/operators.json");
    const eventsConditions = require("../../../inc/eventConditions.json");
    const usersConditions = require("../../../inc/userFilters.json");
    const type =
      conditionType === "events"
        ? eventsConditions[attribute]
        : usersConditions[attribute];

    if (type?.operatorType && operators[type.operatorType]) {
      const operatorConfig = operators[type.operatorType].find(
        (obj) => obj.key === operator
      );
      if (operatorConfig) return operatorConfig;
    }

    const errMsg =
      "Operator unknown, attribute:" + attribute + ", operator:" + operator;
    console.error(errMsg);
    Sentry.captureMessage(errMsg);
  };

  const handleToggleFilterPanel = () => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  };

  return (
    <div ref={dropdownRef}>
      <button
        className="btn-grey"
        id="filters-dropdown"
        aria-haspopup="true"
        aria-expanded={isFilterPanelOpen}
        onClick={handleToggleFilterPanel}
      >
        <span className="icon">
          <InsightechIcon name="Filter" />
        </span>
        Filters{" "}
        {appliedConditionsCount > 0 && (
          <Badge val={appliedConditionsCount} isAnimation={!!crossFilterMsg} />
        )}
        <span className="chevron-icon">
          {isFilterPanelOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>
      <div className="filter-dropdown">
        <div
          className={`dropdown dropdown-menu shadow animated--grow-in filter-dropdown-container segment-builder ${
            isFilterPanelOpen ? "show" : ""
          }`}
        >
          <div className="filters-toggle">
            <small>Rules & Conditions</small>
            <Button size="small" icon="fas fa-users">
              {!userConditionsCount && `All `}
              Users{" "}
              {!!userConditionsCount && (
                <span className="badge badge-danger">
                  {userConditionsCount}
                </span>
              )}
            </Button>
            and
            <Button size="small" icon="fas fa-mouse-pointer">
              {!eventConditionsCount && `All `}
              Events{" "}
              {!!eventConditionsCount && (
                <span className="badge badge-danger">
                  {eventConditionsCount}
                </span>
              )}
            </Button>
            and
            <Button size="small" icon="fas fa-ban">
              {!excludeEventConditionsCount && `No Exclusions`}
              {!!excludeEventConditionsCount && (
                <>
                  Exclude{" "}
                  <span className="badge badge-danger">
                    {excludeEventConditionsCount}
                  </span>
                </>
              )}
            </Button>
            <Button
              className="float-right mr-n2 mt-n1"
              onClick={handleCloseFilterPanel}
            >
              <VscChromeClose size={18} color="#858796" />
            </Button>
          </div>

          <div className="filters-panel bg-gray-100">
            <FilterPanel
              includedPageContainers={includedPageContainers}
              setIncludedPageContainers={setIncludedPageContainers}
              excludedPageContainers={excludedPageContainers}
              setExcludedPageContainers={setExcludedPageContainers}
              userConditions={userConditions}
              eventConditions={eventConditions}
              excludeEventConditions={excludeEventConditions}
              setUserConditions={setUserConditions}
              setEventConditions={setEventConditions}
              invalidFields={invalidFields}
              setExcludeEventConditions={setExcludeEventConditions}
            />
          </div>
          <div className="mt-3 mb-1">
            <Button
              className="mr-4"
              style={{ minWidth: 120 }}
              disabled={!isFiltersValid}
              size="small"
              onClick={handleApplyFilters}
              variant="primary"
            >
              Apply
            </Button>
            <Button size="small" onClick={handleClickSave} variant="secondary">
              Save as Segment
            </Button>
            <Button className="ml-4" size="small" onClick={handleClearFilters}>
              Clear Filters
            </Button>
            <Button
              className="float-right"
              style={{ minWidth: 80 }}
              size="small"
              variant="outline-secondary"
              onClick={handleCloseFilterPanel}
            >
              Close
            </Button>
          </div>

          <Modal
            title="New Segment"
            isVisible={isSegmentModalVisible}
            handleClose={handleCloseSegmentModal}
          >
            <Formik
              initialValues={{
                name: "",
                description: "",
                shared: false,
                default: false,
                tags: [],
              }}
              validate={segmentFormValidator}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={handleSubmitSegmentForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
                  <Input
                    required
                    isInvalid={!!(touched.name && errors.name)}
                    invalidFeedback={errors.name}
                    name="name"
                    label="Name"
                    value={values.name || ""}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Field label="Description">
                    <Textarea
                      name="description"
                      rows="5"
                      value={values.description || ""}
                      onChange={handleChange}
                    />
                  </Field>
                  <Field label="Tags">
                    <CreatableSelect
                      isMulti
                      value={values.tags}
                      onChange={(e) => {
                        setFieldValue("tags", e);
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </Field>
                  <Field>
                    <Switch
                      name="shared"
                      id="shared"
                      label="Shared"
                      isChecked={values.shared}
                      onChange={handleChange}
                    />
                  </Field>
                  <Field>
                    <Switch
                      name="default"
                      id="default"
                      label="Default"
                      isChecked={values.default}
                      onChange={handleChange}
                    />
                  </Field>

                  <div
                    style={{
                      textAlign: "right",
                      marginTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    <Button
                      type="button"
                      size="small"
                      className="ml-2"
                      onClick={handleCloseSegmentModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" size="small" variant="primary">
                      Save & Apply
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      </div>
    </div>
  );
}

SegmentBuilder.propTypes = {};
SegmentBuilder.defaultProps = {};

export default withRouter(SegmentBuilder);
