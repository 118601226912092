export const DISABLED_DATE_COMPARISON =
  "Turn off the segment comparison to enable the date comparison.";
export const DISABLED_SEGMENT_COMPARISON =
  "Turn off the date comparison to enable the segment comparison.";
export const CLOSED_FUNNEL =
  "Closed funnel steps only count sessions that visited every previous steps in the funnel.";
export const OPEN_FUNNEL =
  "Open funnel steps count sessions reach to the steps regardless if the sessions visited the previous steps.";
export const NOTE_PUBLIC =
  "Everyone with access to the profile can see the note";
export const NOTE_PRIVATE = "Only you can see the note";
export const NOTE_CARD_SHARED = "Shared to people with access to this profile";
export const NOTE_CARD_PRIVATE = "Private : only visible to you";
export const REPORT_ROLLING =
  "Rolling Date Ranges will keep up to date as the calendar changes.";
export const REPORT_PRIVATE = "Save as your private report.";
