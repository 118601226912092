import React from "react";
import PropTypes from "prop-types";
import { Button } from "./../../share/InsightUI";
import "./style.scss";

function FunnelScreen(props) {
  const { handleClickCancel, funnelName, title } = props;

  React.useEffect(() => {
    document.body.classList.add("locked");

    return () => {
      document.body.classList.remove("locked");
    };
  });

  return (
    <div className={`funnel-screen ${props.className}`}>
      <div className="title-bar">
        <h1 className="title">{title}</h1>
        <div className="funnel-name mr-auto">{funnelName || "New Funnel"}</div>
        <Button onClick={handleClickCancel} className="btn-done">
          Done
        </Button>
      </div>

      <div className="funnel-report" id="funnel-report">
        {props.children}
      </div>
    </div>
  );
}

FunnelScreen.propTypes = {
  funnelId: PropTypes.number,
  handleClickCancel: PropTypes.func,
};
FunnelScreen.defaultProps = {
  funnelId: null,
};

export default FunnelScreen;
