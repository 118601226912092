import React, { useEffect, useState } from "react";
import { getAuth, firebaseApp, onAuthStateChanged } from "./../../lib/firebase";
import { pushUserSignInEvent } from "../../utils";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({
    user: null,
    checked: false,
    token: null,
    isAdmin: false,
  });

  useEffect(() => {
    onAuthStateChanged(getAuth(firebaseApp), function (user) {
      if (user !== null) {
        user.getIdToken().then((token) => {
          setAuthUser({
            user: user,
            checked: true,
            token: token,
            isAdmin: false,
          });
          pushUserSignInEvent(user);
        });
      } else {
        setAuthUser({
          user: null,
          checked: true,
          token: null,
          isAdmin: false,
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
