import React from "react";
import "./style.scss";
import ListHeader from "./ListHeader";
import ListBody from "./ListBody";

const ReportList = (props) => {
  const {
    columns,
    reportListData,
    onReport,
    handleDeleteButton,
    handleConfirmReportDelete,
    isDeleteModalHidden,
    setIsDeleteModalHidden,
    isDeleting,
  } = props;

  return (
    <table className="table list-table">
      <ListHeader columns={columns} />
      <ListBody
        reportListData={reportListData}
        onReport={onReport}
        handleDeleteButton={handleDeleteButton}
        handleConfirmReportDelete={handleConfirmReportDelete}
        isDeleteModalHidden={isDeleteModalHidden}
        setIsDeleteModalHidden={setIsDeleteModalHidden}
        isDeleting={isDeleting}
      />
    </table>
  );
};

export default ReportList;
