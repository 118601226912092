import {
  firebaseApp,
  getAuth,
  Firestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  query,
  getDocs,
  getDoc,
  deleteDoc,
  where,
} from "./index";

export const saveNote = (data, profileId, id = null) => {
  const collectionRef = collection(Firestore, "profiles", profileId, "notes");

  const utcNow = Date.now();
  if (id) {
    return updateDoc(doc(collectionRef, id), {
      ...data,
      updateTime: String(utcNow),
    }).then(() => {
      return { id };
    });
  }
  return addDoc(collectionRef, {
    ...data,
    ownerId: getAuth(firebaseApp).currentUser.uid,
    creationTime: String(utcNow),
    updateTime: String(utcNow),
  });
};

export const fetchNotes = ({ profileId }) => {
  if (!profileId) {
    return;
  }

  let sharedNotesRef = null;
  let ownedNotesRef = null;

  sharedNotesRef = getDocs(
    query(
      collection(Firestore, "profiles", profileId, "notes"),
      where("shared", "==", true)
    )
  );

  ownedNotesRef = getDocs(
    query(
      collection(Firestore, "profiles", profileId, "notes"),
      where("ownerId", "==", getAuth(firebaseApp).currentUser.uid)
    )
  );

  return Promise.all([sharedNotesRef, ownedNotesRef]).then(
    ([sharedNotes, ownedNotes]) => {
      const aggregateNotes = {};
      sharedNotes.forEach((note) => {
        aggregateNotes[note.id] = note.data();
      });
      ownedNotes.forEach((note) => {
        if (!aggregateNotes[note.id]) {
          aggregateNotes[note.id] = note.data();
        }
      });

      const sortedNotes = {};
      Object.keys(aggregateNotes)
        .sort((a, b) => {
          return (
            aggregateNotes[b].creationTime - aggregateNotes[a].creationTime
          );
        })
        .forEach((key) => {
          sortedNotes[key] = aggregateNotes[key];
        });

      return sortedNotes;
    }
  );
};

export const fetchNoteById = (profileId, noteId) => {
  const collectionRef = collection(Firestore, "profiles", profileId, "notes");

  return getDoc(doc(collectionRef, noteId));
};

export const deleteNoteById = (profileId, noteId) => {
  return deleteDoc(doc(Firestore, "profiles", profileId, "notes", noteId));
};

export const fetchSessionNotes = ({ profileId, sessionKey }) => {
  if (!profileId) {
    return;
  }

  let sharedNotesRef = null;
  let ownedNotesRef = null;

  sharedNotesRef = getDocs(
    query(
      collection(Firestore, "profiles", profileId, "notes"),
      where("shared", "==", true),
      where("sessionKey", "==", sessionKey)
    )
  );

  ownedNotesRef = getDocs(
    query(
      collection(Firestore, "profiles", profileId, "notes"),
      where("ownerId", "==", getAuth(firebaseApp).currentUser.uid),
      where("sessionKey", "==", sessionKey)
    )
  );

  return Promise.all([sharedNotesRef, ownedNotesRef]).then(
    ([sharedNotes, ownedNotes]) => {
      const aggregateNotes = {};
      sharedNotes.forEach((note) => {
        aggregateNotes[note.id] = note.data();
      });
      ownedNotes.forEach((note) => {
        if (!aggregateNotes[note.id]) {
          aggregateNotes[note.id] = note.data();
        }
      });

      return aggregateNotes;
    }
  );
};
