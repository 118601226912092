import React from "react";
import "./style.scss";

const CustomCheckbox = (props) => {
  const { value, onChange, checked, variant, id } = props;
  let variantClass = "";

  switch (variant) {
    case "compared":
      variantClass = "checkbox-compared";
      break;
    default:
      break;
  }

  return (
    <>
      <input
        className={`styled-checkbox ${variantClass}`}
        id={id}
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={false}
      />
      <label htmlFor={id}></label>
    </>
  );
};

export default CustomCheckbox;
