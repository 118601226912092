import React, { useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import "./style.scss";

const CardHeader = (props) => {
  const { title, widget, setIsTablesOpen } = props;
  const [copyStatus, setCopyStatus] = useState("Copy Data");
  const chartWidgets = ["trend", "os", "browser", "country"];
  const isChartTable = chartWidgets.includes(widget);

  // Function to open the chart table and then resolve the promise
  const openTableAndCopyData = (widget, tableId) => {
    return new Promise((resolve) => {
      // Update the state to open the specified table
      setIsTablesOpen((prevState) => ({
        ...prevState,
        [widget]: true,
      }));

      // Adding a short delay to ensure the table is fully rendered
      setTimeout(() => {
        resolve(tableId);
      }, 50);
    });
  };

  // Handler for copying table data to the clipboard
  const handleCopyTableData = async (e) => {
    const tableId = e.currentTarget.id;

    if (isChartTable) {
      // Wait for the table to open before proceeding
      await openTableAndCopyData(widget, tableId);
    }

    const copyTable = document.querySelector(`#table-${tableId}`);

    if (!copyTable) {
      handleCopyFailure();
      return;
    }

    try {
      // Attempt to copy the table's text content to the clipboard
      await navigator.clipboard.writeText(copyTable.innerText);
      handleCopySuccess();
    } catch (err) {
      handleCopyFailure();
    }
  };

  // Helper function for handling successful copy
  const handleCopySuccess = () => {
    setCopyStatus("Copied");
    resetCopyStatus();
  };

  // Helper function for handling copy failure
  const handleCopyFailure = () => {
    setCopyStatus("Please try again");
    console.log("Failed to copy table data.");
    resetCopyStatus();
  };

  // Helper function to reset copy status after a delay
  const resetCopyStatus = () => {
    setTimeout(() => setCopyStatus("Copy Data"), 1000);
  };

  return (
    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
      <h6 className="m-0 font-weight-bold text-uppercase">{title}</h6>
      <div className="clipboard-wrapper">
        <span
          className={`copy-status ${copyStatus === "Copied" ? "copied" : ""}`}
        >
          {copyStatus}
        </span>
        <button
          className="btn-table-clipboard"
          onClick={handleCopyTableData}
          id={widget}
        >
          <AiOutlineCopy />
        </button>
      </div>
    </div>
  );
};

export default CardHeader;
