import {
  DEFAULT_FUNNEL_FOLDER_NAME,
  MAX_FUNNELS,
  MAX_FUNNELS_FOLDER,
} from "../../constants/funnels";
import {
  Firestore,
  collection,
  updateDoc,
  doc,
  getDocs,
  getDoc,
  addDoc,
  firebaseApp,
  getAuth,
  deleteDoc,
  deleteField,
} from "./index";

export const saveFunnel = (data, profileId, id = null) => {
  const collectionRef = collection(Firestore, "profiles", profileId, "funnels");

  if (id) {
    if (data.folder === DEFAULT_FUNNEL_FOLDER_NAME) {
      data.folder = deleteField();
    }
    return updateDoc(doc(collectionRef, id), { ...data }).then(() => {
      return {
        id,
      };
    });
  }

  return fetchFunnels({ profileId }).then((res) => {
    const funnelFoldersMap = new Map();
    funnelFoldersMap.set(DEFAULT_FUNNEL_FOLDER_NAME, 0);

    res.forEach((funnelDoc) => {
      const folder = funnelDoc.data().folder;
      const folderCount = funnelFoldersMap.get(folder) || 0;

      //process funnel folders
      if (!folder || folder === DEFAULT_FUNNEL_FOLDER_NAME) {
        funnelFoldersMap.set(
          DEFAULT_FUNNEL_FOLDER_NAME,
          (funnelFoldersMap.get(DEFAULT_FUNNEL_FOLDER_NAME) || 0) + 1
        );
      } else {
        funnelFoldersMap.set(folder, folderCount + 1);
      }
    });

    if (funnelFoldersMap.get(data.folder) >= MAX_FUNNELS_FOLDER) {
      return {
        error: `The funnel cannot be saved. The funnels in the folder have reached the maximum ${MAX_FUNNELS_FOLDER} funnels.`,
      };
    }

    if (res.docs.length >= MAX_FUNNELS) {
      return {
        error: `The funnel cannot be saved. The funnels in the profile have reached the maximum ${MAX_FUNNELS} funnels.`,
      };
    }

    if (data.folder === DEFAULT_FUNNEL_FOLDER_NAME) {
      delete data.folder;
    }

    return addDoc(collectionRef, {
      ...data,
      ownerId: getAuth(firebaseApp).currentUser.uid,
    });
  });
};

export const fetchFunnel = ({ profileId, funnelId }) => {
  return getDoc(doc(Firestore, "profiles", profileId, "funnels", funnelId));
};

export const fetchFunnels = ({ profileId }) => {
  return getDocs(collection(Firestore, "profiles", profileId, "funnels"));
};

export const deleteFunnelById = (profileId, funnelId) => {
  return deleteDoc(doc(Firestore, "profiles", profileId, "funnels", funnelId));
};
