import React, { useContext, useEffect, useState } from "react";
import RadioButton from "../../../../share/InsightUI/RadioButton";
import { REPORT_ROLLING } from "../../../../../constants/info";
import "./style.scss";
import { Button } from "../../../../share/InsightechUI";
import moment from "moment";
import { UserDataContext } from "../../../UserData";

const RollingCheckbox = (props) => {
  const { handleChange, selectedReport } = props;
  const {
    startDate,
    endDate,
    isDateCompareOn,
    compareStartDate,
    compareEndDate,
  } = useContext(UserDataContext);
  const [dataSelect, setDataSelect] = useState(
    selectedReport?.rolling ? "rolling" : "fixed"
  );

  const handleRadioChange = (e) => {
    e.target.name = "rolling";
    setDataSelect(e.target.id);

    handleChange(e);
  };

  useEffect(() => {
    if (selectedReport?.Id) {
      if (selectedReport?.rolling === true) {
        setDataSelect("rolling");
      } else {
        setDataSelect("fixed");
      }
    } else {
      setDataSelect("fixed");
    }
  }, [selectedReport]);

  return (
    <div className="modal-form-row">
      <div className="row-label">
        <span>Date Range</span>
        {isDateCompareOn && (
          <Button size="badge" color="orange">
            Compare Mode Activated
          </Button>
        )}
      </div>
      <div className="row-input radio-button-container">
        <RadioButton
          id="fixed"
          label="Fixed"
          value="fixed"
          isSelected={dataSelect === "fixed"}
          changed={handleRadioChange}
        />
        <RadioButton
          id="rolling"
          label="Rolling"
          value="rolling"
          isSelected={dataSelect === "rolling"}
          changed={handleRadioChange}
          tooltip={REPORT_ROLLING}
        />
      </div>
      <div className="row-tags">
        <Button size="chip" readOnly={true}>
          {`${moment(startDate.toDate()).format("MMM DD, YYYY")} - ${moment(
            endDate.toDate()
          ).format("MMM DD, YYYY")}`}
        </Button>
        {isDateCompareOn && (
          <Button size="chip" color="orange" readOnly={true}>
            {`${moment(compareStartDate?.toDate()).format(
              "MMM DD, YYYY"
            )} - ${moment(compareEndDate?.toDate()).format("MMM DD, YYYY")}`}
          </Button>
        )}
      </div>
    </div>
  );
};

export default RollingCheckbox;
