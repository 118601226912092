import React from "react";
import "./style.scss";
import ShareableCheckbox from "../CheckboxField/ShareableCheckbox";
import RollingCheckbox from "../CheckboxField/RollingCheckbox";
import TagsGroup from "../TagsGroup";
import FloatingLabelInput from "../FloatingLabelInput";

const ModalForm = (props) => {
  const {
    handleChange,
    isNameValid,
    nameErrorMsg,
    isUrlValid,
    urlErrorMsg,
    selectedReport,
    action,
    isExternalReport,
  } = props;

  return (
    <div
      className={`report-form-wrapper ${
        !isExternalReport ? "scroll-height" : ""
      }`}
    >
      <FloatingLabelInput
        id="name"
        name="name"
        label="Name your report"
        handleChange={handleChange}
        selectedReport={selectedReport}
        action={action}
        type="text"
        required={true}
        errorMessage={!isNameValid ? nameErrorMsg : null}
      />
      <FloatingLabelInput
        id="description"
        name="description"
        label="Add a description"
        handleChange={handleChange}
        selectedReport={selectedReport}
        type="text"
        required={false}
      />
      {isExternalReport && (
        <FloatingLabelInput
          id="url"
          name="url"
          label="External URL"
          handleChange={handleChange}
          selectedReport={selectedReport}
          type="text"
          errorMessage={!isUrlValid ? urlErrorMsg : null}
          required={true}
        />
      )}
      <ShareableCheckbox
        handleChange={handleChange}
        selectedReport={selectedReport}
      />
      {!isExternalReport && (
        <RollingCheckbox
          handleChange={handleChange}
          selectedReport={selectedReport}
        />
      )}
      {!isExternalReport && (
        <TagsGroup id="tags-group" label="Filters & Segments" />
      )}
    </div>
  );
};

export default ModalForm;
