import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as Icons from "../assets/icons/MenuIconProvider";
import { fetchReportById } from "../../lib/firebase/reports";
import Dashboard from "../../pages/app/profiles/Dashboard";
import SessionList from "../../pages/app/profiles/Replay";
import Pathing from "../../pages/app/profiles/Pathing";
import Funnels from "../../pages/app/profiles/Funnels";
import Pages from "../../pages/app/profiles/Pages";
import Forms from "../../pages/app/profiles/Forms";
import ProfileLayout from "./layouts/ProfileLayout";
import DynamicProfileLayout from "./layouts/DynamicProfileLayout";
import FunnelAction from "../../pages/app/profiles/Funnels/FunnelAction";
import { UserDataContext } from "./UserData";
import Loader from "../share/Loader";

const ReportWrapper = ({ rest, matchProps }) => {
  const history = useHistory();
  const { profileId, reportId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isUpdatingReport, setIsUpdatingReport } = useContext(UserDataContext);

  const matchReportComponent = {
    dashboard: {
      component: Dashboard,
      template: ProfileLayout,
      icon: Icons.Dashboard,
      compareAbility: true,
    },
    page_list: {
      component: Pages,
      template: DynamicProfileLayout,
      icon: Icons.PageAnalysis,
    },
    form_list: {
      component: Forms,
      template: DynamicProfileLayout,
      icon: Icons.FormAnalysis,
    },
    funnel_list: {
      component: Funnels,
      template: ProfileLayout,
      icon: Icons.Funnels,
    },
    funnel_view: {
      component: FunnelAction,
      template: ProfileLayout,
      icon: Icons.Funnels,
    },
    pathing: {
      component: Pathing,
      template: ProfileLayout,
      icon: Icons.Pathing,
    },
    replay_list: {
      component: SessionList,
      template: ProfileLayout,
      icon: Icons.Replays,
    },
  };

  const fetchReportData = async () => {
    setLoading(true);

    try {
      // Fetch report details based on reportId
      const res = await fetchReportById(profileId, reportId);
      const fetchedReport = res.data();

      if (!fetchedReport) {
        history.push(`/profile/${profileId}/report/list`);
        return;
      }

      // catch funnel id missing for funnel view report
      if (
        fetchedReport.reportType === "funnel_view" &&
        !fetchedReport.funnelId
      ) {
        history.push(`/profile/${profileId}/report/list`);
        return;
      }

      setReportData({
        title: fetchedReport.name,
        description: fetchedReport.description,
        component: matchReportComponent[fetchedReport.reportType].component,
        template: matchReportComponent[fetchedReport.reportType].template,
        icon: matchReportComponent[fetchedReport.reportType].icon,
        compareAbility:
          matchReportComponent[fetchedReport.reportType]?.compareAbility,
      });
    } catch (error) {
      console.error("Error fetching report data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      fetchReportData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  useEffect(() => {
    if (isUpdatingReport) {
      fetchReportData();
      setIsUpdatingReport(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingReport]);

  // Report Loading
  if (loading) {
    return <Loader />;
  }

  // Report not found
  if (!loading && !reportData) {
    return <div>Report not found</div>;
  }

  const {
    component: ReportComponent,
    title,
    description,
    icon: titleIcon,
    template: ReportTemplate,
    compareAbility: reportCompareAbility,
  } = reportData;

  // Ensure that ReportComponent is valid before rendering
  if (!ReportComponent || !ReportTemplate) {
    return <div>Invalid report component</div>;
  }

  return (
    !loading && (
      <ReportTemplate
        {...rest}
        title={title}
        titleIcon={titleIcon}
        description={description}
        reportCompareAbility={reportCompareAbility}
      >
        <ReportComponent {...matchProps} />
      </ReportTemplate>
    )
  );
};

export default ReportWrapper;
