import React from "react";
import TextareaField from "../../../../components/share/fields/TextareaField";

const ApiErrorSettings = ({
  apiErrorEndpoints,
  apiErrorEndpointsWithPayload,
  handleApiErrorEndpoints,
  handleApiErrorEndpointsWithPayload,
  isApiErrorEndpointsValid,
  isApiErrorEndpointsWithPayload,
}) => {
  return (
    <>
      <TextareaField
        text={apiErrorEndpoints.join("\n")}
        placeholder=""
        label="Endpoints To Monitor"
        id="api-error-endpoint"
        helpText="Every API calls to an URL starting with one of these items will be monitored. Errors will be captured."
        handleChange={handleApiErrorEndpoints}
        isValid={isApiErrorEndpointsValid}
      />
      <TextareaField
        text={apiErrorEndpointsWithPayload.join("\n")}
        placeholder=""
        label="Endpoints To Monitor With Payload"
        id="api-error-endpoint-with-payload"
        helpText="Every API calls to an URL starting with one of these items will be monitored. Errors will be captured with user's payload."
        handleChange={handleApiErrorEndpointsWithPayload}
        isValid={isApiErrorEndpointsWithPayload}
      />
    </>
  );
};

export default ApiErrorSettings;
