import React from "react";
import "./style.scss";
import { FaTimes } from "react-icons/fa";
function SegmentButton(props) {
  const { id, label, className, handleClickDelete, handleClick } = props;

  function onClickDelete(e) {
    handleClickDelete(id);
  }

  function onClick(e) {
    e.preventDefault();
    handleClick(id);
  }

  return (
    <button key={id} className={`btn-chips ${className}`}>
      <span className="text" onClick={onClick}>
        {label}
      </span>
      <span className="icon" onClick={onClickDelete}>
        <FaTimes />
      </span>
    </button>
  );
}

SegmentButton.propTypes = {};
SegmentButton.defaultProps = {};

export default SegmentButton;
