import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import ReactTablePagination from "./../../../../components/app/ReactTablePagination";
import moment from "moment";
import uiApi from "../../../../api/api-ui";
import "./style.scss";

function AccountUsers(props) {
  const accountId = props.match.params.accountId;

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  let searchTimeout = null;

  useEffect(() => {
    setIsLoading(true);

    uiApi.fetchAccountUsers(accountId).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });

    // Clear timeout when component is unmounted
    return () => {
      clearTimeout(searchTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Permission",
        accessor: "role",
      },
      {
        Header: "Last Login",
        accessor: "lastLoginTimestamp",
        Cell: ({ value }) => {
          return value ? moment(value).format("MMM D YYYY H:m:s") : "N/A";
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <>
              <Link
                className="btn btn-primary btn-sm"
                to={`/account/${accountId}/users/${value}`}
              >
                Edit User
              </Link>
            </>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [],
        sortBy: [{ id: "name", desc: false }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleChangeKeyword = (e) => {
    const value = e.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card shadow">
          <div className="card-body">
            <div className="mb-3 d-flex">
              <div className="form-inline mr-auto">
                <div className="form-group">
                  <input
                    placeholder="Keyword search"
                    className="form-control form-control-sm"
                    type="search"
                    onChange={handleChangeKeyword}
                  />
                </div>
              </div>
              <Link
                className="btn btn-primary btn-sm"
                to={`/account/${accountId}/users/add`}
              >
                Add User
              </Link>
            </div>

            <table className="table table-hover ui-table users-table">
              <thead className=" thead-dark">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading && (
                  <tr>
                    <td colSpan="6" align="center">
                      Loading Users...
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} data-rowid={row.values.id}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <ReactTablePagination
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AccountUsers.propTypes = {};
AccountUsers.defaultProps = {};

export default AccountUsers;
