import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { UserDataContext } from "../../../../components/app/UserData";
import { useReports } from "../../../../components/app/QuickReport/hooks";
import ReportList from "./ReportList";
import { defaultSortOrder } from "../../../../utils";
import CollapsableCard from "../../../../components/app/CollapsableCard";
import { Button } from "../../../../components/share/InsightUI";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import { reportTypes } from "../../../../helpers/reportTypes";
import EmptyReportChart from "./components/EmptyReportChart";

const Reports = (props) => {
  const { authUser } = useContext(AuthContext);
  const { profileUsers, reports } = useContext(UserDataContext);
  const columns = [
    { label: "Name" },
    { label: "Report Type" },
    { label: "Range, Segments & Filters" },
    { label: "Created by" },
    { label: "Updated" },
    { label: "Report Actions" },
  ];
  const [reportListData, setReportListData] = useState([]);
  const {
    handleClickReport,
    handleDeleteButton,
    handleConfirmReportDelete,
    isDeleteModalHidden,
    setIsDeleteModalHidden,
    isDeleting,
  } = useReports(props);

  // Real-time temp filters state
  const [filters, setFilters] = useState({
    keyword: undefined,
    owner: undefined,
    reportType: undefined,
  });

  // Applied filters state
  const [appliedFilters, setAppliedFilters] = useState({
    keyword: undefined,
    owner: undefined,
    reportType: undefined,
  });

  // Handles changes to any of the filter inputs: keyword, owner, reportType
  const handleChangeFilters = (filterName) => (e) => {
    setFilters({ ...filters, [filterName]: e.target.value });
  };

  // Apply all filters
  const handleApplyFilters = () => {
    const updatedFilters = { ...filters };
    setAppliedFilters(updatedFilters);
  };

  // Set report list data based on applied filters
  useEffect(() => {
    let filteredReports = [...reports];

    if (reports?.length) {
      if (appliedFilters.keyword) {
        filteredReports = filteredReports.filter((report) =>
          report.name
            .toLowerCase()
            .includes(appliedFilters.keyword.toLowerCase())
        );
      }
      if (appliedFilters.owner) {
        filteredReports = filteredReports.filter(
          (report) => report.ownerId === appliedFilters.owner
        );
      }
      if (appliedFilters.reportType) {
        filteredReports = filteredReports.filter(
          (report) => report.reportType === appliedFilters.reportType
        );
      }

      setReportListData(filteredReports);
    }
  }, [appliedFilters, reports]);

  return (
    <div className="mb-4">
      <CollapsableCard title="Filter by">
        <div className="row">
          <div className="col-md-12">
            <div className="form-inline">
              <div className="form-group">
                <input
                  placeholder="Keyword search"
                  className="form-control form-control-sm"
                  type="search"
                  onChange={handleChangeFilters("keyword")}
                />
              </div>
              <div className="form-group ml-4">
                <label>Creator</label>
                <select
                  className="form-control form-control-sm mx-2"
                  onChange={handleChangeFilters("owner")}
                >
                  <option value="">All</option>
                  <option value={authUser.user.uid}>Me</option>
                  {profileUsers.all.length &&
                    profileUsers.all.map((uid) => {
                      if (uid === authUser.user.uid) {
                        return null;
                      }
                      const user = profileUsers.byId[uid];
                      return (
                        <option value={uid} key={uid}>
                          {user.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group ml-4">
                <label>Report Type</label>
                <select
                  className="form-control form-control-sm mx-2"
                  onChange={handleChangeFilters("reportType")}
                >
                  <option value="">All</option>
                  {Object.entries(reportTypes).map(([key, value]) => (
                    <option value={key} key={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <hr />
            <Button
              style={{ width: 120 }}
              className="apply-button"
              variant="primary"
              size="small"
              onClick={handleApplyFilters}
            >
              Apply
            </Button>
          </div>
        </div>
      </CollapsableCard>
      <div className="list-wrapper">
        {reportListData.length > 0 ? (
          <ReportList
            columns={columns}
            reportListData={reportListData.sort(defaultSortOrder("name"))}
            onReport={handleClickReport}
            handleDeleteButton={handleDeleteButton}
            handleConfirmReportDelete={handleConfirmReportDelete}
            isDeleteModalHidden={isDeleteModalHidden}
            setIsDeleteModalHidden={setIsDeleteModalHidden}
            isDeleting={isDeleting}
          />
        ) : (
          <EmptyReportChart />
        )}
      </div>
    </div>
  );
};

export default Reports;
