// Helper function to get the transform scale from a CSS style
export const getTransformScale = (element) => {
  const transformStyle = window
    .getComputedStyle(element)
    .getPropertyValue("transform");
  if (transformStyle && transformStyle !== "none") {
    const values = transformStyle.split("(")[1].split(")")[0].split(",");
    return parseFloat(values[0]);
  }
  return 1; // Default scale is 1 if no transform is applied
};
