import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  firebaseApp,
  getAuth,
  registerUserWithEmail,
  updateUser,
  addLog,
  sendEmailVerification,
  updateProfile,
} from "./../../../lib/firebase";
import { SIGN_IN } from "./../../../constants/log";
import {
  Button,
  Form,
  Input,
  Alert,
} from "./../../../components/share/InsightUI";
import withAuthMethods from "./../../../components/hoc/withAuthMethods";
import { signupFormValidator } from "./../../../helpers/formValidators";
import { Formik } from "formik";

import "./../common.scss";
import "./style.scss";

const SignUp = (props) => {
  const [isVerificationSent, setIsVerificationSent] = useState(false);

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    registerUserWithEmail(
      values.email,
      values.password,
      (response) => {
        const dt = new Date();
        const currentUser = getAuth(firebaseApp).currentUser;

        // Create users collection entry
        updateUser({
          name: values.name,
          email: values.email,
          lastLoginTimestamp: dt.getTime(),
          lastLoginTime: dt,
        });

        // Must set this up explicitly
        updateProfile(currentUser, { displayName: values.name });

        // Log initial sign in
        addLog(SIGN_IN, response.user.uid);

        // Send verification email for this user
        sendEmailVerification(currentUser)
          .then(() => {
            setSubmitting(false);
            setIsVerificationSent(true);
          })
          .catch((error) => {
            console.log(error);
          });
      },
      (error) => {
        setSubmitting(false);
        switch (error.code) {
          case "auth/email-already-in-use":
            setErrors({
              email: error.message,
            });
            break;
          default:
            break;
        }
      }
    );
  };

  return (
    <div className="page-container container">
      <Link className="main-logo" to="/">
        <img
          className=""
          srcSet="/images/logo@2x.png 2x, /images/logo.png 1x"
          src="/images/logo.png"
          alt=""
        />
      </Link>

      {isVerificationSent ? (
        <>
          <h1 className="h4 text-gray-900 mt-3 mb-4">Verify Your Email</h1>
          <Alert variant="success">
            A verification link was sent to the email address you provided
          </Alert>
        </>
      ) : (
        <>
          <h1 className="h4 text-gray-900 mt-4 mb-5">
            Get started with your account
          </h1>
          <div className="signup-form">
            <Formik
              initialValues={{
                email: "",
                name: "",
                password: "",
                password2: "",
              }}
              validate={signupFormValidator}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form
                  className="user"
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting || props.isBusy}
                >
                  {props.signinError && (
                    <Alert variant="danger">{props.signinError}</Alert>
                  )}
                  <Input
                    required
                    isInvalid={!!(touched.email && errors.email)}
                    invalidFeedback={errors.email}
                    className="form-control-user"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    value={values.email}
                  />
                  <Input
                    required
                    isInvalid={!!(touched.name && errors.name)}
                    invalidFeedback={errors.name}
                    className="form-control-user"
                    name="name"
                    placeholder="Full Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <Input
                    required
                    isInvalid={!!(touched.password && errors.password)}
                    invalidFeedback={errors.password}
                    className="form-control-user"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <Input
                    required
                    isInvalid={!!(touched.password2 && errors.password2)}
                    invalidFeedback={errors.password2}
                    className="form-control-user"
                    name="password2"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password2}
                  />

                  <p className="text-center my-4">
                    <small>
                      By clicking the "Sign Up!" button, you are creating an
                      Insightech account, and you agree to Insightech's {` `}
                      <a
                        target="_blank"
                        href="https://www.insightech.com/terms-conditions"
                      >
                        Terms of Usage
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href="https://www.insightech.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </small>
                  </p>

                  <Button
                    type="submit"
                    className="btn-user btn-block"
                    variant="primary"
                  >
                    Sign Up
                  </Button>
                  <hr />
                </Form>
              )}
            </Formik>
          </div>
          <p className="signin-text">
            <small>
              Already signed up? <Link to="/signin">Sign In</Link>
            </small>
          </p>
        </>
      )}
    </div>
  );
};

export default withAuthMethods(SignUp);
