import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function Input(props){
    const {
        isInvalid,
        invalidFeedback,
        size,
        label,
        inline,
        helptext,
        columnwidths,
        ...rest
    } = props;

    let classNames = props.className ? props.className : '';
    let inputClass = 'form-control';
    let fieldClass = 'form-group';
    let errorClass = isInvalid ? 'is-invalid' : '';
    let layoutClass = inline ? 'row' : '';
    let labelClass = inline ? `col-sm-${columnwidths[0]} col-form-label` : '';

    return (
        props.type === 'checkbox' ?
        <Checkbox {...props} /> :
        <div className={`${fieldClass} ${layoutClass}`}>
            {label &&
                <label className={`${labelClass}`} htmlFor={props.id}>
                    {props.required && <span className="required">*</span>}
                    {` `}
                    {label}
                </label>
            }
            {inline ?
                (
                    <div className={`col-sm-${columnwidths[1]}`}>
                        <input {...rest}
                            className={`${classNames} ${inputClass} ${errorClass}`} />
                        {(!isInvalid && helptext) &&
                            <small className="form-text">{helptext}</small>
                        }
                        {isInvalid &&
                            <div className="invalid-feedback">{invalidFeedback}</div>
                        }
                    </div>
                ) :
                <>
                    <input {...rest}
                        className={`${classNames} ${inputClass} ${errorClass}`} />
                    {(!isInvalid && helptext) &&
                        <small className="form-text">{helptext}</small>
                    }
                    {isInvalid &&
                            <div className="invalid-feedback">{invalidFeedback}</div>
                    }
                </>
            }
        </div>
    )
}

function Checkbox(props){
    const {label, inline, className, size, ...rest} = props;
    let sizeClass = '';
    let id = props.id ? props.id : props.name;
    switch(size){
        case 'small':
            sizeClass = 'small'
            break;
        default:
            break;
    }
    return (
        <div className={`form-group ${className}`}>
            <div className={`custom-control custom-checkbox ${sizeClass}`}>
                <input type="checkbox" className="custom-control-input" {...rest} id={id} />
                <label className="custom-control-label" htmlFor={id}>
                    {label}
                </label>
            </div>
        </div>
    )
}

Input.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    isInvalid: PropTypes.bool,
    invalidFeedback: PropTypes.string,
    inline: PropTypes.bool,
    columnwidths: PropTypes.array
}

Input.defaultProps = {
    type: 'text',
    inline: false,
    columnwidths: [6,6]
}

export default Input;