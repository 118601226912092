import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const FloatingLabelInput = (props) => {
  const {
    id,
    name,
    label,
    type,
    handleChange,
    selectedReport,
    action,
    required,
    errorMessage,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(selectedReport[name] || "");

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    // name prop: name of the input field. name | description
    let nameValue;

    if (action === "copy") {
      nameValue = `Copy of ${selectedReport[name]}`;
      handleChange({ target: { name, value: nameValue } });
    } else {
      nameValue = selectedReport[name] || "";
    }

    setValue(nameValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  return (
    <div
      className={`floating-label ${isFocused || value ? "filled" : ""} ${
        errorMessage ? "error" : ""
      }`}
    >
      <input
        id={id}
        name={name}
        type={type || "text"}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);
          setValue(e.target.value);
        }}
        value={value}
        required={required}
        aria-describedby={errorMessage ? `${id}-error` : undefined}
      />
      <label className={isFocused || value ? "active" : ""}>
        {label}
        {!required && <span className="optional-text"> Optional</span>}
      </label>
      {errorMessage && (
        <span id={`${id}-error`} className="error-message">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default FloatingLabelInput;

FloatingLabelInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedReport: PropTypes.object.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};
