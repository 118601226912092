import React, { Fragment } from "react";
import "./style.scss";
import moment from "moment";
import { Button } from "../../../../../../components/share/InsightechUI";
import {
  getFilterCount,
  seggregatePageContainers,
} from "../../../../../../utils";

//Helper functions
const formatDate = (date, rolling, rollingOffset) => {
  if (rolling) {
    return moment().subtract(rollingOffset, "d").format("MMM DD, YYYY");
  }
  return date ? moment(date.toDate()).format("MMM DD, YYYY") : "";
};

const renderDateRange = (report) => {
  const dateTags = [];
  const startDate = formatDate(
    report.startDate,
    report.rolling,
    report.rollingStart
  );
  const endDate = formatDate(report.endDate, report.rolling, report.rollingEnd);
  dateTags.push(`${startDate} - ${endDate}`);

  if (report?.comparison && report.comparison.type === "date") {
    const compareStartDate = formatDate(
      report.comparison.startDate,
      report.rolling,
      report.comparison.rollingStart
    );
    const compareEndDate = formatDate(
      report.comparison.endDate,
      report.rolling,
      report.comparison.rollingEnd
    );
    dateTags.push(`${compareStartDate} - ${compareEndDate}`);
  }

  return (
    <Fragment>
      <Button size="tag" color="secondary" id="date-tag">
        {dateTags[0]}
      </Button>
      {dateTags[1] && (
        <Button size="tag" color="orange" id="compare-date-tag">
          {dateTags[1]}
        </Button>
      )}
    </Fragment>
  );
};

const renderSegmentsName = (report, segments) => {
  const segmentNames = report.segments
    .filter((segmentId) => segments[segmentId]) // Filter out deleted segments
    .map((segmentId) => segments[segmentId].name);

  const compareSegmentNames =
    report.comparison?.type === "segment"
      ? report.comparison?.segments
          .filter((segmentId) => segments[segmentId])
          .map((segmentId) => segments[segmentId].name)
      : null;
  // Get compared segments is empty case
  const compareToAllTraffic =
    report.comparison?.type === "segment" &&
    !report.comparison?.segments.length;

  return (
    <Fragment>
      {segmentNames?.length > 0 &&
        segmentNames.map((name, idx) => (
          <Button size="tag" color="success" key={idx}>
            {name}
          </Button>
        ))}
      {!!compareSegmentNames &&
        compareSegmentNames.map((name, idx) => (
          <Button size="tag" color="orange" key={idx}>
            {name}
          </Button>
        ))}
      {!!compareToAllTraffic && (
        <span className="tag-plain-text">
          Compare To: All Traffic (no segment selected)
        </span>
      )}
    </Fragment>
  );
};

const renderFiltersCount = (report) => {
  const filters = JSON.parse(report.filter);
  const [includedPageContainers, excludedPageContainers] =
    seggregatePageContainers(filters.pageContainers);
  const userConditions = filters.userConditions;
  const userCount = getFilterCount("user", userConditions);
  const eventCount = getFilterCount("event", includedPageContainers);
  const excludedEventCount = getFilterCount(
    "excludedEvent",
    excludedPageContainers
  );
  return (
    <Button size="tag" color="secondary">
      Filters: {userCount + eventCount + excludedEventCount}
    </Button>
  );
};

const DataTags = (props) => {
  const { report, segments } = props;
  return (
    <span className="data-tags">
      {renderDateRange(report)}
      {renderFiltersCount(report)}
      {renderSegmentsName(report, segments)}
    </span>
  );
};

export default DataTags;
