import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../share/InsightechUI";
import InsightechIcon from "../../share/InsightechIcon";

const ReportsDropdownButton = (props) => {
  const { toggleReportsDropdown, isVisible } = props;
  const BookmarkIcon = () => <InsightechIcon name="Reports" />;
  const DropdownArrowIcon = () => (
    <InsightechIcon name={isVisible ? "ChevronUp" : "ChevronDown"} />
  );

  return (
    <Button
      color="outlined-plain"
      size="chip"
      icon={BookmarkIcon}
      onClick={toggleReportsDropdown}
      dropdownArrow={DropdownArrowIcon}
    >
      View reports
    </Button>
  );
};

ReportsDropdownButton.propTypes = {
  toggleReportsDropdown: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default ReportsDropdownButton;
