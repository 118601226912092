export const renderEventBackgroundColor = (eventType) => {
  switch (eventType) {
    case 97:
      return "table-warning";
    case 98:
      return "bg-warning";
    default:
      return "";
  }
};
