import React from "react";
import "./style.scss";

const TextareaField = ({
  id,
  text = "",
  label = "",
  placeholder = "",
  helpText = "",
  readOnly = false,
  plainText = false,
  rows = 5,
  handleChange,
  isValid = true,
  disabled,
}) => {
  return (
    <div className="form-group row">
      <label htmlFor={id} className="col-sm-2 col-form-label">
        {label}
      </label>
      <div className="col-sm-10 col-md-4 col-lg-6">
        <textarea
          type="text"
          rows={rows}
          className={
            plainText
              ? `form-control-plaintext ${isValid ? "" : "selector-invalid"}`
              : `form-control ${isValid ? "" : "selector-invalid"}`
          }
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          value={text}
          onChange={(e) => {
            if (typeof handleChange === "function") {
              handleChange(e);
            }
          }}
        ></textarea>
        {helpText !== "" && (
          <small id={id + "help"} className="form-text text-muted">
            {helpText}
          </small>
        )}
      </div>
    </div>
  );
};

export default TextareaField;
